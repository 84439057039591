import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filterState: any = {};
  constructor() { }


  private entityName = signal<string>('');

  // Getter to access the current value
  get currentEntityName() {
    return this.entityName();
  }

  // Method to update the string
  setEntityName(newString: string): void {
    this.entityName.set(newString);
  }


  setFilterState(key: string, state: any): void {
    this.filterState[key] = state;
    console.log(this.filterState)
  }

  getFilterState(key: string): any {
    return this.filterState[key];
  }

  clearFilterState(key: string): void {
    delete this.filterState[key];
  }
}
