<p-dialog header="{{lbl_update}} {{entityName}}" (onHide)="onHide($event)" [(visible)]="visible" [style]="{ width: '55vw' }">
    <p class="m-0 pt-2 pb-4">{{'bulk_leave_sub_header' | translate}} {{bulkRowCount}} {{'bulk_leave_sub_header2' | translate}}</p>
    <div class="grid">
      <div class="col-6 md:col-6 lg:col-6">
        <label class="text-color font-bold" translate>lbl_field_upper</label><span class="pl-1 text-color-secondary"
          translate>lbl_details</span>
      </div>
      <div class="col-6 md:col-6 lg:col-6">
        <label class="text-color font-bold" translate>lbl_value_upper</label>
      </div>
    </div>
    <p-divider></p-divider>
    <form [formGroup]="bulkUpdateForm">
      <div formArrayName="filterFieldControls">
        @for (data of formArray.controls; track data; let idx = $index) {
          <div class="grid" [formGroupName]="idx">
            <div class="col-11 grid">
              <div class="col-6 md:col-6 lg:col-6">
                <p-dropdown formControlName="fieldName" [style]="{'width':'100%'}" [options]="data['controls']?.listOfStatus.value" optionLabel="attributeValue"
                  (onChange)="filterStatus($event, idx)" optionDisabled="isDisable" [placeholder]="'lbl_select' | translate">
                </p-dropdown>
              </div>
              <div class="col-6 md:col-6 lg:col-6">
                <p-dropdown formControlName="replaceWith" [style]="{'width':'100%', 'height':'100%'}" (onChange)="removeAllChange($event, idx)"
                  optionDisabled="disabled" [options]="replaceWithDropdown" [ngModel]="selectedItems" optionLabel="label">
                  <ng-template let-item pTemplate="selectedItem">
                    @if (selectedItems) {
                      <div class="flex align-items-center gap-2">
                        <i class="{{item.icon}} wh-16"></i>
                        <div>{{ item.label }}</div>
                      </div>
                    }
                  </ng-template>
                  <ng-template let-obj pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <i class="{{obj.icon}} wh-16"></i>
                      <div>{{ obj.label }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
            @if (formArray.length > 1) {
              <div class="col-1 mt-2">
                <div class="{{miIcons.OFF_CLOSE}} wh-24 cursor-pointer" (click)="deleteMultipleColumn(data['controls']?.fieldName.value, idx)"></div>
              </div>
            }
            @if (data?.get('fieldValue')?.enabled) {
              <div class="col-11 grid">
                <div class="col-6 md:col-6 lg:col-6 col-offset-6">
                  <!-- <p-dropdown formControlName="fieldValue" [style]="{'width':'100%'}" [options]="replaceWithStatusValues" optionLabel="label" [placeholder]="'lbl_select' | translate"></p-dropdown> -->
                  <p-dropdown
                    [id]="idx"
                    formControlName="fieldValue"
                    (onChange)="onFiledValueChange($event, idx)"
                    [style]="{'width':'100%'}"
                    [options]="data['controls']?.presetValues.value"
                    optionLabel="labelKey"
                    optionValue="labelValue"
                    [placeholder]="'lbl_select' | translate">
                </p-dropdown>
                </div>
              </div>
            }
            <div class="col-1">
            </div>
            <div class="col-12">
              <p-divider></p-divider>
            </div>
          </div>
        }
        @if (formArray.length < bulkUpdateMaxCount) {
          <div>
            <button type="submit"  [disabled]="!bulkUpdateForm.valid" class="add-btn bg-white text-primary border-0 cursor-pointer" pButton icon="{{miIcons.PLUS}} inline-block wh-10"
            [label]="'lbl_add_field' | translate" text="true" severity="info" (click)="addMultipleColumn($event)"></button>
            <span>({{'lbl_max' | translate}} {{bulkUpdateMaxCount}})</span>
          </div>
        }
  
        <div class="col-12 relative text-right mb-2">
          <button pButton class="mr-2 surface-ground text-color-secondary border-none hover:surface-200" (click)="onHide($event)" [label]="'cancel' | translate"></button>
          <p-button type="submit"  [disabled]="!bulkUpdateForm.valid"  [label]="'lbl_continue_to_review' | translate" (click)="confirmPopupShow()"></p-button>
        </div>
      </div>
    </form>
  </p-dialog>
  
  <p-dialog [header]="reviewHeader" [(visible)]="isVisible" [style]="{ width: '55vw' }">
    <p class="m-0 pt-2 pb-4">{{'review_bulk_leave_sub_header' | translate}} {{bulkRowCount}} {{'review_bulk_leave_sub_header2' | translate}}</p>
    <p-divider></p-divider>
    <p-table [value]="updateFormData" class="review-table" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th translate>lbl_field</th>
          <th translate>lbl_action</th>
          <th translate>lbl_value</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{data?.fieldName?.attributeValue}}</td>
          <td>{{data?.replaceWith?.label}}</td>
          <td>{{data?.fieldValue === replaceWithDropdown[1]?.label ? '' : data?.fieldValue}}</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="col-12 relative text-right mb-2">
      <button pButton class="mr-2 surface-ground text-color-secondary border-none hover:surface-200" (click)="confirmPopupHide()" [label]="'cancel' | translate"></button>
      <p-button (click)="updateData()">{{'btn_update_vehicles' | translate}} {{bulkRowCount}} {{ btnUpdate }}</p-button>
    </div>
  </p-dialog>