
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AttributeData, entityResponse } from '../../../vehicles/modal/attributeModal';
import { EntityService } from '../../../shared/services/entity.service';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ConfigService } from '../../../shared/services/config.service';

@Component({
  selector: 'app-add-passengers',
  templateUrl: './add-passengers.component.html',
  styleUrls: ['./add-passengers.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule]
})
export class AddPassengersComponent implements OnInit {
  activeIndex: number = 0;
  data: AttributeData;
  passengerAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  passengerAttributeLength: number | undefined;
  nextBtnLabel: string | undefined;
  previousBtnLabel: string | undefined;
  attributeValues: any[] = [];

  privilegeSettingsTab: any = AppSettings.TABS.PRIVILEGE_SETTINGS;
  passengerAddressEntityCode: any;
  bookingTimeRestrictionEntityCode: any;
  passengerEntityId: any;
  addressEntityIdList: any[] = [];
  bookingTimeRestrictionEntityIdList: any[] = [];
  passengerData: any;
  bookingTimeRestriction: any[] = [];
  privilegeData: any;
  passengerEntityData: any;
  stayHomeAddressData: any[] = [];
  prefixForOneToManyRelatedFields: any = '_';
  bookingTimeRestrictionFields: any = 2;
  startIndexToPickOneRecordFromBookingTimeRestrictionArray: any = 0;
  endIndexToPickOneRecordFromBookingTimeRestrictionArray: any = 0;
  startIndexToPickOneRecordFromStayHomeAddressArray: any = 0;
  endIndexToPickOneRecordFromStayHomeAddressArray: any = 0;
  stayHomeAddressFields: any = 10;
  savePassengerLabel: any;
  edit: boolean = false;
  passengerDetails: any;
  addressPresetValues = [];
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST;
  attributeIdForStayHomeAddress: any;
  attributeIdForBookingTimeRestriction: any;
  disableSaveBtn: boolean = false;
  selectLocationAddressAttribute: any;
  relationshipDataForAddress: any[] = [];
  relationshipDataForBookingTimeRestriction: any[] = [];
  saveUpdateBtnLabel: string;
  showAddress: boolean = false;

  constructor(public passengerService: EntityService,
    private router: Router,
    public cs: CommonBindingDataService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.passengerEntityId = this.route.snapshot.paramMap.get('id');
    this.setLabels();
    this.setRoutePath();
    this.setAppSettings();
    this.loadAttributeData();
  }

  private setLabels() {
    this.nextBtnLabel = this.cs.getLabel('lbl_next');
    this.previousBtnLabel = this.cs.getLabel('lbl_cancel');
    this.savePassengerLabel = this.passengerEntityId ? this.cs.getLabel('label_update') : this.cs.getLabel('label_save');
    this.saveUpdateBtnLabel = this.passengerEntityId ? this.cs.getLabel('label_update') : this.cs.getLabel('label_save');
  }

  private setRoutePath() {
    this.routePath = [
      {
        label: this.passengerEntityId ? this.cs.getLabel('passengers.edit_passengers_lbl') : this.cs.getLabel('passengers.add_passengers_lbl'),
        routerLink: '/app/passengers/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px', 'color': '#495057' }
      },
      {
        label: this.activeIndex === 0 ? this.cs.getLabel('passengers.lbl_details') : this.cs.getLabel('passengers.lbl_privilege_settings'),
        routerLink: '../add',
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  private setAppSettings() {
    this.moduleName = AppSettings.ENTITY_TYPE.PASSENGER;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadAttributeData() {
    forkJoin([
      this.passengerEntityId ? this.passengerService.getEntity(this.passengerEntityId, AppSettings.ENTITY_CODE.PASSENGERS) : of(null)
    ]).pipe(
      tap(([entityRes]) => {
        if (entityRes) {
          const data = entityRes;
          this.passengerDetails = entityRes;
          this.attributeValues = this.cs.getOrgAttributeValues(data);
          this.parseAttributeValues();
          this.getStayHomeAddress();
          this.getGetBookingTimeRestrictionData();
        } else {
          this.showAddress = true;
        }
        this.getAttributes();
      })
    ).subscribe();
  }

  getAttributes() {
    this.passengerService.getAttributeDefinition(AppSettings.ENTITY_TYPE.PASSENGER, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.passengerAddressEntityCode = this.data.relations.find(ele => ele.otherEntityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS).otherEntityCode;
        this.bookingTimeRestrictionEntityCode = this.data.relations.find(ele => ele.otherEntityCode === AppSettings.ENTITY_CODE.PASSENGER_BOOKING_TIME_RESTRICTION).otherEntityCode;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.passengerAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.passengerAttributeLength = this.passengerAttributeData.tabs.length;
        this.setClassForTabPrivilegeSettings();
        this.attributeIdForStayHomeAddress = this.getAttributeIdForGroupCode(AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
        this.attributeIdForBookingTimeRestriction = this.getAttributeIdForGroupCode(AppSettings.GROUP_CODE.BOOKING_TIME_RESTRICTION);
        this.getLocationAddressAttribute();
      }
    });
  }

  getLocationAddressAttribute() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          this.selectLocationAddressAttribute = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
        }
      });
    });
    let stayHomeAddressGroup = this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
    const index = stayHomeAddressGroup.fields.findIndex(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
    stayHomeAddressGroup.fields.splice(index, 1);
  }


  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH,
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.VOID_DATE_TIME
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const addressIdArray = this.passengerDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.FIELDS.STAY_HOME_ADDRESS_SECTION);
    const bookingTimeRestrictionArray = this.passengerDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.FIELDS.BOOKING_TIME_RESTRICTION);
    this.addressEntityIdList = (addressIdArray && addressIdArray.attributeValue.length > 0) ? addressIdArray.attributeValue : [];
    this.bookingTimeRestrictionEntityIdList = (bookingTimeRestrictionArray && bookingTimeRestrictionArray.attributeValue.length > 0) ? bookingTimeRestrictionArray.attributeValue : [];
  }



  getStayHomeAddress() {
    this.defaultLocationList = [];
    if (this.addressEntityIdList.length > 0) {
      const that = this;
      this.addressEntityIdList.forEach(function (entityId, i) {
        that.passengerService.getEntity(entityId, AppSettings.ENTITY_CODE.PASSENGER_ADDRESS).subscribe((res: any) => {
          that.setAttributeValuesForStayHomeAddressEditMode(res, i);
          const field = res.attributeCodeValueDtoList.find(element => element.attributeCode === AppSettings.FIELDS.SELECT_LOCATION_TYPE);
          const label = field ? field.attributeValue : '';
          that.defaultLocationList[i] = { labelKey: label, labelValue: label, entityId: that.addressEntityIdList[i], index: i };
          if (i === that.addressEntityIdList.length - 1) {
            that.showAddress = true;
          }
        });
      });
    }
  }

  getGetBookingTimeRestrictionData() {
    if (this.bookingTimeRestrictionEntityIdList.length > 0) {
      this.edit = true;
      let index = 1000;
      for (let i = 0; i < this.bookingTimeRestrictionEntityIdList.length; i++) {
        this.passengerService.getEntity(this.bookingTimeRestrictionEntityIdList[i], AppSettings.ENTITY_CODE.PASSENGER_BOOKING_TIME_RESTRICTION).subscribe((res: any) => {
          this.setAttributeValuesForBookingTimeRestrictionsEditMode(res, index);
          index = index + 1;
        });
      }
    }
  }

  setAttributeValuesForStayHomeAddressEditMode(data, addressCount) {
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[addressCount + '_' + labelsObj.attributeCode] = labelsObj.attributeValue;
    }
  }

  setAttributeValuesForBookingTimeRestrictionsEditMode(data, addressCount) {
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[addressCount + '_' + labelsObj.attributeCode] = labelsObj.attributeCode === AppSettings.FIELDS.TIME_RESTRICTION ? new Date(labelsObj.attributeValue) : labelsObj.attributeValue;
    }
  }


  setClassForTabPrivilegeSettings() {
    if (!this.passengerAttributeData || !this.passengerAttributeData.tabs) {
      return;
    }
    const privilegeTab = this.passengerAttributeData.tabs.find(tab => tab.tabCode === 'privilege_settings');
    if (!privilegeTab || !privilegeTab.groups) {
      return;
    }
    privilegeTab.groups.forEach(group => {
      group.activeStatus = (group.code === 'app_access') ? 'active' : 'inactive';
      group.activeLabel = (group.code === 'app_access') ? 'active' : 'inactive';
    });
  }

  scrollToGroup(event, groupCode) {
    if (event === 1) {
      document.getElementById('scrollable').scrollTo(0, 150);
    } else if (event === 0) {
      document.getElementById('scrollable').scrollTo(0, 0);
    }
    document.getElementById(event).scrollIntoView();
    this.setActiveClassForGroupPrivilegeSettings(groupCode);
  }

  setActiveClassForGroupPrivilegeSettings(groupCode) {
    const privilegeTab = this.passengerAttributeData.tabs.find(tab => tab.tabCode === 'privilege_settings');
    if (!privilegeTab || !privilegeTab.groups) {
      return;
    }

    privilegeTab.groups.forEach(group => {
      group.activeStatus = (group.code === groupCode) ? 'active' : 'inactive';
      group.activeLabel = (group.code === groupCode) ? 'active' : 'inactive';
    });
  }

  onSavePrivilegeSettings(event) {
    this.disableSaveBtn = true;
    setTimeout(() => {
      this.disableSaveBtn = false;
    }, 500);

    if (this.passengerEntityId) {
    this.bookingTimeRestriction = [];
    this.privilegeData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    const privilegeData = event;
    const dateIds = [
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.VOID_DATE_TIME,
    ];
    for (const [key, value] of Object.entries(privilegeData)) {
      let attributeValue = value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      const arr = key.split('_');
      if (parseInt(arr[0]) >= 0) {
        this.setAttributeValuesForBookingTimeRestriction(key, attributeValue);
      } else {
        this.setAttributeValuesForPrivilegeSetting(key, attributeValue);
      }
    }

    this.bookingTimeRestriction = this.cs.mapAttributeIdsForRelatedData(this.bookingTimeRestriction, this.passengerAttributeData.tabs);
    this.privilegeData.data = this.cs.mapAttributeIds(this.privilegeData.data, this.passengerAttributeData.tabs);

    if (this.bookingTimeRestriction.length > 0) {
      this.saveBookingTimeRestriction();
    } else {
      this.savePrivilegeDataToDb();
    }
  } else {
    this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('passengers.please_fill_mandatory_data_in_details_tab') });
    this.activeIndex = 0;
    this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
  }
  }

  setAttributeValuesForBookingTimeRestriction(key, attributeValue) {
    const attributeCode = key.substring(key.indexOf('_') + 1);
    if (attributeValue) {
      const value = attributeCode === AppSettings.FIELDS.TIME_RESTRICTION ? new Date(attributeValue).getTime() : attributeValue;
      this.bookingTimeRestriction.push({ attributeCode, attributeValue: value });
    }
  }

  setAttributeValuesForPrivilegeSetting(key, attributeValue) {
    if (attributeValue) {
      this.privilegeData.data.push({
        attributeCode: key,
        attributeValue
      });
    }
  }

  saveBookingTimeRestriction() {
    this.bookingTimeRestriction = this.cs.mapAttributeIds(this.bookingTimeRestriction, this.passengerAttributeData.tabs);
    const { countryCode } = this.country[0] || {};
    const { entityRelationshipConfigId, otherEntityId } = this.passengerAttributeData?.tabs[1]?.groups.find(ele => ele.code === 'booking_time_restriction')?.relation || {};

    const numberOfBookingTimeRestrictionRecordsAddedByUser = this.bookingTimeRestriction.length / this.bookingTimeRestrictionFields;
    this.startIndexToPickOneRecordFromBookingTimeRestrictionArray = 0;
    this.endIndexToPickOneRecordFromBookingTimeRestrictionArray = this.bookingTimeRestrictionFields;
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.bookingTimeRestrictionEntityCode
    }
    for (let i = 0; i < numberOfBookingTimeRestrictionRecordsAddedByUser; i++) {
      const bookingTimeRestriction = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.bookingTimeRestrictionEntityCode,
        countryCode: countryCode,
        languageCode: this.language[0].langCode,
        data: this.bookingTimeRestriction.slice(this.startIndexToPickOneRecordFromBookingTimeRestrictionArray, this.endIndexToPickOneRecordFromBookingTimeRestrictionArray),
      };
      this.startIndexToPickOneRecordFromBookingTimeRestrictionArray = this.endIndexToPickOneRecordFromBookingTimeRestrictionArray;
      this.endIndexToPickOneRecordFromBookingTimeRestrictionArray = this.endIndexToPickOneRecordFromBookingTimeRestrictionArray + this.bookingTimeRestrictionFields;
      if (this.bookingTimeRestrictionEntityIdList.length > 0) {
        this.saveAttributeDataForBookingTimeRestrictionEditMode(bookingTimeRestriction, this.bookingTimeRestrictionEntityIdList[i], i, numberOfBookingTimeRestrictionRecordsAddedByUser, entitiesData);
      } else {
        this.createEntityForBookingTimeRestriction(entitiesData, bookingTimeRestriction, i, numberOfBookingTimeRestrictionRecordsAddedByUser);
      }

    }
  }

  saveAttributeDataForBookingTimeRestrictionEditMode(bookingTimeRestriction, entityId, index, numberBookingTimeRestrictionData, entitiesData) {
    if (entityId) {
      this.passengerService.saveAttributeData(bookingTimeRestriction.entityCode, entityId, bookingTimeRestriction).subscribe((res: any) => {
        this.relationshipDataForBookingTimeRestriction.push(
          {
            entityRelationshipConfigId: this.passengerAttributeData.tabs[1].groups.find(ele => ele.code === 'booking_time_restriction').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberBookingTimeRestrictionData - 1)) {
          this.privilegeData.data.push({
            attributeId: this.attributeIdForBookingTimeRestriction,
            attributeValue: this.bookingTimeRestrictionEntityIdList
          });
          this.savePrivilegeDataToDb();
        }
      });
    } else {
      this.createEntityForBookingTimeRestriction(entitiesData, bookingTimeRestriction, index, numberBookingTimeRestrictionData);
    }
  }

  createEntityForBookingTimeRestriction(entitiesData, bookingTimeRestriction, index, numberOfBookingTimeRestrictionRecordsAddedByUser) {
    this.passengerService.createEntities(entitiesData.entityCode, entitiesData).subscribe({
      next: (res: entityResponse) => {
        const entityId = res.entityId;
        this.relationshipDataForBookingTimeRestriction.push(
          {
            entityRelationshipConfigId: this.passengerAttributeData.tabs[1].groups.find(ele => ele.code === 'booking_time_restriction').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        this.bookingTimeRestrictionEntityIdList.push(res.entityId);
        this.saveAttributeDataForBookingTimeRestrictionEditMode(bookingTimeRestriction, entityId, index, numberOfBookingTimeRestrictionRecordsAddedByUser, entitiesData);
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel(error) });
      }
    });
  }

  savePrivilegeDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.data.entityCode
    }
    this.privilegeData.relationshipData = this.relationshipDataForBookingTimeRestriction;
    if (this.passengerEntityId) {
      this.saveAttributeData(entitiesData.entityCode)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode) {
    this.passengerService.saveAttributeData(entityCode, this.passengerEntityId, this.privilegeData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.privilege_data_updated_successfully') });
      setTimeout(() => {
        this.router.navigate(['app/passengers']);
      }, 300);
    });
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.passengerService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.passengerEntityId = res.entityId;
      this.passengerService.saveAttributeData(entitiesData.entityCode, this.passengerEntityId, this.privilegeData).subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.privilege_data_saved_successfully') });
        setTimeout(() => {
          this.router.navigate(['app/passengers']);
        }, 300);
      });
    });
  }

  onSavePassenger(event) {
    this.disableSaveBtn = true;

    if (!this.country || !this.language) {
      console.error('Data, country, or language not initialized.');
      return;
    }

    this.stayHomeAddressData = [];
    this.passengerData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    const passengerFormData = event;
    for (let k = 0; k < this.defaultLocationList.length; k++) {
      passengerFormData[`${k}_${AppSettings.ADDRESS_FIELDS.SELECT_LOCATION_TYPE}`] = this.defaultLocationList[k].labelKey
    }
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH];
    for (const [key, value] of Object.entries(passengerFormData)) {
      let attributeValue = value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      const arr = key.split('_');
      if (parseInt(arr[0]) >= 0) {
        this.setAttributeValuesForStayHomeAddress(key, attributeValue);
      } else {
        this.setPassengerData(key, attributeValue);
      }
    }

    this.stayHomeAddressData = this.cs.mapAttributeIdsForRelatedData(this.stayHomeAddressData, this.passengerAttributeData.tabs);
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    this.setPassengerEntityData(this.data.entityCode);
   this.saveAddress();
  }

  setAttributeValuesForStayHomeAddress(key, attributeValue) {
    const keyId = key.substring(key.indexOf('_') + 1);
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_STAY_HOME_ADDRESS.includes(keyId)) {
      if(keyId === AppSettings.PASSENGER_ATTRIBUTE_CODE.SELECT_LOCATION_GEOLOCATION) {
          this.setStayHomeAddress(keyId, attributeValue?.geoLocation);
          this.stayHomeAddressData.push({
            attributeId: this.selectLocationAddressAttribute.attributeId,
            attributeValue: attributeValue?.address
          });
      } else {
        this.setStayHomeAddress(keyId, attributeValue);
      }
    }
  }

  setStayHomeAddress(keyId, attributeValue) {
    this.stayHomeAddressData.push({
      attributeCode: keyId,
      attributeValue: keyId === AppSettings.FIELDS.INSTRUCTIONS ? (attributeValue ? attributeValue : '   ') : (attributeValue ? attributeValue : '')
    });
  }

  setPassengerData(key, attributeValue) {
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_PASSENGER_DETAILS.includes(key)) {
      if (attributeValue) {
        this.passengerData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
  }

  setPassengerEntityData(entityCode) {
    this.passengerEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveAddress() {
    const numberOfStayHomeAddressRecords = this.stayHomeAddressData.length / this.stayHomeAddressFields;
    this.startIndexToPickOneRecordFromStayHomeAddressArray = 0;
    this.endIndexToPickOneRecordFromStayHomeAddressArray = this.stayHomeAddressFields;
    const addressEntitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.passengerAddressEntityCode
    }
    for (let i = 0; i < numberOfStayHomeAddressRecords; i++) {
      const address = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.passengerAddressEntityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: this.stayHomeAddressData.slice(this.startIndexToPickOneRecordFromStayHomeAddressArray, this.endIndexToPickOneRecordFromStayHomeAddressArray)
      };

      this.startIndexToPickOneRecordFromStayHomeAddressArray = this.endIndexToPickOneRecordFromStayHomeAddressArray;
      this.endIndexToPickOneRecordFromStayHomeAddressArray = this.endIndexToPickOneRecordFromStayHomeAddressArray + 9;
      if (this.addressEntityIdList.length > 0) {
        this.saveAddressAttribute(numberOfStayHomeAddressRecords, i, address, this.addressEntityIdList[i], addressEntitiesData);
      } else {
        this.createAndSaveAddressEntity(addressEntitiesData, i, numberOfStayHomeAddressRecords, address);
      }
    }
  }

  createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address) {
    this.passengerService.createEntities(addressEntitiesData.entityCode, addressEntitiesData).subscribe({
      next: (res: entityResponse) => {
        const entityId = res.entityId;
        this.addressEntityIdList.push(res.entityId);
        this.passengerService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
        });
        this.relationshipDataForAddress.push(
          {
            entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberOfAddress - 1)) {
          this.passengerData.data.push({
            attributeId: this.attributeIdForStayHomeAddress,
            attributeValue: this.addressEntityIdList
          });
          this.savePassengerToDb(this.passengerEntityData.entityCode)
        }
       
      },
      error: (error) => {
        console.error('something wrong occurred: ' + error);
      }
    });
  }

  saveAddressAttribute(numberOfAddress, index, address, entityId, addressEntitiesData) {
    if (entityId) {
      this.passengerService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
        this.relationshipDataForAddress.push(
          {
            entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberOfAddress - 1)) {
          this.passengerData.data.push({
            attributeId: this.attributeIdForStayHomeAddress,
            attributeValue: this.addressEntityIdList
          });
          this.savePassengerToDb(this.passengerEntityData.entityCode)
        }
      });
    } else {
      this.createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address);
    }

  }

  getAttributeIdForStayHomeAddress() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          this.attributeIdForStayHomeAddress = group.relation.ownerAttributeId;
        }
      });
    });
  }

  getAttributeIdForBookingTimeRestriction() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.BOOKING_TIME_RESTRICTION) {
          this.attributeIdForBookingTimeRestriction = group.relation.ownerAttributeId;
        }
      });
    });
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.passengerAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }


  savePassengerToDb(entityCode) {
    this.passengerData.relationshipData = this.relationshipDataForAddress;
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    if (this.passengerEntityId) {
      this.savePassengerApiCall(entityCode);
    } else {
      this.createPassengerEntity();
    }
  }

  savePassengerApiCall(entityCode) {
    this.passengerService.saveAttributeData(entityCode, this.passengerEntityId, this.passengerData).subscribe(result => {
      this.messageService.add({key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.lbl_passenger_saved_successfully') });
      setTimeout(() => {
        this.onNextClick();
      }, 500);
      this.disableSaveBtn = false;
    },
    (error) => {
      this.activeIndex--;
      this.disableSaveBtn = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
    })
  }

  createPassengerEntity() {
    this.passengerService.createEntities(this.passengerEntityData.entityCode, this.passengerEntityData).subscribe({
      next: (res: entityResponse) => {
        this.passengerEntityId = res.entityId;
        this.savePassengerApiCall(this.passengerEntityData.entityCode);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onCancel() {
    this.router.navigate(['app/passengers']);
  }

  onNextBtnClick() {
    const btn = document.getElementById(this.activeIndex === 0 ? 'details' : 'privilegeSettings');
    btn.click();
  }

  onPreviousClick() {
    this.router.navigate(['app/passengers']);
  }

  onNextClick() {
    if (this.activeIndex < this.getLastIndex()) {
      this.activeIndex++;
    }
    this.updateNextBtnLabel();
  }

  updateNextBtnLabel() {
    this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
  }

  onTabViewChange(event) {
    this.setRoutePath();
    this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  getLastIndex(): number {
    return this.passengerAttributeLength - 1;
  }
}
