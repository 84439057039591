import * as dayjs from 'dayjs';

export function setWeek(weekNumber: number, weekday: string[]): any[] {
    const weekDaysList = [];
    const startOfWeek = dayjs().startOf('week').add((weekNumber * 7), 'd');
    const currentDate = dayjs();
  
    for (let i = 0; i < 7; i++) {
      const day = dayjs(startOfWeek).add(i, 'd');
      weekDaysList.push({
        dayName: weekday[day.day()],
        day: day.date(),
        available: '31',
        dayOff: '04',
        date: day.valueOf(),
        isCurrent: day.isSame(currentDate, 'day')
      });
    }
    return weekDaysList;
  }
  
  export function generateTimeSlots(): string[] {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (const minute of ['00', '30']) {
        const formattedHour = (hour < 10 ? '0' : '') + hour;
        timeSlots.push(`${formattedHour}:${minute}`);
      }
    }
    return timeSlots;
  }
  