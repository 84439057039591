import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-hide-button",
  templateUrl: "./hide-button.component.html",
  standalone: true,
  imports: [MenuModule, ButtonModule],
})
export class HideButtonComponent implements OnInit {
  items: MenuItem[];
  @Input() cardId!: string;

  constructor(
    private hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.items = [
      {
        label: this.cs.getLabel("lbl_hide"),
        icon: "pi pi-eye-slash",
        command: () => this.onHideButtonClick(),
      },
    ];
  }

  onHideButtonClick() {
    this.hideService.toggleVisibility(this.cardId);
  }
}
