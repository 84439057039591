export interface RelatedDataItem {
    entityCode: string; // or whatever the actual type is
    // other properties can be defined here
}

export interface Data {
    relatedData?: RelatedDataItem[]; // optional array of RelatedDataItem
}

export interface RelatedDataSchema {
    [entityCode: string]: any; 
}

export interface VehicleResponse {
    tenantId:                  string;
    createdBy:                 string;
    updatedBy:                 string;
    createdAt:                 number;
    updatedAt:                 number;
    entityId:                  string;
    countryId:                 string;
    status:                    string;
    actionStatus:              string;
    publishedAt:               number;
    barcodeIdentifier:         null;
    entityCode:                string;
    attributeCodeValueDtoList: VehicleResponseAttributeCodeValueDtoList[];
    relatedData:               RelatedDatum[];
}

export interface VehicleResponseAttributeCodeValueDtoList {
    attributeId:        number;
    attributeValue:     string[] | number | string;
    attributeCode:      string;
    attributeValueHash: null;
    deleted:            boolean;
}

export interface RelatedDatum {
    tenantId:                  string;
    createdBy:                 string;
    updatedBy:                 string;
    createdAt:                 number;
    updatedAt:                 number;
    entityId:                  string;
    countryId:                 string;
    status:                    string;
    actionStatus:              string;
    publishedAt:               number;
    barcodeIdentifier:         null;
    entityCode:                string;
    attributeCodeValueDtoList: RelatedDatumAttributeCodeValueDtoList[];
    relatedData:               null;
}

export interface RelatedDatumAttributeCodeValueDtoList {
    attributeId:        number;
    attributeValue:     string[] | string;
    attributeCode:      string;
    attributeValueHash: null;
    deleted:            boolean;
}
