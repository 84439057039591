@if(hideService.getVisibilityState(cardId) | async){
<div class="card h-full shadow-card">
  <div class="flex justify-content-between align-items-center mb-3">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.open_issues
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>

  <div class="flex flex-row mt-6 mb-6">
    <div class="flex flex-column vehicle-assigned">
      <p class="unassignment-number">50</p>
      <p class="assignment-label" translate>dashboard.open</p>
    </div>
    <div class="flex flex-column vehicle-unassigned">
      <p class="overdue-number">50</p>
      <p class="assignment-label" translate>dashboard.overdue</p>
    </div>
  </div>
</div>
}
