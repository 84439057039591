import { AfterViewInit, ChangeDetectorRef, Component, input, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { DateYearComponent } from '../date-year/date-year.component';
import { MiInputWeightComponent } from '../mi-input-weight/mi-input-weight.component';
import { MiInputWidthHeightLengthComponent } from '../mi-input-width-height-length/mi-input-width-height-length.component';
import { MiInputComponent } from '../mi-input/mi-input.component';
import { MiRadioComponent } from '../mi-radio/mi-radio.component';
import { MiUploadFileComponent } from '../mi-upload-file/mi-upload-file.component';
import { MiYearFilterComponent } from './mi-year-filter/mi-year-filter.component';
import { MiMultiSelectFilterComponent } from './mi-multi-select-filter/mi-multi-select-filter.component';
import { InputCounterSpinnerComponent } from './input-counter-spinner/input-counter-spinner.component';
import { MiDaysMultiSelectComponent } from './mi-days-multi-select/mi-days-multi-select.component';
import { MiAutoCompleteComponent } from './mi-auto-complete/mi-auto-complete.component';
import { MiEntityAutoCompleteComponent } from './mi-entity-auto-complete/mi-entity-auto-complete.component';
import { MiGeoFenceAutoCompleteComponent } from './mi-geo-fence-auto-complete/mi-geo-fence-auto-complete.component';


@Component({
  selector: 'app-mi-filter-fields',
  standalone: true,
  templateUrl: './mi-filter-fields.component.html',
  styleUrl: './mi-filter-fields.component.scss'
})

export class MiFilterFieldsComponent implements AfterViewInit {

  supportedMiComponents = [
    {
      name: 'text',
      component: MiInputComponent
    },
    {
      name: 'inputString',
      component: MiAutoCompleteComponent
    },
    {
      name: 'inputAutocomplete',
      component: MiEntityAutoCompleteComponent
    },    
    {
      name: 'inputId',
      component: MiEntityAutoCompleteComponent
    },    
    {
      name: 'inputInt',
      component: MiInputComponent
    },
    {
      name: 'number',
      component: MiInputComponent
    },
    {
      name: 'inputWeight',
      component: MiInputWeightComponent
    },
    {
      name: 'select',
      component: MiMultiSelectFilterComponent
    }, {
      name: 'selectWithOther',
      component: MiMultiSelectFilterComponent
    },
    {
      name: 'selectDependOnWithOther',
      component: MiMultiSelectFilterComponent
    },    
    {
      name: 'entitySelect',
      component: MiEntityAutoCompleteComponent
    },
    {
      name: 'radio',
      component: MiRadioComponent
    },
    {
      name: 'date',
      component: DateYearComponent
    },
    {
      name: 'dateYear',
      component: MiYearFilterComponent
    },
    {
      name: 'checkbox',
      component: MiMultiSelectFilterComponent
    },
    {
      name: 'checkboxesWithOther',
      component: MiMultiSelectFilterComponent
    }, {
      name: 'tags',
      component: MiAutoCompleteComponent
    }, {
      name: 'inputColor',
      component: MiMultiSelectFilterComponent
    }, {

      name: 'checkBoxWithAddNew',
      component: MiEntityAutoCompleteComponent
    },{

      name: 'daysOfWeek',
      component: MiDaysMultiSelectComponent
    },
    {
      name: 'fileImg',
      component: MiUploadFileComponent
    }, {
      name: 'fileImgPdf',
      component: MiUploadFileComponent
    },
    {
      name: 'geofence',
      component: MiGeoFenceAutoCompleteComponent
    }, {
      name: 'documentTypeNoStartEndDateFiles',
      component: MiInputComponent
    }, {
      name: 'inputPressure',
      component: MiInputComponent
    }, {
      name: 'inputDiameter',
      component: MiInputComponent
    }, {
      name: 'inputMilege',
      component: MiInputComponent
    }, {
      name: 'inputMilege',
      component: MiInputComponent
    }, {
      name: 'input3Dimentions',
      component: MiInputComponent
    }, {
      name: 'inputVolume',
      component: MiInputComponent
    }, {
      name: 'inputWidthHeightLength',
      component: MiInputWidthHeightLengthComponent
    }, {
      name: 'inputVolumeLiquid',
      component: MiInputComponent
    }, {
      name: 'counter',
      component: InputCounterSpinnerComponent

    }
  ]
  @ViewChild('miInputContainer', { read: ViewContainerRef }) miInputContainer!: ViewContainerRef;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() attributeValues: any;
  docType: any;

  constructor(private cd: ChangeDetectorRef,
    public cs: CommonBindingDataService) {
  }

  ngAfterViewInit(): void {
    this.registerMiField();
  }

  private registerMiField() {
    this.miInputContainer.clear();
    const componentInstance = this.getComponentByType(this.field.inputCode)
    const miComponent = this.miInputContainer.createComponent(componentInstance)
    miComponent.setInput('field', this.field);
    miComponent.setInput('attributeLabels', this.attributeLabels);
    miComponent.setInput('moduleName', this.moduleName);
    miComponent.setInput('attributeValues', this.attributeValues);
    if (this.field.inputCode === 'fileImgPdf' || this.field.inputCode === 'fileImg') {
      this.docType = this.field.inputCode === 'fileImgPdf' ? AppSettings.DOCUMENTS_TYPE.DOCUMENT : AppSettings.DOCUMENTS_TYPE.PROFILE;
      miComponent.setInput('docType', this.docType);
    }
    this.cd.detectChanges();
  }

  getComponentByType(type: string): any {
    const componentMi = this.supportedMiComponents.find(c => c.name === type);
    return componentMi?.component || MiInputComponent;
  }

}
