<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="grid mt-1 details-div">
    <div class="col-9">
        @if (showStatus) {
            <status [dataForStatus]="dataForStatus" [entityForStatus]="entityForStatus" [userId]="userId" [imgFileId]="imgFileId"></status>
        }
    </div>
    <div class="col-3 pr-3 text-right align-content-center">
        <button icon="mi-lg basic_tray" (click)="deletePassenger()" pButton class="border-600 bg-white text-color mr-1 delete-btn" [label]="deleteBtnLabel"></button>
    </div>
</div>


<p-tabView [(activeIndex)]="activeIndex"  [scrollable]="true" >
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg overview"></i>
                <label class="capitalize" translate>vehicle.lbl_overview</label>
            </ng-template>
            @if (vehicleAttributeData) {
                <passenger-overview-details
                    [attributeValues]="attributeValues"
                    [attributeLabels]="attributeLabels"
                    [vehicleAttributeData]="vehicleAttributeData"
                    [passengerDetails]="passengerDetails"
                ></passenger-overview-details>
            }
        </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg mi-settings"></i>
            <label translate>passengers.lbl_privilege_settings</label>
        </ng-template>
        @if (vehicleAttributeData) {
            <app-privilege-settings
                [attributeValues]="attributeValues"
                [attributeLabels]="attributeLabels"
                [vehicleAttributeData]="vehicleAttributeData"
                [passengerDetails]="passengerDetails"
            ></app-privilege-settings>
        }

    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg journey-history"></i>
            <label translate>vehicle.lbl_journey_history</label>
        </ng-template>
    </p-tabPanel>
</p-tabView>