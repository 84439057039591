import { NgClass } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class ProfileImageComponent {
  @Input() profileData;
  @Input() activeClasses = '';

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.activeClasses = changes?.activeClasses?.currentValue || changes?.profileData?.currentValue?.activeClasses;
  }

  createAcronym(name) {
    const words = name?.split(' ');
    const acronym = words?.map(word => word[0]).join(''); return acronym.toUpperCase();
  }
}
