@if(moduleName === 'driver_group' || moduleName === 'driver' || moduleName === 'vehicle') {
    @if(colorCode === 'Bronze'){
        <div class="border-round height-26 width-26 bronze group-details">
            <div class="grid relative top-5 left-46"></div>
        </div>
    }
    @else {
        <div class="border-round height-26 width-26 group-details" [style.background]="colorCode">
            <div class="grid relative top-5 left-46"></div>
        </div>
    }
}
@else {
    @if(colorCode === 'Bronze'){
        <div class="border-round height-26 width-26 bronze">
            <div class="grid relative top-5 left-46"> {{colorCode}} </div>
        </div>
    }
    @else {
        <div class="border-round height-26 width-26" [style.background]="colorCode">
            <div class="grid relative top-5 left-46"> {{colorCode}} </div>
        </div>
    }
}