import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppIcons } from '../../../shared/app.icons';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { AppSettings } from '../../../shared/app.settings';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ShiftService } from '../../services/shift.service';
import * as _ from 'lodash';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { TruncatePipe } from 'app/modules/shared/pipes/truncate.pipe';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-week-list',
  standalone: true,
  imports: [TranslateModule, DropdownModule, CheckboxModule, OverlayPanelModule, DatePipe, FormsModule, ReactiveFormsModule, ButtonModule,
    MiImageContainerComponent, NoResultFoundComponent, TruncatePipe, TooltipModule
  ],
  templateUrl: './week-list.component.html',
  styleUrl: './week-list.component.scss',
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeekListComponent {
  @Input() listType: any = [];
  @Input() selectedListType: any = AppSettings.LIST_TYPE_OPTIONS[0].value;
  @Input() weekDaysList;
  @Input() searchValue: any = '';

  @Output() onListTypeChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() shiftPublishedUnpublishedList: EventEmitter<any> = new EventEmitter();

  listTypeCopy: any[] = [];
  weekday = AppSettings.WEEK_DAYS;
  driverList: any = [];
  miIconCaretDown = AppIcons.CARET_DOWN;
  hoveredDriver: any;
  hoveredPublishedUnPublishedShift: any;
  publishedUnpublishedShiftDriver: any;
  currentDate: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  publishedShiftList: any = [];
  unpublishedShiftList: any = [];
  currentDay = new Date();
  isAscendingData: boolean = true;
  country: Country;
  language: Language;

  driverDataPayloadData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: '',
    defaultSortType: "asc",
    startDateTime: 0,
    endDateTime: 0,
    startDateTimeStr: '',
    endDateTimeStr: ''
  };

  private maxDataReached = {
    entityDataForVehicle: false,
  }

  constructor(private entityService: EntityService,
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
    private shiftService: ShiftService) { }

  ngOnInit() {
    this.listTypeCopy = _.cloneDeep(this.listType);
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));  
      const cb = () => {
        this.driverDataPayloadData.offset = this.driverDataPayloadData.offset + (this.driverDataPayloadData.offset === 0 ? this.driverDataPayloadData.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForVehicle) {
          this.getDriverList();
        }
      };
      this.applyInfiniteScroll(cb)
  }

  applyInfiniteScroll(onEndPageCallback) {
    const contentEl = document.querySelector('#shift-cells') as HTMLElement;
    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const endOfPage = Math.round(contentEl.scrollTop) === heightDiff || Math.round(contentEl.scrollTop) === (heightDiff + 1);
      if (endOfPage) {
        onEndPageCallback()
      }
    };
    contentEl.addEventListener("scroll", handleInfiniteScroll)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.driverDataPayloadData.offset = 0;
    if (changes.searchValue) {
      this.searchValue = changes.searchValue.currentValue;
      this.driverDataPayloadData.searchStr = this.searchValue;
      if (this.driverDataPayloadData.searchStr.length >= 3) {
        this.driverList = [];
        this.getDriverList();
      } else if (this.driverDataPayloadData.searchStr.length === 0) {
        this.maxDataReached.entityDataForVehicle = false;
        this.driverList = [];
        this.getDriverList();
      }
    }
     else if(changes.weekDaysList) {
      this.maxDataReached.entityDataForVehicle = false;
      this.driverList = [];
      this.getDriverList();
    }
  }

  onListTypeChange(event) {
    this.onListTypeChangeEvent.emit(event.value);
  }

  getDriverImage(driverImageFileId, driver) {
    this.entityService.getFile(driverImageFileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'driver');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function () {
        const base64data = reader.result;
        driver.driverProfileImage = base64data;
      }
    })
  }

  calculateTotalWorkingHoursInWeekForDriver(driver: any) {
    driver.workingHours = 0
    driver.shift.forEach((shift) => {
      if (!shift.isLeave) {
        driver.workingHours = driver.workingHours + shift.shiftDuration;
      }
    });
  }

  setleaves(driver: any) {
    if (driver?.leaves) {
      driver?.leaves.forEach(leave => {
        driver.shift.push({
          startTimeHour: dayjs(leave.fromDate).format('hha'),
          startDateStr: dayjs(leave.fromDate).valueOf(),
          endDateStr: dayjs(leave.fromDate).valueOf(),
          isLeave: true,
          leaveReason: leave.reasonForLeave
        });
      });
    }
  }

  setShiftDetails(shift: any, driver: any) {
    const startTimeArray = shift.shiftDetailsDto.startTimeStr.split(AppSettings.STRING_SPLIT_BY_SPACE);
    const hourMinutes = startTimeArray[0].split(AppSettings.STRING_SPLIT_BY_COLON);
    shift.shiftDetailsDto.startTimeHour = `${Number(hourMinutes[0])}${startTimeArray[1]}`;
    let startDate = new Date(`${shift.shiftDetailsDto.startDateStr}`);
    let endDate = new Date(`${shift.shiftDetailsDto.startDateStr}`);
    shift.shiftDetailsDto.startTime = Number(hourMinutes[1]);
    shift.shiftDetailsDto.startDateStr = dayjs(shift.shiftDetailsDto.startDateStr).startOf('day').valueOf();
    shift.shiftDetailsDto.endDateStr = dayjs(shift.shiftDetailsDto.endDateStr).startOf('day').valueOf();
    if (shift.shiftCalendarList && shift.shiftCalendarList.length > 0) {
      let shiftCalendar = shift.shiftCalendarList.find(ele => ele.shiftId === shift.shiftDetailsDto.shiftId);
      shift.shiftDetailsDto.isShiftPublish = shiftCalendar.shiftStatus.toLowerCase() === AppSettings.SHIFT_STATUS.PUBLISH.toLowerCase();
    }

    shift.shiftDetailsDto.isDoubleShift =  (currentShift: any) => {
      let shift = driver.shift.find(ele => ((dayjs(ele.startDateStr).format(AppSettings.DD_MM_YYYY) === dayjs(currentShift.startDateStr).format(AppSettings.DD_MM_YYYY)) && (ele.shiftId !== currentShift.shiftId)));
      return shift ? true : false;
    }

    if (startTimeArray[1] === 'PM') {
      startDate.setHours(Number(hourMinutes[0]) + 12);
    } else {
      startDate.setHours(Number(hourMinutes[0]));
    }
    startDate.setMinutes(Number(hourMinutes[1]));

    const endTimeArray = shift.shiftDetailsDto.endTimeStr.split(AppSettings.STRING_SPLIT_BY_SPACE);
    const hourMinutesEndTime = endTimeArray[0].split(AppSettings.STRING_SPLIT_BY_COLON);
    if (endTimeArray[1] === 'PM') {
      endDate.setHours(Number(hourMinutesEndTime[0]) + 12);
    } else {
      endDate.setHours(Number(hourMinutesEndTime[0]));
    }
    endDate.setMinutes(Number(hourMinutesEndTime[1]));
    shift.shiftDetailsDto.timeDifferenceInMinutes = (dayjs(endDate.getTime()).diff(dayjs(startDate.getTime()), 'minute'));
    this.cd.detectChanges();
  }

  setShiftData(shift: any, driver: any) {
    this.setShiftDetails(shift, driver); 
    if (shift.shiftCalendarList && shift.shiftCalendarList.length > 0) {
      shift.shiftCalendarList.forEach(shiftCalendar => {
        shiftCalendar = {...shiftCalendar, ...shift.shiftDetailsDto };
        shiftCalendar.startDateStr = shiftCalendar.startDateTime;
        shiftCalendar.endDateStr = shiftCalendar.endDateTime;
        driver.shift.push(shiftCalendar);
        console.log(driver);
        this.cd.detectChanges();
      });
    }
  }

  getShift(shiftList: any, driver: any) {
    driver.shift = [];
    if (shiftList && shiftList.length > 0) {
      shiftList.forEach(shift => {
        this.setShiftData(shift, driver);
      });
    }
  }

  sortDrivers() {
    this.isAscendingData = !this.isAscendingData;
    this.driverDataPayloadData.defaultSortType = this.isAscendingData ? 'desc' : 'asc';
    this.driverList = [];
    this.getDriverList();
  }

  getDriverDetails(driverIds, driverData) {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      viewCode: AppSettings.VIEW_CODE.SHIFT_CALENDAR_DRIVER_LIST_VIEW,
      entityIds: driverIds,
      limit: this.driverDataPayloadData.limit,
      offset: 0
    }
    this.entityService.getEntityDetailsByEntityIds(requestBody, AppSettings.ENTITY_CODE.DRIVER).subscribe((result: any) => {
      this.setDriverData(driverData, result.data);
      this.cd.detectChanges();
    });
  }

  setDriverData(driverData: any, driverDetailsList: any[]) {
    driverData.data.forEach((driver: any) => {
      this.setDriverDetails(driver, driverDetailsList);
      this.getShift(driver.shiftList, driver);
      this.setDriverImage(driver);
      this.setleaves(driver);
      this.calculateTotalWorkingHoursInWeekForDriver(driver);
    });
  }

  setDriverImage(driver: any) {
    if (driver.driverProfileImageId) {
      this.getDriverImage(driver.driverProfileImageId, driver);
    } else {
      driver.driverProfileImage = this.defaultLoaderImg;
    }
  }

  setDriverDetails(driver: any, driverDetailsList: any[]) {
    let driverDetails = driverDetailsList.find(ele => ele.id === driver.driverEntityId);
    let driverGroup = (driverDetails.relatedData && driverDetails.relatedData.length > 0) ? driverDetails.relatedData.find(ele => ele.entityCode === AppSettings.ENTITY_CODE.DRIVER_GROUP) : null;
    let driverVehicle = (driverDetails.relatedData && driverDetails.relatedData.length > 0) ? driverDetails.relatedData.find(ele => ele.entityCode === AppSettings.ENTITY_CODE.VEHICLE) : null;
    driver.driverName =  `${driver.firstName} ${driver.lastName}`;
    driver.driverId = driver.driverEntityId;
    driver.driverGroup = driverGroup ? driverGroup.values.group_name : null;
    driver.uniqueCode = driver.driverUniqueId;
    driver.status = driverDetails ? driverDetails.values.driver_status : null;
    driver.gender =driverDetails ? driverDetails.values.gender : null;
    driver.email = driverDetails ? driverDetails.values.email : null;
    driver.mobileNumber = driverDetails ? driverDetails.values.mobile_number : null;
    driver.vehicle = driverVehicle ? driverVehicle.values.name_code : null;
  }

  getDriverList() {
    this.driverDataPayloadData.startDateTimeStr = dayjs(this.weekDaysList[0].date).startOf('day').format(AppSettings.DD_MM_YYYY)
    this.driverDataPayloadData.endDateTimeStr = dayjs(this.weekDaysList[this.weekDaysList.length - 1].date).endOf('day').format(AppSettings.DD_MM_YYYY)
    this.shiftService.getDriverList(this.driverDataPayloadData, 'drivers').subscribe((result: any) => {
      this.listTypeCopy[0].name = `${this.listType[0].name} (${result.count})`;
      this.driverList.push(...result.data);
      let driverIds = result.data.map(ele => {
        return ele.driverEntityId
      })
      this.getDriverDetails(driverIds, result);
      this.getPublishedUnpublishedShiftList();
      this.getCalendarHeaderCountByDate();
      if (result?.data.length === 0) {
        this.maxDataReached.entityDataForVehicle = true;
      }

      if (!this.maxDataReached.entityDataForVehicle) {
        this.maxDataReached.entityDataForVehicle = this.driverList.length >= result.count;
      }
    });
  }

  getPublishedUnpublishedShiftList() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      languageCode: this.language[0].langCode,
      startDateTimeStr: dayjs(this.weekDaysList[0].date).startOf('day').format(AppSettings.DD_MM_YYYY),
      endDateTimeStr: dayjs(this.weekDaysList[this.weekDaysList.length - 1].date).endOf('day').format(AppSettings.DD_MM_YYYY)
    }
    this.shiftService.getPublishUnPublishListInDateRange(requestBody).subscribe({
      next: (result: any) => {
        this.publishedShiftList = result.filter(ele => ele.shiftStatus.toLowerCase() === AppSettings.SHIFT_STATUS.PUBLISH.toLowerCase());
        this.unpublishedShiftList = result.filter(ele => ele.shiftStatus.toLowerCase() === AppSettings.SHIFT_STATUS.UNPUBLISH.toLowerCase());
        this.shiftPublishedUnpublishedList.emit({publishedShiftList: this.publishedShiftList, unpublishedShiftList: this.unpublishedShiftList});
      },
      error: (error) => {
      }
    })
  }

  getCalendarHeaderCountByDate() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      languageCode: this.language[0].langCode,
      startDateTimeStr: dayjs(this.weekDaysList[0].date).startOf('day').format(AppSettings.DD_MM_YYYY),
      endDateTimeStr: dayjs(this.weekDaysList[this.weekDaysList.length - 1].date).endOf('day').format(AppSettings.DD_MM_YYYY)
    }
    let selectionType = 'drivers';
    this.shiftService.getCalendarHeaderCountByDate(requestBody, selectionType).subscribe({
      next: (result: any) => {
        if (result.length > 0) {
          this.weekDaysList.forEach(element => {
           let index = result.findIndex(ele => ele.date === dayjs(element.date).format(AppSettings.YYYY_MM_DD));
           if (index >= 0) {
            element.available = result[index].counts.available;
            element.dayOff = result[index].counts.leave;
           }
          });
          this.cd.detectChanges();
        }
      },
      error: (error) => {
      }
    })
  }
}
