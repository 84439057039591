import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../shared/app.settings';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ConfigService } from '../../shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftTemplateService {
  constructor(
    private restApiService: RestApiService, private confService: ConfigService
  ) { }

  addShift(data): Observable<any> {
    return this.restApiService.post('add shift template', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-templates`, data, 'page-center');
  }

  updateShift(data, id): Observable<any> {
    return this.restApiService.put('update shift template', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-templates/${id}`, data, 'page-center');
  }

  searchShiftTemplateList(data) {
    return this.restApiService.post('search shift template', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-templates/search?forTenantCode=${data.forTenantCode}`, data, 'page-center');
  }

  bulkUpdateShiftTemplate(data) {
    return this.restApiService.put('bulk update shift template', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-templates/bulk-update`, data, 'page-center');
  }

  bulkDelete(data) {
    return this.restApiService.put('bulk delete shift template', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-templates/bulk-delete`, data);
  }

  getDetails(id) {
    return this.restApiService.get('shift template details', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-templates/${id}?forTenantCode=${this.confService.getForTenantCode()}`);
  }

}
