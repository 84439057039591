import { Component, OnInit, ViewChild } from "@angular/core";
import { MenuItem, MessageService } from "primeng/api";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { TabViewModule } from "primeng/tabview";
import { AppSettings } from "../../../shared/app.settings";
import { BasicTableComponent } from "../../../shared/components/basic-table/basic-table.component";
import { MiFormComponent } from "../../../shared/components/mi-form/mi-form.component";
import { Country } from "../../../shared/models/country";
import { Language } from "../../../shared/models/language";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { AttributeData, entityResponse } from "../../modal/attributeModal";
import { EntityService } from "../../../shared/services/entity.service";

import { VehicleDocumentEntityComponent } from "../vehicle-document-entity/vehicle-document-entity.component";
import dayjs from "dayjs";
import { RelatedDataSchema, VehicleResponse } from "../../modal/entityResponseModal";

@Component({
  selector: "app-add-vehicle",
  templateUrl: "./add-vehicle.component.html",
  styleUrls: ["./add-vehicle.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    TabViewModule,
    MiFormComponent,
    DialogModule,
    BasicTableComponent,
    ButtonModule,
    TranslateModule,
    VehicleDocumentEntityComponent
],
})
export class AddVehicleComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 0;
  data: AttributeData;
  vehicleAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  relation: string = "oneToOne";
  cols: any = [];
  vehicleAttributeLength;
  vehicleId: string;
  vehicleIdForDocument: string;
  vehicleDocumentId;
  driverRelation: any;  
  attributeValues = [];
  nextBtnLabel;
  previousBtnLabel;
  label:string;
  initialLabel: string = this.cs.getLabel("lbl_details");
  saveVehicleBtnLabel: string;

  constructor(
    private vehicleService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.nextBtnLabel = this.cs.getLabel("lbl_next");
    this.previousBtnLabel = this.cs.getLabel("lbl_previous");
    this.vehicleId = this.route.snapshot.paramMap.get("id");
    if(this.vehicleId) {
      this.vehicleIdForDocument = this.vehicleId;
    }
    this.saveVehicleBtnLabel = this.vehicleId ? this.cs.getLabel("lbl_update_vehicle") : this.cs.getLabel("lbl_save_vehicle")
    this.moduleName = AppSettings.ENTITY_TYPE.VEHICLE;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.vehicleService.setRelatedData([]);
    if (this.vehicleId) {
      this.vehicleService
        .getEntity(this.vehicleId, AppSettings.ENTITY_CODE.VEHICLE)
        .subscribe((res:VehicleResponse) => {
          if (res) {
            const data = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            const yearOfMnfgAttributeCode = "year_of_manufacturing";
            if (this.attributeValues[yearOfMnfgAttributeCode]) {
              this.attributeValues[yearOfMnfgAttributeCode] = new Date(this.attributeValues[yearOfMnfgAttributeCode],0);
            }

            if (data.relatedData.length > 0) { 
              const relatedData: { [key: string]: any[] } = {};
              data.relatedData.forEach(item => {
                const entityCode = item.entityCode;
                if (item.actionStatus === "DELETED") {
                  return;
              }
                if (!relatedData[entityCode]) {
                    relatedData[entityCode] = [];
                }       

                const entityData = this.cs.getOrgAttributeValues(item)
                entityData['entityId'] = item.entityId;
                relatedData[entityCode].push(entityData);
            });
              this.vehicleService.setRelatedData(relatedData)
            }
            this.getAttributes();
          }
        });
    } else {
      this.getAttributes();
    }
    this.setRoutePath();
    this.updateBreadcrumb(this.initialLabel);
  }

  onTabChange(event:any) {
    this.label = event.originalEvent.target.innerText.trim();
    this.updateBreadcrumb(this.label);
  }

  updateBreadcrumb(label: string) {
    this.routePath = [
      {
        label: this.vehicleId ? this.cs.getLabel("lbl_edit_vehicles") : this.cs.getLabel("lbl_add_new_vehicles"),
        routerLink: this.vehicleId ? '../../list' : '../list',
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
      {
        label,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }
  
  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.VEHICLE;
    this.vehicleService
      .getAttributeDefinition(
        entityType,
        this.country[0].tenantId,
        AppSettings.VIEW_CODE.ADD_EDIT_VIEW
      )
      .subscribe((res) => {
        if (res) {
          this.data = res;
          this.attributeLabels = this.cs.getAttributeLabels(this.data);
          this.vehicleAttributeData = this.cs.getOrganizedAttribute(this.data);
          this.vehicleAttributeData.tabs = this.cs.setDependsOnAttributeCode(this.vehicleAttributeData?.tabs);
         
          const documentTab = this.vehicleAttributeData.tabs.find(ele => ele.tabCode === 'documents');
          const groups = documentTab.groups.find(ele => ele.code === 'documents');

          const issuedDatefield = groups.fields.find(ele => ele.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE);
          const expiryDatefield = groups.fields.find(ele => ele.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE);
          if (issuedDatefield) {
            issuedDatefield.validation.max = new Date();
          }
          if (expiryDatefield) {
            expiryDatefield.validation.min = new Date();
          }

          const detailTab = this.vehicleAttributeData.tabs.find(ele => ele.tabCode === 'details');
          const detailGroup = detailTab.groups.find(ele => ele.code === 'additional_details');
          const yearOfMfgField = detailGroup.fields.find(ele => ele.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DETAIL_YEAR_OF_MFG);
          if (yearOfMfgField) {
            yearOfMfgField.validation.max = new Date();
          }

          this.vehicleAttributeLength = this.vehicleAttributeData.tabs.length;

          this.driverRelation = _.find(this.data.relations, { otherEntityCode: 'driver', relation: "oneToMany" });

        }
      });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel("lbl_add_new_vehicles"),
        routerLink: this.vehicleId ? '../../list' : '../list',
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
      {
        label: this.vehicleId
          ? this.cs.getLabel("vehicle.edit")
          : this.cs.getLabel("vehicle.add"),
        
        styleClass: "breadcrumb-child forward-slash breadcrumb-text",
        style: { display: "flex", top: "2px", position: "relative" },
      },
    ];
  }

  onSaveVehicle(event, documents?) {
   
   const  vehicleData = {
        forTenantCode: AppSettings.TENANT_CODE,
        entityCode: this.data.entityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
     data: [],
     relationshipData:[]
      };
    
    const vData = event;
    const dateIds = ["year_of_manufacturing","issued_date","expiry_date", "in_service_date", "out_of_service_date"];
    for (const [key, value] of Object.entries(vData)) {
      let attributeValue = <any>value;
      if (dateIds.includes(key) && key !== "year_of_manufacturing") {
        attributeValue = new Date(<string>value).getTime();
      } else if (key == "year_of_manufacturing") {
        attributeValue = Number(dayjs(<number>value).format('YYYY'))
      } else if (key === 'driver') { 
        attributeValue?.map(driverId=> {
          vehicleData?.relationshipData?.push({
            entityRelationshipConfigId: this.driverRelation.entityRelationshipConfigId,
            otherEntityId: driverId
          });
        })
      }
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (key === 'driver') {
            vehicleData.data.push({
              attributeCode: key,
              attributeValue,
            });
          } else if (attributeValue?.length > 0) {
            vehicleData.data.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          vehicleData.data.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: AppSettings.TENANT_CODE,
    };
  
    if (!this.vehicleId) {
      this.vehicleService
        .createEntities(this.data.entityCode, entitiesData)
        .subscribe((res: entityResponse) => {
          this.vehicleId = res.entityId;
          if(this.vehicleId) {
            this.vehicleIdForDocument = this.vehicleId;
          }
          this.saveVehicleData(vehicleData, true);
        });
    } else {
      this.saveVehicleData(vehicleData, false);
    }
  }

  saveVehicleData(vehicleData, isNew: boolean) {
    const driverData=  vehicleData?.data.find(filter => filter.attributeCode === "driver");

    vehicleData.data = this.cs.mapAttributeIds(vehicleData.data, this.vehicleAttributeData.tabs);
    this.vehicleService
      .saveAttributeData(vehicleData.entityCode, this.vehicleId, vehicleData).subscribe({
        next: (res: any) => {
          const message = isNew ? this.cs.getLabel("vehicle.vehicle_added") : this.cs.getLabel("vehicle.vehicle_update_message");
          this.messageService.add({key: "tst", severity: "success", summary: "Successful",
            detail: message,
          });

          setTimeout(() => {
            if (this.isLastIndex()) {
              this.router.navigate(["app/vehicles/list"]);
            } else {
              this.onNextClick();
            }
          }, 500);
      
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
        });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel("lbl_next");
    }
  }

  onNextBtnClick() {
    if (this.vehicleAttributeData?.tabs && this.vehicleAttributeData.tabs.length > 0) {
      const currentTab = this.vehicleAttributeData.tabs[this.activeIndex];
      if (currentTab) {
        const btn = document.getElementById(currentTab.tabCode);

        if (btn) {
          btn.click();
        } else {
          console.log(`Button with ID ${currentTab.tabCode} not found.`);
        }
      } else {
        console.log(`Tab at index ${this.activeIndex} is not valid.`);
      }
    } else {
      console.log('Tabs data is not available.');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.vehicleAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.saveVehicleBtnLabel;
    }
  }

  getLastIndex(): number {
    return this.vehicleAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onAddDocument(event) {
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(["app/vehicles/list"]);
  }
}
