<div class="grid mt-2 ml-3 speccifications booking-details">
    <div class="col-6 pl-0">

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase"
                        translate>{{cs.getLabelValue('booking.groups.booking_details.label',attributeLabels,'booking_details')}}</label>
                </div>
                <!-- <div (click)="redirectToEdit()" class="edit flex justify-content-center mb-3">
                    <label class="card-action" translate>vehicle.lbl_edit</label>
                    <i class="mi-lg edit_pencil"></i>
                </div> -->
            </div>
            @for (field of bookingInfo.fields; track field; let i = $index) {


            @if (i !== 0) {
            @if (!childSeatsHr.includes(field.attributeCode)){
            <hr class="hr-line">

            }
            }
            <div class="grid">
                <div class="col-6 pt-1">
                    @if (childSeats.includes(field.attributeCode)){

                    @if(field.attributeCode === 'child_seat'){
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>

                    }
                    }@else{
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    }
                </div>

                @if(field.attributeCode === 'booking_status'){
                <div class="col-6 pb-1">
                    <label class="flex mr-2"><i
                            class="{{miIcons[getIcon(attributeValues[field.attributeId])]}} inline-block wh-24 mr-2"></i>
                    </label>

                    <label class="detail-value">{{ attributeValues[field.attributeCode] ?
                        BOOKING_STATUS_LABEL[attributeValues[field.attributeCode]] : ('lbl_empty' | translate)
                        }}</label>
                </div>
                }@else if(field.attributeCode === 'departure_time_and_date' || field.attributeCode ===
                'return_time_and_date'){

                <div class="col-6 pb-1">

                    <label class="detail-value font-bold">{{ attributeValues[field.attributeCode] ?
                        (attributeValues[field.attributeCode]| date:hh_mm ) : ('lbl_empty' |
                        translate) }}


                    </label>

                    <label class="detail-value">{{ attributeValues[field.attributeCode] ?
                        (attributeValues[field.attributeCode]| date: dd_MMM_yyyy_FORMAT) : ('lbl_empty' |
                        translate) }}
                    </label>
                </div>
                }@else if (childSeats.includes(field.attributeCode)) {

                <div class="col-6 pb-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    :
                    <label class="detail-value primary">{{ attributeValues[field.attributeCode] ?
                        attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                </div>

                }
                @else if (field.attributeCode === 'bags') {
                <label class="detail-value primary font-bold ml-2">{{ attributeValues[field.attributeCode] ?
                    attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                }
                @else if (field.attributeCode === 'wheelchair') {
                <label class="detail-value  font-bold ml-2">{{ attributeValues[field.attributeCode] ?
                    attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                }
                @else if (field.attributeCode === 'additional_vehicle_requirement') {
                <label class="detail-value  font-bold ml-2">{{ attributeValues[field.attributeCode] ?
                    attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                }
                @else if(field.attributeCode === 'booking_pickup_location_address'){
                <div class="col-6 pt-1">
                    <label class="detail-value "> <i
                            class="{{miIcons.BOOKING_PICKUP_POINT}} inline-block relative mr-2"></i>
                            {{attributeValues[field.attributeCode] }}
                    </label>
                </div>

                }@else if(field.attributeCode === 'booking_dropoff_location_address'){
                <div class="col-6 pt-1">
                    <label class="detail-value "> <i
                            class="{{miIcons.BOOKING_DROP_OFF_POINT}} inline-block relative mr-2"></i>
                        {{attributeValues[field.attributeCode] }}
                    </label>
                </div>

                }@else if(field.attributeCode === 'booking_stops_location_address' &&
                attributeValues[field.attributeCode] ){

                <div class="col-6 pb-1 p-0 ">
                    @for (item of attributeValues[field.attributeCode]; track item;let s = $index) {


                    <div class="flex-1 flex align-items-center justify-content-left ellipsis mb-3 stops-text relative">
                        <i class="route inline-block relative mr-1 text-xs"></i>
                        <i class="{{miIcons.BOOKING_STOP_POINT}} inline-block relative mr-2 ml-2 text-xs">{{s+1}}</i>{{item ?
                        item : ('lbl_empty' | translate)}}
                    </div>
                    }
                </div>

                }@else if(field.attributeCode ==='transportation_mode'){
                <label class="detail-value ml-1 font-bold flex justify-content-start align-items-center">
                    @switch (attributeValues[field.attributeCode]) {
                    @case (TRANSPORTATION_MODE.CAR) {
                    <i class="{{miIcons.BOOKING_CAR}} wh-24 inline-block mr-2 ml-1"></i>

                    }

                    @case (TRANSPORTATION_MODE.SHUTTLE) {

                    <i class="{{miIcons.BOOKING_SHUTTLE}} wh-24 inline-block mr-2"></i>

                    }
                    @case (TRANSPORTATION_MODE.BUS) {
                    <i class="{{miIcons.BOOKING_BUS}} wh-24 inline-block mr-2"></i>
                    }
                    }

                    {{attributeValues[field.attributeCode] }}
                </label>
                }@else if(field.attributeCode ==='transportation_type'){
                <label class="detail-value font-bold ml-1 justify-content-start align-items-center flex">

                    <i class="{{attributeValues[field.attributeCode]}} wh-24 inline-block mr-2"></i>
                    {{attributeValues[field.attributeCode] }}
                </label>
                }@else if(field.attributeCode === 'number_of_passenger'){
                <label class="detail-value font-bold ml-2 justify-content-start align-items-center flex">
                    {{attributeValues[field.attributeCode] }}
                </label>

                }@else if(field.attributeCode === 'owner_pass_full_name'){
                <label class="detail-value font-bold justify-content-start align-items-center flex">
                    {{ this.passengerRelatedData[0]?.pass_full_name }} <span
                        class="sub-text"></span>({{this.passengerRelatedData[0]?.passenger_type }})
                </label>

                }
                @else{
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ?
                        attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
                }

                <!-- <div class="status-btn cursor-pointer font-semibold" (click)="showStatusDialog(rowData)" [ngStyle]="getStatusStyle(rowData.booking_status)">
                        {{BOOKING_STATUS_LABEL[rowData.booking_status] || "-"}}
                        
                     </div> -->

            </div>
            }
        </div>

        @if(flightDetails){

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase"
                        translate>{{cs.getLabelValue('booking.groups.flight_details.label',attributeLabels,'flight_details')}}</label>
                </div>
                <!-- <div class="flex justify-content-center mb-3">
                    <label class="card-action" (click)="redirectToEdit()" translate>vehicle.lbl_edit   <i class="mi-lg edit_pencil"></i></label>
                  
                </div> -->
            </div>
            @for (field of flightDetails.fields; track field; let i = $index) {
            @if (i !== 0) {
            <hr class="hr-line">
            }
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode]?
                        attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                </div>
            </div>
            }
        </div>
        }


        @if (driverInstructions) {
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <!-- <label class="card-heading uppercase" translate>{{cs.getLabelValue('booking.groups.driver_instructions.label',attributeLabels,'driver_instructions')}}</label> -->
                </div>
                <!-- <div class="flex justify-content-center mb-3">
                    <label class="card-action" (click)="redirectToEdit()" translate>vehicle.lbl_edit    <i class="mi-lg edit_pencil"></i></label>
                 
                </div> -->
            </div>
            @for (field of driverInstructions?.fields; track field; let i = $index) {
            @if (i !== 0) {
            <hr class="hr-line">
            }
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode]?
                        attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
            </div>
            }

        </div>
        }
        @if(adminNoteAttributeId && attributeValues['booking_status'] === completedBooking.bookingStatusId ){

        <div class="card p-fluid">

            <div class="flex justify-content-between flex-wrap">
                <div class="mb-3">
                    <div class="block ">
                        <label class="card-heading uppercase" translate> bookings.admin_note</label>
                    </div>
                    <div class="block ">
                        <label class="" translate>bookings.post_journey_lbl</label>
                    </div>
                </div>

            </div>

            <textarea
                rows="5"
                cols="30"
                pInputTextarea
                [(ngModel)]="adminNote" class="mb-3">
                </textarea>

            <p-button label="{{'lbl_save'| translate }}" class="width-30" (click)="onSaveAdminNote()" />
        </div>
        }


    </div>

    
    <div class="col-6">
        @if (passengersDetails) {

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase"
                        translate>{{cs.getLabelValue('booking.groups.passenger.label',attributeLabels,'passenger')}} ({{
                        passengerRelatedData?.length || 0}})</label>
                </div>
                <!-- <div class="flex justify-content-center mb-3">
                    <label class="card-action" (click)="redirectToEdit()" translate>vehicle.lbl_edit   <i class="mi-lg edit_pencil"></i></label>
                  
                </div> -->
            </div>

            @if(this.passengerRelatedData.length > 0){
            <app-added-passenger-input [isPassengerDetails]="true" [addedPassengerList]="passengerRelatedData"
                [selectAddedPassenger]="selectAddedPassenger" (onAddedPassenger)="onSelectAddedPassenger($event)"
                (onCloseRemove)="onRemove($event)"></app-added-passenger-input>
            }


            <div class="formgrid grid mr-2">
                <div class="col-6 pt-1">
                    <div class="field col pl-1 my-3 flex justify-content-start align-items-center">
                        <img [src]="selectAddedPassenger.passenger_profile_image"
                            class="vertical-align-middle border-round-sm hw-30 list-img mr-2" />
                        <span class="ellipsis mr-2 text-color">
                            {{selectAddedPassenger?.pass_full_name}}
                        </span>
                    </div>
                </div>
                <div class="col-6 pb-1">
                    <div class="field col py-3 flex justify-content-start align-items-center">
                        <div class="flex flex-row flex-wrap p-0 m-0">
                            <div
                                class="flex align-items-center justify-content-center w-2rem h-2rem  allowed cursor-pointer border-round m-2 ml-0 relative"
                                [ngClass]="{ 'allowed': selectAddedPassenger.transport_services_allowed === 'yes', 
                          'not-allowed': selectAddedPassenger.transport_services_allowed !== 'yes' }">
                                <i class="wh-24 inline-block">T</i>
                                <span class="transport-text">{{selectAddedPassenger.transport_services_allowed ===
                                    'yes'?'ON':'OFF'}}</span>
                            </div>

                            <div
                                class="flex align-items-center justify-content-center w-2rem h-2rem  allowed cursor-pointer border-round m-2"
                                [ngClass]="{ 'allowed':selectAddedPassenger.transport_mode?.includes(TRANSPORTATION_MODE.CAR), 
                          'not-allowed': !selectAddedPassenger.transport_mode?.includes(TRANSPORTATION_MODE.CAR)}">
                                <i class="{{miIcons.BOOKING_CAR}} wh-24 inline-block"></i>
                            </div>



                            <div class="flex align-items-center justify-content-center w-2rem h-2rem not-allowed border-round m-2"
                                [ngClass]="{ 'allowed':selectAddedPassenger.transport_mode?.includes(TRANSPORTATION_MODE.SHUTTLE), 
                          'not-allowed': !selectAddedPassenger.transport_mode?.includes(TRANSPORTATION_MODE.SHUTTLE)}">
                                <i class="{{miIcons.BOOKING_SHUTTLE}} wh-24 inline-block"></i>
                            </div>


                            <div
                                class="flex align-items-center justify-content-center w-2rem h-2rem allowed cursor-pointer  border-round m-2"
                                [ngClass]="{ 'allowed':selectAddedPassenger.transport_mode?.includes(TRANSPORTATION_MODE.BUS), 
                          'not-allowed': !selectAddedPassenger.transport_mode?.includes(TRANSPORTATION_MODE.BUS)}">
                                <i class="{{miIcons.BOOKING_BUS}} wh-24 inline-block"></i>
                            </div>


                            <div
                                class="flex align-items-center justify-content-center w-2rem h-2rem allowed cursor-pointer  border-round m-2">
                                <i class="{{miIcons.BOOKING_AIRPORT}} wh-24 inline-block"></i>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            @for (field of passengersDetails?.fields; track field; let i = $index) {
            @if(!excludeFields.includes(field.attributeCode)){
            @if (i !== 0) {
            <hr class="hr-line">
            }
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking_pass.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ selectAddedPassenger?.[field.attributeCode]
                        ?selectAddedPassenger?.[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
            </div>
            }
            }

            <div class="flex justify-content-between flex-wrap align-items-center">
                <div class="flex justify-content-center my-3">
                    <label class="card-heading uppercase"
                        translate>{{cs.getLabelValue('booking_pass.groups.privileges.label',attributeLabels,'passenger')}}</label>
                </div>
            </div>
            @for (field of passengersPrivileges.fields; track field; let i = $index) {
            @if (i !== 0) {
            <hr class="hr-line">
            }
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking_pass.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ selectAddedPassenger?.[field.attributeCode] ?
                        selectAddedPassenger?.[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
            </div>
            }
        </div>
        }


        @if(vehicleId){
        <app-booking-vehicle-info [vehicleId]="vehicleId" [vehicleRelatedData]="vehicleRelatedData" [entityId]="bookingDetails.entityId"></app-booking-vehicle-info>
        }

        <!-- @if(driverId){ -->
        <app-booking-driver-info [driverId]="driverId" [driverRelatedData]="driverRelatedData" [entityId]="bookingDetails.entityId"></app-booking-driver-info>
        <!-- } -->



    </div>

</div>