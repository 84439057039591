import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HideService {
  private visibilityState = new Map<string, BehaviorSubject<boolean>>();

  constructor() { }

  toggleVisibility(cardId: string): void {
    if (!this.visibilityState.has(cardId)) {
      this.visibilityState.set(cardId, new BehaviorSubject<boolean>(true));
    }

    const currentState = this.visibilityState.get(cardId)?.value || false;
    this.visibilityState.get(cardId)?.next(!currentState);
  }

  getVisibilityState(cardId: string): Observable<boolean> {
    if (!this.visibilityState.has(cardId)) {
      this.visibilityState.set(cardId, new BehaviorSubject<boolean>(true));
    }
    return this.visibilityState.get(cardId)?.asObservable()!;
  }
}
