import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-with-add-new',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './select-with-add-new.component.html',
  styleUrl: './select-with-add-new.component.scss'
})
export class SelectWithAddNewComponent implements OnInit {
  addSegmentText: string;

  constructor(private cs: CommonBindingDataService, private router: Router) { }

  ngOnInit() {
    this.setLabels();
  }

  private setLabels() {
    this.addSegmentText = this.cs.getLabel('manage_segments.lbl_add_segment');
  }

  addSegment() {
    this.router.navigate(['app/manage-segments/add-segment']);
  }

}
