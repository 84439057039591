import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSpace',
  standalone: true
})
export class CommaSpacePipe implements PipeTransform {
  transform(value: any): string {
    if (Array.isArray(value)) {
      value = value.join(', ');
    }
    if (typeof value !== 'string') {
      return '';
    }
    return value.split(',').map(part => part.trim()).join(', ');
  }
}
