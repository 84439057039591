import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-mi-checkbox',
  templateUrl: './mi-checkbox.component.html',
  styleUrls: ['./mi-checkbox.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CheckboxModule, TranslateModule, JsonPipe]
})
export class MiCheckboxComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  selectedCategories: any;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  onCheckboxChange() { 
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.selectedCategories);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  
  }
}
