import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AttributeData, entityResponse } from '../../../vehicles/modal/attributeModal';
import { EntityService } from '../../../shared/services/entity.service';
import { ConfigService } from '../../../shared/services/config.service';
import { AppIcons } from 'app/modules/shared/app.icons';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrl: './add-location.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, ButtonModule, TranslateModule]
})
export class AddLocationComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 0;
  data: AttributeData;
  locationAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  locationAttributeLength: any;
  cols: any = [];
  btnLabel: string;
  nextBtnLabel;
  previousBtnLabel;
  locationDocumentId;
  locationId;
  attributeValues = [];
  miIcons = AppIcons;

  constructor(private manageLocationService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService) { }

  ngOnInit() {
    this.nextBtnLabel = this.cs.getLabel('manage_locations.lbl_save_location');
    this.previousBtnLabel = this.cs.getLabel('manage_locations.lbl_cancel');
    this.locationId = this.route.snapshot.paramMap.get('id');
    this.btnLabel = this.locationId ? this.cs.getLabel('manage_locations.lbl_update_location') : this.cs.getLabel('manage_locations.lbl_save_location');

    const entityType = AppSettings.ENTITY_TYPE.LOCATION;
    this.moduleName = AppSettings.ENTITY_TYPE.LOCATION;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    if (this.locationId) {
      this.manageLocationService.getEntity(this.locationId, AppSettings.ENTITY_CODE.LOCATION).subscribe(res => {
        if (res) {
          const data = res;
          this.attributeValues = this.cs.getOrgAttributeValues(data);
          let addressAndLatLng = this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION];
          let latLngArray = addressAndLatLng ? addressAndLatLng?.split(',') : 0;
          const geoLocationValue = {
            address: this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS],
            lat: parseFloat(latLngArray[0]) ,
            lng: parseFloat(latLngArray[1])
          };
          this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS] = geoLocationValue;
          this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION] = geoLocationValue.address;
          this.getAttributes();
        }
      });
    } else {
      this.getAttributes();
    }

    this.setRoutePath();
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.LOCATION;
    this.manageLocationService.getAttributeDefinition(entityType, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.locationAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.locationAttributeLength = this.locationAttributeData.tabs.length;
      }
    });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.locationId ? this.cs.getLabel('manage_locations.edit_location') : this.cs.getLabel('manage_locations.add_location'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
    ];
  }

  onSaveLocation(event, documents?) {
    const locationData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    const lData = event;
    for (const [key, value] of Object.entries(lData)) {
      const attributeValue = <any>value;

      if (attributeValue !== null) {
        const obj = typeof (attributeValue);
        if (key === AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS){
          const geolocation = attributeValue
          
          locationData.data.push({
            attributeCode: key,
            attributeValue: geolocation.address
          })
          locationData.data.push({
            attributeCode: AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION,
            attributeValue:  `${geolocation.lat},${geolocation.lng}`
          })
        } else if(key === AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION) {

        }else {
          locationData.data.push({
            attributeCode: key,
            attributeValue
          });
        }
      }
    }
    let entitiesData;
    entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.data.entityCode
    }

    if (!this.locationId) {
      this.manageLocationService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
        this.locationId = res.entityId;
        this.saveLocationData(entitiesData.entityCode, locationData, 'create');
      });
    } else {
      this.saveLocationData(entitiesData.entityCode, locationData, 'update');
    }

  }

  saveLocationData(entityCode, locationData, action) {
    locationData.data = this.cs.mapAttributeIds(locationData.data, this.locationAttributeData.tabs);
    this.manageLocationService.saveAttributeData(entityCode, this.locationId, locationData).subscribe(res => {
      if (res) {
        if (action === 'create') {
          this.showSuccessMessage(this.cs.getLabel('manage_locations.location_added'));
        } else if (action === 'update') {
          this.showSuccessMessage(this.cs.getLabel('manage_locations.location_update'));
        }
        this.router.navigateByUrl('app/manage-locations/list');
      }
    });
  }

  onSaveBtnClick() {
    const btn = document.getElementById('details');
    btn.click();
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }


  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
  }

  onNextClick() {
    if (this.activeIndex < this.locationAttributeLength - 1) {
      this.activeIndex++;
    }
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/manage-locations/list']);
  }

}
