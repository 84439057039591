<p-breadcrumb [model]="routePath"></p-breadcrumb>
<app-module-header [label]="btnLabel" [lastSync]="lastSync" (onBtnClick)="onAddPassenger()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>
<p-tabView  [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg details mr-2"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.ALL}})</span>
    </ng-template>
    <ng-template pTemplate="body">
      @if (data && showTable) {
          <app-mi-table
            [collectionOfColumns]="allColumns"
            [attributeLabels]="filterAttributeLabels"
            [data]="tableData"
            entityName="passenger"
            [isLoading]="isLoading"
            (onPageChange)="onPageChange($event)"
            (itemPerPageChange)="itemPerPageChange($event)"
            [totalRecords]="totalRecords"
            [itemsPerPage]="itemsPerPage"
            [message]="deletePassengerMsg"
            [header]="deleteVehicleHeaderMsg"
            (onDelete)="onPassengerDelete($event)"
            (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
            (onRowSelection)="onRowSelect($event)"
            [filterList]="filterList"
            (onValueChange)="onFilterValueChange($event)"
            [filterFields]="filterFieldsArray"
            [label]="btnLabel"
            (onBtnClick)="onAddPassenger()"
            [bulkUpdateLabel]="bulkUpdateLabel"
            [bulkDeleteLabel]="bulkDeleteLabel"
            [isBulkPrint]="isBulkPrint"
            [bulkSelectionsItems] = "bulkSelectionsItems"
            [rowItems]="rowItems"
            [attributeData]="attributeData"
            (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
            (onSearchValueChange)="onSearchValueChanges($event)"
            (onUpdate)="onUpdateVehicle($event)"
            [bulkUpdateHeader]="bulkUpdateHeader"
            [reviewHeader]="reviewHeader"
            [btnUpdate]="btnUpdate"
            (onChangeStatus)="onChangeStatus($event)"
            (setCurrentRowData)="setCurrentRowData($event)"
            (onTransportationDateChange)="onTransportationDateChange($event)"
            [emptyResultMsg]="emptyResultMsg"
            [entityNameToDisplay]="entityNameToDisplay"
            >
          </app-mi-table>
      }
    </ng-template>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg basic-delete mr-2"></i>
      <span translate>lbl_deleted</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.DELETED}})</span>
    </ng-template>
    <ng-template pTemplate="body">
      @if (data && showTable) {
        <app-mi-table
        [collectionOfColumns]="allColumns"
        [attributeLabels]="filterAttributeLabels"
        [data]="tableData"
        entityName="passenger"
        [isLoading]="isLoading"
        (onPageChange)="onPageChange($event)"
        (itemPerPageChange)="itemPerPageChange($event)"
        [itemsPerPage]="itemsPerPage"
        [totalRecords]="totalRecords"
        [message]="deletePassengerMsg"
        [header]="deleteVehicleHeaderMsg"
        (onDelete)="onPassengerDelete($event)"
        (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
        (onRowSelection)="onRowSelect($event)"
        [filterList]="filterList"
        (onValueChange)="onFilterValueChange($event)"
        [filterFields]="filterFieldsArray"
        [label]="btnLabel"
        (onBtnClick)="onAddPassenger()"
        [bulkUpdateLabel]="bulkUpdateLabel"
        [bulkDeleteLabel]="bulkDeleteLabel"
        [isBulkPrint]="isBulkPrint"
        [bulkSelectionsItems] = "bulkSelectionsItems"
        [rowItems]="rowItems"
        [attributeData]="attributeData"
        (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
        (onSearchValueChange)="onSearchValueChanges($event)"
        [bulkUpdateHeader]="bulkUpdateHeader"
        [reviewHeader]="reviewHeader"
        [btnUpdate]="btnUpdate"
        (onChangeStatus)="onChangeStatus($event)"
        (setCurrentRowData)="setCurrentRowData($event)"
        (onTransportationDateChange)="onTransportationDateChange($event)"
        [emptyResultMsg]="emptyResultMsg"
        [entityNameToDisplay]="entityNameToDisplay"
        [isDeletedTab]="true"
      >
    </app-mi-table>
      }

    </ng-template>
    <div>
  </div>
</p-tabPanel>

</p-tabView>

<input hidden type="file" id="upload" (change)="onChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>

