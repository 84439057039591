import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { EntityList, LeaveManagementModal, Values } from '../../modal/leaveManagementModal';
import { Language } from 'app/modules/shared/models/language';
import { Country } from 'app/modules/shared/models/country';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { LeaveManagementService } from '../../services/leave-management.service';

@Component({
  selector: 'app-leave-request',
  standalone: true,
  imports: [MITableComponent, TranslateModule],
  templateUrl: './leave-request.component.html',
  styleUrl: './leave-request.component.scss'
})
export class LeaveRequestComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @Output() getDetailsLeaveCountByDriverId: EventEmitter<boolean> = new EventEmitter();
  @Input() driverEntityId;
  data: any;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  leaveListData: any = {};
  totalRecords: number = 0;
  entityName;
  currentRow;
  filterList;
  bulkUpdateHeader;
  reviewHeader;
  deleteLeaveMsg;
  deleteLeaveHeaderMsg;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  isEntity: boolean = true;
  isLeaveRequest: boolean = true;
  attributeData: any;
  filterFieldsArray: any[];
  uniqueId: string = 'leave_management_request';
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    status: [AppSettings.LEAVE_REQUEST_OPTIONS.PENDING],
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
  };
  leaveId: string;
  deleteApproveMsg = this.cs.getLabel('lbl_approve_message');
  deleteApproveHeaderMsg = this.cs.getLabel('lbl_approve_leave_header');
  deleteRejectMsg = this.cs.getLabel('lbl_reject_message');
  deleteRejectHeaderMsg = this.cs.getLabel('lbl_reject_leave_header');

  constructor(private cs: CommonBindingDataService, private route: ActivatedRoute, private messageService: MessageService,
    private leaveManagementService: LeaveManagementService, private configService: ConfigService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.leaveId = this.route.snapshot.paramMap.get('id');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.getData();
    this.initializeColumn();
  }

  initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "leaveType",
        "attributeIndex": 0,
        "field": "leaveType",
        "header": this.cs.getLabel('leave_request.columns.lbl_leave_type')
      },
      {
        "attributeCode": "fromDate",
        "attributeIndex": 1,
        "field": "fromDate",
        "header": this.cs.getLabel('leave_request.columns.lbl_from_date')
      },
      {
        "attributeCode": "toDate",
        "attributeIndex": 2,
        "field": "toDate",
        "header": this.cs.getLabel('leave_request.columns.lbl_to_date')
      },
      {
        "attributeCode": "numberOfDays",
        "attributeIndex": 3,
        "field": "numberOfDays",
        "header": this.cs.getLabel('leave_request.columns.lbl_no_of_days')
      },
      {
        "attributeCode": "leaveReason",
        "attributeIndex": 4,
        "field": "leaveReason",
        "header": this.cs.getLabel('leave_request.columns.lbl_reason')
      },
      {
        "attributeCode": "approvalStatus",
        "attributeIndex": 5,
        "field": "approvalStatus",
        "header": this.cs.getLabel('leave_request.columns.lbl_status')
      },
      {
        "attributeCode": "actionLeaveStatus",
        "attributeIndex": 6,
        "field": "actionLeaveStatus",
        "header": this.cs.getLabel('leave_request.columns.lbl_action')
      },
    ];
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
    })
  }

  getTableView() {
    this.isLoading = false;
    this.searchEntity();
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.isEntity = false;
  }
  
  getFilterView() {
    this.filterList = [
      {
        "attributeCode": "Date",
        "attributeIndex": 0,
        "inputCode": "dateRange"
      }
    ];
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onFilterValueChange(event: { [key: string]: any }) {
    for (const [key, value] of Object.entries(event)) {
      if (value) {
        const attributeValue = Array.isArray(value) ? value : [value];
        if (key === 'Date') {
          this.entityData.fromDateStr = attributeValue[0]?.getTime ? attributeValue[0].getTime() : attributeValue[0];
          this.entityData.toDateStr = attributeValue[1]?.getTime ? attributeValue[1].getTime() : attributeValue[1];
        }
      }
    }
    if (!this.entityData.fromDateStr) {
      delete this.entityData.fromDateStr;
    }
    if (!this.entityData.toDateStr) {
      delete this.entityData.toDateStr;
    }
    this.entityData.offset = event.first;
    this.searchEntity();
  }

  searchEntity() {
    this.entityData.fromDateStr = this.cs.getDate15DaysBefore();
    this.entityData.toDateStr = this.cs.getDate15DaysAfter();
    this.entityData.driverEntityId = this.driverEntityId;
    this.leaveManagementService.searchEntityForLeave(AppSettings.ENTITY_CODE.LEAVES, this.entityData).subscribe((res: LeaveManagementModal) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      this.tableData = res?.data.map(data => ({
        ...data,
        fullName: `${data.driverFirstName} ${data.driverLastName}`
      })) || [];

      this.leaveListData = res;
      const fromDate = Math.min(...this.tableData.map(ele => ele.fromDate));
      const toDate = Math.max(...this.tableData.map(ele => ele.toDate));
      const reqBody = {
        fromDate: fromDate,
        toDate: toDate,
        forTenantCode: this.configService.getForTenantCode(),
      }
    })
  }

  setCurrentRowDataForLeaveApprove(event) {
    const reqBody = {
      forTenantCode: AppSettings.TENANT_CODE,
      countryCode: this.entityData.countryCode = this.country[0]?.countryCode,
      approvalStatus: AppSettings.LEAVE_REQUEST_OPTIONS.APPROVE
    }
    this.confirmationService.confirm({
      header: this.deleteApproveHeaderMsg,
      message: this.deleteApproveMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500 border-0',
      accept: ($event) => {
        this.leaveManagementService.updateActionStatusForLeave(event.leaveId, reqBody).subscribe((res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
          this.searchEntity();
          this.getDetailsLeaveCountByDriverId.emit();
        })
      },
      reject: () => {
      }
    });
  }

  setCurrentRowDataForLeaveReject(event) {
    const reqBody = {
      forTenantCode: AppSettings.TENANT_CODE,
      countryCode: this.entityData.countryCode = this.country[0]?.countryCode,
      approvalStatus: AppSettings.LEAVE_REQUEST_OPTIONS.REJECT
    }
    this.confirmationService.confirm({
      header: this.deleteRejectHeaderMsg,
      message: this.deleteRejectMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500 border-0',
      accept: ($event) => {
        this.leaveManagementService.updateActionStatusForLeave(event.leaveId, reqBody).subscribe((res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
          this.searchEntity();
          this.getDetailsLeaveCountByDriverId.emit();
        })
      },
      reject: () => {
      }
    });
  }
}
