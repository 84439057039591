import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-id',
  standalone: true,
  imports: [],
  templateUrl: './input-id.component.html',
  styleUrl: './input-id.component.scss'
})
export class InputIdComponent {

  @Input() userId: any;

}
