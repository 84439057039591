<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer" (click)="onSaveShift()"
        [label]="'shift.lbl_save' | translate"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
                <i class="mi-lg mi-driver mr-2"></i>
                <span translate>shift.lbl_shift</span>
            </div>
        </ng-template>
        <div class="shift-details mt-2">
            <form  [formGroup]="addShiftForm">
                <div class="grid pl-3 pr-2">
                    <div class="col-6 tab-group">
                        <div class="pl-0">
                            <div class="card p-fluid">
                                <div class="flex justify-content-between flex-wrap">
                                    <div class="flex justify-content-center mb-3">
                                        <label class="card-heading uppercase" translate>shift.shift_details</label>
                                    </div>
                                    <div (click)="edit()" class="edit flex justify-content-center mb-3">
                                        <label class="card-action" translate>shift.lbl_edit</label>
                                        <i class="mi-lg edit_pencil"></i>
                                    </div>
                                </div>

                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate>shift.name</label>
                                    </div>
                                    <div class="col-6 pb-1">
                                        <label class="detail-value">{{ shiftDetails?.shiftName}}</label>
                                    </div>
                                </div>
                                <hr class="hr-line">
                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate>shift.lbl_shift_start_date</label>
                                    </div>
                                    <div class="col-6 pb-1">
                                        <label class="detail-value">{{ shiftDetails?.startDateStr | date: 'dd MMM YYYY' }}</label>
                                    </div>
                                </div>
                                <hr class="hr-line">
                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate>shift.lbl_end_date</label>
                                    </div>
                                    <div class="col-6 pb-1">
                                        <label class="detail-value">{{ shiftDetails?.endDateStr | date: 'dd MMM YYYY' }}</label>
                                    </div>
                                </div>

                                <hr class="hr-line">
                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate>shift.start_time</label>
                                    </div>
                                    <div class="col-6 pb-1">
                                        <label class="detail-value">{{ shiftDetails?.startTimeStr}}</label>
                                    </div>
                                </div>

                                <hr class="hr-line">
                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate> shift.end_time</label>
                                    </div>
                                    <div class="col-6 pb-1">
                                        <label class="detail-value"> {{ shiftDetails?.endTimeStr}}</label>
                                    </div>
                                </div>
                                <hr class="hr-line">
                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate>shift.days</label>
                                    </div>
                                    <div class="col-6 pb-1">

                                        @for (day of shiftDetails?.weekdaysList; track day; let d = $index) {
                                            @if (d === shiftDetails?.weekdaysList.length - 1) {
                                                <label class="detail-value capitalize"> {{ day }}</label>
                                            } @else {
                                                <label class="detail-value capitalize"> {{ day }},</label>
                                            }

                                        }

                                </div>
                                </div>

                                <hr class="hr-line">
                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate>shift.total_break_duration</label>
                                    </div>
                                    <div class="col-6 pb-1">
                                        <label class="detail-value"> {{ shiftDetails?.breakDurationStr }}</label>
                                    </div>
                                </div>

                                <hr class="hr-line">
                                <div class="grid">
                                    <div class="col-6 pt-1">
                                        <label class="detail-heading" translate>shift.driver_type</label>
                                    </div>
                                    <div class="col-6 pb-1">
                                        <label class="detail-value"> {{ shiftDetails?.assignedEntity === DRIVER_TYPE.DRIVERS ? cs.getLabel('shift.driver') : cs.getLabel('shift.group')}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="card p-fluid">
                                <div class="flex justify-content-between flex-wrap">
                                    <div class="flex justify-content-center mb-3">
                                        <label class="card-heading uppercase" translate>shift.breaks</label>
                                    </div>
                                    <div (click)="edit()" class="edit flex justify-content-center mb-3">
                                        <label class="card-action" translate>shift.lbl_edit</label>
                                        <i class="mi-lg edit_pencil"></i>
                                    </div>
                                </div>

                                @for (break of shiftDetails?.shiftBreakList; track break; let j = $index) {
                                    <div class="grid">
                                        <div class="col-6 pt-1">
                                            <label class="detail-heading" translate>shift.lbl_start_time</label>
                                        </div>
                                        <div class="col-6 pb-1">
                                            <label class="detail-value">{{ break?.breakStartTime }}</label>
                                        </div>
                                    </div>
                                    <hr class="hr-line">
                                    <div class="grid">
                                        <div class="col-6 pt-1">
                                            <label class="detail-heading" translate>shift.lbl_end_time</label>
                                        </div>
                                        <div class="col-6 pb-1">
                                            <label class="detail-value">{{ break?.breakEndTime }}</label>
                                        </div>
                                    </div>
                                    <hr class="hr-line">
                                    <div class="grid">
                                        <div class="col-6 pt-1">
                                            <label class="detail-heading" translate>shift.reason</label>
                                        </div>
                                        <div class="col-6 pb-1">
                                            <label class="detail-value">{{ break?.reason }}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    <div class="col-6 tab-group">
                        <div class="card p-fluid">
                            <label class="w-full mt-0 uppercase text-lg font-semibold"
                                translate>shift.lbl_assignment</label>
                            <div class="w-full formgrid grid pt-3">
                                <div class="col-12 flex align-items-center">
                                    @for (assignment of assignmentType; track assignment; let i = $index) {
                                    <p-radioButton
                                      formControlName="assignmentType"
                                      [(ngModel)]="selectedAssignmentType"
                                      [inputId]="assignment.key"
                                      [value]="assignment.key"
                                      (onClick)="assignmentTypeChange(assignment)"
                                     />
                                       <label [for]="assignment.key" class="ml-2 mr-3">
                                        {{assignment.name}}
                                       </label>
                                    }

                                </div>

                                <div class="field col-12">

                                    <div class="driver-div mb-4 mt-3">
                                        <div class="heading col-12 pt-2 pb-1">
                                            <label>Drivers (37)</label>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <input type="text" pInputText class="w-full" [placeholder]="placeholder" />
                                        </div>
                                        @if (selectedAssignmentType === DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER) {
                                            <div class="col-12 mt-3">
                                                <p-scroller [items]="individualDriverList" [lazy]="true"
                                                     [itemSize]="10" [step]="10" [appendOnly]="true" scrollHeight="20rem"
                                                    styleClass="border-0 surface-border" [style]="{'height': '20rem'}">
                                                    <ng-template pTemplate="item" let-driver let-options="options">
                                                        <div class="col-12 flex mb-3">
                                                            <p-checkbox formControlName="drivers" (onChange)="onIndividualCheckboxChange($event, driver, options)" [binary]="true"
                                                             name="driver" [value]="driver.id"></p-checkbox>
                                                            <img class="ml-3 driver-image" src="../../../../../assets/images/mi-theme/light/basic/default_user.svg">
                                                            <div class="col-12 driver-name-div">
                                                                <span class="driver-name">{{ driver?.values?.first_name + ' ' + driver?.values?.last_name}}</span>
                                                                <span class="driver-code">{{ driver?.values?.unique_id }}</span>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-scroller>
                                            </div>
                                        } @else {
                                            <div class="col-12 mt-3">
                                                <p-scroller [items]="driverGroupList"
                                                    [itemSize]="10" scrollHeight="20rem"
                                                    styleClass="border-0 surface-border" [style]="{'height': '20rem'}">
                                                    <ng-template pTemplate="item" let-group let-options="options">
                                                        <div class="col-12 flex mb-3">
                                                            <p-checkbox formControlName="groups" (onChange)="onGroupCheckboxChange($event, group)" [binary]="true"
                                                              name="group"  value="group.id"></p-checkbox>
                                                            <div class="col-12 driver-group-div ml-3">
                                                                <span class="driver-name">{{group?.values?.group_name}}</span>
                                                                <span class="driver-group-count">({{group?.values?.drivers?.length ? group?.values?.drivers?.length : 0}} Drivers)</span>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-scroller>
                                            </div>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="col-12 pr-4 flex justify-content-end flex-wrap">
                <button pButton class="mr-2 bg-white border-500 default-text-light-black-color cursor-pointer"
                    [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="flex align-items-center justify-content-center cursor-pointer" (click)="onSaveShift()"
                    [label]="'shift.lbl_save' | translate"></p-button>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>