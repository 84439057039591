import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityCount, EntityCountsResponse, EntityList } from '../../../vehicles/modal/entityModal';
import { ListResponse, Values } from '../../../vehicles/modal/listResponse';
import { EntityService } from '../../../shared/services/entity.service';
import { FilterResponseModal } from '../../../manage-groups/modal/filterResponseModal';
import { AddBroadcastComponent } from '../add-broadcast/add-broadcast.component';
import { ConfigService } from 'app/modules/shared/services/config.service';

@Component({
  selector: 'app-broadcast-list',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, AsyncPipe, AddBroadcastComponent],
  templateUrl: './broadcast-list.component.html',
  styleUrl: './broadcast-list.component.scss'
})
export class BroadcastListComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @Output() setCountAllOfBroadcast: EventEmitter<any> = new EventEmitter<any>();
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  @Input() moduleName: string;
  filterList;
  btnLabel: string;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  deleteBroadcastMsg: string;
  deleteBroadcastHeaderMsg: string;
  deleteLabel: string = this.cs.getLabel('broadcasting.delete');
  editLabel: string = this.cs.getLabel('broadcasting.edit');
  filterFieldsArray: any[];
  attributeData: any;
  data: any;
  rowItems: MenuItem[] | undefined;
  currentRow: any;
  visible: boolean = false;
  totalRecords: number = 0;
  attributeIdOfGroupStatus: string;
  isAddBroadcastVisible: boolean = false;
  broadcastId: string;
  broadcastListData: any = {};

  constructor(private broadcastService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService, private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService) { }

  ngOnInit() {
    this.setRowItems();
    this.setLabels();
    this.getAttributeData();
    this.getData();
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        },
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.TRASH_FULL + " wh-16",
        iconClass: "bulk-update-icon",
        visible: false,
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
        },
      },
    ];
  }

  public setLabels() {
    this.btnLabel = this.cs.getLabel('broadcasting.lbl_new_broadcast');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.deleteBroadcastMsg = this.cs.getLabel('broadcasting.message.confirm_delete_msg');
    this.deleteBroadcastHeaderMsg = this.cs.getLabel('broadcasting.message.confirm_delete_header');
  }

  getAttributeData() {
    this.broadcastService.getAttributeDefinition(AppSettings.ENTITY_CODE.BROADCAST_MESSAGE, this.country[0]?.tenantId, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.attributeData = res;
        this.isLoading = false;
        this.filterFieldsArray = res?.tabs[0]?.groups[0]?.fields;
      }
    });
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.rowItems[0].disabled = this.currentRow.deleted || event?.select_time <= Date.now();
    this.rowItems[1].disabled = this.currentRow.deleted;
    this.cd.detectChanges();
  }

  update() {
    this.onUpdateBroadcast(this.currentRow.id);
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteBroadcastHeaderMsg,
      message: this.deleteBroadcastMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === 'row') {
          this.onBroadcastDelete(this.currentRow.id);
        }
      },
      reject: () => {
      }
    });
  }

  getTableView() {
    this.broadcastService.getAttributeDefinition(AppSettings.ENTITY_CODE.BROADCAST_MESSAGE, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.attributeIdOfGroupStatus = res?.tabs[0]?.groups[0]?.fields?.find(element => element.attributeCode === 'group_status')?.attributeId;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.allColumns = this.cs.getColumns(this.data);
        this.filterAvailableColumns = _.clone(this.allColumns);
        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            "broadcast_message" + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });
        this.filterAvailableColumns = _.clone(this.allColumns);
      }
    });
    this.searchEntity();
  }

  getFilterView() {
    this.broadcastService.getAttributeDefinition(AppSettings.ENTITY_CODE.BROADCAST_MESSAGE, this.country[0]?.tenantId, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe((filterResponse: FilterResponseModal) => {
      if (filterResponse) {
        this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
        this.filterList = this.cs.getColumns(filterResponse);
        this.filterList = _.sortBy(this.filterList, 'attributeIndex');
      }
    })
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
    })
  }


  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.broadcastService.searchEntity(AppSettings.ENTITY_CODE.BROADCAST_MESSAGE, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      this.tableData = this.cs.getTableData(res);
      this.broadcastListData = res;
    })
  }

  onAddBroadcast() {
    this.isAddBroadcastVisible = true
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onBroadcastDelete(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: AppSettings.TENANT_CODE,
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.BROADCAST_MESSAGE,
      entityIds: [
        event
      ]
    };
    this.broadcastService.deleteEntityActionStatus(requestBody).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('broadcasting.message.broadcast_delete_success') });
      this.searchEntity();
    })
  }

  onUpdateBroadcast(broadcastId) {
    this.isAddBroadcastVisible = true;
    this.broadcastId = broadcastId;
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/broadcast/' + event.data.id,]);
    }
  }

  onFilterValueChange(event) {
    const broadcastData = event;
    this.entityData.filters = Object.entries(broadcastData)
      .filter(([key, value]) => value)
      .map(([key, value]) => ({ attributeCode: key, attributeValue: value }));

    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onToggleOverlayForAdd() {
    this.isAddBroadcastVisible = false;
    this.broadcastId = null;
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted =  AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      default:
        break;
    }
  }
}
