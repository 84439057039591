    @if (field.inputCode === 'date') {
        <p-calendar  (onSelect)="changedValue($event)" [showIcon]="true" [placeholder]="placeholder" ></p-calendar>
    }
    <!-- @else if (field.inputCode === 'inputAutocomplete') {
        <p-autoComplete 
        [(ngModel)]="selectedItems" 
        [suggestions]="items" 
        (completeMethod)="search($event)" 
        [multiple]="true"
        optionLabel="group_name"
        placeholder="Search group" >
        <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div class="passenger-popup bold-name">           
                <span class="ellipsis mr-2">
                  {{item.group_name}}
                </span>
              </div>
            </div>
          </ng-template>
        </p-autoComplete>
    } -->
     @else {
        <p-multiSelect appendTo="body" #multiSelect [placeholder]="'lbl_select_field' | translate: {field:label}"
            [options]="field.presetValues"
            [(ngModel)]="selectedValue"
            optionLabel="labelKey"
            [showToggleAll]="true"
            optionValue="labelValue"
            [filter]="true"
            (onPanelHide)="clearSearchField(multiSelect)"
            (onChange)="changedValue($event)"
            (onSelectAllChange)="onSelectAllChange($event)"
            (onFilter)="onFilter($event)">

            <ng-template pTemplate="filtericon">
                <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
            </ng-template>
        </p-multiSelect>
    }

