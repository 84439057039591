@if(hideService.getVisibilityState(cardId) | async){
<div class="card h-full shadow-card">
  <div class="flex justify-content-between align-items-center">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.recent_comments
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>
  <div class="scrollable-comment-section">
    @for( item of commentSectionsData; track item){
    <div class="flex flex-row comment-section">
      <div class="image-container">
        <span class="mi-lg mi-booking_user wh-18 person-icon"></span>
      </div>
      <div class="flex flex-column flex-grow-1 comment-container">
        <div class="flex flex-row justify-content-between">
          <p class="comment-header">
            <span class="blue-text">{{ item.commenter }}</span>
            <span class="mx-2" translate>dashboard.commented_on</span>
            <span class="blue-text">Issue: {{ item.issueNumber }}</span>
          </p>
          <p class="timeline-text font-13-600">{{ item.timestamp }} min ago</p>
        </div>
        <div class="comment-details">
          {{ item.comment }}
        </div>
      </div>
    </div>
    }
  </div>
</div>
}
