
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators, FormArray } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { Country } from 'app/modules/shared/models/country';
import { MultiSelectModule } from 'primeng/multiselect';
import { SettingsService } from 'app/modules/settings/settings.service';
import { MenuModule } from 'primeng/menu';
import { NgClass } from '@angular/common';

interface AttributeDataType {
  tenantId?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: number;
  updatedAt?: number;
  attributeInputTypeId?: number;
  inputTypeCode?: string;
  inputName?: string;
  useInCustomField?: boolean;
  imperialUnit?: string | null;
  metricUnit?: string | null;
  countryId?: string;
}

@Component({
  selector: 'app-settings-custom-fields',
  standalone: true,
  imports: [TranslateModule, ButtonModule, ToastModule, InputTextModule, ChipsModule, InputTextareaModule, MenuModule, ReactiveFormsModule, FormsModule, TabViewModule, DropdownModule, CheckboxModule, MultiSelectModule, NgClass],
  templateUrl: './settings-custom-fields.component.html',
  styleUrl: './settings-custom-fields.component.scss'
})
export class SettingsCustomFieldsComponent implements OnInit{
  miIcons = AppIcons;
  customFieldForm: FormGroup;
  language: Language;
  country: Country;
  moduleList: any = AppSettings.MODULE_NAME;
  moduleNameList: { label: string, key: string }[] = [];
  selectedModuleName: string;
  selectedModuleNameFilter: string;
  uniqueTabs: Set<string> = new Set();
  uniqueGroups: Set<string> = new Set();
  dataTypes: AttributeDataType[] = [];
  validationList = AppSettings.CUSTOM_FIELD_VALIDATION;
  showCustomLabel: boolean = false;
  isFormShow: boolean = false;
  fieldName: string;
  validationPattern: string;
  allowActions: string[] = [];
  inputTypeIdObj;
  contentArray: string[] = [];
  isShowCustomFieldList: boolean = false;
  isCustomFieldDetailedData: boolean = false;
  attributeCodes: string;
  selectedAttributeCodes: string;
  hoveredItem: string | null = null;
  rowItems: MenuItem[] | undefined;
  normalizedAttributeCode: string;
  customFieldDetailedData;
  deleteLabel: string = this.cs.getLabel('lbl_delete');
  editLabel: string = this.cs.getLabel('lbl_edit');
  deleteMsg: string = this.cs.getLabel('settings.custom_fields.lbl_delete_confirm_message');
  deleteHeaderMsg: string = this.cs.getLabel('settings.custom_fields.confirm_delete_header');
  attributeCodeData;
  setCurrentRow;
  entityCodeForCustomFieldDetails: string;
  tabCodeForCustomFieldDetails: string;
  sectionForCustomFieldDetails: string;
  allowActionsViewArray;
  exampleLabel;
  helpTipsLabel;
  patternForDetails;
  requiredYesNo;
  firstObject;
  matchedAttributeData;
  inputType;
  isChipsDisabled: boolean = false;
  attributeId: string;
  updateForm: any;
  entityData = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: '',
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc'
  }

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder,
    private settingsService: SettingsService, private entityService: EntityService, private cdr: ChangeDetectorRef,
    private messageService: MessageService, private confirmationService: ConfirmationService,) {
  }

  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.formValidation();
    this.setRowItems();
    this.getModuleNames();
    this.getAttributeDataTypes();
    this.onOtherOptionChanges();
    this.subscribeToAllowActionChanges();
  }

  formValidation() {
    this.customFieldForm = this.formBuilder.group({
      moduleName: ['', [Validators.required]],
      tab: [''],
      sections: [''],
      dataType: ['', [Validators.required]],
      content: this.formBuilder.array([]),
      otherOption: [false],
      allowActionSearch: [false],
      allowActionAdvanceFilter: [false],
      allowActionManageColumn: [false],
      informationText: [''],
      note: ['', [Validators.required]],
      validation: [''],
      requiredField: [false],
    });
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.editCustomField();
        }
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_DELETE + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.delete();
        },
      },
    ];
  }

  delete() {
    const attributeId = this.matchedAttributeData ? this.matchedAttributeData[0].attributeId : this.firstObject.attributeId;
    const attributeCode = this.matchedAttributeData ? this.matchedAttributeData[0].attributeCode : this.firstObject.attributeCode;
    this.confirmationService.confirm({
      header: this.deleteHeaderMsg,
      message: this.deleteMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.settingsService.deleteCustomFieldById(this.selectedModuleNameFilter, attributeId, attributeCode).subscribe((res : any) => {
          this.messageService.add({ key:'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
        })
      },
      reject: () => {
      }
    });
  }

  editCustomField() {
    this.isCustomFieldDetailedData = false;
    this.isFormShow = true;
    this.attributeId = this.setCurrentRow.attributeId;
    this.fieldName = this.setCurrentRow.attributeCode;
    const validations = JSON.parse(this.setCurrentRow?.validations || '{}');
    let selectedValidationValues='';

    const patterns = {
      '^[a-zA-Z\\s]*$': ['alphabetsAllowed'],
      '^[0-9]+$': ['numbersAllowed'],
      '^[!@#$%^&*()_+\\-=\[\\]{}|;:\'",.<>?/~"\\]*$': ['specialCharactersAllowed'],
      '^[a-zA-Z0-9\\s]*$': ['alphabetsAllowed', 'numbersAllowed'],
      '^[a-zA-Z!@#$%^&*()_+\\-=\'";:,.?/\\\\ ]*$': ['alphabetsAllowed', 'specialCharactersAllowed'],
      '^[0-9!@#$%^&*()_+\\-=\'";:,.?/\\\\ ]*$': ['numbersAllowed', 'specialCharactersAllowed']
    };
    
    if (validations.pattern && patterns.hasOwnProperty(validations.pattern)) {
      selectedValidationValues =patterns[validations.pattern];
      this.validationPattern = validations.pattern;
    }
    
    this.customFieldForm.patchValue({
      moduleName: this.setCurrentRow?.entityCode,
      tab: this.setCurrentRow?.tabCode,
      sections: this.setCurrentRow?.groupCode,
      dataType: this.setCurrentRow?.inputType?.inputTypeCode,
      content: this.setCurrentRow?.attributePresetValues,
      otherOption: this.setCurrentRow?.attributePresetValues[0]?.labelKey,
      allowActionSearch: this.setCurrentRow?.views,
      allowActionAdvanceFilter: this.setCurrentRow?.views,
      allowActionManageColumn: this.setCurrentRow?.views,
      informationText: this.setCurrentRow?.attributeLabels[2]?.labelValue,
      note: this.setCurrentRow?.attributeLabels[3]?.labelValue,
      validation: selectedValidationValues,
      requiredField: validations?.required || false
    });
    
  }

  updateCustomFields() {
    const attributeCode = this.fieldName;
    const transformedAttributeCode = attributeCode?.toLowerCase().replace(/\s+/g, '_');
    const transformedTab = this.customFieldForm.value.tab.toLowerCase().replace(/\s+/g, '_');
    const transformedSection = this.customFieldForm.value.sections.toLowerCase().replace(/\s+/g, '_');
    const validations = {
      required: this.customFieldForm.value.requiredField,
      ...(this.validationPattern ? { pattern: this.validationPattern } : {})
    };
    const reqBody = {
      forTenantCode: AppSettings.TENANT_CODE,
      inputTypeId: this.customFieldForm.value.dataType,
      dataType: this.inputTypeIdObj,
      attributeCode: transformedAttributeCode,
      presetValue: {},
      validations: validations,
      encrypted: true,
      multiValue: this.inputType === 'checkBox' ? true : false,
      editable: true,
      customField: true,
      allowActions: this.allowActions,
      entityViewAttribute: {
        tabCode: transformedTab,
        groupCode: transformedSection
      },
      fieldName: transformedAttributeCode,
      informationText: this.customFieldForm.value.informationText,
      note: this.customFieldForm.value.note,
      content: this.customFieldForm.value.content,
      other: this.customFieldForm.value.otherOption
    }
    this.settingsService.updateCustomFields(reqBody, this.customFieldForm.value.moduleName, this.attributeId).subscribe((res : any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
    },
    error => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    });
  }

  selectCustomList(item) {
    this.selectedAttributeCodes = item;
    if(item) {
      this.normalizedAttributeCode = item.toLowerCase().replace(/\s+/g, '_');
      this.matchedAttributeData = this.attributeCodeData.filter(item => item.attributeCode.toLowerCase() === this.normalizedAttributeCode);
      this.getCustomFieldDetailsByAttributeId(this.selectedModuleNameFilter, this.matchedAttributeData);
    }
  }

  onMouseEnter(item: string): void {
    this.hoveredItem = item;
  }

  onMouseLeave(): void {
    this.hoveredItem = null;
  }

  onIconClick(event: Event, menu: any) {
    event.stopPropagation(); 
    menu.toggle(event);
  }

  addCustomLabel() {
    this.showCustomLabel = true;
    this.isShowCustomFieldList = false
    this.isCustomFieldDetailedData = false;
  }

  onAddCustomLabel() {
    if(this.fieldName) {
      this.isFormShow = true;
      this.customFieldForm.reset();
    }
  }

  onCancelCustomLabel() {
    this.fieldName = '';
    this.showCustomLabel = false;
    this.isFormShow = false;
    this.isShowCustomFieldList = true;
  }

  get content(): FormArray {
    return this.customFieldForm.get('content') as FormArray;
  }

  onOtherOptionChange(): void {
    const otherOptionChecked = this.customFieldForm.get('otherOption')?.value;
    if (otherOptionChecked) {
      if (!this.contentArray.includes('Other')) {
        this.contentArray.push('Other');
        this.updateContentArray();
      }
    } else {
      const index = this.contentArray.indexOf('Other');
      if (index >= 0) {
        this.contentArray.splice(index, 1);
        this.updateContentArray();
      }
    }
  }

  updateContentArray(): void {
    const formArray = this.content;
    formArray.clear();
    this.contentArray.forEach(value => {
      formArray.push(this.formBuilder.control(value));
    });
  }

  onOtherOptionChanges(): void {
    this.customFieldForm.get('otherOption')?.valueChanges.subscribe(() => {
      this.onOtherOptionChange();
    });
  }

  subscribeToAllowActionChanges() {
    this.customFieldForm.get('allowActionSearch')?.valueChanges.subscribe((value: boolean) => {
      this.updateAllowActions(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_FILTER_VIEW, value);
    });

    this.customFieldForm.get('allowActionAdvanceFilter')?.valueChanges.subscribe((value: boolean) => {
      this.updateAllowActions(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.ADVANCED_FILTER_VIEW, value);
    });

    this.customFieldForm.get('allowActionManageColumn')?.valueChanges.subscribe((value: boolean) => {
      this.updateAllowActions(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_TABLE_VIEW, value);
    });
  }

  updateAllowActions(action: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.allowActions.includes(action)) {
        this.allowActions.push(action);
      }
    } else {
      const index = this.allowActions.indexOf(action);
      if (index > -1) {
        this.allowActions.splice(index, 1);
      }
    }
  }

  onChangeValidation(event) {
    const selectedValues = event.value;
    this.validationPattern = '';

    if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value)) {
      this.validationPattern = '';
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_NUMERIC;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_SPECIAL;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.NUMBER_SPECIAL;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHABETS;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.NUMBERS;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.SPECIAL_CHARACTERS;
    }
  }

  getModuleNames() {
    const requestBody = {
      limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_CUSTOM_FIELDS,
      offset: 0
    };
    this.entityService.getEntityConfiguration(requestBody, AppSettings.TENANT_CODE).subscribe({
      next: (res: any) => {
        res.data.forEach(element => {
          const module = this.moduleList.find(module => module.key === element.entityCode);
          if (module) {
            module.value = element.entityConfigId;
            module.showInAccess = element.showInAccess
            this.moduleNameList.push({
              label: module.label,
              key: module.key
            });
          }
        });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  getSelectedModuleNameFilter(event) {
    this.selectedModuleNameFilter = event.value;
    this.settingsService.getCustomFieldListByEntityCode(this.selectedModuleNameFilter, this.entityData).subscribe((res: any) => {
      if(res.count > 0) {
        this.isShowCustomFieldList = true;
      }
      else {
        this.isShowCustomFieldList = false;
      }
      this.attributeCodeData = res.data.map(item => item);
      const transformedAttributeCodes = res.data.map(item => this.transformAttributeCode(item.attributeCode));
      this.attributeCodes = transformedAttributeCodes;
      this.selectedAttributeCodes = this.attributeCodes?.[0];
      this.firstObject = this.attributeCodeData[0];
      if(res.data.length > 0) {
        this.getCustomFieldDetailsByAttributeId(this.selectedModuleNameFilter, res.data);
      }
      else {
        this.isCustomFieldDetailedData = false;
        this.isFormShow = false;
      }
    })
  }

  getCustomFieldDetailsByAttributeId(selectedModuleNameFilter, data) {
    this.settingsService.getCustomFieldDetailsByAttributeId(selectedModuleNameFilter, data[0].attributeId, data[0].attributeCode).subscribe((res: any) => {
      if(res) {
        this.setCurrentRow = res;
        const attributeCodes = data.map(item => item.attributeCode);
        this.customFieldDetailedData = res;
        this.entityCodeForCustomFieldDetails = res.entityCode;
        this.tabCodeForCustomFieldDetails = res.tabCode;
        this.sectionForCustomFieldDetails = res.groupCode;
        this.isCustomFieldDetailedData = true;
        this.entityCodeForCustomFieldDetails = this.transformAttributeCode(this.entityCodeForCustomFieldDetails);
        this.tabCodeForCustomFieldDetails = this.transformAttributeCode(this.tabCodeForCustomFieldDetails);
        this.sectionForCustomFieldDetails = this.transformAttributeCode(this.sectionForCustomFieldDetails);
        this.allowActionsViewArray = res.views;
        this.exampleLabel = res.attributeLabels.find(item => item.labelKey === res.entityCode+".fields."+attributeCodes+".example")?.labelValue || '';
        this.helpTipsLabel = res.attributeLabels.find(item => item.labelKey === res.entityCode+".fields."+attributeCodes+".helpTips")?.labelValue || '';
        const validationObj = JSON.parse(res.validations);
        this.patternForDetails = validationObj.pattern || '';
        this.requiredYesNo = validationObj.required ? this.cs.getLabel('lbl_yes') : this.cs.getLabel('lbl_no');
      }
    })
  }

  transformAttributeCode(attributeCode: string): string {
    const replaced = attributeCode.replace(/_/g, ' ');
    const capitalized = replaced.replace(/\b\w/g, char => char.toUpperCase());
    return capitalized;
  }

  getSelectedModuleName(event) {
    this.uniqueTabs.clear();
    this.uniqueGroups.clear();
    this.selectedModuleName = event.value;
    this.getTabsAndSections(this.selectedModuleName);
  }

  getTabsAndSections(selectedModuleName) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityService.getAttributeDefinition(selectedModuleName, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      this.uniqueTabs.clear();
      res.attributeLabels.forEach(label => {
        if (label.labelKey.startsWith(selectedModuleName + '.tabs.')) {
          this.uniqueTabs.add(label.labelValue);
        } else if (label.labelKey.startsWith(selectedModuleName + '.groups.')) {
          this.uniqueGroups.add(label.labelValue);
        }
      });
      this.cdr.detectChanges();
    })
  }

  getAttributeDataTypes(): void {
    this.settingsService.getAttributeDataTypes().subscribe(res => {
      const data = res as AttributeDataType[];
      this.dataTypes = data
        .filter(item => item.useInCustomField)
        .map(item => ({
          inputName: item.inputName,
          attributeInputTypeId: item.attributeInputTypeId,
          inputTypeCode: item.inputTypeCode,
        }));
    });
  }

  // uniqueTabsArray(): string[] {
  //   return Array.from(this.uniqueTabs);
  // }

  uniqueTabsArray(): { label: string, value: string }[] {
    return Array.from(this.uniqueTabs).map(tab => ({ label: tab, value: tab }));
  }

  uniqueGroupsArray(): string[] {
    return Array.from(this.uniqueGroups);
  }

  onCancel() {
    this.customFieldForm.reset();
  }

  onChangeDataType(event: any): void {
    this.inputType = event.value.inputTypeCode;
    if (AppSettings.CUSTOM_FIELD_INPUT_TYPE_MAPPING[this.inputType]) {
      this.inputTypeIdObj = AppSettings.CUSTOM_FIELD_INPUT_TYPE_MAPPING[this.inputType];
    } else {
      this.inputTypeIdObj = '';
    }
    this.isChipsDisabled = !(event.value.inputName === 'Check Box' || event.value.inputName === 'Dropdown');
  }

  onAddCustomFields() {
    const attributeCode = this.fieldName;
    const transformedAttributeCode = attributeCode?.toLowerCase().replace(/\s+/g, '_');
    const transformedTab = this.customFieldForm.value.tab.toLowerCase().replace(/\s+/g, '_');
    const transformedSection = this.customFieldForm.value.sections.toLowerCase().replace(/\s+/g, '_');
    if (this.customFieldForm.valid) {
      const validations = {
        required: this.customFieldForm.value.requiredField,
        ...(this.validationPattern ? { pattern: this.validationPattern } : {})
      };
      const reqBody = {
        forTenantCode: AppSettings.TENANT_CODE,
        inputTypeId: this.customFieldForm.value.dataType,
        dataType: this.inputTypeIdObj,
        attributeCode: transformedAttributeCode,
        presetValue: {},
        validations: validations,
        encrypted: true,
        multiValue: this.inputType === 'checkBox' ? true : false,
        editable: true,
        customField: true,
        allowActions: this.allowActions,
        entityViewAttribute: {
          tabCode: transformedTab,
          groupCode: transformedSection
        },
        fieldName: transformedAttributeCode,
        informationText: this.customFieldForm.value.informationText,
        note: this.customFieldForm.value.note,
        content: this.customFieldForm.value.content,
        other: this.customFieldForm.value.otherOption
      }
      this.saveCustomFields(reqBody, this.customFieldForm.value.moduleName);
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

  saveCustomFields(reqBody, entityCode) {
    this.settingsService.saveCustomFields(reqBody, entityCode).subscribe((res : any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
    })
  }

}
