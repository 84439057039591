import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { RouterModule } from "@angular/router";
import * as _ from 'lodash';
import { ConfirmationService, MessageService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MenuModule } from "primeng/menu";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { AppIcons } from "../../app.icons";
import { AppSettings } from "../../app.settings";
import { CommonBindingDataService } from "../../services/common-binding-data.service";
import { BulkUpdateDialogComponent } from "../bulk-update-dialog/bulk-update-dialog.component";
import { MITableService } from "./mitable.service";
import { UserConfig } from "./modal/config";
import { Column } from "./modal/table";
import { StatusCellComponent } from "./status-cell/status-cell.component";

import { CommonModule, DatePipe, JsonPipe, NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from "primeng/inputtext";
import { PaginatorModule } from "primeng/paginator";
import { TooltipModule } from 'primeng/tooltip';
import { BulkLeaveUpdateDialogComponent } from "../../../driver/components/bulk-leave-update-dialog/bulk-leave-update-dialog.component";
import { GroupColorComponent } from "../../../manage-groups/components/group-color/group-color.component";
import { ConfigService } from "../../services/config.service";
import { EntityService } from "../../services/entity.service";
import { AdvanceFilterFormComponent } from "../advance-filter-form/advance-filter-form.component";
import { FilterBoxComponent } from "../filter-box/filter-box.component";
import { ManageColumnComponent } from "../manage-column/manage-column.component";
import { ManageColumnService } from "../manage-column/manage-column.service";
import { GetTimeForQueueComponent } from "../mi-fields/get-time-for-queue/get-time-for-queue.component";
import { MiTooltipComponent } from "../mi-fields/mi-tooltip/mi-tooltip.component";
import { QueueLocationDropdownComponent } from "../mi-fields/queue-location-dropdown/queue-location-dropdown.component";
import { PerPageColumnsComponent } from "../per-page-columns/per-page-columns.component";
import { PrintLabelsDialogComponent } from "../print-labels-dialog/print-labels-dialog.component";
import { QuickFilterFormComponent } from "../quick-filter-form/quick-filter-form.component";
import { FilterService } from "../advance-filter-form/filter.service";
import { TruncatePipe } from "../../pipes/truncate.pipe";
import { MiImageContainerComponent } from "../../ui-sharable/mi-image-container/mi-image-container.component";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

enum IncidentStatus {
  Open = 'Open',
  UnderReview = 'Under Review',
  InProgress = 'In Progress',
  Resolved = 'Resolved'
}
@Component({
  selector: "app-mi-table",
  templateUrl: "./mi-table.component.html",
  styleUrls: ["./mi-table.component.scss"],
  standalone: true,
  imports: [
    InputTextModule,
    AdvanceFilterFormComponent,
    FilterBoxComponent,
    ButtonModule,
    PaginatorModule,
    PerPageColumnsComponent,
    MenuModule,
    TableModule,
    StatusCellComponent,
    BulkUpdateDialogComponent,
    ToastModule,
    ConfirmDialogModule,
    TranslateModule,
    ManageColumnComponent,
    PerPageColumnsComponent,
    MiTooltipComponent,
    TooltipModule,
    QuickFilterFormComponent,
    PrintLabelsDialogComponent,
    JsonPipe,
    NgClass,
    DatePipe,
    DropdownModule,
    FormsModule,
    CalendarModule,
    InputSwitchModule,
    FormsModule,
    GroupColorComponent,
    BulkLeaveUpdateDialogComponent,
    GetTimeForQueueComponent,
    QueueLocationDropdownComponent,
    InputSwitchModule,
    RouterModule,
    TruncatePipe,
    MiImageContainerComponent,
    CommonModule
  ],
})
export class MITableComponent implements OnInit {
  @ViewChildren('queueLocationButton') queueLocationRef: QueryList<ElementRef>;
  miIcons = AppIcons;
  searchValue: string = '';
  @Output() onBtnClick: EventEmitter<any> = new EventEmitter();
  @Input() collectionOfColumns;
  @Input() attributeLabels: any;
  @Input() data: [];
  @Input() entityName: string;
  @Input() staticEntity: string;
  @Input() entityNameForBulkUpdate: string;
  @Input() isLoading: boolean;
  @Input() uniqueId: string;
  @Input() isEntity: boolean = true;
  @Input() isLeaveRequest: boolean;
  @Input() filterSize: number =3;
  @Input() filterList;
  @Input() filterFields = [];
  @Input() label: string;
  @Input() bulkUpdateLabel = 'Update';
  @Input() bulkDeleteLabel = 'Delete';
  @Input() bulkPrintLabel = 'Print';
  @Input() attributeData;
  @Input() rowItems;
  @Input() bulkSelectionsItems;
  @Input() showBulkUpdateDialog;
  @Input() showBulkLeaveUpdateDialog;
  @Input() visible;
  @Input() totalRecords: number;
  @Input() visiblePrintLabel;
  @Input() bulkUpdateHeader;
  @Input() reviewHeader;
  @Input() btnUpdate;
  @Input() emptyResultMsg:  any = this.cs.getLabel('lbl_no_results_to_show');
  @Input() entityNameToDisplay: any = this.cs.getLabel('Vehicle');
  @Input() message: string;
  @Input() header: string;
  @Input() isDeletedTab: boolean = false;


  @Output() visibleChange = new EventEmitter<any>();
  @Output() showBulkUpdateDialogChange = new EventEmitter<any>();
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();
  @Output() itemPerPageChange: EventEmitter<any> = new EventEmitter();
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onDeleteRowConfirmation: EventEmitter<any> = new EventEmitter();
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onChangeStatus: EventEmitter<any> = new EventEmitter();
  @Output() onChangeAssignDriverGroup: EventEmitter<any> = new EventEmitter();
  @Output() onChangeDefaultVehicle: EventEmitter<any> = new EventEmitter();
  @Output() onChangeSelectCheckInLocation: EventEmitter<any> = new EventEmitter();
  @Output() onChangeOffDutyReason: EventEmitter<any> = new EventEmitter();
  @Output() onChangeOnDutyReason: EventEmitter<any> = new EventEmitter();
  @Output() onEventStatusChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() onOrganizationStatusChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() queueLocationFromMiTable: EventEmitter<any> = new EventEmitter();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onRowSelection = new EventEmitter<any>();
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onBulkDataUpdateEvent = new EventEmitter<boolean>();
  @Output() onBulkLeaveDataUpdateEvent = new EventEmitter<boolean>();
  @Output() onBulkDataDeleteEvent = new EventEmitter<any>();
  @Output() setCurrentRowData = new EventEmitter<any>();
  @Output() setCurrentRowDataForLeaveApprove = new EventEmitter<any>();
  @Output() setCurrentRowDataForLeaveReject = new EventEmitter<any>();
  @Output() onTransportationDateChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(QuickFilterFormComponent) quickFilterCmp!: QuickFilterFormComponent;
  @Output() onAssignDriver: EventEmitter<any> = new EventEmitter();
  @Output() onStaticBulkDataUpdateEvent: EventEmitter<any> = new EventEmitter();

  selectedFilterCount: number =0;
  columns;
  manageCols;
  selectedData;
  cols = [];
  selectedColumns!: Column[];
  btnLabel: string;
  value2;
  columnValue: number = 50;
  first2: number = 0;
  value: string;
  rows2: number = 10;
  @Input() itemsPerPage: number;
  isVisible: boolean = false;
  show: boolean = false;
  position: string = "center";
  selectedRows: any;
  isModal: boolean = false;
  removeOnChangeValue;
  currentRow: any;
  bulkRowCount;
  moduleName;
  smartSearchTimer: any;
  sortable: boolean = true;
  selectedPrintData: any[] = [];
  selectedEntityId: any;
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];
  manageColumnFromLocal: boolean = false;
  EXCLUDE_CHECKBOX= [ this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.SEGMENT,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.BROADCAST_MESSAGE,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.ORGANIZATION,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.MANAGE_SHIFT,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.LEAVES,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.LEAVE_MANAGEMENT_HISTORY,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.LEAVE_MANAGEMENT_REQUEST,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.VEHICLE_ASSIGNMENT_HISTORY,
    this.appSettings?.ENTITY_CODE_FOR_REMOVE_CHECKBOX?.EVENT,
  ];
  HOUR_MINUTE_FORMAT: any = AppSettings.HOUR_MINUTE_FORMAT;
  dd_MMM_yyyy_hh_mm_FORMAT: any = AppSettings.dd_MMM_yyyy_hh_mm_FORMAT;
  dd_MMM_yyyy_hh_mm_FORMAT2: any = AppSettings.dd_MMM_yyyy_hh_mm_FORMAT2;
  dd_MMM_yyyy_FORMAT: any = AppSettings.dd_MMM_yyyy_FORMAT;
  hh_mm_dd_MMM_yyyy_FORMAT: any = AppSettings.hh_mm_dd_MMM_yyyy_FORMAT;
  DATE_FORMAT_DD_MM_YYYY_hh_mm_a: any = AppSettings.DATE_FORMAT_DD_MM_YYYY_hh_mm_a;
  breakLabel: any;
  breaksLabel: any;
  isQueueLocationBtnDisabled: boolean = true;
  EVENT_STATUS = AppSettings.EVENT_STATUS;
  DRIVER_TYPE = AppSettings.DRIVER_TYPE;
  entityImage: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  exclude_modules = ['incidents_and_complaint'];
  titleForTransportationEndDate = this.cs.getLabel('passengers.title_for_transportation_end_date_in_passenger_list')
  codesToRemoveFromManageColumn = ["features", "vehicle_images", 'driver_profile_image', "passenger_profile_image", "event_image"];
  filterValues: any;
  constructor(
    private cs: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private miTableService: MITableService,
    public appSettings: AppSettings,
    private cd: ChangeDetectorRef,
    private manageColumnService: ManageColumnService,
    private filterService: FilterService
  ) { }

  ngOnInit() {

    this.updateEntityName();
    this.filterValues = this.filterService.getFilterState('filter');
    this.collectionOfColumns= _.reject( this.collectionOfColumns, attribute => 
      _.includes(this.codesToRemoveFromManageColumn, attribute.attributeCode)
    );

    this.moduleName = this.entityName;
    this.getPreferredColumns();
    this.btnLabel = this.cs.getLabel("label_filters");
    this.setLabel();
    this.updateFilterSize();
    this.manageColumnService.visibleColumns.subscribe(data => {
      const tempData =_.cloneDeep(data);
      this.manageCols= _.cloneDeep(tempData);
    });

    // this.setFilterData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsPerPage && changes.itemsPerPage.currentValue !== undefined) {
      this.rows2 = changes.itemsPerPage.currentValue;
    }
  }

  private setFilterData() { 
    const prevData = this.filterService.getFilterState(this.entityName || this.uniqueId);
    this.selectedFilterCount = prevData.selectedFilterCount;
  }
  private updateEntityName(): void {
    this.filterService.setEntityName(this.entityName || this.uniqueId);
  }

  setLabel() {
    this.breakLabel = this.cs.getLabel('lbl_break');
    this.breaksLabel = this.cs.getLabel('lbl_breaks');
  }

  clearSearch() {
    this.searchValue = "";
    this.onSearchValueChange.emit('');
  }

  stopPropagation(event) {
    event.stopPropagation();
  }


  onStatusChange(rowData) {
    this.onChangeStatus.emit(rowData);
  }

  onDutyStatus(rowData, event) {
    if (event.checked === true) {
      this.onChangeOffDutyReason.emit({ ...rowData, checked: event.checked });
    } else {
      this.onChangeOnDutyReason.emit({ ...rowData, checked: event.checked });
    }
  }

  assignDriverGroup(rowData) {
    this.onChangeAssignDriverGroup.emit(rowData);
  }

  defaultVehicle(rowData) {
    this.onChangeDefaultVehicle.emit(rowData);
  }

  selectCheckInLocation(rowData) {
    this.onChangeSelectCheckInLocation.emit(rowData);
  }

  getStatusClassForStatus(status: IncidentStatus): string {
    switch (status) {
      case IncidentStatus.Open:
        return AppSettings.INCIDENTS_STATUS_CLASS.OPEN;
      case IncidentStatus.UnderReview:
        return AppSettings.INCIDENTS_STATUS_CLASS.UNDER_REVIEW;
      case IncidentStatus.InProgress:
        return AppSettings.INCIDENTS_STATUS_CLASS.IN_PROGRESS;
      case IncidentStatus.Resolved:
        return AppSettings.INCIDENTS_STATUS_CLASS.RESOLVED;
      default:
        return AppSettings.INCIDENTS_STATUS_CLASS.DEFAULT;
    }
  }

  setCurrentRow(rowData) {
    this.currentRow = rowData;
    this.setCurrentRowData.emit(this.currentRow);
  }

  setCurrentRowForLeaveApprove(rowData) {
    this.currentRow = rowData;
    this.setCurrentRowDataForLeaveApprove.emit(this.currentRow);
  }

  setCurrentRowForLeaveReject(rowData) {
    this.currentRow = rowData;
    this.setCurrentRowDataForLeaveReject.emit(this.currentRow);
  }

  delete(deleteType: string) {
    this.confirmationService.confirm({
      header: this.header,
      message: this.message,
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'bg-white text-color cancel',
      acceptButtonStyleClass: 'bg-red-500 delete',
      acceptLabel: this.cs.getLabel('lbl_delete'),
      rejectLabel: this.cs.getLabel('lbl_cancel'),
      accept: () => {
        if (deleteType === AppSettings.DELETE_TYPE.ROW) {
          this.onDelete.emit(this.currentRow.id);
        } else {
          this.onBulkDataDeleteEvent.emit(this.selectedRows?.map(ele => {
            return ele.id
          }));
        }
        this.clearSelected();

      },
      reject: () => {
      }
    });
  }

  deleteLeave() {
    this.confirmationService.confirm({
      header: this.header,
      message: this.message,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.onBulkDataDeleteEvent.emit(this.selectedRows?.map(ele => {
          return ele.leaveId
        }));
      },
      reject: () => {
      }
    });
  }

  changeStatusOfEntity() {
    this.onChangeStatus.emit(this.currentRow);
  }

  onAdd() {
    this.onBtnClick.emit();
  }

  onFiltersPopup() {

  }

  openDialog() {
    this.isModal = true;
  }

  selectionChange() {
    this.bulkRowCount = this.selectedRows.length;
    this.miTableService.bulkRowCount.next(this.bulkRowCount);
  }

  showBulkRowDialog() {
    this.visible = true;
    this.showBulkUpdateDialog = false;
    this.cd.detectChanges();
    this.showBulkUpdateDialog = true;
    this.bulkRowCount = this.selectedRows.length;
  }

  showBulkLeaveDialog() {
    this.visible = true;
    this.showBulkLeaveUpdateDialog = true;
    this.bulkRowCount = this.selectedRows.length;
  }

  clearSelected(): void {
    this.selectedRows = [];
  }

  showPrintLabelDialog() {
    this.visiblePrintLabel = true;
    this.bulkRowCount = this.selectedRows.length;
  }

  showFilterDialog(position: string) {
    this.position = position;
    this.show = true;
  }

  onToggle() {
    this.isModal = false;
    this.visible = false;
  }

  onSearch(event) {
    const value = event.target.value;
    if (event.target.value.length >= 3) {
      this.onSearchValueChange.emit(value);
    } else if (event.target.value.length === 0) {
      this.onSearchValueChange.emit(value);
    }
  }

  onPageChange2(event: PageEvent) {
    this.onPageChange.emit(event);
  }

  onRowSelect(event: any) {
    this.onRowSelection.emit(event);
    this.selectedPrintData.push(event.data);
    this.selectedEntityId = this.selectedPrintData[0].id;
  }

  onRowUnselect(event: any) {
    this.messageService.add({
      severity: "info",
      summary: "Product Unselected",
      detail: event.data.name,
    });
  }

  toggleCheckbox(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    const checkbox = event.target as HTMLInputElement;
    checkbox.checked = !checkbox.checked;
  }


  onUpdateColumns(event) {
    this.selectedColumns = event;
    this.isModal = false;

    const data = {
      config: JSON.stringify(event),
      forTenantCode: AppSettings.TENANT_CODE,
      prefType: AppSettings.USER_PREFERENCE.COLUMN,
    };

    if (this.entityName) {
      this.miTableService.updateUserEntityConfig(this.entityName, data).subscribe(() => {
        this.setColumns(event);
      });
    } else {
      localStorage.setItem(this.uniqueId, JSON.stringify(event));
      this.setColumns(event);
    }
  }

  getPreferredColumns() {
    if (this.isEntity) {
      this.getColumnsFromUserEntityConfig();
      this.manageColumnFromLocal = false;
    } else {
      this.getColumnsFromLocalStorage();
      this.manageColumnFromLocal = true;
    }
  }

  private getColumnsFromUserEntityConfig() {
  this.miTableService.getUserEntityConfig(this.entityName, AppSettings.USER_PREFERENCE.COLUMN).subscribe((res: UserConfig) => {
    const configObject = res.config ? JSON.parse(res.config) : null;
    const restoreDefault = Math.min(this.collectionOfColumns?.length || 0, 6);
    const columns = configObject && Object.keys(configObject).length !== 0 || configObject.length > 0 ? configObject : this.collectionOfColumns.slice(0, restoreDefault);
    this.setColumns(columns);
  });
}

private getColumnsFromLocalStorage() {
  const storedColumns = JSON.parse(localStorage.getItem(this.uniqueId));
  const restoreDefault = Math.min(this.collectionOfColumns?.length || 0, 6);
  const columns = storedColumns && storedColumns.length !== 0? storedColumns : this.collectionOfColumns.slice(0, restoreDefault);
  this.setColumns(columns);
}

  private setColumns(columns: any[]) {
    columns= _.reject( columns, attribute =>
      _.includes(this.codesToRemoveFromManageColumn, attribute.attributeCode)
    );
  this.columns = columns;
  this.selectedColumns = columns;
  this.manageCols = _.cloneDeep(columns);
  this.manageColumnService.changeVisibleColumnsData(this.manageCols);
  this.updateCollectionOfColumns();
}

  private updateCollectionOfColumns() {
    this.collectionOfColumns?.filter((el) => {
      el.selected = this?.columns?.some((f) => f.attributeCode === el.attributeCode);
    });

    this.manageColumnService.changeSelectedAndCollectionData(this.collectionOfColumns);
  }

  updateRows(value: number) {
    this.rows2 = value;
    this.itemPerPageChange.emit(this.rows2);
  }

  onToggleManageColumn() {
    this.isModal = true;
  }

  onFilterValueChange(filterFlag, event) {
    filterFlag ? (this.advanceFilterValue = event) : (this.quickFilterValue = event);

    // const prevData = this.filterService.getFilterState(this.entityName || this.uniqueId);

    // const userDataObject = prevData.userData.reduce((acc, obj) => ({ ...acc, ...obj }), {});
    // ...userDataObject 
    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue}

    this.selectedFilterCount = this.countNonNullValues(combineFilter)


    console.log('combineFilter',combineFilter)
  //   this.filterService.setFilterState(this.entityName || this.uniqueId, {
  //     apiData: combineFilter,
  //     userData:prevData?.userData || [],
  //     selectedFilterCount: this.selectedFilterCount
  //   }
  // );
    this.onValueChange.emit(combineFilter);
  }

  countNonNullValues(obj) {
    return _.reduce(obj, (count, value) => {
        return (value !== null && value !== "" && value?.length !== 0 ) ? count + 1 : count;
    }, 0);
  }

  setAllValuesToNull(obj) {
    return _.mapValues(obj, () => null);
}

  clearFilter() {
    this.selectedFilterCount = 0;
    this.advanceFilterValue = this.setAllValuesToNull(this.advanceFilterValue);
    this.quickFilterValue = this.setAllValuesToNull(this.quickFilterValue);
    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue }
    this.onValueChange.emit(combineFilter);
    this.quickFilterCmp.resetForm();
    const filter = _.cloneDeep(this.filterList);
    this.filterList = [];
    this.cd.detectChanges();
    this.filterList = filter;

  }


  onBulkDataUpdate(event) {
    this.selectedRows = [];
    this.showBulkUpdateDialog = false;
    this.visible = false;
    this.onBulkDataUpdateEvent.emit(event);
  }

  onBulkLeaveDataUpdate(event) {
    this.selectedRows = [];
    this.showBulkLeaveUpdateDialog = false;
    this.visible = false;
    this.onBulkLeaveDataUpdateEvent.emit(event);
  }

  onTransportationDateSelect(event, data) {
    this.onTransportationDateChange.emit({ newDate: event, row: data })
  }

  onEventStatusChange(rowData, event) {
      this.onEventStatusChangeEvent.emit({ ...rowData, checked: event.checked });
  }

  onOrganizationStatusChange(rowData, event) {
      this.onOrganizationStatusChangeEvent.emit({ ...rowData, checked: event.checked });
  }

  rowMouseOver(rowData) {
    rowData.showRowMenu = true;
  }

  rowMouseOut(rowData) {
    rowData.showRowMenu = false;
  }

  getQueueLocationChange(event, rowIndex) {
    this.queueLocationFromMiTable.emit(event);
    this.queueLocationRef.forEach((ele, index) => {
      if(index === rowIndex) {
        ele.nativeElement.disabled = false;
      }
    })
  }

  assignDriver(rowData) {
    this.onAssignDriver.emit(rowData);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateFilterSize();
  }


  private updateFilterSize(): void {
    const width = window.innerWidth;
    if (width >= 1920) {
      this.filterSize = 3;
    } else if (width >= 1440) {
      this.filterSize = 3;
    } else if(width >= 1225){
      this.filterSize = 3;
    }else if (width >= 1060) {
      this.filterSize = 2;
    } else if (width >= 994) {
      this.filterSize = 1;
    } else if (width >= 850) {
      this.filterSize = 1;
    } else {
      this.filterSize = 0;
    }
  }

  onStaticBulkDataUpdate(event) {
    this.onStaticBulkDataUpdateEvent.emit(event)
  }

}
