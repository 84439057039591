import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { EntityList, LeaveManagementModal, Values } from '../../modal/leaveManagementModal';
import { Language } from 'app/modules/shared/models/language';
import { Country } from 'app/modules/shared/models/country';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { LeaveManagementService } from '../../services/leave-management.service';

@Component({
  selector: 'app-leave-history',
  standalone: true,
  imports: [MITableComponent, TranslateModule],
  templateUrl: './leave-history.component.html',
  styleUrl: './leave-history.component.scss'
})
export class LeaveHistoryComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @Input() driverEntityId: string;
  data: any;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  leaveListData: any = {};
  totalRecords: number = 0;
  entityName;
  currentRow;
  filterList;
  bulkUpdateHeader;
  reviewHeader;
  deleteLeaveMsg;
  deleteLeaveHeaderMsg;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  isEntity: boolean = true;
  isLeaveRequest: boolean = true;
  attributeData: any;
  filterFieldsArray: any[];
  uniqueId: string = 'leave_management_history';
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    countryCode: '',
    forTenantCode: this.configService.getForTenantCode(),
    driverEntityId: "",
  };
  constructor(private cs: CommonBindingDataService, private router: Router, private messageService: MessageService,
    private leaveManagementService: LeaveManagementService, private configService: ConfigService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.getData();
    this.initializeColumn();
  }

  initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "leaveType",
        "attributeIndex": 0,
        "field": "leaveType",
        "header": this.cs.getLabel('leave_request.columns.lbl_leave_type')
      },
      {
        "attributeCode": "fromDate",
        "attributeIndex": 1,
        "field": "fromDate",
        "header": this.cs.getLabel('leave_request.columns.lbl_from_date')
      },
      {
        "attributeCode": "toDate",
        "attributeIndex": 2,
        "field": "toDate",
        "header": this.cs.getLabel('leave_request.columns.lbl_to_date')
      },
      {
        "attributeCode": "numberOfDays",
        "attributeIndex": 3,
        "field": "numberOfDays",
        "header": this.cs.getLabel('leave_request.columns.lbl_no_of_days')
      },
      {
        "attributeCode": "leaveReason",
        "attributeIndex": 4,
        "field": "leaveReason",
        "header": this.cs.getLabel('leave_request.columns.lbl_reason')
      },
      {
        "attributeCode": "historyStatus",
        "attributeIndex": 5,
        "field": "historyStatus",
        "header": this.cs.getLabel('leave_request.columns.lbl_status')
      },
    ];
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
    })
  }

  getTableView() {
    this.isLoading = false;
    this.searchEntity();
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.isEntity = false;
  }
  
  getFilterView() {
    this.filterList = [
      {
        "attributeCode": "Date",
        "attributeIndex": 0,
        "inputCode": "dateRange"
      }
    ];
  }
  
  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onFilterValueChange(event: { [key: string]: any }) {
    for (const [key, value] of Object.entries(event)) {
      if (value) {
        const attributeValue = Array.isArray(value) ? value : [value];
        if (key === 'Date') {
          this.entityData.fromDateStr = attributeValue[0]?.getTime ? attributeValue[0].getTime() : attributeValue[0];
          this.entityData.toDateStr = attributeValue[1]?.getTime ? attributeValue[1].getTime() : attributeValue[1];
        }
      }
    }
    if (!this.entityData.fromDateStr) {
      delete this.entityData.fromDateStr;
    }
    if (!this.entityData.toDateStr) {
      delete this.entityData.toDateStr;
    }
    this.entityData.offset = event.first;
    this.searchEntity();
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0]?.countryCode;
    this.entityData.fromDateStr = this.cs.getDate15DaysBefore();
    this.entityData.toDateStr = this.cs.getDate15DaysAfter();
    this.entityData.driverEntityId = this.driverEntityId;
    this.leaveManagementService.getHistoryLeaveByDriverId(this.entityData).subscribe((res: LeaveManagementModal) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = res?.data.map(data => ({
        ...data,
      })) || [];

      this.leaveListData = res;
    })
  }
}
