import { DatePipe, NgClass } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import { AppIcons } from "../../../../shared/app.icons";
import { AppSettings } from "../../../../shared/app.settings";
import { Router, RouterLink } from "@angular/router";

@Component({
  selector: "app-booking-list-card",
  templateUrl: "./booking-list-card.component.html",
  styleUrls: ["./booking-list-card.component.scss"],
  standalone: true,
  imports: [TooltipModule, ButtonModule, DatePipe, NgClass, RouterLink],
})
export class BookingListCardComponent implements OnInit {
  @Input() bookingListItem: any;
  @Input() forDetail: boolean = false;
  @Output() closeBtnClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() bookingCardClicked: EventEmitter<any> = new EventEmitter<any>();
  miIcons = AppIcons;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  BOOKING_STATUS_IDS = AppSettings.BOOKING_STATUS_IDS;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  onCardClick(item) {
    this.bookingCardClicked.emit(item);
  }

  onCloseDetailView() {
    this.closeBtnClicked.emit(false);
  }

  onEditClick() {
    this.router.navigate(['app/bookings/edit/' + this.bookingListItem.id]);
  }
}
