<div class="flex flex-column p-3 pl-4 pt-0">
  @if(this.addedPassengerList.length > 0){
    <div class="grid">
        <div class="col-12 mt-3">
            <p-dropdown [options]="addedPassengerList" [(ngModel)]="selectAddedPassenger" placeholder="Select added passenger"
              [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false" (onChange)="onSelectAddedPassenger($event)">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2">
                  <div class="passenger-popup bold-name">
                    <span class="ellipsis mr-2">
                      {{selectAddedPassenger.pass_full_name}}
                    </span>
                    <span class="unique ellipsis mr-2">
                      | {{selectAddedPassenger.unique_id}} | {{selectAddedPassenger.passenger_type}}
                    </span>
                  </div>
                </div>
              </ng-template>
        
              <ng-template let-passenger pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div class="passenger-popup bold-name">
        
                    <img [src]="getPassengerImageUrl(passenger)" class="vertical-align-middle border-round-sm list-img mr-2" />
                    <span class="ellipsis mr-2">
                      {{passenger.name}}
                    </span>
                    <span class="unique ellipsis mr-2">
                      ({{passenger.unique_id}}) ({{passenger.passenger_type}})
                    </span>
        
                    <i class="{{miIcons.OFF_OUTLINE_CLOSE}} wh-24 close-icon cursor-pointer"></i>
        
                  </div>
        
                </div>
              </ng-template>
            </p-dropdown>
        </div>
    </div>

    @if(selectAddedPassenger){
        <div class="flex flex-row align-items-center py-12px">
            <div class="w-6">   
              <img [src]="getPassengerImageUrl(selectAddedPassenger)" class="vertical-align-middle border-round-sm hw-30 list-img mr-2" />
              <span class="ellipsis mr-2 text-color">
                  {{selectAddedPassenger.pass_full_name}}
              </span>
            </div>
            <div class="w-6">
              <div class="flex flex-row flex-wrap">
                <div class="flex align-items-center justify-content-center w-2rem h-2rem active allowed cursor-pointer border-round m-2">
                  <i class="wh-24 inline-block"></i>
                  T
                </div>
  
                <!-- selectAddedPassenger[field.attributeCode] -->
              <div class="flex align-items-center justify-content-center w-2rem h-2rem active allowed cursor-pointer border-round m-2">
                <i class="{{miIcons.BOOKING_CAR}} wh-24 inline-block"></i>
              </div>
              <div class="flex align-items-center justify-content-center w-2rem h-2rem allowed cursor-pointer  border-round m-2">
                <i class="{{miIcons.BOOKING_AIRPORT}} wh-24 inline-block"></i>
              </div>
              <div class="flex align-items-center justify-content-center w-2rem h-2rem not-allowed border-round m-2">
                <i class="{{miIcons.BOOKING_SHUTTLE}} wh-24 inline-block"></i>
              </div>
              <div class="flex align-items-center justify-content-center w-2rem h-2rem allowed cursor-pointer  border-round m-2">
                <i class="{{miIcons.BOOKING_BUS}} wh-24 inline-block"></i>
              </div>
            </div>
            </div>
        </div>
      <hr class="hr-line">
      @for (group of groups; track $index) {

        <div class="mb-4 mt-2">
          <label class="font-semibold text-lg " translate>{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>      
        </div>
        @for (field of group.fields; track $index) {
            <div class="formgrid grid mr-2">
              <div class="field col p-2 text-500 ">
                @if (group.code === DETAILS_GROUP_CODE) {
                  <i class="{{getIcon(field.attributeId)}} wh-24 inline-block cursor-pointer icon-color mr-2"></i>
                }@else {
                  <i class="{{miIcons.BOOKING_LOCK}} wh-24 inline-block cursor-pointer icon-color mr-2"></i>
                }
                
              <label class="font-normal text-lg text-500 value-color mb-0" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
              </div>
                <div class="field value col p-2 font-semibold text-color">
                {{selectAddedPassenger[field.attributeCode] || '-'}}
              </div>
            </div>                  
          
            <hr class="hr-line">
        }
      }
    }

  }
</div>
