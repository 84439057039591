<p-dialog [header]="header" [(visible)]="visibleDriverStatus" modal="true" (onHide)="onHide()" [style]="{width: '40vw'}">
    <div class="flex pb-3 driver-group">
        <span class="p-input-icon-left relative w-full">
            <i class="pi pi-search absolute"></i>
            <input type="text" pInputText class="w-full" (input)="onSearch($event, tableCheckInData)"
                [placeholder]="'lbl_search' | translate" />
        </span>
    </div>
    <div class="flex flex-column justify-content-between align-items-center pb-3">
        @if (tableCheckInData?.length > 0) {
            @for(data of tableCheckInData; track data; let i = $index) {
                <div class="w-full flex justify-content-between align-content-center separator pt-2 pb-2 cursor-pointer"  (click)="selectCheckInRadioButton(data?.id)">
                    <div class="align-items-center flex">
                        <p-radioButton name="data" [value]="data?.id" class="flex ml-2" [(ngModel)]="driverStatus"></p-radioButton>
                        <label class="ml-2">{{data?.location_display_name_for_booker}}</label>
                    </div>
                    <button pButton class="bg-white default-text-black-color border-1 border-black-alpha-20 px-3 py-1" (click)="rowData?.checkin_location_id === data?.id ? removeCheckInLocation() : selectCheckInLocation(data)" [label]="rowData?.checkin_location_id === data?.id ? ('lbl_remove' | translate) : ('lbl_select_only' | translate)"></button>
                </div>
            }
        }
        @else {
            <div class="w-full pt-0 pr-2 pb-0 pl-3">
                <app-no-result-found></app-no-result-found>
            </div>         
        }
    </div>
</p-dialog>