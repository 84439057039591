<div>
    <span class="p-input-icon-left w-full">
        <i class="pi pi-search" [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue, 'cursor-pointer': searchValue }" (click)="clearSearch()"></i>
        <input type="text" pInputText [(ngModel)]="searchValue" [placeholder]="inputPlaceholder" (input)="onSearch($event)"/>
    </span>
</div>

<div class="pt-3 pb-3 pr-3 flex">
    <div class="flex-1 flex align-items-center">
        <label translate> lbl_select_only </label> &nbsp;
        <div class="truncate-text">
            {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
        </div>
    </div>

    <div class="flex justify-content-end align-items-center select-all-div">
        <p-checkbox name="groupname" value="val1" class="flex"
            [label]="'lbl_select_all_count' | translate: {value:yearList.length}" [(ngModel)]="checked"
            (onChange)="onChange($event)"></p-checkbox>
    </div>
</div>
<div class="overflow-y-scroll list-section">
    @for(item of yearList; track item;let i=$index;) {
    <div class="pr-3">
        <p-checkbox class="cursor-pointer pt-3 pb-3 pb-2" [label]="item.labelKey" [name]="item"
            [value]="item.labelKey" [(ngModel)]="selectedControl" (onChange)="valueChange()"></p-checkbox>
    </div>
        @if( i < yearList.length-1){
        <p-divider></p-divider>
        }
    }
</div>