<app-dashboard-navbar></app-dashboard-navbar>
<app-filter-section></app-filter-section>

<div class="bg-white accordion-container">
  <p-accordion>
    @for(node of nodes; track node){
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div>
          <i
            [ngClass]="node.icon"
            class="mi-lg inline-block vertical-align-text-bottom wh-20"
          ></i>
          <span
            [ngClass]="node.labelClass"
            class="font-14 font-semibold mr-3"
            >{{ node.label }}</span
          >
          @if(node.label === "Cancelled"){
          <span
            class="font-13 default-print-label-color font-normal mr-3 more-horizontal-icon pxy-2-5"
            >Vehicle Transfer</span
          >
          }
          <span class="default-text-light-black-color font-14 font-normal mr-1"
            >By
          </span>
          <span class="font-14 font-semibold default-text-black-color mr-1">{{
            node.adminName
          }}</span
          ><span class="default-text-light-black-color font-14 font-normal">
            (System Admin)</span
          >
          <i
            class="mi-lg mi-ellipse inline-block wh-10 mx-3 bg-default-e00"
          ></i>
          <span class="default-print-label-color font-14 font-normal">{{
            node.timeAgo
          }}</span>
          <i
            class="mi-lg mi-redirect_feed inline-block wh-20 ml-3 vertical-align-text-bottom bg-redirect-icon"
        (click)="callFull($e)" ></i>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        @if(node.data){
        <div>
          <div class="mb-13">
            <span class="font-14 default-print-label-color"
              >#{{ node.data.feedId }}</span
            >
            <i
              class="mi-lg mi-private_ride wh-28 relative top-4 inline-block vertical-align-text-bottom ml-2 mr-1"
            ></i
            ><span>{{ node.data.feedRequestor }}</span>
            <span class="font-14 font-semibold default-text-black-color mr-2"
              >{{ node.data.userName }}, +2</span
            >
            <span class="font-13 mr-2 default-print-label-color">(Player)</span>
            <i
              class="mi-lg mi-watchlist wh-16 inline-block vertical-align-text-bottom"
            ></i>
          </div>
          <div class="flex flex-row">
            <div class="mt-1px">
              <i
                class="mi-lg mi-activity_feed_route_icon wh-20-45 inline-block bg-route-icon"
              ></i>
            </div>
            <div class="flex flex-column">
              <p class="mb-6px default-text-light-black-color">
                The American Hotels, New York City
              </p>
              <p class="default-text-light-black-color">
                Great American Ball Park, New York City
              </p>
            </div>
          </div>
        </div>
        }
      </ng-template>
    </p-accordionTab>
    }
  </p-accordion>
</div>
