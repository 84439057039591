<p-dialog [header]="'lbl_assign_vehicle' | translate" modal="true" (onHide)="onHide()" [(visible)]="visibleAssignVehicle" [style]="{ width: '55vw' }">
    <div class="flex">
        <span class="p-input-icon-left ">
            <i
            class="pi search-icon relative left-20 cursor-pointer"
            [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
            (click)="clearSearch()"
          ></i>
            <input class="width-200 search-filter" type="text" pInputText  (input)="onSearch($event)"
              [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue"
            />
          </span>
        @if(filterList){
        <app-quick-filter-form [fields]="filterList" [moduleName]="moduleName" class="relative mr-2 "
        [attributeLabels]="attributeLabels" (onValueChange)="onFilterValueChange(false, $event)"></app-quick-filter-form>
        }
    </div>
    <div class="flex justify-content-between align-items-center grid pt-2">
     
        @if(loading){
            <div class="loader"> 
                <div >
                    <div  class="spin-div">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        }
        @for(data of vehicleList; track data; let i = $index) {
            <div class="flex justify-content-between align-items-center w-full list-row">
                <div class="flex align-items-center">
                    <p-radioButton name="data"  [inputId]=" 'vehicle' +i" [value]="data?.id" class="flex ml-2" [(ngModel)]="selectedModify"></p-radioButton>
                    <div class="flex flex-column ml-2" [attr.htmlFor]=" 'vehicle' +i">
                        <div class="col-12 lg:col-6 xl:col-3 flex align-items-center white-space-nowrap relative top-5">
                            {{data?.name_code}} - {{data?.reg_number}} - {{data?.vehicle_status}}
                        </div>
                        <div class="col-12 lg:col-6 xl:col-3 flex align-items-center white-space-nowrap relative bottom-5">
                            {{data?.vehicle_type}} | {{data?.manufacturer}} | {{data?.fuel_type}}
                        </div>
                    </div>
                </div>
                <div>
                    <button pButton class="bg-white default-text-black-color border-1 border-black-alpha-20 px-10px" (click)="assignVehicleData(data)" [label]="'bookings.lbl_select' | translate"></button>
                </div>
            </div>
        }

        @if (vehicleList?.length === 0) {
          <div class="w-full pt-0 pr-2 pb-0 pl-3">
            <app-no-result-found></app-no-result-found>    
          </div>         
          }
    </div>
</p-dialog>