
<div class="col-12 p-0">
    <div class="initial-details">
        <div class="flex">
            <div class=" flex ">
                <label translate="" class="font-semibold font-20"> bookings.lb_journey </label>

                <label class="font-semibold font-20">&nbsp; {{bookingId}}</label> 
                <div class="separator-div "></div>
                <label class="font-semibold font-20"> {{ data.departure_time_and_date | date: hh_mm_FORMAT}}</label>
                <label class="font-medium font-20"> {{ data.departure_time_and_date | date:dd_MMM_yyyy_FORMAT}}</label> 
            </div>
            <div class="separator-div "></div>
            <div class="flex ml-2">
                @if(data.transportation_type === TRANSPORTATION_TYPE.AIRPORT) {
                <div class="icon-div flex align-item-center mr-2">
                    <i class="{{miIcons.BOOKING_AIRPORT}} inline-block wh-24 relative cursor-pointer"></i>
                </div>
                <div class="icon-div flex align-item-center mr-2">
                    <i class="{{miIcons.BOOKING_CAR}} inline-block wh-24 relative "></i>
                </div>
                }@else {
                <div class="icon-div flex align-item-center mr-2">
                    @switch (data.transportation_mode) {
                    @case (TRANSPORTATION_MODE.CAR) {
                    <i class="{{miIcons.BOOKING_CAR}} inline-block wh-24 relative "></i>
                    }

                    @case (TRANSPORTATION_MODE.SHUTTLE) {
                    <i class="{{miIcons.BOOKING_SHUTTLE}} inline-block wh-24 relative "></i>
                    }

                    @case (TRANSPORTATION_MODE.BUS) {
                    <i class="{{miIcons.BOOKING_BUS}} inline-block wh-24 relative "></i>
                    }

                    }
                </div>
                }

                @if(data.additional_vehicle_requirement){
                <div class="icon-div flex align-item-center mr-2">
                    <i class="{{miIcons.BOOKING_ADDITIONAL_VEHICLE}} inline-block wh-24 relative "></i>
                </div>
                }

                @if(passenger?.watchlist === "Yes") {
                <div class="icon-div flex align-item-center mr-2">
                    <i class="{{miIcons.BOOKING_WATCHLIST}} inline-block wh-24 relative "></i>
                </div>
                }


            </div>
        </div>
        <div class="flex">
            <div class="mr-2">{{passenger?.passenger_type}}</div> <label class="separator">|</label>
            <div class="mr-2">{{data?.number_of_passenger + passengerCount}} <label translate> bookings.pass</label>
            </div>
            <label class="separator">|</label>
        </div>

    </div>

</div>

