import { Component, Input, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { DriverTabComponent } from "./driver-tab/driver-tab.component";
import { ScheduleTabComponent } from "./schedule-tab/schedule-tab.component";
import { VehicleTabComponent } from "./vehicle-tab/vehicle-tab.component";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-driver-details",
  templateUrl: "./driver-details.component.html",
  styleUrls: ["./driver-details.component.scss"],
  standalone: true,
  imports: [TabMenuModule, DriverTabComponent, ScheduleTabComponent, VehicleTabComponent, TranslateModule],
})
export class DriverDetailsComponent implements OnInit {
  items: MenuItem[];
  selectedTab: string = this.cs.getLabel("dispatch.driver_tab.tab");
  @Input() driverDetailItem: any;

  constructor(private cs: CommonBindingDataService) { }

  ngOnInit() {
    this.items = [
      { label: this.cs.getLabel("dispatch.driver_tab.tab"), command: () => this.selectTab(this.cs.getLabel("dispatch.driver_tab.tab")) },
      { label: this.cs.getLabel("dispatch.schedule_tab.tab"), command: () => this.selectTab(this.cs.getLabel("dispatch.schedule_tab.tab")) },
      { label: this.cs.getLabel("dispatch.vehicle_tab.tab"), command: () => this.selectTab(this.cs.getLabel("dispatch.vehicle_tab.tab")) },
    ];
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }
}
