import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { CommonBindingDataService } from '../../../../services/common-binding-data.service';

@Component({
  selector: 'app-input-counter-spinner',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, InputNumberModule],
  templateUrl: './input-counter-spinner.component.html',
  styleUrl: './input-counter-spinner.component.scss'
})
export class InputCounterSpinnerComponent {
  value2: number = 0;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;


  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  onChange(event) {
  }

}
