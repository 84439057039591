
<p-dialog [header]="dialogHeader | translate" [(visible)]="isVisible" [modal]="true" [style]="{ width: '600px' }" [draggable]="false" [resizable]="false" (onShow)="onDialogShow()" (onHide)="onHide()">
  <form [formGroup]="settingsForm">
    <div>
      <div class="form-field col-12 md:col-12 lg:col-12 pr-4 mb-2">
        <label class="flex mb-2 font-semibold" translate>lbl_name
          <span class="required">*</span>
        </label>
        <div class="form-control">
          <input #nameInput formControlName="name" class="p-component p-element w-full" type="text" pInputText appAutoFocus [disabled]="rowData?.customValue"/>
        </div>
        @if (settingsForm.controls.name.errors && (settingsForm.controls.name.dirty || settingsForm.controls.name.touched)) {
          <div class="form-errors mt-1 required-field">
            <div [hidden]="!settingsForm.controls.name.errors.required" translate>
              settings.lbl_name_req
            </div>
          </div>
        }
      </div>
      <div class="form-field col-12 md:col-12 lg:col-12 pr-4">
        <div class="form-control">
          <p-checkbox class="mr-2" [binary]="true" formControlName="markAsDefault" inputId="markAsDefault"></p-checkbox>
          <label class="font-semibold" for="markAsDefault" translate>settings.lbl_mark_as_default</label>
        </div>
      </div>
      <div class="flex form-field col-12 md:col-12 lg:col-12 pr-4 mb-2">
        <div class="col-5 md:col-5 lg:col-5">
          <label class="flex mb-2 font-semibold" translate>settings.lbl_select_color</label>
          <div class="form-control">
            <p-colorPicker [inline]="true" formControlName="color" (onChange)="onColorChange($event.value)"></p-colorPicker>
          </div>
        </div>
        <div class="col-5 md:col-5 lg:col-5">
          <label class="font-semibold" translate>lbl_hex_code</label>
          <div class="flex">
            <input type="text" class="p-inputtext-sm uppercase mt-2 mr-2" pInputText [value]="selectedColor" readonly/>
            @if (selectedColor) {
              <div class="color-box mt-2" [ngStyle]="{'background-color': selectedColor}"></div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end mr-4 mb-2">
      <button pButton class="mr-2 mb-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onHide()"></button>
      <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'label_save' | translate" (click)="onAddSettings()"></p-button>
    </div>
  </form>
</p-dialog>

<p-toast></p-toast>