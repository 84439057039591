import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { AttributeData, entityResponse } from '../../../vehicles/modal/attributeModal';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { EntityService } from '../../../shared/services/entity.service';
import { AppSettings } from '../../../shared/app.settings';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';

import { SelectedPeopleComponent } from '../selected-people/selected-people.component';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-add-segment',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, SelectedPeopleComponent, CardModule],
  templateUrl: './add-segment.component.html',
  styleUrl: './add-segment.component.scss'
})
export class AddSegmentComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 1;
  data: AttributeData;
  segmentAttributeData: any = {};
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  segmentAttributeLength: number;
  segmentId: string;
  attributeValues = [];
  nextBtnLabel: string;
  saveAndAddBtnText: string;
  segmentEntityData: any;
  selectedColumns = [];
  attributeIdForSelectedColumns: string = 'people_count';
  selectedPeople = [];

  constructor(private segmentService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.setLabels();
    this.setAppSettings();
    this.setRoutePath();
    this.loadSegmentData();
  }

  private setLabels() {
    this.segmentId = this.route.snapshot.paramMap.get('id');
    this.nextBtnLabel = this.cs.getLabel('manage_segments.lbl_save_segment');
    this.saveAndAddBtnText = this.cs.getLabel('manage_segments.lbl_save_and_add_another');
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.moduleName = AppSettings.ENTITY_CODE.SEGMENT;
  }

  private loadSegmentData() {
    if (this.segmentId) {
      this.segmentService
        .getEntity(this.segmentId, AppSettings.ENTITY_CODE.SEGMENT)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.selectedPeople = this.attributeValues[5];
            this.getAttributes();
          }
        });
    } else {
      this.getAttributes();
    }
  }

  private getAttributes() {
    const entityType = AppSettings.ENTITY_CODE.SEGMENT;
    this.segmentService.getAttributeDefinition(entityType, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.segmentAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.segmentAttributeLength = this.segmentAttributeData.tabs.length;
      }
    });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.segmentId ? this.cs.getLabel('manage_segments.lbl_edit_segment') : this.cs.getLabel('manage_segments.lbl_add_segment'),
        routerLink: this.segmentId ? '../../../../app/communication/segment' : '../../../app/communication/segment',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  onChangeSelectedDriver(event) {
    this.selectedColumns = event.map(ele => ele.id);
  }

  onSaveSegments(event) {
    let segmentData;
    segmentData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    const segmentFormData = event;
    for (const [key, value] of Object.entries(segmentFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            segmentData?.data?.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          segmentData?.data?.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }

    segmentData?.data?.push({
      attributeCode: this.attributeIdForSelectedColumns,
      attributeValue: this.selectedColumns
    })

    this.setSegmentEntityData(this.data.entityCode);
    if (!this.segmentId) {
      this.segmentService.createEntities(this.segmentEntityData.entityCode, this.segmentEntityData).subscribe((res: entityResponse) => {
        this.segmentId = res.entityId;
        this.saveSegmentData(this.segmentEntityData.entityCode, segmentData);
      });
    } else {
      this.saveSegmentData(this.segmentEntityData.entityCode, segmentData);
    }
  }

  setSegmentEntityData(entityCode) {
    this.segmentEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveSegmentData(entityCode, segmentData) {
    segmentData.data = this.cs.mapAttributeIds(segmentData.data, this.segmentAttributeData.tabs);
    this.segmentService.saveAttributeData(entityCode, this.segmentId, segmentData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel(res.message) });
      this.router.navigate(['../../../app/communication/segment']);
    });
  }

  
  onSaveAndAddSegments(event) {
    let segmentData;
    segmentData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };
    const segmentFormData = event;
    for (const [key, value] of Object.entries(segmentFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            segmentData?.data?.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          segmentData?.data?.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }

    segmentData?.data?.push({
      attributeCode: this.attributeIdForSelectedColumns,
      attributeValue: this.selectedColumns
    })

    this.setSegmentEntityData(this.data.entityCode);
    if (!this.segmentId) {
      this.segmentService.createEntities(this.segmentEntityData.entityCode, this.segmentEntityData).subscribe((res: entityResponse) => {
        this.segmentId = res.entityId;
        this.saveAndAddSegmentData(this.segmentEntityData.entityCode, segmentData);
      });
    } else {
      this.saveAndAddSegmentData(this.segmentEntityData.entityCode, segmentData);
    }
  }

  saveAndAddSegmentData(entityCode, segmentData) {
    segmentData.data = this.cs.mapAttributeIds(segmentData.data, this.segmentAttributeData.tabs);
    this.segmentService.saveAttributeData(entityCode, this.segmentId, segmentData).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('manage_segments.segment_added_successfully'), });
      this.miFormComponent.resetForm();
    });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel('lbl_next');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.segmentAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.cs.getLabel('label_save');
    }
  }

  getLastIndex(): number {
    return this.segmentAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onNextBtnClick() {
    const btn = document.getElementById('details');
    btn.click();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['../../../app/communication/segment']);
  }
}

