import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageColumnService {
  private selectedAndCollection = new BehaviorSubject<any>(null);
  private visibleCols = new BehaviorSubject<any>(null);
  availableData = this.selectedAndCollection.asObservable();
  visibleColumns = this.visibleCols.asObservable();

  constructor() { }

  changeSelectedAndCollectionData(data: any) {
    this.selectedAndCollection.next(data);
  }

  changeVisibleColumnsData(data: any) {
    this.visibleCols.next(data);
  }
}
