<div class="my-auto mt-2">
    <div class="card contacts_card p-0 border-none height-calc overflow-y-auto overflow-x-hidden">
      <div class="card-body overflow-y-auto white-space-nowrap height-90">
        <ul class="contacts list-none p-0">
          @for(data of profileData| filtersCommunication : searchValue; track data.name) {    
          <li class="cursor-pointer message-card" 
            (click)="activateClass(data)" [ngClass]="{'active': data?.active}">
            <div class="flex relative">
              <div class="relative height-53 width-53 mr-2 mt-2">
                <app-profile-image [profileData]="data" [activeClasses]="'height-53 width-53 border-circle'"></app-profile-image>
                <div class="border-3 border-circle border-solid border-white width-15 height-15 relative bottom-20 left-36">
                  <i class="{{miIcons.OVERDUE}} inline-block wh-24 bg-green-400 absolute top-50 left-50 overdue-icon"
                  [ngClass]="{'bg-green-500': data.activeStatus === appSettings.MESSAGE_COLOR_STATUS.active, 
                    'bg-red-600': data.activeStatus === appSettings.MESSAGE_COLOR_STATUS.onCall, 
                    'bg-yellow-500': data.activeStatus === appSettings.MESSAGE_COLOR_STATUS.away, 
                    'surface-400': data.activeStatus === appSettings.MESSAGE_COLOR_STATUS.offline}"></i>
                </div>
              </div>
              <div class="relative w-full">
                <div class="flex justify-content-between">
                    <span><span class="text-base font-bold">{{data.name}} </span> | <span class="relative">{{ (data.read ? 'lbl_seen' : 'lbl_sent') | translate}}</span></span>
                    <span class="text-xs">{{data.date | date:'h:mm a'}}</span>
                </div>
                <p class="text-xs top-7 relative">{{data.paxNumber}}</p>
                <p class="text-sm" translate>{{data.vehicleMessage}}</p>
              </div>
            </div>
          </li>
        }
        </ul>
      </div>
    </div>
</div>