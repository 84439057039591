@if(isReady){
<form [formGroup]="miFormGroup">
  <div class="booking-form-container">
    @for (group of groups; track groups.fields;let i = $index) {
    @if(skipGroup.includes(group.code)){

    }@else {
    <div class="col-12 md:col-12 pt-0 pb-0">
      @if(excludedCodes.includes(group.code)){


        @if(vehiclePreference === group.code){
          <hr class="prime-horizontal-line">
        }
      <div class=" p-fluid" [id]="i" [ngClass]="group.code">

        <div class="p-fluid p-formgrid grid m-0 p-0">
          <label
            class="w-full mt-0 uppercase text-xl font-bold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
          <div class=" formgrid w-full grid pt-3">
            @for (field of group.fields; track field.attributeCode;let j = $index) {
            @if(field.inputCode !== 'spacer'){
            @if (!excludeFields.includes(field.attributeCode)) {
            <div class="mb-0 field col-12 md:col-{{field.uiColumns}}" [ngClass]="{'align-content-start': group.code === vehiclePreference, 'align-content-center': group.code !== vehiclePreference}">
              @if(!isRoundTrip && field.attributeCode === "return_time_and_date" || (!isHourlyTrip && field.attributeCode
              === "journey_time")){


              }@else{
       

                @if(!excludeLabels.includes(field.attributeCode) ){
                 
                 <label class="flex">{{cs.getLabelValue(moduleName
                    +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                    @if (field.validation?.required) {
                    <span class="required">&nbsp;*</span>
                    }
                    @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                    <app-mi-tooltip
                      [messageData]="cs.getLabelValue(moduleName
                                                        +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                    </app-mi-tooltip>
                    }
                  </label>

                }
           

              <div class=" justify-content-left align-items-center" [ngClass]="{'flex pt-25': trip_code === field.attributeCode}" >
              <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
                [moduleName]="moduleName"></app-mi-fields>
              </div>
              }

              <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"></app-mi-error>

            </div>
            } @else {
            @if(field.attributeCode === 'booking_pickup_location_geolocation'){
              
            <div class="field col-12 align-content-center md:col-12 py-0 m-0">
            <div class="mb-2 font-semibold" translate>bookings.select_pick_up_and_drop_locations</div>
            <div class="col-12 align-content-end md:col-12 p-0">
              <div class="flex-1 flex align-items-center justify-content-left ellipsis relative">
                <i class="route inline-block relative mr-1 text-xs"></i>
                <i class="{{miIcons.BOOKING_PICKUP_POINT}} inline-block relative mr-2"></i>

                <label translate class="lbl-geo-pickup"> bookings.pickup</label>
                <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
                  [moduleName]="moduleName" class="w-full"></app-mi-fields>
              </div>
            </div>
            </div>
            }

            @if(field.attributeCode === 'booking_stops_location_geolocation'){
              <div class="field col-12 align-content-center md:col-12 py-0 m-0">
            <div class="col-12 align-content-end md:col-12">
              <app-stop-locations class="col-12"
                [field]="field"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [attributeValues]="attributeValues"
                [edit]="isEdit"></app-stop-locations>
            </div>
            </div>
            }

            @if(field.attributeCode === 'booking_dropoff_location_geolocation'){
              <div class="field col-12 align-content-center md:col-12 py-0 m-0">
            <div class="col-12 align-content-end md:col-12 mb-4 p-0">

              <div class="flex-1 flex align-items-center justify-content-left ellipsis relative">
                <i class="drop-route inline-block relative mr-1 text-xs"></i>
                <i class="{{miIcons.BOOKING_DROP_OFF_POINT}} inline-block relative mr-2"></i>
                <label translate class="lbl-geo-pickup"> bookings.drop_off</label>
                <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
                  [moduleName]="moduleName" class="w-full"></app-mi-fields>
              </div>
            </div>
            </div>
            }

            <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
              [moduleName]="moduleName"></app-mi-error>

            }

            }

            }

            @if(isManualDispatch && group.code === 'vehicle_preference'){
            <div class="field col-12 align-content-end md:col-6 ">
            </div>
            <div class="field col-12 align-content-end md:col-{{manualDispatch.uiColumns}} horizontal">
              <app-mi-fields [field]="manualDispatch" [attributeLabels]="attributeLabels" [showImg]=""
                [moduleName]="moduleName"></app-mi-fields>
            </div>
            }

            @if(isVisibleSelectVehicle && group.code === 'vehicle_preference'){
            <div class="field col-12 align-content-end md:col-6 ">
            </div>
            <div class="field col-12 align-content-end md:col-6 horizontal">

              <div translate="" class="mt-3 font-bold"> bookings.select_vehicle</div>
              <p-dropdown
                [options]="selectVehicle.presetValues"
                optionLabel="labelKey" optionValue="labelValue"
                [formControlName]="selectVehicle.attributeCode"
                [placeholder]=" 'bookings.any_vehicle' | translate"
                (onClick)="showAssignVehicle()" />
            </div>
            }


          </div>
        </div>
      </div>
      }@else {

        @if(!excludeToggle.includes(group.code)){
          <p-accordion class="w-full" >
            <p-accordionTab expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up" iconPos="right">
              <ng-template pTemplate="header">
                <div class="flex  justify-content-start align-items-center">
                  <div class="flex w-full align-items-center justify-content-left gap-2 mr-4">
                    <i class="{{getIcon(group.code)}} wh-36 inline-block cursor-pointer icon-color mr-2"  [ngClass]="{'active': group.codeActive}" ></i>
                    <span class="font-bold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label', attributeLabels, group.code)}}</span>
                  </div>

                  @for (field of group.fields; track field.attributeCode; let j = $index) {
                    @if(formValue?.[field.attributeCode]){
                        @if(field?.attributeCode == ride_preference_code){
                          @for (item of formValue?.[field.attributeCode]; track $index) {
                            <div class="flex justify-content-left selected-items mr-2">
                              <div class="flex align-items-left justify-content-left mr-1 active-label">
                                {{item}}  
                              </div>
                              <div class="flex align-items-center justify-content-center highlight-label">
                                <app-attribute-label [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-attribute-label>
                              </div>
                            </div>
                          }
                         }@else{
                      <div class="flex justify-content-left selected-items mr-2">                       
                          <div class="flex align-items-left justify-content-left mr-1 active-label">
                            {{formValue?.[field.attributeCode]}}  
                          </div>
                          <div class="flex align-items-center justify-content-center highlight-label">
                            <app-attribute-label [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-attribute-label>
                          </div>
                        </div>
                      }
                     }
                    }
                  
                </div>
              </ng-template>
              @if(!excludeToggle.includes(group.code)){
              <div class="booking-content p-fluid p-0 m-0" [id]="i" [ngClass]="{' ml-3 mr-2': !horizontalForm.includes(group.code)}">
                <div class="p-fluid p-formgrid grid mt-0">
                  <div class="w-full formgrid grid pt-3">
                    @for (field of group.fields; track field.attributeCode;let j = $index) {
    
                    @if(includeSpacer.includes(group.code)){
                    <div class="field col-12 align-content-end md:col-6">
                    </div>
                    }
    
                    <div class=" col-auto field col-12 align-content-end md:col-{{field.uiColumns}} align-items-center" 
                    [ngClass]="{'flex horizontal justify-content-between': horizontalForm.includes(group.code)}">
                      @if(field.inputCode !== 'spacer'){
                        <div>
                      <app-attribute-label [field]="field" [attributeLabels]="attributeLabels"
                        [moduleName]="moduleName"></app-attribute-label>

                        <div class="help-tips-text"> {{field.inputCode === 'fileImg' ? '' : cs.getLabelValue(moduleName +'.fields.'+ field.attributeCode + '.example',attributeLabels, '') }}</div>
                      </div>

                      <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
                        [moduleName]="moduleName"></app-mi-fields>
                 
                      }
                    </div>
                    }
    
                  </div>
                </div>
              </div>
            
          }
            </p-accordionTab>
          </p-accordion>
        }@else{
            <div class="flex w-full justify-content-start mi-panel  mt-3">
              <div class="flex align-items-center justify-content-left gap-2">
                <i class="{{getIcon(group.code)}} wh-36 inline-block cursor-pointer icon-color mr-2"  [ngClass]="{'active': group.codeActive}"></i>
                <span class="font-bold text-nowrap">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label', attributeLabels, group.code)}}</span>
              </div>
            
              @if (excludeToggle.includes(group.code)) {
                <div class="flex w-full justify-content-between ">
                  @for (field of group.fields; track field.attributeCode; let j = $index) {
                    <div class="flex align-items-center justify-content-center">
                      <app-attribute-label [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-attribute-label>
                    </div>
            
                    <div class="flex align-items-center justify-content-center">
                      <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]="" [moduleName]="moduleName"></app-mi-fields>
                      <app-mi-error [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-mi-error>
                    </div>
                  }
                </div>
              }
            </div>
        }

      
      }
    </div>
    }

    }
  </div>
</form>
<div class="col-12 save-action-div flex justify-content-end mt-3">

  <p-button [id]="saveBtnId" class="inline-flex height-44" (click)="submitData($event)"
    [label]="submitBtnText"></p-button>
</div>


@if(isVisibleAssignVehicle){
  <app-assign-vehicle-from-bookings 
  (onToggleOverLay)="isVisibleAssignVehicle"
    [visibleAssignVehicle]="isVisibleAssignVehicle"
    (onSelectVehicle)="onSelectedVehicle($event)"
     [vehicleBodyType]="vehicleBodyType">
    
    </app-assign-vehicle-from-bookings>
}

}