import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { KeyFilterModule } from 'primeng/keyfilter';
import { UppercaseDirective } from 'app/modules/shared/directives/uppercase.directive';

@Component({
  selector: 'app-mi-input-unique-id',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, KeyFilterModule, UppercaseDirective],
  templateUrl: './mi-input-unique-id.component.html',
  styleUrl: './mi-input-unique-id.component.scss'
})
export class MiInputUniqueIdComponent implements OnInit {
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() pKeyFilter: string = null;
  formName: FormGroup;
  placeholder: any;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    this.placeholder = this.placeholder === null ? '' : this.placeholder;
  }
}
