import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AppSettings } from '../../../../shared/app.settings';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-action-buttons',
  standalone: true,
  imports: [ButtonModule, TranslateModule],
  templateUrl: './action-buttons.component.html',
  styleUrl: './action-buttons.component.scss'
})
export class ActionButtonsComponent {
  SHOW_ASSIGN = AppSettings.BOOKING.SHOW_ASSIGN;
  SHOW_REASSIGN = AppSettings.BOOKING.SHOW_REASSIGN;
  @Input() rowData:any;

  constructor(private router: Router) { }

  onAssign(rowData: any) {
    this.router.navigate(['app/dispatch/' + rowData.id]);
  }
}
