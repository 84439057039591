<div class="card p-fluid">
    <div class="flex justify-content-between flex-wrap">
        <div class="flex justify-content-center mb-3">
            <label class="card-heading uppercase" translate>{{cs.getLabelValue('driver.groups.driver_details.label',attributeLabels,'driver_details')}}</label>
        </div>
    </div>
    @for (field of driverDetails.fields; track field; let i = $index) {
        @if (i !== 0) {
            <hr class="hr-line">
        }
    <div class="grid">
        <div class="col-4 pt-1">
            <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
        </div>
        <div class="col-8 pb-1">
            <label class="detail-value">{{ attributeValues?.[field.attributeCode] ? attributeValues?.[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
        </div>
    </div>
}
</div>