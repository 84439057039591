
<form [formGroup]="formName">
    <!-- <div class="field col-12 md:col-6"> -->
        @if(field?.presetValues){
            @for (item of field.presetValues; track $index) {
                <div class="mb-3">
                <p-checkbox
                    [value]="item.labelValue"
                    [label]="item.labelKey"
                    [inputId]="field.attributeCode+ $index"
                    (onChange)="onChange($event)"
                    />
                </div>
            }
        }@else {
            <p-checkbox
            [value]="field.labelValue"
            [label]="field.labelKey"
            name="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
            binary="true"
            inputId="field.attributeCode" />
        }
        <!-- </div> -->
    </form>

    <input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/>