<form class="radio-btns align-items-left" [ngClass]="{'flex flex-column vertical': field.optionVertical} "
  [formGroup]="formName">
  @for (option of field.presetValues; track option.value) {
  @if(!field.optionVertical){
  @if( !excludeCode.includes(field.attributeCode)){
  <div class="mr-5">
    <p-radioButton
      [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
      [value]="option.labelKey"
      [label]="option.labelValue"
      class="cursor-pointer" />

  </div>
  }
  }



  @if( excludeCode.includes(field.attributeCode)){
  <div class="field col-12 md:col-6">
    <p-radioButton
      [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
      [value]="option.labelKey"
      [label]="option.labelValue"
      class="cursor-pointer  booking-radio" />
    @if(option.labelKey === "Auto Dispatch" ){
    <div class="radio-label">
      <label>Selection based on <br>
        predefined criteria</label>
    </div>
    }

    @if(option.labelKey === "Manual Dispatch" ){
    <div class="radio-label">
      <label>Selection based on <br>
        specific requirement</label>
    </div>
    }
  </div>
  }


  @if(field.optionVertical){
  <div class="flex align-items-left justify-content-left mb-3 ">
    <p-radioButton
      [inputId]="option.labelKey"
      [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
      [value]="option.labelKey"
      class="cursor-pointer" />
    <label [for]="option.labelKey" class="ml-2 flex justify-content-center align-items-center"
      [class.selected]="isSelected(option.labelKey)">
      {{ option.labelValue}}
      <i class="{{option.labelIcon}} mi-lg wh-24  cursor-pointer inline-block ml-2"></i>
    </label>
  </div>

  }
  }
</form>