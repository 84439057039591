

<div class="header-strip">
        <p-selectButton
            (click)="op.toggle($event);"
            multiple="false"
            [(ngModel)]="selectBtnValue" 
            [options]="btnOptions">
                <ng-template let-item pTemplate>
                    <i [class]="item.icon"></i>
                </ng-template>
        </p-selectButton>

    <p-overlayPanel #op [style]="{ width: '174px' , height: '125px;'}" (onHide)="onHide()"  [dismissable]="true" (onHide)="onOverlayHide()">
        <ng-template pTemplate="content">
            <div (click)="showManageColumn();" class="inline-flex align-items-center justify-content-between w-full cursor-pointer pr-1 hover:surface-100 p-1">
                <span class="white-space gap-2" translate>manage_column_header</span>
                <span (click)="$event.stopPropagation(); showManageColumn($event)"><i class="{{miIcons.SETTINGS_FUTURE}} inline-block wh-16 top-2 relative cursor-pointer"></i></span>
            </div>
            <p-divider></p-divider>
            <div>
                <div class="white-space-nowrap bottom-8 relative mt-4 pb-1 font-text-style w-full" translate>items_per_page</div>
                    <div class=" relative" for="minmax-buttons">
                        <p-inputNumber [ngModel]="columnValue"
                            mode="decimal" inputId="minmax-buttons"
                            [min]="5"
                            [max]="500"
                            [step]="5"
                            [showButtons]="true"
                            class="per-page-input w-full"
                            (onInput)="onItemPerPageChange($event)">
                        </p-inputNumber>
                    </div>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>
<!-- <div class="flex-auto spac grid">
                  <p-inputNumber class="per-page-limit" inputStyleClass="bg-white" [(ngModel)]="columnValue" mode="decimal"
                    [showButtons]="true" [min]="0" [max]="500" [step]="5" (onBlur)="onItemPerPageChange()">
                  </p-inputNumber>
                </div> -->