<div class="grid ml-2">
    <div class="col">
        <div class="initial-details">
            <label class="category-name">{{ locationCategory }}</label>
        </div>
        <div class="other-details">
            @for (data of dataForStatus; track data; let i = $index) {
                @if (data.label) {
                    <label class="name">{{ data.label }} :</label>
                }
                <label class="value"> {{ data.text }}</label>
                @if (i < dataForStatus.length - 1) {
                    <label class="separator">|</label>
                }
            }
        </div>
    </div>
</div>
