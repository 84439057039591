import { Component } from '@angular/core';

@Component({
  selector: 'app-add-multiple-vehicles',
  templateUrl: './add-multiple-vehicles.component.html',
  styleUrls: ['./add-multiple-vehicles.component.scss'],
  standalone: true
})
export class AddMultipleVehiclesComponent {

}
