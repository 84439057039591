import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AutoFocusDirective } from '../../../../../../shared/directives/auto-focus.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { CommonBindingDataService } from '../../../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../../../shared/services/config.service';
import { MessageService } from 'primeng/api';
import { AppSettings } from '../../../../../../shared/app.settings';
import { SettingsService } from 'app/modules/settings/settings.service';
import { CheckboxModule } from 'primeng/checkbox';
import { ListResponse } from 'app/modules/vehicles/modal/listResponse';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-settings-add-body-types',
  standalone: true,
  imports: [TranslateModule, ButtonModule, DialogModule, ToastModule, InputTextModule, ReactiveFormsModule, FormsModule, CheckboxModule, DropdownModule],
  templateUrl: './settings-add-body-types.component.html',
  styleUrl: './settings-add-body-types.component.scss',
  providers: [AutoFocusDirective]
})
export class SettingsAddBodyTypesComponent {
  @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  settingsForm: FormGroup;
  @Input() isVisible;
  @Input() rowData;
  dialogHeader: string;
  tableDataForVehicleType: any = [];
  entityDataForVehicleType: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: AppSettings.TENANT_CODE,
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.VEHICLE_TYPE
  };

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder,
    private configService: ConfigService, private settingsService: SettingsService,
    private messageService: MessageService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.searchAttributeSettingsForVehicleType();
    this.formValidation();
    this.updateDialogHeader();
    if(this.rowData) {
      this.isEditModeForm();
    }
  }

  searchAttributeSettingsForVehicleType() {
    this.settingsService.searchAttributeSettings(AppSettings.ENTITY_TYPE.VEHICLE, this.entityDataForVehicleType).subscribe((res: ListResponse) => {
      this.tableDataForVehicleType = this.cs.getTableDataForSettings(res);
    })
  }

  formValidation() {
    this.settingsForm = this.formBuilder.group({
      vehicleType: ['', [Validators.required]],
      name: ['', [Validators.required]],
      markAsDefault: [''],
    });
  }

  updateDialogHeader() {
    this.dialogHeader = this.rowData ? this.cs.getLabel('settings.passenger.lbl_edit_passenger_type') : this.cs.getLabel('settings.passenger.lbl_add_passenger_type');
  }

  isEditModeForm() {
    this.patchFormValues(this.rowData);
  }

  patchFormValues(formData) {
    this.settingsForm.patchValue({
      vehicleType: formData.parentLabelKey,
      name: formData.labelValue,
      markAsDefault: formData?.defaultPreset
    });

    if (!this.rowData.customValue) {
      this.settingsForm.get('name')?.disable();
    } else {
      this.settingsForm.get('name')?.enable();
    }
  }

  onHide() {
    this.isVisible = false;
    this.settingsForm.reset();
    this.cd.detectChanges();
    this.onToggleOverLay.emit(this.isVisible);
  }

  onDialogShow() {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput?.nativeElement?.focus();
      } else {
        console.error('nameInput is undefined');
      }
    }, 0);
  }

  onAddSettings() {
    if (this.settingsForm.valid) {
      let reqBody;
      reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.BODY_TYPE,
        presetValue: this.settingsForm.value.name,
        presetKey: this.settingsForm.value.name,
        defaultPreset: this.settingsForm.value.markAsDefault,
        parentPresetKey: this.settingsForm.value.vehicleType,
      }

      if(!this.rowData) {
        reqBody.presetValue = this.settingsForm.value.name;
        reqBody.presetKey = this.settingsForm.value.name;
      }
      else {
        reqBody.presetValue = this.rowData.customValue ? this.settingsForm.value.name : this.rowData.labelValue;
        reqBody.presetKey = this.rowData.customValue ? this.settingsForm.value.name : this.rowData.labelKey;
      }

      const addEditAPI = this.rowData ? this.settingsService.editAttributeSettings(AppSettings.ENTITY_CODE.VEHICLE, this.rowData.attributePresetLabelId, reqBody) : this.settingsService.addAttributeSettings(AppSettings.ENTITY_CODE.VEHICLE, reqBody)
      addEditAPI.subscribe((res : any) => {
        this.messageService.add({ key:'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
        this.onHide();
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

}
