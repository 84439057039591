import { Injectable } from '@angular/core';
import { RestApiService } from '../../services/rest-api.service';
import { AppSettings } from '../../app.settings';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MITableService {

  constructor(private restApiService: RestApiService) { }

  bulkRowCount = new BehaviorSubject(null);

  updateUserEntityConfig(entityCode: string, data: any) {
    return this.restApiService.post('Update user entity preferences', `${AppSettings.TENANT_CODE}/api/v1/entity-conf/${entityCode}/user-preferences`, data, 'page-center');
  }


  getUserEntityConfig(entityCode: string, prefType: string) {
    return this.restApiService.get('GET USER ENTITY CONFIG', `${AppSettings.TENANT_CODE}/api/v1/entity-conf/${entityCode}/user-preferences/${prefType}?forTenantCode=${AppSettings.TENANT_CODE}`, 'page-center');
  }

}
