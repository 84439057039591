import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HideButtonComponent } from "../hide-button/hide-button.component";
import { CommonModule, NgClass } from "@angular/common";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-live-journey-types",
  templateUrl: "./live-journey-types.component.html",
  standalone: true,
  imports: [TranslateModule, HideButtonComponent, NgClass, CommonModule],
})
export class LiveJourneyTypesComponent implements OnInit {
  @Input() transportRequestData: any;
  cardId: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.live_journey_by_types");
  }
}
