<div>
    <span class="flex align-items-start p-input-icon-left w-full input-container pr-3">
        <i class="pi pi-search flex align-items-start text-sm text-500 wh-8 pl-1"
            [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }" (click)="clearSearch()"></i>
        <input type="text" pInputText class="flex align-items-start pl-6 text-base" (input)="onSearch($event)"
            [placeholder]="inputPlaceholder" [(ngModel)]="searchValue" />
    </span>
</div>

<div class="pt-3 pb-3 pr-3 flex">
    <div class="flex-1 flex align-items-center">
        <label class="truncate-text"> {{'lbl_select_only' |translate}}  {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label> &nbsp;

    </div>

    <div class="flex align-items-center">
        <label class="flex align-items-center pr-1">      
            <p class="flex mb-0" [ngClass]="{'text-blue': checked.length > 0, 'text-black': checked.length == 0}">{{'lbl_select_all_count' | translate}}</p> 
            <p class="mb-0 ml-1 mr-2" [ngClass]="{'bold': checked.length > 0, 'text-color-secondary': checked.length == 0}" style="width: 40px;">({{field?.presetValues?.length}})</p>
            <p-checkbox name="groupName" value="val1" class="flex w-auto"
                [(ngModel)]="checked"
                (onChange)="onChange($event)" ></p-checkbox>
        </label>
    </div>

</div>

<div class="overflow-y-auto list-section">
    @if(field){
        @if(filteredValues?.length>0){
            @for(item of filteredValues; track item; let i=$index;) {
                @if(field.attributeCode === 'color' || field.attributeCode === 'group_color'){
                    <div class="relative pr-3">
                        <span class="dot " [ngClass]="{'black-border': item.labelKey === 'White'}"
                            [style.background]="item.labelKey"></span> <p-checkbox class="cursor-pointer pt-3 pb-3 pl-3 "
                            [label]="item.labelKey" [name]="field.attributeCode" [value]="item.labelValue" [(ngModel)]="selectedControl"
                            (onChange)="valueChange()"></p-checkbox>
                    </div>
                }@else{
                    <div class=" pr-3">
                        <p-checkbox class="cursor-pointer pt-3 pb-3" [label]="item.labelKey" [name]="field.attributeCode"
                            [value]="item.labelValue" [(ngModel)]="selectedControl" (onChange)="valueChange()"></p-checkbox>
                    </div>
                }
                @if( i < field.presetValues.length-1){
                    <p-divider></p-divider>
                }
            }
        }
    }
</div>