import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';

import { CardModule } from 'primeng/card';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { AttributeData, entityResponse } from 'app/modules/vehicles/modal/attributeModal';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { AppIcons } from 'app/modules/shared/app.icons';
import { EntityList } from 'app/modules/vehicles/modal/entityModal';
import * as _ from 'lodash';

@Component({
  selector: 'app-settings-driver-app-login',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, ButtonModule, TranslateModule, CardModule],
  templateUrl: './settings-driver-app-login.component.html',
  styleUrl: './settings-driver-app-login.component.scss'
})
export class SettingsDriverAppLoginComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  miIcons = AppIcons;
  activeIndex: number = 1;
  data: AttributeData;
  appLoginAttributeData: any = {};
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  appLoginAttributeLength: number;
  appLoginId: string;
  attributeValues = [];
  nextBtnLabel: string;
  previousBtnText: string;
  appLoginEntityData: any;
  selectedLoginOptionIds = [];
  tableData: any = [];
  attributeCodeForRoleCode: any;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: AppSettings.TENANT_CODE,
    actionStatus: ''
  };

  constructor(private appLoginService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private configService: ConfigService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.setLabels();
    this.setAppSettings();
    this.loadAppLoginData();
  }

  private setLabels() {
    this.appLoginId = this.route.snapshot.paramMap.get('id');
    this.nextBtnLabel = this.cs.getLabel('lbl_save');
    this.previousBtnText = this.cs.getLabel('cancel');
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.moduleName = AppSettings.ENTITY_CODE.USER_APP_LOGIN_SETTING;
  }

  private loadAppLoginData() {
    if (this.appLoginId) {
      this.appLoginService
        .getEntity(this.appLoginId, AppSettings.ENTITY_CODE.USER_APP_LOGIN_SETTING)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.getAttributes();
          }
        });
    } else {
      this.getAttributes();
    }
  }

  private getAttributes() {
    const entityType = AppSettings.ENTITY_CODE.USER_APP_LOGIN_SETTING;
    this.appLoginService.getAttributeDefinition(entityType, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        const group = this.data.tabs[0].groups.filter(group => group.code === 'setting');
        const roleCodeGroup = _.cloneDeep(group[0]?.fields);
        this.attributeCodeForRoleCode = _.find(roleCodeGroup, { attributeCode: 'role_code' });
        this.data.tabs[0].groups[0].fields = _.reject(group[0]?.fields, { attributeCode: 'role_code' });
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.appLoginAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.appLoginAttributeLength = this.appLoginAttributeData.tabs.length;
        this.searchEntity();
      }
    });
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.countryCode = country[0].countryCode;
    this.entityData.filters.push({
      attributeId: this.attributeCodeForRoleCode?.attributeId,
      attributeValue: AppSettings.DRIVER_APP_LOGIN_ATTRIBUTE_CODE.ROLE_CODE_DRIVER,
    });
    this.appLoginService
      .searchEntity(AppSettings.ENTITY_CODE.USER_APP_LOGIN_SETTING, this.entityData)
      .subscribe((res: any) => {
        this.tableData = [];
        this.tableData = res.data?.map(data => {
          return { id: data.id, ...data.values };
        }) || [];
      });
  }

  selectedLoginOptionIdToAppLogin(event) {
    this.selectedLoginOptionIds = event;
  }

  onSaveAppLogins(event?) {
    let appLoginData;
    appLoginData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    const appLoginFormData = event;
    for (const [key, value] of Object.entries(appLoginFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            appLoginData?.data?.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          appLoginData?.data?.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }

    appLoginData?.data?.push({
      attributeCode: AppSettings.DRIVER_APP_LOGIN_ATTRIBUTE_CODE.SELECT_LOGIN_OPTION,
      attributeValue: this.selectedLoginOptionIds
    })

    appLoginData?.data?.push({
      attributeCode: AppSettings.DRIVER_APP_LOGIN_ATTRIBUTE_CODE.ROLE_CODE,
      attributeValue: AppSettings.DRIVER_APP_LOGIN_ATTRIBUTE_CODE.ROLE_CODE_DRIVER
    })

    this.setAppLoginEntityData(this.data.entityCode);
    if (!this.appLoginId) {
      this.appLoginService.createEntities(this.appLoginEntityData.entityCode, this.appLoginEntityData).subscribe((res: entityResponse) => {
        this.appLoginId = res.entityId;
        this.saveAppLoginData(this.appLoginEntityData.entityCode, appLoginData);
      });
    } else {
      this.saveAppLoginData(this.appLoginEntityData.entityCode, appLoginData);
    }
  }

  setAppLoginEntityData(entityCode) {
    this.appLoginEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveAppLoginData(entityCode, appLoginData) {
    appLoginData.data = this.cs.mapAttributeIds(appLoginData.data, this.appLoginAttributeData.tabs);
    this.appLoginService.saveAttributeData(entityCode, this.appLoginId, appLoginData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel(res.message) });
    });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel('label_save');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.appLoginAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.cs.getLabel('label_save');
    }
  }

  getLastIndex(): number {
    return this.appLoginAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.miFormComponent.resetLoginOptions();
  }
}

