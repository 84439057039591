import { Component, OnInit, ViewChild } from "@angular/core";
import { MenuItem } from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { DispatchMapComponent } from "../dispatch-map/dispatch-map.component";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { SidebarModule } from "primeng/sidebar";
import { TooltipModule } from "primeng/tooltip";
import { BookingSectionComponent } from "../booking-section/booking-section.component";
import { AssignDriverComponent } from "../assign-driver/assign-driver.component";
import { DialogHeaderComponent } from "../dialog-header/dialog-header.component";
import { DispatchBookingDetailsComponent } from "../booking-section/booking-details/dispatch-booking-details.component";
import { BookingListCardComponent } from "../booking-section/booking-list-card/booking-list-card.component";
import { DriverDetailsComponent } from "../assign-driver/driver-details/driver-details.component";
import { DriverListCardComponent } from "../assign-driver/driver-list-card/driver-list-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { DatePipe } from "@angular/common";
import { AppIcons } from "../../../shared/app.icons";
import { AppSettings } from "../../../shared/app.settings";
import * as _ from 'lodash';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { EntityService } from "app/modules/shared/services/entity.service";
import { Country } from "app/modules/shared/models/country";
import { Language } from "app/modules/shared/models/language";
import { ConfigService } from "app/modules/shared/services/config.service";
@Component({
  selector: "app-dispatch",
  templateUrl: "./dispatch.component.html",
  styleUrls: ["./dispatch.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    DispatchMapComponent,
    SidebarModule,
    DialogModule,
    ButtonModule,
    TooltipModule,
    BookingSectionComponent,
    AssignDriverComponent,
    DialogHeaderComponent,
    DispatchBookingDetailsComponent,
    BookingListCardComponent,
    DriverDetailsComponent,
    DriverListCardComponent,
    TranslateModule,
    DatePipe
  ],
})
export class DispatchComponent implements OnInit {
  @ViewChild(AssignDriverComponent) assignDiverComponent: AssignDriverComponent;
  @ViewChild(BookingSectionComponent) bookingSectionComponent: BookingSectionComponent;
  @ViewChild(DialogHeaderComponent) dialogComponent: DialogHeaderComponent;
  routePath: MenuItem[];
  leftBookingsSectionVisible: boolean = false;
  rightDriversSectionVisible: boolean = false;
  leftDialogPosition: string = "center";
  rightDialogPosition: string = "center";
  dummyNote: string = "Hello There";
  showBookingDetails: boolean = false;
  showDriverDetails: boolean = false;
  bookingDetailItem: any = {};
  driverDetailItem: any = {};
  bookingDetailsById: any[] = [];
  bookingHeader: string;
  assignDriverHeader: string;
  position: string;
  miIcons = AppIcons;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  geoLocationsData: any = {};
  dispatchBookingDetails;

  constructor(private cs: CommonBindingDataService, 
    private router: Router,
    private entityService: EntityService,private route: ActivatedRoute, private configService: ConfigService
  ) { }

  ngOnInit() {
    this.setRoutePath();    
    this.bookingHeader = this.cs.getLabel("dispatch.bookings");
    this.assignDriverHeader = this.cs.getLabel("dispatch.assign_driver");
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel("dispatch.dispatch"),
        routerLink: "",
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
    ];
  }

  showLeftBookingsDialog(position: string) {
    this.leftDialogPosition = position;
    this.position = position;
    this.leftBookingsSectionVisible = true;
  }
  showRightDriversDialog(position: string) {
    this.rightDialogPosition = position;
    this.position = position;
    this.rightDriversSectionVisible = true;
  }

  onShowBookingDetail(event) {
    this.showBookingDetails = event.hasData;
    this.bookingDetailItem = event.eventData;

    this.getBookingDetail();
  }

  getBookingDetail() {
    this.entityService.getEntity(this.bookingDetailItem.id, AppSettings.ENTITY_CODE.BOOKING).subscribe((result: any) => {
      const details = {};
      result.attributeCodeValueDtoList.map(data => {
        details[data.attributeCode] = data.attributeValue;
      })
      this.dispatchBookingDetails = details;
      this.bookingDetailsById.splice(0, this.bookingDetailsById.length);
      this.bookingDetailsById.push(details);
      const booking = this.bookingDetailsById[0];
      if (booking) {
        this.geoLocationsData=  this.cs.getGeoLocations(booking);
      }
      this.router.navigate(['app/dispatch', this.bookingDetailItem.id]);
    })
  }

 

  onCloseBookingDetailView(event) {
    this.showBookingDetails = event;
    this.bookingDetailItem.id = null;
    this.router.navigate(['app/dispatch']);
  }

  onShowDriverDetail(event) {
    this.showDriverDetails = event.hasData;
    this.driverDetailItem = event.eventData;
  }

  onCloseDriverDetailView(event) {
    this.showDriverDetails = event;
  }

  onDriverFilterValueChange(event) {
    const driverFormData = event;
    this.assignDiverComponent.entityData.filters = [];
    for (const [key, value] of Object.entries(driverFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.assignDiverComponent.entityData.filters.push({
          attributeCode: key,
          attributeValue,
        });
      }
    }
    this.assignDiverComponent.entityData.filters = _.filter(
      _.uniq(this.assignDiverComponent.entityData.filters, function(item, key, a) {
        if (item.attributeValue.length > 0) {
          return item.attributeId;
        }
      }),
      function(element) {
        if (element.attributeValue.length > 0) {
          return true;
        }
        return false;
      }
    );
    this.assignDiverComponent.entityData.offset = event.first;
    this.assignDiverComponent.entityData.filters = this.cs.mapAttributeIds(this.assignDiverComponent.entityData.filters, this.dialogComponent.filterList, 'filter');
    this.assignDiverComponent.searchEntity();
  }

  onBookingFilterValueChange(event) {
    const bookingData = event;
    this.bookingSectionComponent.entityData.filters = [];
    for (const [key, value] of Object.entries(bookingData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.bookingSectionComponent.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.bookingSectionComponent.entityData.filters = _.filter(_.uniq(this.bookingSectionComponent.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeId;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.bookingSectionComponent.entityData.offset = event.first;
    this.bookingSectionComponent.entityData.filters = this.cs.mapAttributeIds(this.bookingSectionComponent.entityData.filters, this.dialogComponent.filterList, 'filter');
    this.bookingSectionComponent.getBookingList();
  }

}
