import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RestApiService } from "../../shared/services/rest-api.service";
import { ZoneItem, ZoneItemPagination } from "../modal/zoneModal";
import { ConfigService } from "../../shared/services/config.service";
import { AppSettings } from "../../shared/app.settings";

@Injectable({
  providedIn: "root",
})
export class ManageZonesService {
  tenantCode: string;
  constructor(
    private restApiService: RestApiService,
    private confService: ConfigService
  ) {
    this.tenantCode = confService.getLoggedInTenantCode();
  }

  getZonesList(data: ZoneItemPagination): Observable<any> {
    return this.restApiService.post(
      "",
      `${this.tenantCode}/api/v1/zones/search?forTenantCode=${AppSettings.TENANT_CODE}`,
      data,
      "page-center"
    );
  }
  getZoneDetails(zoneId: string): Observable<any> {
    return this.restApiService.get(
      "",
      `${this.tenantCode}/api/v1/zones/${zoneId}?forTenantCode=${AppSettings.TENANT_CODE}`,
      "page-center"
    );
  }
  addZone(zoneItem: ZoneItem): Observable<any> {
    return this.restApiService.post(
      "",
      `${this.tenantCode}/api/v1/zones`,
      zoneItem,
      "page-center"
    );
  }
  editZone(zoneId: string, zoneItem: ZoneItem): Observable<any> {
    return this.restApiService.put(
      "",
      `${this.tenantCode}/api/v1/zones/${zoneId}`,
      zoneItem,
      "page-center"
    );
  }
}
