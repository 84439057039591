import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { DriverListCardComponent } from "../assign-driver/driver-list-card/driver-list-card.component";
import { FilterBoxComponent } from "../../../shared/components/filter-box/filter-box.component";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { EntityService } from "../../../shared/services/entity.service";
import { AppSettings } from "../../../shared/app.settings";
import * as _ from 'lodash';

@Component({
  selector: "app-dialog-header",
  templateUrl: "./dialog-header.component.html",
  styleUrls: ["./dialog-header.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    TranslateModule,
    InputTextModule,
    ButtonModule,
    DriverListCardComponent,
    FilterBoxComponent
  ],
})
export class DialogHeaderComponent implements OnInit {
  @Output() closeBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filterValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onValueChange = new EventEmitter<any>();
  @Input() headerLabel: string;
  @Input() forDetail: string;
  @Input() driverDetail: string;
  @Input() hideSearchFilters: boolean = false;
  @Input() position: string;
  @Input() moduleName;
  @Input() view;

  selectedFilterCount: number;
  searchValue: string = "";
  show: boolean = false;
  lastSyncTime: any;
  lastSync: Date = new Date();
  filterList;
  filterAttributeLabels = {};
  attributeLabels = {};
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];
  constructor(private cs: CommonBindingDataService, private entityService: EntityService) {}

  ngOnInit() { 
    this.lastSyncTime = this.lastSync.toLocaleTimeString();

    if (this.moduleName === 'driver' && this.view === 'DRIVER_FILTER_ON_DISPATCH_VIEW') {
      this.getFilterView(this.moduleName, this.view);
    }
    if (this.moduleName === 'booking' && this.view === 'BOOKING_FILTER_ON_DISPATCH_VIEW') {
      this.getFilterView(this.moduleName, this.view);
    }
  }

  onSearch(event) {
    const value = event.target.value;
    this.cs.setSearchData(value);
  }

  clearSearch() {
    this.searchValue = "";
    this.cs.setSearchData('');
  }

  onCloseDriverDetailView(event) {
    this.closeBtnClicked.emit(false);
  }

  showFilterDialog() {
    this.show = true;
  }

  refreshTime() {
    this.lastSync = new Date();
    this.lastSyncTime = this.lastSync.toLocaleTimeString()
  }

  getFilterView(moduleName, view?) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityService.getAttributeDefinition(moduleName,country[0]?.tenantId, view).subscribe((filterResponse) => {
        if (filterResponse) {
          this.filterAttributeLabels =
            this.cs.getAttributeLabels(filterResponse);
          this.filterList = this.cs.getColumns(filterResponse);
          this.filterList = _.sortBy(this.filterList, "attributeIndex");
        }
      });
  }

   onFilterValueChange(filterFlag,event) {
    filterFlag ? (this.advanceFilterValue = event) : (this.quickFilterValue = event);

    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue }
    this.selectedFilterCount = 0;

    for (const [key, value] of Object.entries(event)) {
      const attributeValue = value;
      if (attributeValue) {
        this.selectedFilterCount++;
      }

    }
    this.onValueChange.emit(combineFilter);
  }
}
