import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AttributeData } from 'app/modules/vehicles/modal/attributeModal';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { MITableComponent } from 'app/modules/shared/components/mi-table/mi-table.component';
import { GroupColorComponent } from '../group-color/group-color.component';
import { StatusCellComponent } from 'app/modules/shared/components/mi-table/status-cell/status-cell.component';
import { EntityList } from 'app/modules/vehicles/modal/entityModal';
import { DriverList } from 'app/modules/driver/modal/driverListModal';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manage-group-details',
  standalone: true,
  imports: [TranslateModule, BreadcrumbModule,
    ButtonModule, MenuModule,MITableComponent,GroupColorComponent,StatusCellComponent,DatePipe],
  templateUrl: './manage-group-details.component.html',
  styleUrl: './manage-group-details.component.scss'
})
export class ManageGroupDetailsComponent implements OnInit {

  routePath: MenuItem[] = [];
  groupId: string;
  country: Country;
  language: Language;
  activeIndex: any = 0;
  data: AttributeData;
  groupAttributeData: any;
  attributeLabels = {};
  vehicleAttributeLength;
  attributeValues = [];
  showStatus: boolean = false;
  vehicleEntityId;
  currentRow;
  vehicleDetails: any;
  groupDetails: any;
  entityForStatus: any;
  tab: any;
  details: any;
  groupAddedDrivers: any;
  moduleName: any;
  deleteBtnLabel: any;
  editBtnLabel: any;
  countryCodeWithName = AppSettings.COUNTRY_CODE_WITH_COUNTRY_NAME;
  countryCodeForTransportSupervisorNumber: any;
  emergencyNumber: any;
  userId: any;
  message: string;
  header: string;
  miIcons = AppIcons;
  isDeletedRecord: boolean = false;
  driverListPayload: any ={
    forTenantCode: AppSettings.TENANT_CODE,
    countryCode: '',
    viewCode: AppSettings.VIEW_CODE.DRIVER_LIST_FOR_GROUP_VIEW,
    entityIds:[],
    limit: AppSettings.ENTITY_DATA_PER_PAGE_LIMIT,
    offset: 0,
  }
  tableData: any = [];
  dd_MMM_yyyy_hh_mm_FORMAT: any = AppSettings.dd_MMM_yyyy_hh_mm_FORMAT;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private configService: ConfigService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private driverService: EntityService) {

  }

  ngOnInit() {
    this.groupId = this.route.snapshot.paramMap.get('id');
    this.getAttributeData();
    this.setLabels();
    this.setRoute();
    this.entityService.vehicleCurrentRow.subscribe(res => {
      this.currentRow = res;
    })
  }

  setLabels() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.header = this.cs.getLabel('manage_group.message.confirm_delete_header');
    this.message = this.cs.getLabel('manage_group.message.confirm_delete_msg');
  }

  setRoute() {
    this.routePath = [
      {
        label: "Groups",
        routerLink: '/app/manage-groups',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Details",
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {
      this.data = res;
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.groupAttributeData = this.cs.getOrganizedAttribute(this.data);
      this.vehicleAttributeLength = this.groupAttributeData.tabs.length;
      this.moduleName = this.data.entityCode;
      this.getGroupDetails();
    })
  }

  getGroupDetails() {
    this.entityService.getEntity(this.groupId, AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe((result: any) => {
      this.groupDetails = result;
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.isDeletedRecord = this.groupDetails.actionStatus === AppSettings.ACTION_STATUS.DELETED;
      this.showStatus = true;
      this.tab = this.groupAttributeData.tabs.find(ele => ele.tabCode === 'details');
      this.details = this.tab.groups.find(ele => ele.code === 'group_details');
      this.getFlagsForMobileNumber();
      let assignedDrivers = result.attributeCodeValueDtoList.find(element => element.attributeCode === 'assigned_drivers')
      if(assignedDrivers){
        this.driverListPayload.entityIds = assignedDrivers.attributeValue
        this.getEntityDetails();
      }
    })
  }

  getEntityDetails(){
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.driverListPayload.countryCode = this.country[0].countryCode;
    this.entityService.getEntityDetailsByEntityIds(AppSettings.ENTITY_CODE.DRIVER,this.driverListPayload).subscribe((res: any) => {
      this.tableData = res.data
    })
  }

  getImage(fileId, driver) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'driver');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
       driver.profilePhoto = base64data;
      }
    })
 }

  getFlagsForMobileNumber() {
    const findAttributeValue = (attributeCode: string) =>
      this.groupDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;

    const findFlag = (flagCode: string) =>
      this.countryCodeWithName.find(ele => ele.countryCode === flagCode)?.code;
    const transportSuperVisorNumber = findAttributeValue('transport_supervisor_number');
    const flag1 = findFlag(transportSuperVisorNumber?.split('-')[0])
    this.countryCodeForTransportSupervisorNumber = flag1 ? flag1 : null;

    const emergencyNumber = findAttributeValue('emergency_number');
    const flag2 = findFlag(emergencyNumber?.split('-')[0])
    this.emergencyNumber = flag2 ? flag2 : null;

  }

  deleteGroup() {
    this.confirmationService.confirm({
      header: this.header,
      message: this.message,
      acceptIcon: null,
      rejectIcon: null,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      acceptLabel: this.cs.getLabel('lbl_delete'),
      rejectLabel: this.cs.getLabel('cancel'),
      accept: ($event) => {
        this.deleteGroupApiCall();
      },
      reject: () => {
      }
    });
  }

  deleteGroupApiCall() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER_GROUP,
      entityIds: [this.groupId],
      countryCode: this.country[0].countryCode
    };
    this.entityService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('manage_group.message.group_delete_success') });
      this.router.navigate(['/app/manage-groups']);
    },
    error: (error) => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
    }
  });
  }

  edit() {
    this.router.navigate(['app/manage-groups/edit/' + this.groupId,]);
  }

}
