import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { OverviewDetailsComponent } from './overview-details/overview-details.component';
import { OverviewStatusComponent } from './overview-status/overview-status.component';
import { MenuItem } from 'primeng/api';
import { Status } from '../../../shared/models/status';
import { Country } from '../../../shared/models/country';
import { AttributeData } from '../../../vehicles/modal/attributeModal';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { AppSettings } from '../../../shared/app.settings';
import { DatePipe, NgClass } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { Language } from 'app/modules/shared/models/language';

enum IncidentStatus {
  Open = 'Open',
  UnderReview = 'Under Review',
  InProgress = 'In Progress',
  Resolved = 'Resolved'
}

@Component({
  selector: 'app-incidents-complaints-details',
  templateUrl: './incidents-complaints-details.component.html',
  styleUrl: './incidents-complaints-details.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, TranslateModule, MenuModule, ToastModule, ButtonModule, OverviewDetailsComponent, OverviewStatusComponent, DropdownModule, FormsModule, NgClass],
  providers: [DatePipe]
})
export class IncidentsComplaintsDetailsComponent implements OnInit {
  routePath: MenuItem[] = [];
  incidentsComplaintsId: string;
  dataForStatus: Array<Status> = [];
  country: Country;
  data: AttributeData;
  incidentsComplaintsAttributeData: any;
  attributeLabels = {};
  incidentsAttributeLength;
  attributeValues = [];
  showStatus: boolean = false;
  incidentsComplaintsDetails: any;
  incidentsComplaintsCategory: any;
  editBtnLabel: any;
  statuses = [];
  selectedStatus: any;
  language: Language;

  constructor(private route: ActivatedRoute,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private incidentsComplaintService: EntityService,
    private datePipe: DatePipe,
    private router: Router,
    private configService: ConfigService) {

  }

  ngOnInit(): void {
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.incidentsComplaintsId = this.route.snapshot.paramMap.get('id');
    this.getIncidentsComplaintsDetails();
    this.getAttributeData();
    this.setRoutePath();
    this.searchStatuses();
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('incidents_complaints.lbl_route_path'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.cs.getLabel('incidents_complaints.lbl_overview'),
        routerLink: '../' + this.incidentsComplaintsId,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  setStatusData() {
    const attributes = this.incidentsComplaintsDetails?.attributeCodeValueDtoList;
    const attributeMap = {
      'raised_by': 'Raised By',
      'category': 'Category',
      'raised_by_type': 'Raised By Type'
    };

    this.dataForStatus = Object.keys(attributeMap).map(attributeCode => {
      const attributeValue = attributes?.find(ele => ele.attributeCode === attributeCode)?.attributeValue;
      return {
        label: attributeMap[attributeCode],
        text: attributeValue ?? ''
      };
    });
  }


  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.incidentsComplaintService.getAttributeDefinition(AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {
      this.data = res;
      this.setStatusData();
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.incidentsComplaintsAttributeData = this.cs.getOrganizedAttribute(this.data);
      this.incidentsAttributeLength = this.incidentsComplaintsAttributeData.tabs.length;
    })
  }

  getIncidentsComplaintsDetails() {
    this.incidentsComplaintService.getEntity(this.incidentsComplaintsId, AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT).subscribe((result: any) => {
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.incidentsComplaintsDetails = result;
      const statusValue = result.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'incidents_and_complaint_status');
      if (statusValue) {
        this.selectedStatus = statusValue.attributeValue;
      }
      this.attributeValues[AppSettings.DATE_ATTRIBUTE_IDS.INCIDENTS_DATE_TIME] = this.datePipe.transform(new Date(this.attributeValues[AppSettings.DATE_ATTRIBUTE_IDS.INCIDENTS_DATE_TIME]), AppSettings.INCIDENT_DATE_TIME_FORMAT);
      const value = result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'journey_id');
      if (value) {
        this.incidentsComplaintsCategory = value.attributeValue;
      }
      this.showStatus = true;
    })
  }

  edit() {
    this.router.navigate(['app/incidents-complaints/add/' + this.incidentsComplaintsId,]);
  }

  searchStatuses() {
    const entityData = {
      limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
      offset: 0,
      searchStr: "",
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc',
      forTenantCode: AppSettings.TENANT_CODE,
      attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.INCIDENTS_AND_COMPLAINT_STATUS
    };
    this.incidentsComplaintService.searchAttributeSettings(AppSettings.ENTITY_TYPE.INCIDENTS_AND_COMPLAINT, entityData).subscribe((res: any) => {
      this.statuses = res.data.map(item => ({
        labelKey: item.labelKey,
        labelValue: item.labelValue,
        value: item,
        color: item.color
      }))
    })
  }

  onStatusChange(event) {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    if (this.incidentsComplaintsDetails) {
      this.incidentsComplaintsDetails.incidents_and_complaint_status = event.value.labelValue;
      const requestBody = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        incidentAndComplaintEntityId: this.incidentsComplaintsDetails.entityId,
        incidentAndComplaintStatus: event.value,
      };
      const entityCode = AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT;
      this.incidentsComplaintService.updateIncidentStatus(entityCode, requestBody).subscribe(result => {
        
      });
    }
  }

  getStatusClassForStatus(status: IncidentStatus) {
    switch (status) {
      case IncidentStatus.Open:
        return AppSettings.INCIDENTS_STATUS_CLASS.OPEN;
      case IncidentStatus.UnderReview:
        return AppSettings.INCIDENTS_STATUS_CLASS.UNDER_REVIEW;
      case IncidentStatus.InProgress:
        return AppSettings.INCIDENTS_STATUS_CLASS.IN_PROGRESS;
      case IncidentStatus.Resolved:
        return AppSettings.INCIDENTS_STATUS_CLASS.RESOLVED;
      default:
        return AppSettings.INCIDENTS_STATUS_CLASS.DEFAULT;
    }
  }

}
