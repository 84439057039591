import { Injectable } from '@angular/core';
import { Country } from 'app/modules/shared/models/country';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  country: Country;

  constructor(private restApiService: RestApiService, private confService: ConfigService) { }

  addOrganization(requestBody) {
    return this.restApiService.post('ADD ORGANIZATION', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants`, requestBody, 'page-center');
  }

  updateOrganization(requestBody, tenantId) {
    return this.restApiService.put('UPDATE ORGANIZATION', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/${tenantId}`, requestBody, 'page-center');
  }

  getOrganizationList(requestBody) {
    return this.restApiService.post('ORGANIZATION LIST', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/search`, requestBody, 'page-center');
  }

  getOrgDetails(tenantId: string) {
    return this.restApiService.get('GET TENANT DETAILS BY TENANT ID', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/${tenantId}`, 'page-center');
  }

  getUserDetails(userId: string, forTenantCode) {
    return this.restApiService.get('GET USER BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/users/${userId}?forTenantCode=${forTenantCode}`, 'page-center');
  }

  updateOrganizationStatus(tenantId: string, data: {}) {
    return this.restApiService.put('Update Organization Status', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/${tenantId}/action`, data, 'page-center');
  }

  getModuleList() {
    return this.restApiService.get('Get Module List', `${this.confService.getLoggedInTenantCode()}/api/v1/modules/search?forTenantCode=tsys`, 'page-center');
  }

  getAccessModuleList(tenantId) {
    return this.restApiService.get('Get Access Module List', `${this.confService.getLoggedInTenantCode()}/api/v1/tenant-modules/search?forTenantCode=${this.confService.getForTenantCode()}&tenantId=${tenantId}`, 'page-center');
  }

  updatedAccessModulesByTenantId(tenantId, data) {
    return this.restApiService.post('update access modules by tenant id', `${this.confService.getLoggedInTenantCode()}/api/v1/tenant-modules/${tenantId}`, data, 'page-center');
  }

}
