import { Component, OnInit, OnDestroy } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Subscription } from "rxjs";
import { LayoutService } from "../../../../app.layout.service";
import { NgStyle, NgClass } from "@angular/common";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { BookingStatusComponent } from "./booking-status/booking-status.component";
import { CancelledJourneyComponent } from "./cancelled-journey/cancelled-journey.component";
import { LiveStatusComponent } from "./live-status/live-status.component";
import { JourneyStatusComponent } from "./journey-status/journey-status.component";
import { EmergencyComponent } from "./emergency/emergency.component";
import { DriversComponent } from "./drivers/drivers.component";
import { UpcomingJourneyComponent } from "./upcoming-journey/upcoming-journey.component";
import { LiveJourneyTypesComponent } from "./live-journey-types/live-journey-types.component";
import { PassengerByTypesComponent } from "./passenger-by-types/passenger-by-types.component";
import { VehiclesCardComponent } from "./vehicles-card/vehicles-card.component";
import { ParkingQueuesComponent } from "./parking-queues/parking-queues.component";
import data from "../../../../../../src/assets/json/dashboard/data.json";
import { DashboardService } from "./dashboard.service";
import { AppSettings } from "app/modules/shared/app.settings";
import { ConfigService } from "app/modules/shared/services/config.service";
import { Country } from "app/modules/shared/models/country";
import { RestApiService } from "app/modules/shared/services/rest-api.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  standalone: true,
  imports: [
    DashboardNavbarComponent,
    NgStyle,
    NgClass,
    TranslateModule,
    BookingStatusComponent,
    CancelledJourneyComponent,
    LiveStatusComponent,
    JourneyStatusComponent,
    EmergencyComponent,
    DriversComponent,
    UpcomingJourneyComponent,
    LiveJourneyTypesComponent,
    PassengerByTypesComponent,
    VehiclesCardComponent,
    ParkingQueuesComponent,
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  upcomingJourneyData: any[] = [];
  bookingStatusData: any[];
  cancelledJourneyData: any[];
  liveStatusData: any[];
  driverStatusData: any[];
  transportRequestData: any[];
  playerData: any[];
  journeyStatusData: any[];
  emergencyStatusData: any[];
  totalVehicles: number;
  totalVehiclesData: any[];
  parkingQueueData: any[];
  items!: MenuItem[];
  passengerByTypes: any[] = [];
  onTabMenu: boolean = false;
  products!: [];
  chartData: any;
  chartOptions: any;
  subscription!: Subscription;
  country: Country;
  reqPayload = {
    limit: 20,
    offset: 0,
    searchStr: '',
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE.BOOKING_STATUS
  };
  countPayload: {
    forTenantCode: string;
    countryCode: string;
  }
  vehicleAssignmentData: any[];

  constructor(
    public layoutService: LayoutService,
    private dashboardService: DashboardService,
    private configService: ConfigService,
    private restApiService: RestApiService
  ) {
    this.subscription = this.layoutService.configUpdate$.subscribe(() => { });
  }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.countPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
    };

    this.getBookingStatusData();

    this.items = [{ label: "Hide", icon: "pi pi-eye-slash" }];
    this.passengerByTypes = data.passengerByTypes;
    this.cancelledJourneyData = data.cancelledJourneyData;
    this.driverStatusData = data.vehicleStatusData;
    this.emergencyStatusData = data.emergencyStatusData;
    this.upcomingJourneyData = data.upcomingJourneyData;
    this.totalVehicles = 161;
    this.totalVehiclesData = data.totalVehiclesData;
    this.parkingQueueData = data.parkingQueueData;
  }

  getBookingStatusData() {
    const payload = this.getMultiApiPayload();
    this.dashboardService.multipleApiData(payload).subscribe((res) => {
      const liveJourneyTypeData = JSON.parse(res.liveJourneyTypeData.responseBody);
      const newRequest = JSON.parse(res.newRequest.responseBody);
      const criticalWatchListData = JSON.parse(res.criticalWatchListData.responseBody);
      const passengerByType = JSON.parse(res.passengerByType.responseBody);
      const totalVehicleData = JSON.parse(res.totalVehicleData.responseBody);
      const vehicleByBodyType = JSON.parse(res.vehicleByBodyType.responseBody);

      const newRequestData = newRequest.data.find(v => v.labelKey === AppSettings.BOOKING.STATUS.NEW_REQUEST);
      const countsData = criticalWatchListData.counts;
      this.bookingStatusData = [newRequestData, {
        labelKey: AppSettings.BOOKING_TAB_LIST.CRITICAL,
        labelValue: AppSettings.BOOKING_TAB_LIST.CRITICAL,
        usage: countsData.CRITICAL,
      }, {
          labelKey: AppSettings.BOOKING_TAB_LIST.WATCHLIST,
          labelValue: AppSettings.BOOKING_TAB_LIST.WATCHLIST,
          usage: countsData.WATCHLIST,
        }];

      const completedData = newRequest.data.find(v => v.labelKey === AppSettings.BOOKING_STATUS.COMPLETED);
      const expiredData = newRequest.data.find(v => v.labelKey === AppSettings.BOOKING_STATUS.EXPIRED);
      this.journeyStatusData = [completedData, expiredData];

      this.liveStatusData = newRequest.data;

      this.transportRequestData = liveJourneyTypeData.data;

      this.playerData = passengerByType.data;

      const totalCount = totalVehicleData.counts.ALL;
      const attributeData = vehicleByBodyType.data;
      const totalAvailable = attributeData.reduce((total, a) => total + a.usage, 0);
      this.totalVehiclesData = [{
        label: `All(${totalCount})`,
        available: totalAvailable,
        occupied: 0
      }];
      attributeData.forEach(v => this.totalVehiclesData.push({
        ...v,
        label: v.labelValue,
        available: v.usage,
        occupied: 0
      }));
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getMultiApiPayload() {
    return [
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/attributes/preset-values/search`,
        inputBody: this.reqPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.NEW_REQUEST,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/entities/counts`,
        inputBody: this.countPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.CRITICAL_WATCHLIST_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/entities/counts`,
        inputBody: this.countPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.TOTAL_VEHICLE_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/attributes/preset-values/search`,
        inputBody: {
          limit: 20,
          offset: 0,
          searchStr: '',
          defaultSortColumn: 'updatedAt',
          defaultSortType: 'desc',
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.BODY_TYPE
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.VEHICLE_BY_BODY_TYPE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/attributes/preset-values/search`,
        inputBody: {
          limit: 20,
          offset: 0,
          searchStr: '',
          defaultSortColumn: 'updatedAt',
          defaultSortType: 'desc',
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.TRANSPORTATION_TYPE
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.LIVE_JOURNEY_TYPE_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.PASSENGERS}/custom/preset-labels/search`,
        inputBody: {
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.PASSENGER_TYPE,
          searchStr: '',
          usageRequired: true
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.PASSENGER_BY_TYPE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      }
    ];
  }
}
