import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FullScreenService {
  private isFullScreenSubject = new BehaviorSubject<boolean>(false);
  isFullScreen$: Observable<boolean> = this.isFullScreenSubject.asObservable();

  toggleFullScreen(): void {
    const currentState = this.isFullScreenSubject.getValue();
    this.isFullScreenSubject.next(!currentState);
  }
}
