<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
  <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
  <p-button [disabled]="disableSaveBtn" class="inline-flex height-44" (click)="onNextBtnClick()"  [label]="nextBtnLabel"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabViewChange($event)">

  @for(tab of passengerAttributeData?.tabs; track tab?.tabCode) {
  <p-tabPanel class="add-passenger">
    <ng-template pTemplate="header">
      <div class="tab-icons">
        <i class="mi-lg" [class]="tab.tabCode"></i>
        <span>{{ cs.getLabelValue('passenger.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
      </div>
    </ng-template>
    @if (tab.tabCode !== privilegeSettingsTab && showAddress) {
    <app-mi-form [attributeLabels]="attributeLabels" [moduleName]="moduleName" [attributeValues]="attributeValues" [addressEntityIdList]="addressEntityIdList"
      [groups]="tab.groups" (saveData)="onSavePassenger($event)" [activeIndex]="activeIndex" [showImg]="true" [defaultLocationList]="defaultLocationList"
      (previousClick)="onPreviousClick()" (nextClick)="onNextClick()" [submitBtnText]="nextBtnLabel"  [edit]="edit" [saveBtnId]="'details'"
      [previousBtnText]="'Cancel'" [isMultipleFilesUpload]="false"></app-mi-form>
    } @else if (tab.tabCode === privilegeSettingsTab) {
    <div class="grid">
      <div class="col-fixed bg-surface">
        <div class="navigation-menu">
          @for(group of tab.groups; track group;let i = $index) {
          <div class="navigation-item" [class]="group.activeStatus"  (click)="scrollToGroup(i, group.code)">
            <label class="navigation-item-label" [class]="group.activeLabel">{{
              cs.getLabelValue('passenger.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
          </div>
          }
        </div>
      </div>
      <div class="col pl-0 pr-0 scrollable" id="scrollable">
        <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleName" [previousBtnText]="previousBtnLabel" [saveBtnId]="'privilegeSettings'"
        (previousClick)="onPreviousClick()"  [isHorizontalForm]="true" [groups]="tab.groups" (saveData)="onSavePrivilegeSettings($event)" [bookingTimeRestrictionIds]="bookingTimeRestrictionEntityIdList"
        [edit]="edit"  [submitBtnText]="savePassengerLabel"></app-mi-form>
      </div>
    </div>

    }
  </p-tabPanel>
  }
</p-tabView>