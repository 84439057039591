import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { Column } from '../../../vehicles/modal/attributeModal';
import { EntityList } from '../../../vehicles/modal/entityModal';
import { Values } from '../../../vehicles/modal/listResponse';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrl: './manage-roles.component.scss',
  standalone: true,
  imports: [TabViewModule, TranslateModule, MITableComponent, JsonPipe, BreadcrumbModule, ModuleHeaderComponent,]
})
export class ManageRolesComponent {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  attributeLabelsData: any = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: '',
    status: 'both'
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  columnsData;
  bulkDeleteLabel: string = this.cs.getLabel('manage_roles.delete');
  bulkUpdateLabel: string = this.cs.getLabel('manage_roles.update_roles');
  bulkUpdateHeader: any;
  reviewHeader: any;
  deleteRoleHeaderMsg: string;
  deleteRoleMsg: string;
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  filterFieldsArray: any[];
  uniqueId: string = 'manage_roles_grid';
  isEntity: boolean = true;
  entityName;
  showImportExportBtn: boolean = false;
  entityNameToDisplay: any;
  btnUpdate: any;
  currentRow;
  miIcons = AppIcons;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.setUpRoutePath();
    this.setUpRowItems();
    this.setupBulkSelectionsItems();
    this.initializeOtherVariablesAndAPI();
    this.initializeColumn();
  }

  initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "roleName",
        "attributeIndex": 0,
        "field": "roleName",
        "header": this.cs.getLabel('manage_roles.columns.lbl_role_name')
      },
      {
        "attributeCode": "roleCode",
        "attributeIndex": 1,
        "field": "roleCode",
        "header": this.cs.getLabel('manage_roles.columns.lbl_role_code')
      },
      {
        "attributeCode": "createdByName",
        "attributeIndex": 2,
        "field": "createdByName",
        "header": this.cs.getLabel('manage_roles.columns.lbl_created_by')
      },
      {
        "attributeCode": "createdAt",
        "attributeIndex": 3,
        "field": "createdAt",
        "header": this.cs.getLabel('manage_roles.columns.lbl_created_on')
      },
      {
        "attributeCode": "updatedByName",
        "attributeIndex": 4,
        "field": "updatedByName",
        "header": this.cs.getLabel('manage_roles.columns.lbl_modified_by')
      },
      {
        "attributeCode": "updatedAt",
        "attributeIndex": 5,
        "field": "updatedAt",
        "header": this.cs.getLabel('manage_roles.columns.lbl_modified_on')
      },
      {
        "attributeCode": "role_status",
        "attributeIndex": 6,
        "field": "active",
        "header": this.cs.getLabel('manage_roles.columns.lbl_role_status')
      }
    ];
  }

  initializeOtherVariablesAndAPI() {
    this.btnLabel = this.cs.getLabel('manage_roles.lbl_create_new');
    this.bulkDeleteLabel = this.cs.getLabel('manage_roles.message.delete_Roles');
    this.bulkUpdateLabel = this.cs.getLabel('manage_roles.update_roles');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('manage_roles.lbl_bulk_update_header');
    this.entityNameToDisplay = this.cs.getLabel('manage_roles.lbl_entity_name_display');
    this.btnUpdate = this.cs.getLabel('manage_roles.btn_bulk_update');
    this.reviewHeader = this.cs.getLabel('manage_roles.lbl_review_roles');
    this.deleteRoleMsg = this.cs.getLabel('manage_roles.message.confirm_delete_msg');
    this.deleteRoleHeaderMsg = this.cs.getLabel('manage_roles.message.delete_Roles');
    this.getBulkUpdateAttributeData();

    this.getData();
  }

  private setUpRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('manage_roles.lbl_route_path') },
    ];
  }

  private setUpRowItems() {
    this.rowItems = [
      {
        label: this.cs.getLabel('manage_roles.edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      },
      {
        label: this.cs.getLabel('manage_roles.deactivate'),
        icon: AppIcons.OVERDUE + " wh-16 bg-red-500",
        iconClass: "bulk-update-icon",
        visible: false,
        command: () => {
          this.makeRoleDeactivate(this.currentRow);
        },
      },
    ];
  }

  private setupBulkSelectionsItems() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.visible = true;
          this.tableRef.showBulkRowDialog();
        },
      },
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  getBulkUpdateAttributeData() {
    this.attributeData = {
      tabs: [
        {
          groups: [
            {
              fields: [
                {
                  attributeCode: 'role_status',
                  attributeValue: 'Status',
                  isDisable: false,
                  presetValues: [
                    { labelValue: 'Active', labelKey: 'Active' },
                    { labelValue: 'Inactive', labelKey: 'Inactive' },
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
    this.updateFilterFieldsArray(this.attributeData.tabs[0]?.groups[0]?.fields);
  }

  private updateFilterFieldsArray(fields: any[]) {
    if (!fields) return;

    this.filterFieldsArray = [];
    fields.forEach(field => {
      if (field.attributeCode === 'role_status') {
        this.filterFieldsArray.push(field)
      }
    });
  }

  getTableView() {
    this.isLoading = false;
    this.searchEntity();

    this.filterAvailableColumns = _.clone(this.allColumns);
    this.isEntity = false;
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
    })
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.userManagementService.searchEntity(this.entityData).subscribe((res: any) => {
      this.listColumns = res?.data[0];
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = res?.data.map(data => ({ id: data.roleId, ...data, active: data.active ? 'Active' : 'Inactive' })) || [];
    })
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  update() {
    this.onUpdateUser(this.currentRow.roleId);
  }

  onUpdateUser(roleId) {
    this.router.navigate(['app/user-management/manage-roles/edit-role/' + roleId]);
  }

  makeRoleDeactivate(currentRow) {
    const isActive = currentRow.active === AppSettings.USERS_STATUS.ACTIVE;
    const headerMsg = isActive ? this.cs.getLabel('manage_roles.deactive_role_header_message') : this.cs.getLabel('manage_roles.active_role_header_message');
    const bodyMsg = isActive ?  this.cs.getLabel('manage_roles.deactive_status_message') :  this.cs.getLabel('manage_roles.active_status_message')
    const acceptLabel = isActive ? this.cs.getLabel('manage_roles.lbl_deactivate') : this.cs.getLabel('manage_roles.lbl_activate');
    this.confirmationService.confirm({
      header: headerMsg,
      message: bodyMsg,
      acceptLabel: acceptLabel,
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectLabel: this.cs.getLabel('manage_roles.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: isActive ? 'bg-red-500' : 'bg-green-500',
      accept: ($event) => {
        this.tableRef.changeStatusOfEntity();
      },
      reject: () => {
      }
    });

  }

  onChangeStatus(currentRow) {
    const newStatus = currentRow.active === AppSettings.USERS_STATUS.ACTIVE ? false : true;
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      active: newStatus
    };
    this.userManagementService.updateRoleActionStatus(currentRow.roleId, requestBody).subscribe(result => {
      setTimeout(() => {
        this.searchEntity();
      }, 500);
      if (newStatus) {
        this.showSuccessMessage(this.cs.getLabel('manage_roles.message.msg_role_active'));
      } else {
        this.showSuccessMessage(this.cs.getLabel('manage_roles.message.msg_role_deactive'));
      }
    }, error => {
      const errorMsg = error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
      this.showErrorMessage(errorMsg);
    });
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    if (event.active === AppSettings.USERS_STATUS.INACTIVE) {
      this.rowItems[1].visible = true;
      this.rowItems[1].label = this.cs.getLabel('manage_roles.active');
      this.rowItems[1].icon =  AppIcons.OVERDUE + " wh-16 bg-green-500";
      this.cdr.detectChanges();
    } else {
      this.rowItems[1].label = this.cs.getLabel('manage_roles.deactivate'),
      this.rowItems[1].icon = AppIcons.OVERDUE + " wh-16 bg-red-500",
      this.rowItems[1].visible = true;
      this.cdr.detectChanges();
    }
  }

  onStaticBulkDataUpdate(event: any) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      active: event.data[0].attributeValue === AppSettings.ROLES_STATUS.ACTIVE,
      roleIds: event.entityIds
    };
    this.userManagementService.updateBulkRoleActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.searchEntity();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].messageCode) });
      }
    })
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      roleIds: event
    };
    this.userManagementService.deleteBulkActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.searchEntity();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].messageCode) });
      }
    })
  }

  tabViewChange(event) {

  }

  onAddRole() {
    this.router.navigate(['app/user-management/manage-roles/add-role']);
  }

  getLabel(params) {
    return params + 'hi';
  }

  onRowClicked(e: any) {
    alert('Row click : ' + JSON.stringify(e));
  }

  onActionBtnClicked(e: any) {
    alert('Action btn click : ' + JSON.stringify(e));
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }

}
