import { DatePipe, JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { CommunicationService } from '../../services/communication.service';
import { ProfileImageComponent } from '../profile-image/profile-image.component';

@Component({
  selector: 'app-group-chats',
  templateUrl: './group-chats.component.html',
  styleUrls: ['./group-chats.component.scss'],
  standalone: true,
  imports: [ProfileImageComponent, InputTextModule, JsonPipe, DatePipe, TranslateModule]
})
export class GroupChatsComponent {
  miIcons = AppIcons;
  countryCode = '+91';
  phoneNumber = '678 678 4365';
  paxNumber = 'PAX2023001';
  chatProfile;

  constructor(private communicationService: CommunicationService, public appSettings: AppSettings) { }

  ngOnInit() {
    this.communicationService.profileIndividualData.subscribe(res => {
      this.chatProfile = { ...res, activeClasses: 'height-53 width-53 border-round' };
    });
  }
}
