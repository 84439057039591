import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EntityService } from '../../../../../shared/services/entity.service';
import { AppSettings } from '../../../../../shared/app.settings';

@Component({
  selector: 'app-driver-tab',
  templateUrl: './driver-tab.component.html',
  styleUrl: './driver-tab.component.scss',
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class DriverTabComponent {
  @Input() driverDetailItem: any;
  driverDetailsById: any[] = [];

  constructor(private entityService: EntityService) { }

  ngOnInit(): void {
    this.getDriverDetailsById();
  }

  getDriverDetailsById() {
    this.entityService.getEntity(this.driverDetailItem.id, AppSettings.ENTITY_CODE.DRIVER).subscribe((result: any) => {
        const details = {};
      result.attributeCodeValueDtoList.map(data => {
        details[data.attributeCode] = data.attributeValue;
      })
      this.driverDetailsById.push(details);
    })
  }

}
