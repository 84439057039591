import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from '../../../shared/app.settings';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { KeyFilterModule } from 'primeng/keyfilter';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { MiUploadFileComponent } from 'app/modules/shared/components/mi-fields/mi-upload-file/mi-upload-file.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Countries } from 'app/modules/shared/countries';
import { OrganizationService } from '../../services/organization.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PasswordModule } from 'primeng/password';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';

@Component({
  selector: 'app-add-organization',
  standalone: true,
  imports: [BreadcrumbModule, ButtonModule, TabViewModule, TranslateModule,
    InputTextModule, FormsModule, ReactiveFormsModule, CheckboxModule, InputSwitchModule, PasswordModule,
    CalendarModule, InputGroupModule, InputGroupAddonModule, DropdownModule, KeyFilterModule, MiUploadFileComponent,
    NoResultFoundComponent
  ],
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.scss', '../../../shared/components/mi-fields/mobile-input/flags.scss']
})
export class AddOrganizationComponent {
  activeIndex: any = 0;
  routePath: MenuItem[] = [];
  nextBtnLabel: string | undefined;
  previousBtnLabel: string | undefined;
  saveOrganizationLabel: string | undefined;
  moduleName: string;
  country: Country;
  language: Language;
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST_FOR_ORGANIZATION;
  addOrgForm: FormGroup;

  placeholder: any;
  countries: any[] | undefined;
  selectedCountryCodeForCompany: any;
  selectedCountryCodeForUser: any;
  selectedMobile: any;
  imgField: any;
  imgFileId: any;
  mobileNumber: any;
  moduleList: any[] = [];
  accessModueList: any[] = [];
  industryList = AppSettings.INDUSTRY_LIST;
  tenantId: any;
  tenantDetails: any;
  userDetails: any;
  logoImage: any;
  showImgUpload: boolean = false;
  tenantCode: any;
  attributeLabels: any[] = [];
  showCompanyContactNumberValidationError: boolean = false;
  showUserContactNumberValidationError: boolean = false;
  filterIcon = 'pi pi-search';
  accessToModulesDeletedList: any[] = [];
  isEdit: boolean = false;

  constructor(
    private router: Router,
    public cs: CommonBindingDataService,
    private orgService: OrganizationService,
    private entityService: EntityService,
    private configService: ConfigService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private confService: ConfigService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.tenantId = this.route.snapshot.paramMap.get('id');
    this.setCountries();
    this.setLabels();
    this.setRoutePath();
    this.setAppSettings();
    this.initializeForm();
    this.setImgField();

    if (this.tenantId) {
      this.isEdit = true;
      this.getTenantDetails();
    } else {
      this.showImgUpload = true;
    }

  }

  setImgField() {
    this.imgField = {
      attributeId: AppSettings.ATTRIBUTE_ID_FOR_IMAGE_UPLOAD_IN_ORGANIZATION,
      attributeCode: 'event_image',
      attributeValue: null,
      inputCode: 'fileImg',
      imgId: null
    }
  }

  getTenantDetails() {
    this.orgService.getOrgDetails(this.tenantId).subscribe((result: any) => {
      this.tenantDetails = result;
      this.imgField.imgId = this.tenantDetails?.logoFileId;
      this.showImgUpload = true;
      this.imgFileId = this.tenantDetails.logoFileId;
      this.addOrgForm.controls['firstName'].setValue(this.tenantDetails?.accountOwnerFirstName);
      this.addOrgForm.controls['lastName'].setValue(this.tenantDetails?.accountOwnerLastName);
      this.addOrgForm.controls['companyName'].setValue(this.tenantDetails?.tenantName);
      this.addOrgForm.controls['code'].setValue(this.tenantDetails?.tenantCode);
      this.addOrgForm.controls['email'].setValue(this.tenantDetails?.email);
      this.addOrgForm.controls['adminEmail'].setValue(this.tenantDetails?.accountOwnerEmailId);
      this.addOrgForm.controls['adminContactNumberWithCountryCode'].setValue(this.tenantDetails?.accountOwnerPhone);
      this.addOrgForm.controls['contactNumberWithCountryCode'].setValue(this.tenantDetails?.contactNumber);
      
      let accountOwnerPhone = this.tenantDetails?.accountOwnerPhone.split('-');
      this.selectedCountryCodeForUser = this.countries.find(ele => ele.value === accountOwnerPhone[0]);
      this.addOrgForm.controls['adminContactNumber'].setValue(accountOwnerPhone[1]);

      let orgPhone = this.tenantDetails?.contactNumber.split('-');
      this.selectedCountryCodeForCompany = this.countries.find(ele => ele.value === orgPhone[0]);
      this.addOrgForm.controls['contactNumber'].setValue(orgPhone[1]);

      this.getLogo(result);
      this.getUserDetails(result.accountOwnerUserId);
      this.getModuleList();
    })
  }

  getUserDetails(userId) {
    this.orgService.getUserDetails(userId, this.tenantDetails?.tenantCode).subscribe(result => {
      this.userDetails = result;
      this.addOrgForm.controls['username'].setValue(this.userDetails.username);
    })
  }

  getLogo(orgDetails) {
    this.entityService.getFile(orgDetails.logoFileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'organization-logo');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function () {
        const base64data = reader.result;
        that.logoImage = base64data;
      }
    })
  }

  onCancel() {
    this.router.navigate(['app/organization']);
  }

  activeIndexChange(event) {
    if (event === 1) {
      this.nextBtnLabel = this.tenantId ? this.cs.getLabel('organization.lbl_update_organization') : this.cs.getLabel('organization.lbl_save_organization');
    } else {
      this.nextBtnLabel = this.cs.getLabel('lbl_next');
    }
  }

  onFirstNameBlur() {
    this.addOrgForm.controls['firstName'].setValue(this.addOrgForm.controls['firstName'].value.trim())
  }

  onLastNameBlur() {
    this.addOrgForm.controls['lastName'].setValue(this.addOrgForm.controls['lastName'].value.trim())
  }

  onOrgNameBlur() {
    this.addOrgForm.controls['companyName'].setValue(this.addOrgForm.controls['companyName'].value.trim())
  }

  setCountries() {
    this.countries = Countries.COUNTRY_CODE;
    this.selectedCountryCodeForCompany = this.selectedCountryCodeForUser = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
  }

  initializeForm() {
    this.addOrgForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(AppSettings.NAME_SPACE_PATTERN2)]],
      lastName: ['', [Validators.required, Validators.pattern(AppSettings.NAME_SPACE_PATTERN2)]],
      companyName: ['', [Validators.required]],
      code: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contactNumberWithCountryCode: ['', [Validators.required]],
      contactNumber: ['', [Validators.maxLength(10), Validators.minLength(10)]],
      adminContactNumberWithCountryCode: ['', [Validators.required]],
      adminContactNumber: ['', [Validators.maxLength(10), Validators.minLength(10)]],
      adminEmail:  ['', [Validators.required]],
      username:  ['', [Validators.required]],
      password:  ['', [this.tenantId ? Validators.nullValidator : Validators.required, Validators.pattern(AppSettings.PASSWORD_PATTERN)]],
    });
  }

  fileUploadEvent(event) {
    this.imgFileId = event[0];
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private setLabels() {
    this.nextBtnLabel = this.cs.getLabel('lbl_next');
    this.previousBtnLabel = this.cs.getLabel('lbl_cancel');
    this.saveOrganizationLabel = this.cs.getLabel('label_save');
  }

  private setRoutePath() {
    this.routePath = [
      {
        label: this.tenantId ? this.cs.getLabel('organization.edit_organization') : this.cs.getLabel('organization.add_organization'),
        routerLink: '/app/organization',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px', 'color': '#495057' }
      }
    ];
  }

  getPhoneNumberWithCountryCodeForCompany() {
    const mobileNumber: any[] = [this.selectedCountryCodeForCompany?.value, this.addOrgForm.controls['contactNumber'].value];
    this.addOrgForm.controls['contactNumberWithCountryCode'].setValue(mobileNumber.join('-'));
    this.addOrgForm.get('contactNumberWithCountryCode').updateValueAndValidity();
  }

  getPhoneNumberWithCountryCodeForUser() {
    const mobileNumber: any[] = [this.selectedCountryCodeForUser?.value, this.addOrgForm.controls['adminContactNumber'].value];
    this.addOrgForm.controls['adminContactNumberWithCountryCode'].setValue(mobileNumber.join('-'));
    this.addOrgForm.get('adminContactNumberWithCountryCode').updateValueAndValidity();
  }

  save() {
    this.saveOrgDetails();
  }

  saveOrgDetails() {
    if (this.activeIndex === 0) {
      this.saveOrganizationDetails();
    } else {
      this.saveAccessToModules();
    }
   
  }

  saveOrganizationDetails() {
    if (this.addOrgForm.valid) {
      const requestBody = {
        tenantCode: this.addOrgForm.controls['code'].value,
        tenantName: this.addOrgForm.controls['companyName'].value,
        logoFileId: this.imgFileId,
        tenantEmail: this.addOrgForm.controls['email'].value,
        tenantContactNumber: this.addOrgForm.controls['contactNumberWithCountryCode'].value,
        userFirstName: this.addOrgForm.controls['firstName'].value,
        userLastName: this.addOrgForm.controls['lastName'].value,
        userContactNumber: this.addOrgForm.controls['adminContactNumberWithCountryCode'].value,
        userEmail: this.addOrgForm.controls['adminEmail'].value,
        username: this.addOrgForm.controls['username'].value,
        password: this.addOrgForm.controls['password'].value,
        active: true
      };

      if (this.tenantId) {
        this.updateOrganizationDetails(requestBody);
      } else {
        this.addOrganization(requestBody);
      }

    } else {
      this.addOrgForm.markAllAsTouched();
    }
  }

  updateOrganizationDetails(requestBody) {
    requestBody['accountOwnerUserId'] = this.tenantDetails.accountOwnerUserId;
    this.orgService.updateOrganization(requestBody, this.tenantId).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.activeIndex += 1;
        this.nextBtnLabel = this.cs.getLabel('organization.lbl_update_organization');
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  addOrganization(requestBody) {
    this.orgService.addOrganization(requestBody).subscribe({
      next: (res: any) => {
        this.tenantCode = res.tenantCode;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('organization.lbl_organization_saved_successfully') });
        this.router.navigate(['app/organization'])
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
       this.filterIcon = 'pi pi-search';
    }
  }

  onSelectCountryCodeForCompany(event): void {
    let companyContactNumber = this.addOrgForm.controls['contactNumber'].value;
    const mobileNumber: any[] = [this.selectedCountryCodeForCompany?.value, companyContactNumber];
    this.addOrgForm.controls['contactNumberWithCountryCode'].setValue(mobileNumber.join('-'));
    this.addOrgForm.get('contactNumberWithCountryCode').updateValueAndValidity();

    if (companyContactNumber && (companyContactNumber.length > AppSettings.MAX_MOBILE_LENGTH || companyContactNumber.length < AppSettings.MAX_MOBILE_LENGTH)) {
      this.showCompanyContactNumberValidationError = true;
    } else {
      this.showCompanyContactNumberValidationError = false;
    }
  }

  onSelectCountryCodeForUser(event): void {
    let userContactNumber = this.addOrgForm.controls['adminContactNumber'].value;
    const mobileNumber: any[] = [this.selectedCountryCodeForUser?.value, userContactNumber];
    this.addOrgForm.controls['adminContactNumberWithCountryCode'].setValue(mobileNumber.join('-'));
    this.addOrgForm.get('adminContactNumberWithCountryCode').updateValueAndValidity();
    if (userContactNumber && (userContactNumber.length > AppSettings.MAX_MOBILE_LENGTH || userContactNumber.length < AppSettings.MAX_MOBILE_LENGTH)) {
      this.showUserContactNumberValidationError = true;
    } else {
      this.showUserContactNumberValidationError = false;
    }
  }

  getModuleList() {
    this.orgService.getModuleList().subscribe({
      next: (res: any) => {
       this.moduleList = res;
       this.getListOfAccessModulesList();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  getListOfAccessModulesList() {
    this.orgService.getAccessModuleList(this.tenantDetails.tenantId).subscribe({
      next: (res: any) => {
       this.accessModueList = res;
       this.setAccessModules();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  setAccessModules() {
    if (this.accessModueList.length > 0) {
      this.moduleList.forEach(element => {
        let module = this.accessModueList.find(module => module.moduleId === element.moduleId && module.active);
        if (module) {
          element.isAccessible = true;
          element.tenantModuleId = module.tenantModuleId;
        }
      });
    }
  }

  onAccessModuleChange(event, module) {
    if (!event.checked) {
     this.accessToModulesDeletedList.push(module.tenantModuleId);
    }
  }

  showConfirmPopup(event, module) {
    this.confirmationService.confirm({
      header: this.cs.getLabel('organization.remove_access_to_module'),
      message: this.cs.getLabel('organization.are_you_sure_to_remove_access_of_this_module'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'bg-white text-color cancel',
      acceptButtonStyleClass: 'bg-red-500 delete',
      acceptLabel: this.cs.getLabel('lbl_remove'),
      rejectLabel: this.cs.getLabel('lbl_cancel'),
      accept: () => {
        if (!event.checked) {
          this.accessToModulesDeletedList.push(module.tenantModuleId);
         }

      },
      reject: () => {
        module.isAccessible = true;
      }
    });
  }

  saveAccessToModules() {
    const requestBody = {
      forTenantCode: this.confService.getLoggedInTenantCode(),
      tenantModuleIds: this.accessToModulesDeletedList
    }

    if (this.accessToModulesDeletedList.length > 0) {
      this.orgService.updatedAccessModulesByTenantId(this.tenantDetails.tenantId, requestBody).subscribe({
        next: (res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
          this.router.navigate(['app/organization'])
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
      });
    } else {
      this.messageService.add({ key: 'tst', severity: 'info', summary: 'Info', detail: this.cs.getLabel('organization.lbl_there_is_no_data_to_save')  });
    }

  }

}
