import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from '../../../app.settings';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { BlockUIModule } from 'primeng/blockui';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-geo-transport',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, TranslateModule, ButtonModule, DialogModule, BlockUIModule, InputTextModule

  ],
  templateUrl: './geo-transport.component.html',
  styleUrl: './geo-transport.component.scss'
})
export class GeoTransportComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onAddressChange: EventEmitter<any> = new EventEmitter();
  defaultLbl: string;
  placeholder: any;
  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  visible: boolean = false;
  ENTITY_CODE = AppSettings.ENTITY_CODE;

  zoom = 4;
  position: any;
  cancelBtnText: any;
  saveBtnText: any;
  blockedDocument: boolean = false;
  newGeoLocation: any;
  newGeoAddress: any;
  premiseAddress;
  marker;
  showGoogleSearchBox: boolean = false;
  searchText: string = null;
  suggestions: any = [];

  constructor(
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef) {
    this.formName = formGroupDirective.control;
  }

  ngOnInit(): void {
    this.cancelBtnText = this.cs.getLabel("cancel");
    this.saveBtnText = this.cs.getLabel("label_save");
    this.defaultLbl = this.cs.getLabel("lbl_please_select");
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, this.field.attributeCode);
  }

  changedValue() {
    this.cd.markForCheck();
  }

  showDialog() {
    this.visible = true;
    this.blockedDocument = true;
    this.showGoogleSearchBox = true;
    this.initMap();
  }

  close() {
    this.visible = false;
    this.blockedDocument = false;
    this.showGoogleSearchBox = false;
  }

  saveGeoLocation() {
    let index = -1;
    if (this.field.presetValues && this.field.presetValues.length > 0) {
      index = this.field.presetValues.findIndex(ele => ele.labelKey === this.newGeoAddress);
    } else {
      this.field.presetValues = [];
    }

    if (index === -1) {
      const address = {
        labelKey: this.newGeoAddress,
        labelValue: {
          address: this.newGeoAddress,
          geoLocation: this.newGeoLocation
        }

      }
      this.field.presetValues.push(address);
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(address.labelValue);
    }
    this.visible = false;
    this.blockedDocument = false;
    this.showGoogleSearchBox = false;
  }

  async initMap(): Promise<void> {
    this.searchText = null;
    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.509863497519373, lng: 74.31908731567906 },
      zoom: 8,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP, 
      mapId: AppSettings.MAP_ID
    });

    this.infoWindow = new google.maps.InfoWindow();
    const element = document.getElementById('googleSearch');
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(element);
    element.focus();

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: this.position,
      gmpDraggable: true
    });

    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
    };

    const inputHtmlElement = document.getElementById('autocompleteSearch') as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(inputHtmlElement, options);
    autocomplete.bindTo('bounds', this.map);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }
      marker.position = place.geometry.location;
      const latLong = place.geometry.location.lat() + ', ' + place.geometry.location.lng();
      this.getFormattedAddress(latLong);
    });


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          marker.position = this.position;
          const latLong = this.position.lat + ', ' + this.position.lng;
          this.getFormattedAddress(latLong);
          marker.addListener('dragend', (event) => {
            const currentPosition = marker.position as google.maps.LatLng;
            this.position = { lat: currentPosition.lat, lng: currentPosition.lng };
            const latLong = this.position.lat + ', ' + this.position.lng;
            this.getFormattedAddress(latLong);
          });
          this.map.panTo(this.position);
        },
        () => {
          this.handleLocationError(true, this.infoWindow, this.map.getCenter());
        });
    } else {
      this.handleLocationError(false, this.infoWindow, this.map.getCenter());
    }
  }

  getFormattedAddress(latLong) {
    this.cs.getFormattedAddressFromLatLong(latLong, AppSettings.GOOGLE_API_KEY).subscribe((result: any) => {
      if (result.results && result.results.length > 0) {
        this.newGeoAddress = null;
        this.newGeoAddress = result.results[0].formatted_address;
        const { lat, lng } = result.results[0].geometry.location
        this.newGeoLocation = `${lat},${lng}`

      }
    })
  }

  handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation.",
    );
    infoWindow.open(this.map);
  }

}
