import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MiValidationsService {

  constructor() { }

  addValidator(rules, currentSelectedCode) {
    const stringValues = ['inputString', 'text', 'textArea', 'inputMobile'];
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      const isString = stringValues.includes(currentSelectedCode);
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "min" && rules.min && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.minLength(rules[rule] ? rules[rule] : 10));
        }
      }

      if (rule === "max" && rules.max && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.maxLength(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));

    return validators;
  }
}
