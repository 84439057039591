import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { EntityService } from '../../services/entity.service';
import { AppIcons } from '../../app.icons';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { MITableService } from '../mi-table/mitable.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-print-labels-dialog',
  standalone: true,
  imports: [DialogModule, TranslateModule, TooltipModule, InputNumberModule, FormsModule, RadioButtonModule, ButtonModule, ToastModule, NgClass],
  templateUrl: './print-labels-dialog.component.html',
  styleUrl: './print-labels-dialog.component.scss'
})
export class PrintLabelsDialogComponent implements OnInit {
  miIcons = AppIcons;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Input() visiblePrintLabel;
  @Input() bulkRowCount;
  @Input() rowData;
  @Input() vehicleRowData;
  selectLabelValue: number = 1;
  selectedRadioOption = this.appSettings.PRINT_LABEL_RADIO_OPTIONS[0].value;
  smallRadioData: any[] = new Array(10).fill(null);
  largeRadioData: any[] = new Array(5).fill(null);
  singleRowData: any;
  singleRowDataId: any;
  singleRowDataName: any;
  barcodeImage;
  showZoomIcon: boolean = false;
  zoomed: boolean = false;

  constructor(public appSettings: AppSettings,
    private messageService: MessageService, private miTableService: MITableService,
    public printLabelService: EntityService,
    public cs: CommonBindingDataService) { }

  ngOnInit() {
    this.miTableService.bulkRowCount.subscribe(res => {
      this.bulkRowCount = res;
    })
    this.getBarcode();
  }

  handleMouseEnter() {
    this.showZoomIcon = true
  }

  handleMouseLeave() {
    this.showZoomIcon = false
  }

  zoomQR() {
    this.zoomed = !this.zoomed;
  }

  loadContent(option: string) {
    this.selectedRadioOption = option;
  }

  onHide(event) {
    this.selectLabelValue = 1;
    this.visiblePrintLabel = false;
    this.onToggleOverLay.emit(this.visiblePrintLabel);
  }

  getBarcode() {
    if (this.rowData.length === 1) {
      this.singleRowData = this.rowData.map(ele => {
        return ele
      });
      this.singleRowDataId = this.singleRowData[0].id;
      this.singleRowDataName = this.singleRowData[0].name_code;

      this.printLabelService.downloadBarcode(this.singleRowDataId, AppSettings.ENTITY_CODE.VEHICLE).subscribe(res => {
        const reader = new FileReader();
        reader.readAsDataURL(res);
        const that = this;
        reader.onloadend = function() {
          that.barcodeImage = reader.result;
        }
      });
    }
  }

  generateTemplate() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const labelQuantity = this.bulkRowCount * this.selectLabelValue;
    let data;
    if (this.vehicleRowData) {
      data = {
        forTenantCode: AppSettings.TENANT_CODE,
        entityCode: AppSettings.ENTITY_CODE.VEHICLE,
        countryCode: country[0].countryCode,
        entityIds: [this.vehicleRowData.id],
        labelQuantity: 1,
        labelSize: 'small'
      };
    } else {
      data = {
        forTenantCode: AppSettings.TENANT_CODE,
        entityCode: AppSettings.ENTITY_CODE.VEHICLE,
        countryCode: country[0].countryCode,
        entityIds: this.rowData.map(ele => {
          return ele.id
        }),
        labelQuantity: labelQuantity,
        labelSize: this.selectedRadioOption
      };
    }

    this.printLabelService.printLabels(data.entityCode, data).subscribe(res => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_download_successfully') });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(res);
      a.download = 'Print-Labels.pdf';
      a.click();
      this.visiblePrintLabel = false;
    },
      err => {
        console.log(err);
      });
  }

}
