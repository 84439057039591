<div class="ellipsis-container">
  <button
    pButton
    type="button"
    icon="pi pi-ellipsis-h"
    class="p-button-rounded p-button-text p-button-plain"
    (click)="menu.toggle($event)"
  ></button>
  <p-menu #menu [popup]="true" [model]="items"></p-menu>
</div>
