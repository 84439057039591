<p-breadcrumb [model]="routePath"></p-breadcrumb>
<p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
  @if(isMessaging){
  <p-tabPanel>
    <ng-template pTemplate="header"  >
      <i class="{{miIcons.MENU_ALT_03}} wh-12 mr-1"></i>
      <span translate>lbl_messaging <span>({{messageCount}})</span></span>
    </ng-template>
    <ng-template pTemplate="body">
      <div class="grid">
        <div class="col-4">
          <div class="my-2 mx-2 flex w-full justify-content-between align-items-center">
            <p-dropdown class="uppercase" [style]="{'width':'100%'}" [options]="dropdownList" optionLabel="name"></p-dropdown>
            <span><i class="{{miIcons.FILTER_2}} inline-block wh-20 relative right-7"></i></span>
          </div>
          <div class="ml-3">
            <p-selectButton (onChange)="clickOnGroup($event)" [options]="stateOptions" [(ngModel)]="value" optionLabel="label" optionValue="value"></p-selectButton>
          </div>
          <div class="mt-3 relative left-6">
            <span class="p-input-icon-left">
              <i class="{{miIcons.SEARCH}} inline-block wh-10 relative left-20"></i>
              <input class="width-96" type="text" pInputText #searchFilter (input)="searchRecords(searchFilter.value)" [placeholder]="'lbl_search' | translate" />
            </span>
          </div>
          
          <div>
            @if (isIndividual) {
              <div>
                <app-individual-messaging></app-individual-messaging>
              </div>
            }
            
            @if (!isIndividual) {
              <div>
                <app-group-messaging></app-group-messaging>
              </div>
            }
          </div>
        </div>
        <div class="col-8 relative border-left-2 pr-4 surface-border mt-2 overflow-y-auto h-auto">
          @if (isIndividual) {
            <div class="col-md-8 col-xl-6 h-full flex flex-column my-auto">
              <app-individual-chats></app-individual-chats>
            </div>
          }
          @if (!isIndividual) {
            <div>
              <app-group-chats></app-group-chats>
            </div>
          }
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
}
  
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.CIRCLE_CHECKED}} wh-12 mr-1"></i>
      <span translate>lbl_broadcasting <span>({{broadcastCount || '0'}})</span></span>
    </ng-template>
    <ng-template pTemplate="body">
      <app-broadcast-list [activeIndex]="activeIndex"></app-broadcast-list>
    </ng-template>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.USER_GROUP}} wh-13 mr-1"></i>
      <span translate>lbl_manage_segments <span>({{manageSegmentsCount || '0'}})</span></span>
    </ng-template>
    <ng-template pTemplate="body">
      <app-segment-list [activeIndex]="activeIndex"></app-segment-list>
    </ng-template>
  </p-tabPanel>
</p-tabView>
