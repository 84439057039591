import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { Country } from 'app/modules/shared/models/country';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AppSettings } from 'app/modules/shared/app.settings';

@Injectable({
  providedIn: 'root'
})
export class LeaveManagementService {
  country: Country;

  constructor(private restApiService: RestApiService, private confService: ConfigService) { }

  vehicleCurrentRow = new BehaviorSubject(null);

  searchEntityForLeave(entityCode: string, data, loader?: boolean) {
    return this.restApiService.post('SEARCH ENTITY', `${this.confService.getLoggedInTenantCode()}/api/v1/${entityCode}/search?forTenantCode=${AppSettings.TENANT_CODE}`, data, loader ? 'page-center' : '');
  }

  searchEntityForTomorrowLeave(entityCode: string, data, loader?: boolean) {
    return this.restApiService.post('SEARCH ENTITY', `${this.confService.getLoggedInTenantCode()}/api/v1/${entityCode}/search?forTenantCode=${AppSettings.TENANT_CODE}`, data, loader ? 'page-center' : '');
  }

  saveNewLeave(data, loader?: boolean) {
    return this.restApiService.post('SAVE NEW LEAVE', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves`, data, loader ? 'page-center' : '');
  }

  editLeave(leaveId: string, data, loader?: boolean) {
    return this.restApiService.put('EDIT NEW LEAVE', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/${leaveId}`, data, loader ? 'page-center' : '');
  }

  rejectLeave(leaveId: string, data, loader?: boolean) {
    return this.restApiService.put('REJECT LEAVE', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/${leaveId}/action`, data, loader ? 'page-center' : '');
  }

  getLeaveByLeaveId(leaveId: string) {
    return this.restApiService.get('GET LEAVE DETAILS BY LEAVEID', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/${leaveId}?forTenantCode=${AppSettings.TENANT_CODE}`, 'page-center');
  }

  getOverviewLeaveCount(data) {
    return this.restApiService.post('GET OVERVIEW LEAVE COUNT', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/overview`, data, 'page-center');
  }

  getAllLeavesCount(data) {
    return this.restApiService.post('GET ALL LEAVES COUNT', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/status/count`, data, 'page-center');
  }

  updateBulkLeaveData(data) {
    return this.restApiService.put('BULK UPDATE', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/bulk-update`, data, 'page-center');
  }

  deleteBulkLeaveActionStatus(data) {
    return this.restApiService.put('DELETE LEAVE', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/bulk-delete`, data);
  }

  getLeaveCountByDriverId(driverEntityId) {
    return this.restApiService.get('GET LEAVE COUNT BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/overview/${driverEntityId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  getHistoryLeaveByDriverId(data) {
    return this.restApiService.post('GET HISTORY LEAVE BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/history`, data, 'page-center');
  }

  updateActionStatusForLeave(leaveId, data) {
    return this.restApiService.put('UPDATE ACTION STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/${leaveId}/action`, data, 'page-center');
  }

  getLeaveTypes(data) {
    return this.restApiService.post('GET LEAVE TYPES', `${this.confService.getLoggedInTenantCode()}/api/v1/preset-label/search`, data, 'page-center');
  }

  getDetailsLeaveCountByDriverId(driverEntityId) {
    return this.restApiService.get('GET DETAILS LEAVE COUNT BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/driver-leaves-count/${driverEntityId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

}