<div class="checkbox-container">
    @for (optionPair of chunkedLoginOptions; track optionPair;) {
        <div class="checkbox-row">
            @if(optionPair[0]) {
                <div class="checkbox-item">
                    <p-checkbox [label]="optionPair[0].loginOptionType"
                        [(ngModel)]="selectedOptions[optionPair[0].loginOptionId]"
                        [binary]="true"
                        (onChange)="onCheckboxChange(optionPair[0].loginOptionId, $event)"></p-checkbox>
                </div>
            }
            @if(optionPair[1]) {
                <div class="checkbox-item">
                <p-checkbox [label]="optionPair[1].loginOptionType"
                    [(ngModel)]="selectedOptions[optionPair[1].loginOptionId]"
                    [binary]="true"
                    (onChange)="onCheckboxChange(optionPair[1].loginOptionId, $event)"></p-checkbox>
                </div>
            }
          </div>
    }
</div>