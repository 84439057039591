
    <div class="flex  align-items-center my-2">
        <label translate> lbl_select </label> &nbsp;
        {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
    </div>

    <div class=" flex justify-content-left align-items-center">

        <p-inputNumber [(ngModel)]="value2" mode="decimal"  (onChange)="onChange($event)" 
        [showButtons]="true"
        buttonLayout="horizontal"
        inputId="horizontal"
        spinnerMode="horizontal"
        [step]="1"
        decrementButtonClass="p-button-primary mr-2"
        incrementButtonClass="p-button-primary ml-2"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        [min]="field?.validation?.min"
         [max]="field?.validation?.max"
       > </p-inputNumber>

    </div>
