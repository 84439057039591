import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-vehicle-week-list',
  standalone: true,
  imports: [TranslateModule, DropdownModule, CheckboxModule, OverlayPanelModule, DatePipe, FormsModule, ReactiveFormsModule, ButtonModule],
  templateUrl: './vehicle-week-list.component.html',
  styleUrl: './vehicle-week-list.component.scss'
})
export class VehicleWeekListComponent {
  isClearSelected: boolean = true;
  @Input() weekDaysList: any = [];
  @Input() listType: any = [];
  @Input() selectedListType: any = AppSettings.LIST_TYPE_OPTIONS[1].value;

  @Output() onSelectAllChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() onNextPreviousClick: EventEmitter<any> = new EventEmitter();
  @Output() onListTypeChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() onShiftDeleteEvent: EventEmitter<any> = new EventEmitter();
  @Output() onShiftEditEvent: EventEmitter<any> = new EventEmitter();
  @Output() onShiftPublishEvent: EventEmitter<any> = new EventEmitter();
  @Output() onShiftUnPublishEvent: EventEmitter<any> = new EventEmitter();

  weekday = AppSettings.WEEK_DAYS;
  groupList: any = [];
  selectAll: boolean = true;
  miIconCaretDown = AppIcons.CARET_DOWN;
  weekNumber: number = 0;
  hoveredVehicle: any;
  hoveredPublishedUnPublishedShift: any;
  publishedUnpublishedShiftGroup: any;


  constructor(private entityService: EntityService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService,
    private datePipe: DatePipe,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.setCurrentWeek();
    this.getGroupList();
  }

  setCurrentWeek() {
    this.weekDaysList = [];
    const startOfWeek: any = dayjs().startOf('week');
    this.onNextPreviousClick.emit(startOfWeek);

    for (let i = 0; i < 7; i++) {
      const day: any = dayjs(startOfWeek.d).startOf('week').add(i, 'd')
      this.weekDaysList.push({
        dayName: this.weekday[day.$d.getDay()],
        day: day.$D,
        available: '31',
        dayOff: '04',
        date: day.$d.getTime()
      })
    }
  }

  onListTypeChange(event) {
    this.onListTypeChangeEvent.emit(event.value);
  }

  onDeleteShift(event) {
    this.onShiftDeleteEvent.emit(event);
  }

  onUnPublishShift(event) {
    this.onShiftPublishEvent.emit(event);
  }

  onPublishShift(event) {
    this.onShiftUnPublishEvent.emit(event);
  }

  onShiftEdit() {
  }

  onSelectAllChange(event) {
    if (event.checked) {
      const driverIds = this.groupList.map(ele => {
 return ele.driverId 
});
      this.onSelectAllChangeEvent.emit(driverIds);
      this.selectAll = true;
      this.groupList.forEach(element => {
        element.isSelected = true;

      });

    } else {
      this.onSelectAllChangeEvent.emit([]);
    }
  }

  getGroupList() {
    this.groupList = [
      {
        driverId: 1,
        driverName: 'vehicle week list',
        driverGroup: 'Gold12345',
        uniqueCode: 'G1082',
        status: 'active',
        gender: 'Male',
        email: 'charles.a456@transportation.com',
        mobileNumber: '+1 -  201 563 7777',
        isSelected: false,
        color: '#f4b261',
        shift: [{
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1714933800000,
          endDate: 1714933800000,
          isLeave: true,                  
          leaveReason: 'Sick Leave',
          isShiftPublish: false
        }]
      },
      {
        driverId: 2,
        driverName: 'Sid Moncur',
        driverGroup: 'Silver12345',
        uniqueCode: 'S7866',
        status: 'active',
        gender: 'Male',
        email: 't22@mipltest.in',
        mobileNumber: '+1 -  345 678 9834',
        isSelected: false,
        color: '#f9d9d7',
        shift: [{
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1714933800000,
          endDate: 1714933800000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: false
        }, {
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715020200000,
          endDate: 1715020200000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: false
        },
        {
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715106600000,
          endDate: 1715106600000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: true
        }, {
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715193000000,
          endDate: 1715193000000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: true
        },
        {
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715279400000,
          endDate: 1715279400000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: true
        },
        {
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715365800000,
          endDate: 1715365800000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: true
        }]
      },
      {
        driverId: 3,
        driverName: 'New Driver',
        driverGroup: 'Gold4563',
        uniqueCode: 'G5679',
        status: 'active',
        gender: 'Male',
        email: 't32@mipltest.in',
        mobileNumber: '+1 -  187 312 4567',
        isSelected: false,
        color: '#a7e3df',
        shift: []
      },
      {
        driverId: 4,
        driverName: 'Terriann2 Moncur2',
        driverGroup: 'Gold12345',
        uniqueCode: 'G1082',
        status: 'active',
        gender: 'Male',
        email: 't42@mipltest.in',
        mobileNumber: '+1 -  123 456 3421',
        isSelected: false,
        color: '#dbdbdc',
        shift: [{
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715020200000,
          endDate: 1715020200000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: false,
          dateDifference: 1
        }]
      },
      {
        driverId: 5,
        driverName: 'Terriann3 Moncur3',
        driverGroup: 'Gold12345',
        uniqueCode: 'G1082',
        status: 'active',
        gender: 'Male',
        email: 't52@mipltest.in',
        mobileNumber: '+1 -  567 342 1230',
        isSelected: false,
        color: '#b5c2f2',
        shift: [{
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: null,
          startDate: 1714933800000,
          endDate: 1715192999000,
          shiftDate: 1714933800000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: true,
          dateDifference: (dayjs(1715192999000).diff(dayjs(1714933800000), 'hour')) / 24
        }]
      },
      {
        driverId: 6,
        driverName: 'Terriann4 Moncur4',
        driverGroup: 'Gold12345',
        uniqueCode: 'G1082',
        status: 'active',
        gender: 'Male',
        email: 't62@mipltest.in',
        mobileNumber: '+1 -  443 662 2131',
        isSelected: false,
        shift: [{
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715193000000,
          endDate: 1715193000000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: true
        }]
      },
      {
        driverId: 7,
        driverName: 'Terriann5 Moncur5',
        driverGroup: 'Gold12345',
        uniqueCode: 'G1082',
        status: 'active',
        gender: 'Male',
        email: 't72@mipltest.in',
        mobileNumber: '+1 -  654 333 2245',
        isSelected: false,
        shift: [{
          shiftName: 'GOLD AM',
          startTime: 1714985061000,
          endTime: 1714992261000,
          startDate: 1715279400000,
          endDate: 1715279400000,
          isLeave: false,
          leaveReason: null,
          isShiftPublish: true
        }]
      }
    ];
  }
}
