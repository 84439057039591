import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { AppSettings } from "../../../shared/app.settings";
import { ToggleButtonModule } from "primeng/togglebutton";
import { FormsModule } from "@angular/forms";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-dispatch-map",
  templateUrl: "./dispatch-map.component.html",
  styleUrls: ["./dispatch-map.component.scss"],
  standalone: true,
  imports: [ToggleButtonModule, FormsModule],
})
export class DispatchMapComponent implements OnInit {
  @Input() geoLocationsData;
  map: google.maps.Map;
  checked: boolean = false;
  infoWindow: google.maps.InfoWindow;
  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;
  marker: any = null;
  markers: any = [];
  records: any = [];
  bookingId;

  constructor(private messageService: MessageService) { }

  ngOnInit() {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.geoLocationsData) {
        const currentValue = changes.geoLocationsData.currentValue;
        const previousValue = changes.geoLocationsData.previousValue;
        if (previousValue !== currentValue) { 
          this.createRoute();
        }
      
      }
      
    }
  }

  async initMap(): Promise<void> {
    const { Map, InfoWindow } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;

    this.map = new Map(document.getElementById("map") as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 10,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
    });
    this.infoWindow = new InfoWindow();
    this.setupControls();
    if (this.geoLocationsData) { 
      this.createRoute();
    }
  }

  createRoute(): void {
    const directionsService = new google.maps.DirectionsService();

    if (!this.directionsRenderer) {
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        draggable: true,
        map: this.map,
        suppressMarkers: true
      });
    } else {
      this.directionsRenderer.setMap(this.map);
      this.directionsRenderer.setOptions({
        draggable: true,
        suppressMarkers: true
      });
    }
  
    const waypoints = this.geoLocationsData.stops?.map(location => ({
      location: new google.maps.LatLng(
        parseFloat(location[0]),
        parseFloat(location[1])
      )
    }));
    const request = {
      origin: new google.maps.LatLng(
        parseFloat(this.geoLocationsData.pickup[0]),
        parseFloat(this.geoLocationsData.pickup[1])
      ),
      destination: new google.maps.LatLng(
        parseFloat(this.geoLocationsData.dropoff[0]),
        parseFloat(this.geoLocationsData.dropoff[1])
      ),
      waypoints: waypoints,
      travelMode: google.maps.TravelMode.DRIVING
    };

    directionsService.route(request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.directionsRenderer.setDirections(response);

        const route = response.routes[0];
        const routePath = route.overview_path;

        const iconFrom = {
          url: "/assets/images/mi-theme/light/booking/pickup_point.svg",
          scaledSize: new google.maps.Size(30, 30),
          anchor: new google.maps.Point(10, 20)
        };

        const iconTo = {
          url: "/assets/images/mi-theme/light/booking/dropoff_point.svg",
          scaledSize: new google.maps.Size(30, 30),
          anchor: new google.maps.Point(10, 20)
        };

        new google.maps.Marker({
          position: routePath[0],
          map: this.map,
          icon: iconFrom,
          label: {
            text: 'Pickup',
            color: '#FFF',
            fontSize: '12px',
            fontWeight: 'bold',
            className: 'pickup-drop-label'
          }
        });
    
        new google.maps.Marker({
          position: routePath[routePath.length - 1],
          map: this.map,
          icon: iconTo,
          label: {
            text: 'Drop off',
            color: '#FFF',
            fontSize: '12px',
            fontWeight: 'bold',
            className: 'pickup-drop-label'
          }
        });

        waypoints.forEach((waypoint, index) => {
          new google.maps.Marker({
            position: waypoint.location,
            map: this.map,
            title: `Waypoint ${index + 1}`,
            label: {
              text: `${index + 1}`,
              color: "#000",
              fontSize: "10px",
              fontWeight: "bold",
              className: 'custom-label'
            },
            icon: {
              url: "/assets/images/mi-theme/light/booking/black_border_circle.svg",
              scaledSize: new google.maps.Size(20, 20),
              anchor: new google.maps.Point(10, 20),
            }
          });
        });

      } else {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: 'Directions request failed due to invalid location points'});
      }
    });
  }

  setupControls() {
    const toggleControl = document.getElementById("style-selector-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      toggleControl
    );

    const geolocationControl = document.getElementById("my-location-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      geolocationControl
    );
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.infoWindow.setPosition(pos);
          this.infoWindow.setContent("Location found.");
          this.infoWindow.open(this.map);
          this.map.setCenter(pos);
        },
        () => {
          this.handleLocationError(true);
        }
      );
    } else {
      this.handleLocationError(false);
    }
  }

  handleLocationError(browserHasGeolocation: boolean) {
    const pos = this.map.getCenter();
    this.infoWindow.setPosition(pos);
    this.infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    this.infoWindow.open(this.map);
  }

  toggleDarkAndLightMap(event) {
    this.checked = event.checked;
    const mapStyles = this.checked
      ? AppSettings.LOCATION_MAP_STYLE_DARK
      : AppSettings.LOCATION_MAP_STYLE_DEFAULT;
    if (!this.checked) {
      mapStyles.push({ featureType: "poi", stylers: [{ visibility: "off" }] });
    }
    this.map.setOptions({ styles: mapStyles });
  }
}
