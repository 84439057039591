<app-loader></app-loader>
<div>
  <p-toast key="tst"></p-toast>
  @if(!isFullScreen){
  <div
    class="sidebar"
    (mouseenter)="isSidebarFull = true"
    (mouseleave)="isSidebarFull = false"
    [ngClass]="isSidebarFull ? 'full-width' : 'shrink-width'"
  >
    <app-sidebar></app-sidebar>
  </div>
  }

  <div
    class="main-panel"
    [ngClass]="{
      'shrink-width': isSidebarFull && !isFullScreen,
      'full-width': isSidebarFull ? false : !isFullScreen,
      'full-screen-width': isFullScreen
    }"
  >
    @if(!isFullScreen){<app-header
      class="top-bar p-grid p-col-nogutter"
    ></app-header
    >}
    <div class="main-container">
      @if (languageDetails) {
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
      }
    </div>
  </div>
</div>
