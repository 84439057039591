import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MenuItem } from "primeng/api";
import { AppIcons } from "../../../shared/app.icons";
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { MenubarModule } from "primeng/menubar";
import { TabMenuModule } from "primeng/tabmenu";
import { Router } from "@angular/router";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { InputSwitchModule } from "primeng/inputswitch";
import { FullScreenService } from "../../services/full-screen-service";
import { BreadcrumbModule } from "primeng/breadcrumb";

interface LiveDashboardMenu {
  label: string;
  routerLink: string;
}

@Component({
  selector: "app-dashboard-navbar",
  templateUrl: "./dashboard-navbar.component.html",
  styleUrls: ["./dashboard-navbar.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    FormsModule,
    DropdownModule,
    MenubarModule,
    TabMenuModule,
    OverlayPanelModule,
    CheckboxModule,
    InputSwitchModule,
  ],
})
export class DashboardNavbarComponent implements OnInit {
  routePath: MenuItem[];
  liveDashboardMenu: LiveDashboardMenu[] = [];
  selectedMenuItem: LiveDashboardMenu;
  menuItemsStart: MenuItem[] = [];
  menuItemsEnd: MenuItem[] = [];
  reorganizeAutomatically;
  checkbox1;
  checkbox2;
  @Input() includeRearrange: boolean = false;
  @Input() includeManageWidgets: boolean = false;
  @ViewChild("overlayPanel") overlayPanel: OverlayPanel;
  isFullScreen: boolean = false;

  constructor(
    private router: Router,
    private fullScreenService: FullScreenService
  ) { }

  ngOnInit() {
    this.fullScreenService.isFullScreen$.subscribe((isFullScreen) => {
      this.isFullScreen = isFullScreen;
      this.updateMenuItemsEnd();
    });
    const dashboardBasePath = "/app/dashboard";
    this.routePath = [{ label: "Dashboard", routerLink: "dashboard" }];

    this.liveDashboardMenu = [
      {
        label: "Live Dashboard",
        routerLink: dashboardBasePath,
      },
      {
        label: "Vehicle",
        routerLink: `${dashboardBasePath}/vehicle`,
      },
      {
        label: "Booking",
        routerLink: `${dashboardBasePath}/booking`,
      },
      {
        label: "Training",
        routerLink: `${dashboardBasePath}/training`,
      },
      {
        label: "Communication",
        routerLink: `${dashboardBasePath}/communication`,
      },
    ];

    this.selectedMenuItem = this.liveDashboardMenu[0];

    this.menuItemsStart = [
      {
        label: "Map",
        routerLink: `${dashboardBasePath}/map`,
        icon: AppIcons.BOOKING_DROP + " wh-18",
      },
      {
        label: "Heat Map",
        routerLink: `${dashboardBasePath}/heat-map`,
        icon: AppIcons.DASHBOARD_HEAT_MAP + " wh-16",
      },
      {
        label: "Activity Feed",
        routerLink: `${dashboardBasePath}/activity-feed`,
        icon: AppIcons.DASHBOARD_ACTIVITY_FEED + " wh-16",
      },
    ];
  }

  updateMenuItemsEnd() {
    this.menuItemsEnd = [
      {
        icon: !this.isFullScreen
          ? `${AppIcons.DASHBOARD_FULL_SCREEN} wh-28 mr-0`
          : `${AppIcons.DASHBOARD_SMALL_SCREEN} wh-28 mr-0`,
        styleClass: "bordered-menu-item-full-screen",
        command: () => this.onFullScreenButtonClick(),
      },
    ];

    const conditions = [
      {
        condition: this.includeManageWidgets,
        menuItem: {
          label: "Manage Widgets",
          icon: AppIcons.DASHBOARD_MANAGE_COLUMN + " wh-20",
          styleClass: "bordered-menu-item",
          command: (event) => this.onManageWidgetsClick(event),
        },
      },
      {
        condition: this.includeRearrange,
        menuItem: {
          label: "Rearrange",
          icon: AppIcons.GRID_O + " wh-20",
          styleClass: "bordered-menu-item",
        },
      },
    ];

    conditions.forEach(({ condition, menuItem }) => {
      if (condition) {
        this.menuItemsEnd.unshift(menuItem);
      }
    });
  }

  onFullScreenButtonClick() {
    this.fullScreenService.toggleFullScreen();
  }

  onManageWidgetsClick(event: any) {
    this.overlayPanel.toggle(event.originalEvent);
  }

  onMenuItemChange(menuItem: LiveDashboardMenu) {
    if (menuItem.routerLink) {
      this.router.navigate([menuItem.routerLink]);
    }
  }
}
