import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../../../app.layout.service';
import { AppIcons } from '../../app.icons';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { AppSettings } from './../../app.settings';
import { SidebarService } from './../../services/sidebar.service';
import { Country } from '../../models/country';
import { MenuAccess } from './mdoal/sidebarModal';
import * as _ from 'lodash';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [PanelMenuModule] // Removed unnecessary imports
})
export class SidebarComponent implements OnInit {
  subscription: Subscription;
  items: MenuItem[] = [];

  ORGANIZATION = {
    label: this.commonService.getLabel('sidebar_lbl_organization'),
    icon: AppIcons.ORGANIZATION,
    routerLink: ['/app/organization']
  };

  CONTENT_MANAGEMENT = {
    label: this.commonService.getLabel('sidebar_lbl_content_management'),
    icon: AppIcons.USER_MANAGEMENT,
    routerLink: ['/app/content/about-company'],
    items: [
      {
        label: this.commonService.getLabel('sidebar_lbl_about_company'),
        routerLink: ['/app/content/about-company']
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_faqs'),
        routerLink: ['/app/content/faqs']
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_terms_and_conditions'),
        routerLink: ['/app/content/terms-and-conditions']
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_privacy_policy'),
        routerLink: ['/app/content/privacy-policy']
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_contact_support'),
        routerLink: ['/app/content/contact-support']
      },
    ]
  }

  menuLinkMap = {
    DASHBOARD: { icon: AppIcons.HOME, routerLink: ['/app/dashboard'] },
    BOOKING: { icon: AppIcons.BOOKING },
    EVENTS: { icon: AppIcons.EVENT_MENU, routerLink: ['/app/events'] },
    CURRENT_BOOKING: { routerLink: ['/app/bookings/current-bookings'] },
    BOOKING_HISTORY: { routerLink: ['/app/bookings/booking-history'] },
    DISPATCH: { icon: AppIcons.DISPATCH, routerLink: ['/app/dispatch'] },
    PASSENGERS: { icon: AppIcons.PASSENGER_USER, routerLink: ['/app/passengers'] },
    VEHICLES: { icon: AppIcons.VEHICLE, routerLink: ['/app/vehicles'] },
    DRIVERS: { icon: AppIcons.DRIVER },
    ALL_DRIVERS: { routerLink: ['/app/drivers/list'] },
    LEAVE_MANAGEMENT: { routerLink: ['/app/driver-leave-management'] },
    ALL_QUEUES_LIST: { icon: AppIcons.QUEUE_MANAGEMENT, routerLink: ['/app/queue/list'] },
    QUEUE_MGT: { icon: AppIcons.QUEUE_MANAGEMENT, routerLink: ['/app/queue/list'] },
    MANAGE_LOCATIONS: { icon: AppIcons.MANAGE_LOCATIONS, routerLink: ['/app/manage-locations/list'] },
    MANAGE_ZONES: { icon: AppIcons.MANAGE_ZONES, routerLink: ['/app/manage-zones'] },
    SHIFT_MANAGEMENT: { icon: AppIcons.SHIFT_MANAGEMENT },
    SHIFT_CALENDAR: { routerLink: ['/app/shift-management'] },
    MANAGE_SHIFT: { routerLink: ['/app/manage-shift'] },
    MANAGE_GROUPS: { routerLink: ['/app/manage-groups'] },
    SHIFT_TEMPLATES: { routerLink: ['/app/shift-template'] },
    COMMUNICATION: { icon: AppIcons.SMS, routerLink: ['/app/communication'] },
    USER_MANAGEMENT: { icon: AppIcons.USER_MANAGEMENT },
    MANAGE_USERS: { routerLink: ['/app/user-management/manage-users'] },
    MANAGE_ROLES: { routerLink: ['/app/user-management/manage-roles'] },
  };

  country: Country;

  constructor(
    private commonService: CommonBindingDataService,
    private sidebarService: SidebarService,
    public layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.setupSidebar();
  }

  private setupSidebar(): void {
    this.sidebarService.getMenuPermissions().subscribe((permission) => {
      console.log(permission); // Consider handling permissions if needed
    });

    this.sidebarService.getSidebarMenus().subscribe((menus: MenuAccess[]) => {
      const sortedData = this.getSortedMenus(menus);
      this.items = this.buildMenuItems(sortedData);
      this.items.splice(1, 0, this.ORGANIZATION);
      this.items.push(this.CONTENT_MANAGEMENT);
    });
  }

  private getSortedMenus(menus: MenuAccess[]): MenuAccess[] {
    return _.sortBy(menus, [
      menu => parseInt(menu.orderIndex),
      menu => _.map(menu.pages, page => parseInt(page.orderIndex))
    ]).map(menu => ({
      ...menu,
      pages: _.sortBy(menu.pages, page => parseInt(page.orderIndex))
    }));
  }

  private buildMenuItems(menus: MenuAccess[]): MenuItem[] {
    return menus
      .filter(menu => this.menuLinkMap[menu.menuCode])
      .map(menu => ({
        ...menu,
        icon: this.menuLinkMap[menu.menuCode]?.icon,
        routerLink: this.menuLinkMap[menu.menuCode]?.routerLink,
        items: menu.subMenus ? this.buildMenuItems(menu.subMenus) : undefined
      }));
  }
}
