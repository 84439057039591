import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputTextModule } from 'primeng/inputtext';
import { Countries } from 'app/modules/shared/countries';
import { AppSettings } from 'app/modules/shared/app.settings';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-mobile-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CalendarModule, InputGroupModule, InputGroupAddonModule, DropdownModule, KeyFilterModule, InputTextModule, TranslateModule, NgClass],
  templateUrl: './mobile-input.component.html',
  styleUrls: ['./mobile-input.component.scss', './flags.scss']
})
export class MobileInputComponent {
  @ViewChild('dropdown') dropdown!: Dropdown;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  placeholder: any;
  countries: any[] | undefined;
  selectedCountry: any;
  selectedMobile: any;
  filterIcon = 'pi pi-search';
  showMobileValidationError: boolean = false;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit(): void {
    this.setCountryCode();
    this.setMobileInEditMode();
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
  }

  setMobileInEditMode() {
   let mobileNumberWithCountryCode = this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].value;
   if (mobileNumberWithCountryCode) {
    let mobileNumberCountryCodeArray = mobileNumberWithCountryCode.split('-');
    this.selectedMobile = mobileNumberCountryCodeArray[1];
    this.selectedCountry = Countries.COUNTRY_CODE.find(ele => ele.value === mobileNumberCountryCodeArray[0]);
   }

  }

  setCountryCode() {
    this.countries = Countries.COUNTRY_CODE;
    this.selectedCountry = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
  }

  selectCountry(event): void {
    const mobileNumber: any[] = [this.selectedCountry?.value, this.selectedMobile];
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(mobileNumber.join('-'));
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
    if (this.selectedMobile && (this.selectedMobile.length > AppSettings.MAX_MOBILE_LENGTH || this.selectedMobile.length < AppSettings.MAX_MOBILE_LENGTH)) {
      this.showMobileValidationError = true;
    } else {
      this.showMobileValidationError = false;
    }
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
       this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.dropdown.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }
}
