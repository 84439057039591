<button pButton [label]="buttonLabel" class="w-6 reset-password" (click)="btnClick()"></button>

<p-dialog [header]="header" [(visible)]="showPopup" [style]="{width: '50vw'}">
    <form [formGroup]="resetPasswordGroup">
        <div class="pr-3">
            <div class="pt-3">
                <label translate>lbl_warning</label>
            </div>
            <div class="pt-3 justify-content-start">
                <label class="font-semibold label" translate>please_type_reset_to_confirm</label>
            </div>
            <div class="py-2 justify-content-start">
                <input class="w-full" formControlName="value" type="text" pInputText placeholder="Enter here" />
            </div>
        </div>
    </form>
    <div class="flex py-2 pr-3 justify-content-end">
        <button pButton class="w-2 bg-white border-0 default-text-light-black-color cursor-pointer"
            [label]="'cancel' | translate" (click)="showPopup = false"></button>
        <button pButton class="border-round-sm reset-btn w-2" type="submit" [label]="'lbl_reset' | translate"  [disabled]="!resetPasswordGroup.valid" (click)="setValue()"></button>
    </div>
</p-dialog>