<!-- <div  class="{{miIcons.LOCATION_OUTLINE_RED}}"></div> -->

<div id="icons-grid" class="grid icons-list m-5">

    @for(item of miIcons|keyvalue; track item.key) {    
    <div class="col-12 md:col-3 justify-content-center" >
        <div class="{{item.value}} wh-24" ></div>
        <div><b>Key:</b> AppIcons.{{item.key}} </div>
        <div class=" mb-4"><b>Value:</b> {{item.value}}</div>
    </div>
        }
</div>
