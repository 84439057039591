import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HideButtonComponent } from "../../dashboard/hide-button/hide-button.component";
import { CommonModule } from "@angular/common";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-overdue-inspections",
  templateUrl: "./overdue-inspections.component.html",
  standalone: true,
  imports: [TranslateModule, HideButtonComponent, CommonModule],
})
export class OverdueInspectionsComponent implements OnInit {
  cardId: string;
  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.overdue_inspection");
  }
}
