@if(hideService.getVisibilityState(cardId) | async){
<div class="card h-full shadow-card">
  <div class="flex flex-column">
    <div class="flex justify-content-between align-items-center mb-4">
      <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
        dashboard.live_journeys_by_type
      </h5>
      <app-hide-button [cardId]="cardId"></app-hide-button>
    </div>
    <ul class="list-none p-0 m-0">
      @for(item of transportRequestData;track item){
      <li
        class="flex flex-column justify-content-between md:flex-row md:align-items-center md:justify-content-between mb-4"
      >
        <div>
          <span [ngClass]="item.labelIcon" class="stepper-car"></span
          ><span [ngClass]="item.labelClass">{{ item.labelKey }}</span>
        </div>
        <div class="mt-2 md:mt-0 flex align-items-center">
          <span [ngClass]="item.countClass">{{ item.usage }}</span>
        </div>
      </li>
      }
    </ul>
  </div>
</div>
}
