import { CommonModule, JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { AppSettings } from 'app/modules/shared/app.settings';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { Country } from 'app/modules/shared/models/country';
import * as _ from 'lodash';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-entity-select',
  standalone: true,
  imports: [AutoCompleteModule, FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass, CommonModule],
  templateUrl: './entity-select.component.html',
  styleUrl: './entity-select.component.scss'
})
export class EntitySelectComponent {    
    checked=[];
    @Input() attributeValues;
    @Input() field: any;
    @Input() attributeLabels: [];
    @Input() moduleName: string;
    @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
    formName: FormGroup;
    searchValue: string = '';
    selectedControl: string[] = [];
    inputPlaceholder: string;
    filteredValues: any[] = [];
    suggestions: any[] | undefined;
    selectedItems: any = [];
  entityIds= [];
  
    items: any[] | undefined;
    country: Country;
  
    constructor(private formGroupDirective: FormGroupDirective,
      public cs: CommonBindingDataService,
      private entityService: EntityService,
    private configService: ConfigService) {
      this.formName = this.formGroupDirective.control;
    }
  
  
  ngOnInit(): void {
    const relatedData = this.entityService.getRelateData;
    const data = relatedData?.[this.field.attributeCode];
    if (data) { 
      this.selectedItems.push(...data);
      data.forEach(item => {
        this.entityIds.push(item.entityId)
      });
      this.formName.controls[this.field.attributeCode].setValue(this.entityIds);    
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }
  }
  
    search(event: AutoCompleteCompleteEvent) {       
       const entityActions = ['active_vehicle_id', 'default_vehicle_id', 'driver',]
       
       let viewCode = this.field.attributeCode.toUpperCase() +'_' +'AUTOCOMPLETE_VIEW'
       if (entityActions.includes(this.field.attributeCode)) { 
         viewCode = 'AUTOCOMPLETE_VIEW';
       }
            
       const activeAttributeCodes = ['driver'];
      let actionStatus = '';
      
       if (activeAttributeCodes.includes(this.field.attributeCode)) { 
        actionStatus= 'Active'
       }
      
        this.fetchFromAPI(viewCode, event.query,actionStatus);
    }
  
  onSelectItem(event) {
    this.entityIds.push(event.value.id)
      this.formName.controls[this.field.attributeCode].setValue(this.entityIds);    
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }
  
  
    fetchFromAPI(viewCode: string, search: string,actionStatus:string) {
      this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
      const requestBody = {
        viewCode: viewCode,
        countryCode: this.country[0].countryCode,
        forTenantCode: this.configService.getForTenantCode(),
        searchText: search,
        actionStatus: actionStatus,
        deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
      }
  
      const attributeCodes = ['active_vehicle_id', 'default_vehicle_id'];
      if (this.field.attributeCode === 'checkin_location_id') { 
        this.moduleName= AppSettings.ENTITY_CODE.LOCATION
      }
      if (this.field.attributeCode === 'checkin_location_id') { 
        this.moduleName= AppSettings.ENTITY_CODE.LOCATION
      }
      if (attributeCodes.includes(this.field.attributeCode)) {
        this.moduleName= AppSettings.ENTITY_CODE.VEHICLE
      }    
      if(this.field.attributeCode === 'driver'){ 
        this.moduleName=AppSettings.ENTITY_CODE.DRIVER
      }

    
      this.entityService.autoComplete(AppSettings.ENTITY_CODE[this.moduleName.toUpperCase()], requestBody).subscribe(res => {
        this.items = this.cs.getTableData(res)|| [];
      });
    }
  
    handleUnselect(event: any) {
      const unselectedItem = event.value; 
  console.log('Item unselected:', unselectedItem);
      this.selectedItems = this.selectedItems.filter(item => item !== unselectedItem);
      this.entityIds= this.entityIds.filter(item => item !== unselectedItem.entityId);
      this.formName.controls[this.field.attributeCode].setValue(this.entityIds);    
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }
  
  }
  
  