  <div class="card p-0 border-none height-calc overflow-y-auto overflow-x-hidden">
    <div class="card-header relative">
      <div class="flex justify-content-between">
        <div class="relative height-53 width-53 flex">
          <app-profile-image [profileData]="chatProfile" [activeClasses]="'height-53 width-53 border-round'"></app-profile-image>
          <div class="flex flex-column my-auto ml-15">
              <span class="flex align-items-center mb-3">
                  <span class="text-base font-bold white-space-nowrap">{{chatProfile.name}}</span>
                  <i class="{{miIcons.OVERDUE}} inline-block wh-24" 
                    [ngClass]="{'bg-green-500': chatProfile.activeStatus === appSettings.MESSAGE_COLOR_STATUS.active, 
                    'bg-red-600': chatProfile.activeStatus === appSettings.MESSAGE_COLOR_STATUS.onCall, 
                    'bg-yellow-500': chatProfile.activeStatus === appSettings.MESSAGE_COLOR_STATUS.away, 
                    'surface-400': chatProfile.activeStatus === appSettings.MESSAGE_COLOR_STATUS.offline}"></i>
                  <span>{{chatProfile.activeStatus}}</span>
              </span>
              <div class="flex text-xs relative white-space-nowrap">
                  <span>{{chatProfile.messageDetails.paxNumber}}</span>
                  <span>|</span>
                  <span class="white-space-nowrap">{{chatProfile.messageDetails.countryCode}} - {{chatProfile.messageDetails.phoneNumber}}</span>
              </div>
          </div>
        </div>
        <div class="video_cam grid float-r relative">
          <span class="grid relative top-7 mr-20 font-20 cursor-pointer justify-content-center align-items-center border-1 border-round text-primary height-45 width-45"><i class="{{miIcons.VIDEO}} inline-block wh-24"></i></span>
          <span class="grid relative top-7 mr-20 font-20 cursor-pointer justify-content-center align-items-center border-1 border-round text-primary height-45 width-45"><i class="{{miIcons.PHONE_WHITE}} inline-block wh-24"></i></span>
        </div>
      </div>
      <span class="absolute right-115 top-10 text-color cursor-pointer font-20" id="action_menu_btn"><i class="{{miIcons.MORE_HORIZONTAL}} inline-block wh-24 top-10 right-7 relative"></i></span>
    </div>
    <div class="card-body overflow-y-auto height-85 surface-50 pt-8 mt-3">
      @for(sysMessage of chatProfile.messageBody; track sysMessage.messageBody){
        <ng-container>
          <div class="flex justify-content-end mb-4">
            @if(sysMessage.type === 'system'){
              <div class="message-body-received float-r ml-10 max-w-40 my-auto border-round bg-white padding-10 relative text-color border-1 border-solid surface-border mr-1">
                {{sysMessage.message | json}}
              </div>
            }
          </div>

          <div class="flex justify-content-between mb-4">
            
            @if(sysMessage.type === 'user') {
            <div class="message-body-sent max-w-40 my-auto border-round bg-cyan-msg padding-10 relative text-white border-1 border-solid surface-border">
              {{sysMessage.message | json}}
              <div class="flex absolute width-200 bottom-m-28 font-10 text-color left-0">{{sysMessage.date | date:'EEEE h:mm a'}} | <span class="font-bold relative">{{ (sysMessage.read ? 'lbl_seen' : 'lbl_sent') | translate}}</span>
              </div>
            </div>
          }
          </div>
        </ng-container>
      }
    </div>
    
  </div>
  <div>
      <div class="p-inputgroup w-full">
          <input type="text" pInputText [placeholder]="'lbl_type_a_message' | translate" />
          <span class="p-inputgroup-addon cursor-pointer">
              <i class="{{miIcons.IMAGE_ALT}} inline-block wh-24 relative top-7"></i>
          </span>
          <span class="p-inputgroup-addon cursor-pointer">
              <i class="{{miIcons.ATTACH}} inline-block wh-24 relative left-0"></i>
          </span>
          <span class="p-inputgroup-addon cursor-pointer">
            <i class="{{miIcons.CAMERA}} inline-block wh-24 relative left-0 top-7"></i>
          </span>
          <span class="p-inputgroup-addon cursor-pointer">
              <i class="{{miIcons.SENT}} inline-block wh-24 relative left-0 top-7"></i>
          </span>
      </div>
  </div>