<form [formGroup]="filterForm" class=" {{isFilterBox? 'filter-box': 'filter-box-row'}} flex-1 flex justify-content-center filter-box fix-height-overflow-hidden">


    @for (field of fields; track field.attributeCode) {

        @if(isFilterBox){
          <div [ngClass]="{ 'mr-2 my-1 visibility-height-1 fix-height-overflow-hidden': selected?.attributeCode === field?.attributeCode,
            'mr-2 my-1 visibility-height-0': selected?.attributeCode != field?.attributeCode}">
            <app-mi-filter-fields [field]="field" [attributeLabels]="attributeLabels"
            [moduleName]="moduleName" [attributeValues]="attributeValues?.[field?.attributeCode]"></app-mi-filter-fields>      
          </div>
        }@else{
          <div class="mr-2 my-1 width-132">
            <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"
            [moduleName]="moduleName"></app-mi-fields>
          </div>
        }
    }
</form>
