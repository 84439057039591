<div class="pb-1 pr-3 pl-1 flex">
    <div class="flex-1 flex align-items-center">
        <label class="truncate-text"> {{'placeholder_search_by' |translate}}
            {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
        &nbsp;

    </div>


</div>
<div class="flex align-items-center w-full -ml-2">
    @if(searchQuery){
      <span><i class="pi pi-times cursor-pointer text-500" (click)="onSearchClear()"></i></span>
    }
    @if(!searchQuery){
        <span><i class="pi pi-search text-500"></i></span>
    }
    <p-autoComplete
        [(ngModel)]="selectedItems"
        [suggestions]="items"
        (completeMethod)="search($event)"
        (onSelect)="onSelectItem($event)"
        [placeholder]="'placeholder_search' |translate"
        [multiple]="true"
        class="mi-auto-complete">

        <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div class="passenger-popup bold-name">
                    <a
                    class="flex w-full cursor-pointer"
                    [ngClass]="{ 'bg-hover': item.isHovered }"
                    
                  >
                    <div
                      class="color-bar mr-2"
                      [ngClass]="{ green: item?.active, red: !item?.active }"
                    ></div>
                    <div class="flex flex-column justify-content-between w-full">
                      <div>
                        <span
                          class="font-14 font-semibold default-text-black-color mr-1"
                          >{{ item.zoneName }}
                        </span>
                        <span
                          class="font-14 font-normal default-text-light-black-color"
                          >({{ item.aliasName }})
                        </span>
                      </div>
                      <div>
                        @if(item.radius > 0){<span
                          class="font-14 font-normal default-print-label-color mr-2"
                          >{{ item.radius }} M |</span
                        >}
                        <span class="font-14 font-normal default-print-label-color">{{
                                item.updatedAt | date:'MMM d, HH:mm'
                        }}</span>
                      </div>
                    </div>
                  </a>

                </div>

            </div>
        </ng-template>
    </p-autoComplete>
</div>

@if(visibleItems.length > 0){
    <div class="selected-container mt-3">
      <div class="font-semibold mb-2"> {{'vehicle.lbl_selected_driver' | translate}} </div> 
        @for (item of visibleItems; track $index) {
    <div class="grid p-1 border-radius hover:surface-200 border-bottom-1 border-200">
        <div class="form-field col-10 md:col-10 lg:col-10 field mb-0">
            <div class="passenger-popup bold-name">
                <div class="ellipsis mr-2 font-semibold">
                    <a
                    class="flex w-full cursor-pointer"
                    [ngClass]="{ 'bg-hover': item.isHovered }"
                    
                  >
                    <div
                      class="color-bar mr-2"
                      [ngClass]="{ green: item?.active, red: !item?.active }"
                    ></div>
                    <div class="flex flex-column justify-content-between w-full">
                      <div>
                        <span
                          class="font-14 font-semibold default-text-black-color mr-1"
                          >{{ item.zoneName }}
                        </span>
                        <span
                          class="font-14 font-normal default-text-light-black-color"
                          >({{ item.aliasName }})
                        </span>
                      </div>
                      <div>
                        @if(item.radius > 0){<span
                          class="font-14 font-normal default-print-label-color mr-2"
                          >{{ item.radius }} M |</span
                        >}
                        <span class="font-14 font-normal default-print-label-color">{{
                                item.updatedAt | date:'MMM d, HH:mm'
                        }}</span>
                      </div>
                    </div>
                  </a>
                </div>
            </div>
        </div>
        <div class="col-2 md:col-2 lg:col-2 mt-2 flex justify-content-end">
            <p-button icon="pi pi-trash" [outlined]="true" (click)="removeSelected(i)" />
        </div>
    </div>
    }
    </div>
}