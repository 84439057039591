import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';

import { MessageService } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import { EntityService } from 'app/modules/shared/services/entity.service';

@Component({
  selector: 'app-driver-check-in-location',
  standalone: true,
  imports: [DialogModule, TranslateModule, InputTextModule, RadioButtonModule, ButtonModule, FormsModule, NoResultFoundComponent],
  templateUrl: './driver-check-in-location.component.html',
  styleUrl: './driver-check-in-location.component.scss'
})
export class DriverCheckInLocationComponent implements OnDestroy {
  @Output() loadSearchEntity: EventEmitter<any> = new EventEmitter();
  miIcons = AppIcons;
  @Input() visibleDriverStatus;
  @Input() rowData;
  @Input() header: string;
  @Input() tableCheckInData: any[] = [];
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onValueChange = new EventEmitter<any>();
  moduleName: string | null;
  selectedFilterCount: number;
  driverData: any;
  driverStatus: any;
  country: Country;
  language: Language;
  tableCheckInDataTemp: any;

  constructor(private driverService: EntityService, private cs: CommonBindingDataService, private messageService: MessageService) { }

  ngOnInit() {
    this.moduleName = AppSettings.ENTITY_CODE.DRIVER;
  }

  ngOnChanges(): void {
    this.driverData = this.rowData;
    const indexOfDefaultVehicle = this.tableCheckInData.findIndex(ele => ele?.id === this.driverData?.checkin_location_id);
    if (indexOfDefaultVehicle >= 0) {
      const deletedData = this.tableCheckInData.splice(indexOfDefaultVehicle, 1);
      this.tableCheckInData.unshift(deletedData[0]);
    }
    this.driverStatus = this.tableCheckInData[0]?.id;
    this.tableCheckInDataTemp = this.tableCheckInData;
  }

  onHide() {
    this.visibleDriverStatus = false;
    this.onToggleOverLay.emit(this.visibleDriverStatus);
  }

  onSearch(event, checkInList) {
    const value = event.target.value.toLowerCase();
    if (checkInList) {
      this.tableCheckInData = this.tableCheckInDataTemp.filter(ele => ele?.location_display_name_for_booker.toLowerCase().includes(value));
    }
  }

  selectCheckInRadioButton(id: any) {
    this.driverStatus = id;
  }

  removeCheckInLocation() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const reqBody = {
      forTenantCode: AppSettings.TENANT_CODE,
      countryCode: country[0].countryCode,
      driverEntityId: this.driverData?.id,
      type: AppSettings.DRIVER.RELEASE.LOCATION
    }
    const entityCode = AppSettings.ENTITY_CODE.LOCATION;
    this.driverService.releaseLocation(entityCode, reqBody).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_release_checkin_location_success') });
      this.visibleDriverStatus = false;
    },
      err => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
  }

  selectCheckInLocation(data) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const reqBody = {
      forTenantCode: AppSettings.TENANT_CODE,
      countryCode: country[0].countryCode,
      languageCode: language[0].langCode,
      driverEntityId: this.driverData?.id,
      locationEntityId: data?.id
    }
    const entityCode = AppSettings.ENTITY_CODE.LOCATION;
    this.driverService.assignLocation(entityCode, reqBody).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_assign_checkin_location_success'), });
      this.visibleDriverStatus = false;
    },
      errors => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
  }

  goTodriverList() {
    this.loadSearchEntity.emit();
  }

  ngOnDestroy() {
    this.tableCheckInData = [];
  }
}
