<app-dashboard-navbar
  [includeRearrange]="true"
  [includeManageWidgets]="true"
></app-dashboard-navbar>
<div class="grid m-3 dashboard-content">
  <!-- booking status -->
  <div class="col-12 lg:col-6 xl:col-3">
    <app-booking-status
      [items]="items"
      [bookingStatusData]="bookingStatusData"
    ></app-booking-status>
  </div>

  <!-- total cancelled journey and live status -->
  <div class="flex flex-column col-12 lg:col-6 xl:col-3">
    <app-cancelled-journey
      [cancelledJourneyData]="cancelledJourneyData"
    ></app-cancelled-journey>

    <app-live-status
      class="flex-grow-1"
      [items]="items"
      [liveStatusData]="liveStatusData"
    ></app-live-status>
  </div>

  <!-- journey emergency and drivers tab -->
  <div class="col-6">
    <div class="grid">
      <!-- journey status -->
      <div class="col-12 lg:col-6 xl:col-6">
        <app-journey-status
          [journeyStatusData]="journeyStatusData"
        ></app-journey-status>
      </div>
      <!-- emergency -->
      <div class="col-12 lg:col-6 xl:col-6">
        <app-emergency
          [emergencyStatusData]="emergencyStatusData"
        ></app-emergency>
      </div>
      <!-- drivers tab -->
      <div class="col-12 pb-0">
        <app-drivers [driverStatusData]="driverStatusData"></app-drivers>
      </div>
    </div>
  </div>

  <!-- upcoming journey stepper -->
  <div class="col-12 lg:col-6 xl:col-3">
    <app-upcoming-journey
      [upcomingJourneyData]="upcomingJourneyData"
    ></app-upcoming-journey>
  </div>

  <!-- live journey by types -->
  <div class="col-3">
    <app-live-journey-types
      [transportRequestData]="transportRequestData"
    ></app-live-journey-types>
  </div>

  <!-- passenger by types -->
  <div class="col-3">
    <app-passenger-by-types [playerData]="playerData"></app-passenger-by-types>
  </div>

  <!-- vehicles -->
  <div class="col-6 mb-6">
    <app-vehicles-card
      [totalVehiclesData]="totalVehiclesData"
    ></app-vehicles-card>
  </div>

  <!-- parking queues -->
  <div class="col-6 mb-6">
    <app-parking-queues
      [parkingQueueData]="parkingQueueData"
    ></app-parking-queues>
  </div>
</div>

