import { NgClass } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AppSettings } from "app/modules/shared/app.settings";
import { Country } from "app/modules/shared/models/country";
import { Language } from "app/modules/shared/models/language";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";

@Component({
  selector: "app-driver-list-card",
  templateUrl: "./driver-list-card.component.html",
  styleUrls: ["./driver-list-card.component.scss"],
  standalone: true,
  imports: [ButtonModule, NgClass, OverlayPanelModule, TranslateModule],
})
export class DriverListCardComponent implements OnInit {
  isHovered: boolean = false;
  @ViewChild("op") op: OverlayPanel;
  @Input() driverDetail: any;
  @Input() forDetail: boolean = false;
  @Input() bookingDetailItem;
  @Output() closeBtnClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() driverCardClicked: EventEmitter<any> = new EventEmitter<any>();
  country: Country;
  language: Language;

  constructor(private enitityService: EntityService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private cs: CommonBindingDataService) { }

  ngOnInit(): void { }

  onCardClick(item) {
    this.driverCardClicked.emit(item);
  }

  onCloseDetailView() {
    this.closeBtnClicked.emit(false);
  }

  onMouseEnter(event: MouseEvent) {
    this.isHovered = true;
    this.op.toggle(event);
  }

  onMouseLeave(event: MouseEvent) {
    this.isHovered = false;
    this.op.hide();
  }
  onAssignClick(driverDetail, event:Event) {
    event.stopPropagation();
    if (this.bookingDetailItem.id) {
      this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
      this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
      const data = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        bookingEntityId: this.bookingDetailItem.id,
        driverEntityId: driverDetail.id
      }
      this.enitityService.assignDriver(data).subscribe(() => {
        this.messageService.add({  key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('dispatch.driver_assign_msg') });
      })
    } else {
      this.messageService.add({  key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('dispatch.booking_assign_first') });
    }
  }
}
