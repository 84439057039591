import { CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FilterService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { AppIcons } from '../../app.icons';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { Column } from '../mi-table/modal/table';
import { NoResultFoundComponent } from '../no-result-found/no-result-found.component';
import { ManageColumnService } from './manage-column.service';

@Component({
  selector: 'app-manage-column',
  templateUrl: './manage-column.component.html',
  styleUrls: ['./manage-column.component.scss'],
  standalone: true,
  imports: [
    DialogModule,
    CdkDropListGroup,
    PanelModule,
    FormsModule,
    InputTextModule,
    CheckboxModule,
    CdkDropList,
    CdkDrag,
    ButtonModule,
    TranslateModule,
    JsonPipe,
    NoResultFoundComponent
  ],
})
export class ManageColumnComponent implements OnInit, OnChanges {
  miIcons = AppIcons;
  columns;
  selectedColumns = [];
  storedData = {
    columns: [],
    selectedColumns: []
  }
  headerText;
  @Input() isShow: boolean;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onManageColumns = new EventEmitter<Column[]>();
  draggedProduct: Column | undefined | null;
  availableColumnCount;
  visibleColumnCount;
  sourceProducts;
  selectedTempColumns = [];
  constructor(private cs: CommonBindingDataService, private cdr: ChangeDetectorRef, private manageColumnService: ManageColumnService) {

  }
  ngOnInit() {
    this.cdr.markForCheck();
    this.manageColumnService.availableData.subscribe(data => {
      const tempData = _.cloneDeep(data);
      this.storedData.columns = _.cloneDeep(tempData);
      this.columns = _.cloneDeep(tempData);
      this.sourceProducts = _.cloneDeep(tempData);
    });
    this.manageColumnService.visibleColumns.subscribe(data => {
      data= _.map(data, item => {
        item.selected = true;
        return item;
      });
      
      const tempData = _.cloneDeep(data);
      if (tempData) {
        this.storedData.selectedColumns = _.cloneDeep(tempData);
      }
      this.selectedColumns = _.cloneDeep(tempData);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('isShow' in changes) {
      this.columns = _.cloneDeep(this.storedData.columns);
      this.selectedColumns = _.cloneDeep(this.storedData.selectedColumns);
    }
  }

  dragStart(product: Column) {
    this.draggedProduct = product;
  }

  dragEnd() {
    this.draggedProduct = null;
  }

  findIndex(product: Column) {
    let index = -1;
    for (let i = 0; i < (this.columns as Column[]).length; i++) {
      if (product.field === (this.columns as Column[])[i].field) {
        index = i;
        break;
      }
    }
    return index;
  }


  onHide() {
    this.isShow = false;
    this.columns = _.cloneDeep(this.storedData.columns);
    this.selectedColumns = _.cloneDeep(this.storedData.selectedColumns);
    this.onToggleOverLay.emit(this.isShow);
  }

  onManageColumn() {
    this.selectedTempColumns.forEach(removedColumn => {
      this.selectedColumns = this.selectedColumns.filter(col => col.attributeCode !== removedColumn.attributeCode);
    });

    this.columns.forEach(col => {
      if (col.selected && !this.selectedColumns.find(sc => sc.attributeCode === col.attributeCode)) {
        this.selectedColumns.push(col);
      }
    });

    this.onManageColumns.emit(this.selectedColumns);
    this.selectedTempColumns = [];
    this.visibleColumnCount = this.selectedColumns?.length;
    this.availableColumnCount = this.columns?.length;
  }


  drop1(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  pushToVisibleColumns(selectedCol:any,index:number) {
    this.selectedTempColumns = [];
    if (selectedCol.selected) {
      this.selectedTempColumns.push(selectedCol);
      this.selectedColumns.push(selectedCol);
    } else {
      this.removeFromVisibleColumn(index, selectedCol);
    }
  }

  removeFromVisibleColumn(index, removedColumns) {
    this.columns.filter(col => {
      if (col.attributeCode === removedColumns.attributeCode) {
        col.selected = false;
      }
    })
    _.remove(this.selectedColumns, { attributeCode: removedColumns.attributeCode});
  }

  restoreDefault() {
    this.selectedColumns = [];
    this.columns.filter(col => {
      col.selected = false;
    });

    const restoreCount = Math.min(this.columns?.length || 0, 6);

    for (let i = 0; i < restoreCount; i++) {
      const item = this.columns[i];
      this.columns[i].selected = true;
      this.pushToVisibleColumns(item, i);
    }
  }

  onSearch(event, columns) {
    const value = event.target.value.toLowerCase();
    if (columns) {
      this.columns = this.sourceProducts.filter(
        ele => ele?.header.toLowerCase().includes(value)
      );
    }
  }

}
