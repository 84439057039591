import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Status } from '../../../../shared/models/status';
import { EntityService } from '../../../../shared/services/entity.service';
import { AppSettings } from '../../../../shared/app.settings';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, JsonPipe } from '@angular/common';
import { AppIcons } from '../../../../shared/app.icons';

@Component({
  selector: 'app-booking-header',
  standalone: true,
  imports: [TranslateModule, DatePipe, JsonPipe],
  templateUrl: './booking-header.component.html',
  styleUrl: './booking-header.component.scss'
})
export class BookingHeaderComponent {
 dd_MMM_yyyy_FORMAT: any = AppSettings.dd_MMM_yyyy_FORMAT;
  hh_mm_FORMAT: any = AppSettings.hh_m_FORMAT;
  @Input() passengerCount: number;
  @Input() passenger: any;
  @Input() data: any;
  @Input() bookingId: string;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  miIcons = AppIcons;
  constructor(
    private statusService: EntityService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }


}
