<div class="my-auto mt-2">
    <div class="card contacts_card p-0 border-none height-calc overflow-y-auto overflow-x-hidden">
      <div class="card-body overflow-y-auto white-space-nowrap h-25rem">
        <ul class="contacts list-none p-0">
          @for(data of profileData | filtersCommunication : searchValue; track data.name) { 
          <li class="cursor-pointer message-card" 
          (click)="activateClass(data)" [ngClass]="{'active': data?.active}">
            <div class="relative">
              <div class="mr-2 mt-2 flex">
                <app-profile-image [profileData]="data" [activeClasses]="'height-37 width-37 border-circle'"></app-profile-image>
                <span class="flex justify-content-center align-items-center bg-primary rounded-circle border-circle height-37 width-37 relative border-2 border-solid border-white right-30">+2</span>
                <!-- <span class="text-xs">{{data.date | date:'h:mm a'}}</span> -->
              </div>
              <div class="relative w-full">
                <div class="flex justify-content-between">
                    <span class="text-base font-bold">{{data.name}}</span>
                </div>
                <p class="text-sm" translate>{{data.vehicleMessage}}</p>
              </div>
            </div>
          </li>
        }
        </ul>
      </div>
    </div>
</div>