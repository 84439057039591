import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';

import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import sha256 from 'crypto-js/sha256';
import * as dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { LoaderService } from './../components/loader/loader.service';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
    'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
    Accept: AppSettings.HEADER_CONTENT_TYPE
  })
};

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  httpHandleError: HandleError;
  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private router: Router,
    private storageService: StorageService, private loaderService: LoaderService) {
    this.httpHandleError = httpErrorHandler.createHandleError();
  }

  private prependApiUrl(url: string): string {
    return AppSettings.BASE_URL + url;
  }

  get(identifier: string, url: string, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithoutBody('get', identifier, url));
  }

  post(identifier: string, url: string, body: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('post', identifier, url, body));
  }

  put(identifier: string, url: string, body?: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('put', identifier, url, body));
  }

  delete(identifier: string, url: string, loader?: string): Observable<{}> {
    return this.handleHttpSuccess(this.callerWithoutBody('delete', identifier, url));
  }

  imagePost(identifier: string, url: string, body?: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.imagePostRequest('post', identifier, url, body));
  }


  callerWithoutBody(method: string, identifier: string, url: string, data?): Observable<{}> {
    if (identifier !== 'sign-out') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const timestamp = new Date().getTime();
    const head = { headers: this.getHttpClientHeaders(timestamp, method, url, '', identifier), withCredentials: true };
    const that = this;
    if (method === 'get') {
      return this.httpClient.get(this.prependApiUrl(url), head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'delete') {
      return this.httpClient.delete(this.prependApiUrl(url), head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }
  callerWithBody(method: string, identifier: string, url: string, body?: any): Observable<{}> {
    if (identifier !== 'sign-out') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const that = this;
    const timestamp = new Date().getTime();
    const head = { headers: this.getHttpClientHeaders(timestamp, method, url, body, identifier), withCredentials: true };

    if (method === 'put') {
      return this.httpClient.put(this.prependApiUrl(url), body, head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'post') {
      return this.httpClient.post(this.prependApiUrl(url), body, head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }

  getFormattedAddress(identifier: string, url: string, loader?: string): Observable<{}> {
    this.showLoader('page-center');
    const head = { headers: null };
    const that = this;
    return this.httpClient.get(url, head).pipe(
      catchError(this.httpHandleError(identifier, []))
    ).pipe(
      map((r: Response) => {
        that.hideLoader();
        return r;
      })
    );
  }

  image(url: string, fileName: string, identifier: string, loader?: string) {
    
    this.showLoader(loader);
    const timestamp = new Date().getTime();
    const head = { headers: this.getHttpClientHeaders(timestamp, 'GET', url, '', identifier), withCredentials: true };
    const res = this.httpClient.get(url, head).pipe(
      catchError(this.httpHandleError(identifier, []))
    );
    this.downloadFile(res, this.getContentType(fileName), fileName);
  }

  getHeaderOfExportPost(timestamp, method, url, body, identifier): HttpHeaders {
    return new HttpHeaders({
      'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_UPLOAD_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE,
      'x-client-req-id': uuidv4(),
      'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
      'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), body, 'NA'),
      'X-AUTHORIZATION-TIMESTAMP': timestamp
    });
  }

  imagePostRequest(method, identifier: string, url: any, body: any) {
    this.showLoader('page-center');
    const timestamp = new Date().getTime();
    const that = this;
    const head: any = {
      headers: this.getHttpClientHeadersForFileUpload(timestamp, body),
      withCredentials: true,
      
    };
    return this.httpClient.post(this.prependApiUrl(url), body, head).pipe(
      catchError(this.httpHandleError(identifier, []))
    ).pipe(
      map((r: any) => {
        that.hideLoader();
        return r;
      })
    );

  }

  importEntities(identifier: string, url: any, body: any) {
    this.showLoader('page-center');
    const timestamp = new Date().getTime();
    const that = this;
    const head: any = {
      headers: this.getHttpClientHeadersForFileUpload(timestamp, body),
      withCredentials: true,
      
    };
    return this.httpClient.post(this.prependApiUrl(url), body, head).pipe(
      catchError(this.httpHandleError(identifier, []))
    ).pipe(
      map((r: any) => {
        that.hideLoader();
        return r;
      })
    );

  }

  private getHttpClientHeadersForFileUpload(timestamp: any, body: any): HttpHeaders {
    return new HttpHeaders({
      'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      
      'Accept': AppSettings.ACCEPT_TYPE_FOR_FILE_UPLOAD,
      'x-client-req-id': uuidv4(),
      'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
      'X-AUTH': this.onCreateHMACEncryption(timestamp, 'POST', body, 'NA'),
      'X-AUTHORIZATION-TIMESTAMP': timestamp
    });
  }

  private getHttpClientHeaders(timestamp, method, url, body, identifier: any): HttpHeaders {
    if (this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== undefined
      && this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== null
      && this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY).length > 0) {
      return new HttpHeaders({
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        Accept: AppSettings.HEADER_CONTENT_TYPE,
        
      });
    }
    if (AppSettings.magneticHolder()) {
      return new HttpHeaders({
        'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
        'x-client-req-id': uuidv4(),
        'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
        'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), body, 'NA'),
        'X-AUTHORIZATION-TIMESTAMP': timestamp
      });
    } else {
      return new HttpHeaders({
        'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
      });
    }
  }

  private handleHttpSuccess(res: Observable<{}>): Observable<{}> {
    return res;
  }

  downloadFile(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data.blob()], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    this.hideLoader();
    
  }

  public downloadDocument(url: string, contentType: string, responseType: any = 'arraybuffer'): Observable<any> {
    const timestamp = new Date().getTime();
    const method = 'get';
    const headers = new HttpHeaders(
      {
        'Content-Type': contentType,
        'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Accept': '*/*',
        'x-client-req-id': uuidv4(),
        'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
        'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), '', 'NA'),
        'X-AUTHORIZATION-TIMESTAMP': timestamp
      }
    );

    return this.httpClient.get(this.prependApiUrl(url),
      {
        headers,
        responseType: responseType,
        observe: 'response',
        withCredentials: true
      }
    )
      .pipe(map(res => new Blob([res.body], { type: res.headers.get('Content-Type') })));
  }

  public downloadDocumentBarcode(url: string): Observable<any> {
    const timestamp = new Date().getTime();
    const method = 'get';
    const headers = new HttpHeaders(
      {
        'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Accept': '*/*',
        'x-client-req-id': uuidv4(),
        'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
        'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), '', 'NA'),
        'X-AUTHORIZATION-TIMESTAMP': timestamp
      }
    );

    return this.httpClient.get(this.prependApiUrl(url),
      {
        headers,
        responseType: 'arraybuffer',
        observe: 'response',
        withCredentials: true
      }
    )
      .pipe(map(res => new Blob([res.body], { type: res.headers.get('Content-Type') })));
  }

  public downloadDocumentPost(url: string, body): Observable<any> {
    const timestamp = new Date().getTime();
    const method = 'post';
    const headers = new HttpHeaders(
      {
        'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Accept': '*/*',
        'x-client-req-id': uuidv4(),
        'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
        'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), body, 'NA'),
        'X-AUTHORIZATION-TIMESTAMP': timestamp
      }
    );

    return this.httpClient.post(this.prependApiUrl(url), body,
      {
        headers,
        responseType: 'arraybuffer',
        observe: 'response',
        withCredentials: true
      }
    )
      .pipe(map(res => new Blob([res.body], { type: res.headers.get('Content-Type') })));
  }

  private getContentType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    switch (extension) {
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'bmp':
        return 'image/x-ms-bmp';
      case 'pdf':
        return 'application/pdf';
      case 'xls':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return '';
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(loader?: string): void {
    if (loader !== undefined && loader !== null && 'none' !== loader?.toLowerCase() && loader !== '') {
      this.loaderService.show();
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }


  checkIdleTime() {
    const idleTime = dayjs().add((AppSettings.IDLE_TIME), 'm').valueOf();
    this.storageService.setItem('interval', idleTime);
  }


  onCreateHMACEncryption(timestamp, method, body, url): any {
    const bodyHash = this.getBodyContentInSha256Base64(body);
    const payload = this.generatePayloadString(
      AppSettings.TENANT_CODE,
      AppSettings.HEADER_WEB,
      timestamp,
      method,
      url,
      bodyHash
    );
    return this.generateHMAC256Signature(payload, AppSettings.magneticHolder());
  }


  getBodyContentInSha256Base64(body) {
    let bodyHash = "";
    if (body) {
      bodyHash = sha256(JSON.stringify(body)).toString(Base64);
    }
    return bodyHash;
  }

  generatePayloadString(tenantCode, client, time, method, path, bodySha2556base64) {
    const tenant = tenantCode.toUpperCase();
    const payload = tenant.concat(
      ";",
      client,
      ";",
      time,
      ";",
      method,
      ";",
      path,
      ";",
      bodySha2556base64
    );
    return payload.trim();
  }

  generateHMAC256Signature(payload, secretKey) {
    const hash = HmacSHA256(payload, secretKey);
    const hashInBase64 = Base64.stringify(hash);
    const authHeaderValue = AppSettings.HMAC_ENC_SIGNATURE + hashInBase64;
    return authHeaderValue;
  }

  getHeaderForMultipleAPI() {
    const timestamp = new Date().getTime();
    const method = 'post';
    return {
      'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), '', 'NA'),
      'X-AUTHORIZATION-TIMESTAMP': timestamp
    };
  }

// payload:T001;WEB;1720794288151;POST;NA;HxKT2UaK4CqREA5IjV5mWh4FBfvE8WC/g6nL01eiwRQ=
 
}
