import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SettingsService } from 'app/modules/settings/settings.service';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-driver-app-login-option',
  standalone: true,
  imports: [CheckboxModule, FormsModule],
  templateUrl: './driver-app-login-option.component.html',
  styleUrl: './driver-app-login-option.component.scss'
})
export class DriverAppLoginOptionComponent implements OnInit {
  @Output() selectedLoginOptionIdChanges: EventEmitter<any> = new EventEmitter();
  loginOptionsList;
  selectedOptions: { [key: string]: boolean } = {};
  chunkedLoginOptions: any[][] = [];
  selectedLoginOptionIds: string[] = [];
  
  constructor(private settingsService: SettingsService,) { }

  ngOnInit() {
    this.getLoginOptions();
  }

  getLoginOptions() {
    this.settingsService.getLoginOptions().subscribe(res => {
      this.loginOptionsList = res;
        this.loginOptionsList.forEach(option => {
          this.selectedOptions[option.loginOptionId] = false;
        });
      this.chunkLoginOptions();
    })
  }

  chunkLoginOptions(): void {
    for (let i = 0; i < this.loginOptionsList.length; i += 2) {
      this.chunkedLoginOptions.push(this.loginOptionsList.slice(i, i + 2));
    }
  }

  onCheckboxChange(optionId: string, event: any): void {
    this.selectedOptions[optionId] = event.checked;
    this.updateSelectedLoginOptionIds();
  }

  updateSelectedLoginOptionIds(): void {
    this.selectedLoginOptionIds = Object.keys(this.selectedOptions).filter(key => this.selectedOptions[key]);
    this.selectedLoginOptionIdChanges.emit(this.selectedLoginOptionIds);
  }

  resetCheckboxes(): void {
    Object.keys(this.selectedOptions).forEach(key => {
      this.selectedOptions[key] = false;
    });
    this.updateSelectedLoginOptionIds();
  }

}
