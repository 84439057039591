<p-breadcrumb [model]="routePath"></p-breadcrumb>

<div class="flex justify-content-end flex-wrap btn-with-links">
  <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer" [label]="'cancel' | translate" (click)="onCancel()"></button>
  <p-button class="flex align-items-center justify-content-center cursor-pointer" (click)="save()" [label]="isEdit ? nextBtnLabel : 'organization.save_organization' | translate"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="activeIndexChange($event)">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg details mr-2"></i>
              <span translate>organization.details</span>
            </div>
          </ng-template>
             <div>
              <div class="org-details pt-2">
                <form  [formGroup]="addOrgForm" class="details-group">
                        <div class="col-12 pl-3 pr-3 tab-group">
                            <div class="org-card card p-fluid">
                                <label class="w-full mt-0 uppercase text-lg font-semibold" translate>organization.company_details</label>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_company_name</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="companyName" pInputText class="w-full" [placeholder]="'organization.lbl_enter_company_name' | translate" />
                                            <label translate>organization.lbl_org_name_text</label>
                                            @if (addOrgForm.controls.companyName.errors && (addOrgForm.controls.companyName.dirty || addOrgForm.controls.companyName.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addOrgForm.controls.companyName.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.name_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_code</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="code" pInputText class="w-full" [placeholder]="'organization.lbl_enter_company_code' | translate" />
                                            @if (addOrgForm.controls.code.errors && (addOrgForm.controls.code.dirty || addOrgForm.controls.code.touched)) {
                                                <div class="form-errors ">
                                                <div [hidden]="!addOrgForm.controls.code.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.code_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3 pb-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_email_address</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="email" pInputText class="w-full" [placeholder]="'organization.lbl_enter_email_address' | translate" />
                                            @if (addOrgForm.controls.email.errors && (addOrgForm.controls.email.dirty || addOrgForm.controls.email.touched)) {
                                                <div class="form-errors ">
                                                <div [hidden]="!addOrgForm.controls.email.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.email_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_contact_number</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                                <div class="flex mobile-dropdown">
                                                    <p-dropdown #dropdown (onChange)="onSelectCountryCodeForCompany($event)" [options]="countries" [(ngModel)]="selectedCountryCodeForCompany" [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false">
                                                        <ng-template pTemplate="selectedItem">
                                                            @if(selectedCountryCodeForCompany) {
                                                                <div class="flex align-items-center gap-2">
                                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountryCodeForCompany?.code?.toLowerCase()" class="width-18"/>
                                                                    <div>{{ selectedCountryCodeForCompany.value }}</div>
                                                                </div>
                                                            }
                                                        </ng-template>
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="flex align-items-center gap-2">
                                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                                                <div>{{ country.value }}</div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="filtericon">
                                                            <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                                        </ng-template>
                                                    </p-dropdown>
                                                    <input pInputText type="text" class="w-full input-mobile"  formControlName="contactNumber" 
                                                            (input)="getPhoneNumberWithCountryCodeForCompany()" pKeyFilter="int"  
                                                            [placeholder]="'organization.lbl_enter_contact_number' | translate"/>

                                                </div>
                                                <input  style="display: none;" pInputText type="text" class="w-full input-mobile" formControlName="contactNumberWithCountryCode" pKeyFilter="int"  />
                                                @if (addOrgForm.controls.contactNumberWithCountryCode.errors && (addOrgForm.controls.contactNumberWithCountryCode.dirty || addOrgForm.controls.contactNumberWithCountryCode.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="!addOrgForm.controls.contactNumberWithCountryCode.errors.required" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        organization.contact_number_is_required
                                                    </div>
                                                </div>
                                                }
                                                @if (addOrgForm.controls.contactNumber.errors && (addOrgForm.controls.contactNumber.dirty || addOrgForm.controls.contactNumber.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="addOrgForm.get('contactNumber').hasError(minLength) || addOrgForm.get('contactNumber').hasError(maxLength)" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        lbl_mobile_number_max_length
                                                    </div>
                                                </div>
                                                }
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.logo</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            @if (showImgUpload) {
                                                <app-mi-upload-file
                                                [showImg]="true"
                                                [isMultipleFilesUpload]="false"
                                                [field]="imgField"
                                                [docType]="'profile'"
                                                [moduleName]="'organization'"
                                                [attributeLabels]="attributeLabels"
                                                (fileUploadEvent)="fileUploadEvent($event)"
                                            ></app-mi-upload-file>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-12 pl-3 pr-3 tab-group">
                            <div class="org-card card p-fluid">
                                <label class="w-full mt-0 uppercase text-lg font-semibold" translate>organization.user_details</label>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_first_name</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="firstName" (blur)="onFirstNameBlur()" pInputText class="w-full" [placeholder]="'organization.lbl_enter_first_name' | translate" />
                                            @if (addOrgForm.controls.firstName.errors && (addOrgForm.controls.firstName.dirty || addOrgForm.controls.firstName.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addOrgForm.controls.firstName.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.first_name_is_required
                                                </div>
                                                <div [hidden]="!addOrgForm.controls.firstName.errors.pattern" class="text-err" translate>
                                                    organization.please_enter_valid_first_name
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_last_name</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="lastName" (blur)="onLastNameBlur()" pInputText class="w-full" [placeholder]="'organization.lbl_enter_last_name' | translate" />
                                            @if (addOrgForm.controls.lastName.errors && (addOrgForm.controls.lastName.dirty || addOrgForm.controls.lastName.touched)) {
                                                <div class="form-errors ">
                                                <div [hidden]="!addOrgForm.controls.lastName.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.last_name_is_required
                                                </div>
                                                <div [hidden]="!addOrgForm.controls.lastName.errors.pattern" class="text-err" translate>
                                                    organization.please_enter_valid_last_name
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                   
                            

                                <div class="w-full formgrid grid pt-3 pb-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_email_address</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="adminEmail" pInputText class="w-full" [placeholder]="'organization.lbl_enter_email_address' | translate" />
                                            @if (addOrgForm.controls.adminEmail.errors && (addOrgForm.controls.adminEmail.dirty || addOrgForm.controls.adminEmail.touched)) {
                                                <div class="form-errors ">
                                                <div [hidden]="!addOrgForm.controls.adminEmail.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.email_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.lbl_contact_number</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                                <div class="flex mobile-dropdown">
                                                    <p-dropdown #dropdown (onChange)="onSelectCountryCodeForUser($event)" [options]="countries" [(ngModel)]="selectedCountryCodeForUser" [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false">
                                                        <ng-template pTemplate="selectedItem">
                                                            @if(selectedCountryCodeForUser) {
                                                                <div class="flex align-items-center gap-2">
                                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountryCodeForUser?.code?.toLowerCase()" class="width-18"/>
                                                                    <div>{{ selectedCountryCodeForUser.value }}</div>
                                                                </div>
                                                            }
                                                        </ng-template>
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="flex align-items-center gap-2">
                                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                                                <div>{{ country.value }}</div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="filtericon">
                                                            <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                                        </ng-template>
                                                    </p-dropdown>
                                                    <input pInputText type="text" class="w-full input-mobile"  formControlName="adminContactNumber" (input)="getPhoneNumberWithCountryCodeForUser()" pKeyFilter="int"  />

                                                </div>
                                                <input  style="display: none;" pInputText type="text" class="w-full input-mobile" formControlName="adminContactNumberWithCountryCode" pKeyFilter="int"  />
                                                @if (addOrgForm.controls.adminContactNumberWithCountryCode.errors && (addOrgForm.controls.adminContactNumberWithCountryCode.dirty || addOrgForm.controls.adminContactNumberWithCountryCode.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="!addOrgForm.controls.adminContactNumberWithCountryCode.errors.required" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        organization.contact_number_is_required
                                                    </div>
                                                </div>
                                                }

                                                @if (addOrgForm.controls.adminContactNumber.errors && (addOrgForm.controls.adminContactNumber.dirty || addOrgForm.controls.adminContactNumber.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="addOrgForm.get('adminContactNumber').hasError(minLength) || addOrgForm.get('adminContactNumber').hasError(maxLength)" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        lbl_mobile_number_max_length
                                                    </div>
                                                </div>
                                                }
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.username</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="username" pInputText class="w-full" [placeholder]="'organization.lbl_enter_username' | translate" />
                                            @if (addOrgForm.controls.username.errors && (addOrgForm.controls.username.dirty || addOrgForm.controls.username.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addOrgForm.controls.username.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.username_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text" translate>organization.password</label>
                                            @if (!tenantId ) {
                                                <span class="required ml-1">*</span>
                                            }

                                        </div>
                                        <div class="col-12 pb-4">
                                           
                                            <p-password autocomplete="null" class="w-full"  formControlName="password" [toggleMask]="true" [placeholder]="'organization.lbl_enter_password' | translate" autocomplete="new-password" />
                                            @if (addOrgForm.controls.password.errors && (addOrgForm.controls.password.dirty || addOrgForm.controls.password.touched)) {
                                                <div class="form-errors ">
                                                <div [hidden]="!addOrgForm.controls.password.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    organization.password_is_required
                                                </div>
                                                <div [hidden]="!addOrgForm.controls.password.errors.pattern" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    <label translate>organization.password_must_contain</label>
                                                    <label translate>organization.at_least_one_uppercase_letter</label>
                                                    <label translate>organization.at_least_one_lowercase_letter</label>
                                                    <label translate>organization.at_least_one_number</label>
                                                    <label translate>organization.at_least_one_special_character</label>
                                                    <label translate>organization.minimum_eight_character</label>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                </form>
            </div>
             </div>
    </p-tabPanel>

    @if (tenantId) {
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-icons">
                  <i class="mi-lg privilege_settings mr-2"></i>
                  <span translate>organization.accessToModules</span>
                </div>
              </ng-template>
              <div class="org-details mt-2">
                <div class="grid pl-3 pr-3">
                    <div class="col-12 pb-0 tab-group">
                        <div  class="access-to-module-card card p-fluid">
                            <div class="col-12">
                                <label class="pl-2 heading uppercase" translate>organization.accessToModules</label>
                            </div>
    
                            @if (accessModueList.length > 0) {
                                @for (module of moduleList; track module;let i = $index) {
                                    @if (module.isAccessible) {
                                        <div class="row-module col-12 inline-flex">
                                            <div class="col-6">
                                                <label translate>{{ module.moduleName }}</label>
                                            </div>
                                            <div class="col-6 text-right">
                                                <p-inputSwitch [(ngModel)]="module.isAccessible" (onChange)="showConfirmPopup($event, module)" />
                                            </div>
                                    </div>
                                    }
                                }
                            } @else {
                                <app-no-result-found></app-no-result-found>
                            }

                          </div>
                    </div>
                  </div>
              </div>
        </p-tabPanel>
    }


</p-tabView>

<div class="col-12 save-action-div">
    <button pButton class="mr-2 btn-bg-color border-0 default-text-light-black-color cursor-pointer" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="cursor-pointer" (click)="save()" [label]="isEdit ? nextBtnLabel : 'organization.lbl_save_organization' | translate"></p-button>
  </div>