import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppIcons } from '../../../shared/app.icons';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { AppSettings } from '../../../shared/app.settings';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ShiftService } from '../../services/shift.service';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import * as _ from 'lodash';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import { TruncatePipe } from 'app/modules/shared/pipes/truncate.pipe';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-group-week-list',
  standalone: true,
  imports: [TranslateModule, DropdownModule, CheckboxModule, OverlayPanelModule, DatePipe, FormsModule, ReactiveFormsModule, ButtonModule,
    NoResultFoundComponent, TruncatePipe, TooltipModule
  ],
  templateUrl: './group-week-list.component.html',
  styleUrl: './group-week-list.component.scss'
})
export class GroupWeekListComponent {
  @Input() listType: any = [];
  @Input() weekDaysList: any = [];
  @Input() selectedListType: any = AppSettings.LIST_TYPE_OPTIONS[1].value;
  @Input() searchValue: any = '';

  @Output() onNextPreviousClick: EventEmitter<any> = new EventEmitter();
  @Output() onListTypeChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() shiftPublishedUnpublishedList: EventEmitter<any> = new EventEmitter();

  listTypeCopy: any[] = [];
  isClearSelected: boolean = true;
  weekday = AppSettings.WEEK_DAYS;
  groupList: any = [];
  selectAll: boolean = true;
  miIconCaretDown = AppIcons.CARET_DOWN;
  weekNumber: number = 0;
  hoveredGroup: any;
  hoveredPublishedUnPublishedShift: any;
  publishedUnpublishedShiftGroup: any;
  isAscendingData: boolean = true;
  country: Country;
  language: Language;
  publishedShiftList: any = [];
  unpublishedShiftList: any = [];
  currentDay = new Date();

  groupDataPayloadData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: '',
    defaultSortType: "asc",
    startDateTime: 0,
    endDateTime: 0,
    startDateTimeStr: '',
    endDateTimeStr: ''
  };

  private maxDataReached = {
    entityDataForGroup: false,
  }

  constructor(private entityService: EntityService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService,
    public datePipe: DatePipe,
    private shiftService: ShiftService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    // this.setCurrentWeek();
    this.listTypeCopy = _.cloneDeep(this.listType);
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    setTimeout(() => {
      const cb = () => {
        this.groupDataPayloadData.offset = this.groupDataPayloadData.offset + (this.groupDataPayloadData.offset === 0 ? this.groupDataPayloadData.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForGroup) {
          this.getGroupList();
        }
      };
      this.applyInfiniteScroll(cb)
    }, 30);
  }

  applyInfiniteScroll(onEndPageCallback) {
    const contentEl = document.querySelector('#shift-cells') as HTMLElement;
    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const endOfPage = Math.round(contentEl.scrollTop) === heightDiff || Math.round(contentEl.scrollTop) === (heightDiff + 1);
      if (endOfPage) {
        onEndPageCallback()
      }
    };
    contentEl.addEventListener("scroll", handleInfiniteScroll)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.groupDataPayloadData.offset = 0;
    if (changes.searchValue) {
      this.searchValue = changes.searchValue.currentValue;
      this.groupDataPayloadData.searchStr = this.searchValue;
      if (this.groupDataPayloadData.searchStr.length >= 3) {
        this.maxDataReached.entityDataForGroup = false;
        this.groupList = [];
        this.getGroupList();
      } else if (this.groupDataPayloadData.searchStr.length === 0) {
        this.maxDataReached.entityDataForGroup = false;
        this.groupList = [];
        this.getGroupList();
      }
    }
     else if(changes.weekDaysList) {
      this.maxDataReached.entityDataForGroup = false;
      this.groupList = [];
      this.getGroupList();
    }
  }

  setCurrentWeek() {
    this.weekDaysList = [];
    const startOfWeek: any = dayjs().startOf('week');
    this.onNextPreviousClick.emit(startOfWeek);

    for (let i = 0; i < 7; i++) {
      const day: any = dayjs(startOfWeek.d).startOf('week').add(i, 'd')
      this.weekDaysList.push({
        dayName: this.weekday[day.$d.getDay()],
        day: day.$D,
        available: '31',
        dayOff: '04',
        date: day.$d.getTime()
      })
    }
  }

  onListTypeChange(event) {
    this.onListTypeChangeEvent.emit(event.value);
  }

  sortGroups() {
    this.isAscendingData = !this.isAscendingData;
    this.groupDataPayloadData.defaultSortType = this.isAscendingData ? 'desc' : 'asc';
    this.groupList = [];
    this.getGroupList();
  }

  calculateTotalWorkingHoursInWeekForDriver(driver: any) {
    driver.workingHours = 0
    driver.shift.forEach((shift) => {
      if (!shift.isLeave) {
        driver.workingHours = driver.workingHours + shift.shiftDuration;
      }
    });
  }

  getShift(shiftList: any, driver: any) {
    driver.shift = [];
    if (shiftList && shiftList.length > 0) {
      shiftList.forEach(shift => {
        const startTimeArray = shift.shiftDetailsDto.startTimeStr.split(AppSettings.STRING_SPLIT_BY_SPACE);
        const hourMinutes = startTimeArray[0].split(AppSettings.STRING_SPLIT_BY_COLON);
        shift.shiftDetailsDto.startTimeHour = `${Number(hourMinutes[0])}${startTimeArray[1]}`;
        let startDate = new Date(`${shift.shiftDetailsDto.startDateStr}`);
        let endDate = new Date(`${shift.shiftDetailsDto.startDateStr}`);
        shift.shiftDetailsDto.startTime = Number(hourMinutes[1]);
        shift.shiftDetailsDto.startDateStr = dayjs(shift.shiftDetailsDto.startDateStr).startOf('day').valueOf();
        shift.shiftDetailsDto.endDateStr = dayjs(shift.shiftDetailsDto.endDateStr).startOf('day').valueOf();
        if (shift.shiftCalendarList && shift.shiftCalendarList.length > 0) {
          let shiftCalendar = shift.shiftCalendarList.find(ele => ele.shiftId === shift.shiftDetailsDto.shiftId);
          shift.shiftDetailsDto.isShiftPublish = shiftCalendar.shiftStatus === AppSettings.SHIFT_STATUS.PUBLISH;
        }

        if (startTimeArray[1] === 'PM') {
          startDate.setHours(Number(hourMinutes[0]) + 12);
        } else {
          startDate.setHours(Number(hourMinutes[0]));
        }
        startDate.setMinutes(Number(hourMinutes[1]));

        const endTimeArray = shift.shiftDetailsDto.endTimeStr.split(AppSettings.STRING_SPLIT_BY_SPACE);
        const hourMinutesEndTime = endTimeArray[0].split(AppSettings.STRING_SPLIT_BY_COLON);
        if (endTimeArray[1] === 'PM') {
          endDate.setHours(Number(hourMinutesEndTime[0]) + 12);
        } else {
          endDate.setHours(Number(hourMinutesEndTime[0]));
        }
        endDate.setMinutes(Number(hourMinutesEndTime[1]));
        shift.shiftDetailsDto.timeDifferenceInMinutes = (dayjs(endDate.getTime()).diff(dayjs(startDate.getTime()), 'minute'));
        this.cd.detectChanges();
        if (shift.shiftCalendarList && shift.shiftCalendarList.length > 0) {
          shift.shiftCalendarList.forEach(shiftCalendar => {
            shiftCalendar = {...shiftCalendar, ...shift.shiftDetailsDto };
            shiftCalendar.startDateStr = shiftCalendar.startDateTime;
            shiftCalendar.endDateStr = shiftCalendar.endDateTime;
            driver.shift.push(shiftCalendar);
            this.cd.detectChanges();
          });
        }
      });
    }
  }


  getDriverDetails(groupIds, groupData) {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      viewCode: AppSettings.VIEW_CODE.DETAIL_VIEW,
      entityIds: groupIds,
      limit: this.groupDataPayloadData.limit,
      offset: 0
    }
    this.entityService.getEntityDetailsByEntityIds(requestBody, AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe((result: any) => {
      console.log(result, 'group details');
      groupData.data.forEach((group) => {
        let groupDetails = result.data.find(ele => ele.id === group.driverGroupEntityId);
        group.color = groupDetails.values?.group_color;
        group.driverGroup = groupDetails.values?.group_name;
        group.groupMembers = groupDetails.values?.assigned_drivers.length;
        group.status = groupDetails.values?.group_status
        this.getShift(group.shiftList, group);
        this.calculateTotalWorkingHoursInWeekForDriver(group);
      });
      this.cd.detectChanges();
    });
  }

  getGroupList() {
    this.groupDataPayloadData.startDateTimeStr = dayjs(this.weekDaysList[0].date).startOf('day').format(AppSettings.DD_MM_YYYY);
    this.groupDataPayloadData.endDateTimeStr = dayjs(this.weekDaysList[this.weekDaysList.length - 1].date).endOf('day').format(AppSettings.DD_MM_YYYY);
    console.log(this.groupDataPayloadData);
    this.shiftService.getDriverList(this.groupDataPayloadData, 'groups').subscribe((result: any) => {

      this.listTypeCopy[1].name = `${this.listType[1].name} (${result.count})`;
      this.groupList.push(...result.data);
      let groupIds = result.data.map(ele => {
        return ele.driverGroupEntityId
      })
      this.getDriverDetails(groupIds, result);
      this.getPublishedUnpublishedShiftList();
      this.getCalendarHeaderCountByDate();
      if (result?.data.length === 0) {
        this.maxDataReached.entityDataForGroup = true;
      }

      if (!this.maxDataReached.entityDataForGroup) {
        this.maxDataReached.entityDataForGroup = this.groupList.length >= result.count;
      }
    });
  }

  getPublishedUnpublishedShiftList() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      languageCode: this.language[0].langCode,
      startDateTimeStr: dayjs(this.weekDaysList[0].date).startOf('day').format(AppSettings.DD_MM_YYYY),
      endDateTimeStr: dayjs(this.weekDaysList[this.weekDaysList.length - 1].date).endOf('day').format(AppSettings.DD_MM_YYYY)
    }
    this.shiftService.getPublishUnPublishListInDateRange(requestBody).subscribe({
      next: (result: any) => {
        this.publishedShiftList = result.filter(ele => ele.shiftStatus.toLowerCase() === AppSettings.SHIFT_STATUS.PUBLISH.toLowerCase());
        this.unpublishedShiftList = result.filter(ele => ele.shiftStatus.toLowerCase() === AppSettings.SHIFT_STATUS.UNPUBLISH.toLowerCase());
        this.shiftPublishedUnpublishedList.emit({publishedShiftList: this.publishedShiftList, unpublishedShiftList: this.unpublishedShiftList});
      },
      error: (error) => {

      }
    })
  }

  getCalendarHeaderCountByDate() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      languageCode: this.language[0].langCode,
      startDateTimeStr: dayjs(this.weekDaysList[0].date).startOf('day').format(AppSettings.DD_MM_YYYY),
      endDateTimeStr: dayjs(this.weekDaysList[this.weekDaysList.length - 1].date).endOf('day').format(AppSettings.DD_MM_YYYY)
    }
    let selectionType = 'groups';
    this.shiftService.getCalendarHeaderCountByDate(requestBody, selectionType).subscribe({
      next: (result: any) => {
        console.log(result, 'calendar header count');
        if (result.length > 0) {
          this.weekDaysList.forEach(element => {
            let index = result.findIndex(ele => ele.date === dayjs(element.date).format(AppSettings.YYYY_MM_DD));
           if (index >= 0) {
            element.drivers = result[index].counts.drivers;
            element.shifts = result[index].counts.shifts;
            element.groups = result[index].counts.groups;
           }
          });
          this.cd.detectChanges();
        }
      },
      error: (error) => {

      }
    })
  }


}
