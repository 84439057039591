import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { AutoFocusDirective } from 'app/modules/shared/directives/auto-focus.directive';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MessageService } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { Language } from 'app/modules/shared/models/language';
import { Countries } from 'app/modules/shared/countries';
import { SettingsService } from 'app/modules/settings/settings.service';
import { EntityList } from 'app/modules/vehicles/modal/entityModal';
import { Country } from 'app/modules/shared/models/country';
import { ListResponse } from 'app/modules/vehicles/modal/listResponse';
import { AttributeData } from 'app/modules/vehicles/modal/attributeModal';

interface UploadedFiles {
  file: File,
  fileId: string
}

@Component({
  selector: 'app-settings-user-profile',
  standalone: true,
  imports: [TranslateModule, ButtonModule, DialogModule, ToastModule, InputTextModule, ReactiveFormsModule, FormsModule, TabViewModule, DropdownModule, FileUploadModule],
  templateUrl: './settings-user-profile.component.html',
  styleUrl: './settings-user-profile.component.scss',
  providers: [AutoFocusDirective]
})
export class SettingsUserProfileComponent implements OnInit{
  @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
  profileForm: FormGroup;
  addBtnLabel = this.cs.getLabel('label_save');
  editLabel: string = this.cs.getLabel('settings.edit');
  deleteLabel: string = this.cs.getLabel('settings.delete');
  deleteHeaderMsg: string = this.cs.getLabel('settings.events.delete_header');
  deleteMsg: string = this.cs.getLabel('settings.events.delete_msg');
  userDetails: any;
  userId: number;
  countries: any[] | undefined;
  selectedCountry: any;
  chooseLabel: string = this.cs.getLabel('lbl_select_image');
  accept: string;
  fileUploadInstruction: string;
  chooseIcon: any = null;
  dropAreaMsg: any = '';
  maxFileSize: any = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  img: any;
  showImg: boolean = false;
  docType: string = 'profile';
  field: any;
  showCheckIcon: boolean = true;
  isMultipleFilesUpload: boolean = true;
  @Output() fileUploadEvent: EventEmitter<any> = new EventEmitter<any>();
  uploadedFiles: UploadedFiles[] = [];
  uploadedFileIds: string = null;
  userName: string;
  language: Language;
  country: Country;
  data: AttributeData;
  userProfileAttributeData;
  attributeIdForUserProfile;
  tableData: any = [];
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getLoggedInTenantCode(),
  };

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder,
    private configService: ConfigService, private entityService: EntityService, private settingsService: SettingsService,
    private messageService: MessageService,) {
  }

  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.uploadProfileImage();
    this.setCountryCode();
    this.onDialogShow();
    this.formValidation();
    this.getUserId();
  }

  uploadProfileImage() {
    const fileIds = this.profileForm?.controls['userProfileId']?.value;
    if (fileIds && fileIds.length > 0) {
      this.img = `https://mitransportationv3-devapi.mi2.in/${this.configService.getLoggedInTenantCode()}/api/v1/files/profile/download/${fileIds[0]}?forTenantCode=${this.configService.getForTenantCode()}`;
      this.getImageByFileId(fileIds[0]);
    }
    this.accept = AppSettings.IMAGE_UPLOAD_TYPES;
    this.fileUploadInstruction = this.cs.getLabel('lbl_img_upload_instruction');
    this.chooseLabel = this.cs.getLabel('lbl_select_image');
    this.dropAreaMsg = this.cs.getLabel('lbl_drop_img');
  }

  setCountryCode() {
    this.countries = Countries.COUNTRY_CODE;
    this.selectedCountry = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
  }

  formValidation() {
    this.profileForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      email: ['', [Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      contactNumber: [''],
      userProfileId: [''],
    });
  }

  onDialogShow() {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput?.nativeElement?.focus();
      } else {
        console.error('nameInput is undefined');
      }
    }, 0);
  }

  getUserId() {
    const profileData = JSON.parse(localStorage.getItem(AppSettings.PROFILE_DATA));
    this.userName = profileData.username;
    this.userId = profileData.userId;
    if(this.userId) {
      this.getUserDetails(this.userId);
      this.getAttributes();
    }
  }
  
  getUserDetails(userId) {
    this.entityService.getUserDetails(userId, this.configService.getForTenantCode()).subscribe(result => {
      this.userDetails = result;
      this.profileForm.controls['firstName'].setValue(this.userDetails.firstName);
      this.profileForm.controls['lastName'].setValue(this.userDetails.lastName);
      this.profileForm.controls['email'].setValue(this.userDetails.email);
      this.profileForm.controls['contactNumber'].setValue(this.userDetails.contactNumber);
      
    })
  }

  previewImage(event) {
    const reader = new FileReader();
    const that = this;
    reader.onloadend = function() {
      that.img = reader.result
    }
    reader.readAsDataURL(event.files[0]);
  }

  showCloseImageIcon() {
    this.showCheckIcon = false;
  }

  showCheckImageIcon() {
    this.showCheckIcon = true;
  }

  removeImage(file) {
    const index = this.uploadedFiles.findIndex(ele => ele.fileId === file.fileId);
    this.uploadedFiles.splice(index, 1);
    this.img = null;
    this.profileForm.controls['userProfileId'].setValue(this.uploadedFileIds);
    this.profileForm.get('userProfileId').updateValueAndValidity();
  }

  onUpload(event) {
    this.previewImage(event);
    const files: FileList = event.files;
    for (const file of event.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ key: "fileupload", severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("files", file);
          formData.append('forTenantCode', this.configService.getForTenantCode(),);
          this.cs.uploadFile(formData, this.docType).subscribe(res => {
            this.messageService.add({ key: "fileupload", severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
            if (this.isMultipleFilesUpload) {
              this.uploadedFileIds = res.fileId;
              this.uploadedFiles.push({ file: file, fileId: res.fileId });
              this.showImg = true;
            } else {
              this.uploadedFileIds = null;
              this.uploadedFiles = [];
              this.uploadedFileIds = res.fileId;
              this.uploadedFiles.push({ file: file, fileId: res.fileId });

            }
            this.fileUploadEvent.emit(this.uploadedFileIds);
            this.profileForm.controls['userProfileId'].setValue(this.uploadedFileIds);
            this.profileForm.get('userProfileId').updateValueAndValidity();
          })
        }
      }
    }
  }

  getImageByFileId(fileId) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'User Profile');
      this.uploadedFiles.push({ file: file, fileId: fileId });
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.img = base64data;
      }
    })
  }

  onCancel() {
    this.profileForm.reset();
    this.onDialogShow();
  }

  getAttributes() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.USER_DETAIL, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.userProfileAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.attributeIdForUserProfile = this.getAttributeId('user_id');
        this.searchEntity(this.attributeIdForUserProfile);
      }
    });
  }

  getAttributeId(attributeCode) {
    const fields = this.userProfileAttributeData.tabs[0].groups[0].fields;
    const field = fields.find(field => field.attributeCode === attributeCode);
    return field ? field.attributeId : null;
  }

  searchEntity(attributeIdForUserProfile) {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.filters.push({
      attributeId: attributeIdForUserProfile,
      attributeValue: this.userId
    });
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.USER_DETAIL, this.entityData).subscribe((res: ListResponse) => {
      this.tableData = [];
      this.tableData = this.cs.getTableData(res);
      const userProfileImage = this.getUserProfileImage(this.tableData);
      if(userProfileImage) {
        this.showImg = true;
        this.getImageByFileId(userProfileImage);
      }
    })
  }

  getUserProfileImage(data) {
    if (data.length > 0) {
      return data[0].user_profile_image;
    }
    return null;
  }

  onAddUserProfile() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    if (this.profileForm.valid) {
      const reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: country[0].countryCode,
        languageCode: this.language[0].langCode,
        firstName: this.profileForm.value.firstName,
        lastName: this.profileForm.value.lastName,
        userName: this.userName,
        email: this.profileForm.value.email,
        contactNumber: this.profileForm.value.contactNumber,
        userProfileId: this.uploadedFileIds,
      }
      this.settingsService.updateUserProfileById(this.userId, reqBody).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.lbl_profile_data_updated_successfully') });
        this.profileForm.reset();
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

}
