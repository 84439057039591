import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { BookingStatusLabel, StatusData } from '../../modal/bookingModal';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'app-booking-status',
  standalone: true,
  imports: [ButtonModule, DialogModule, RadioButtonModule, TranslateModule, FormsModule, NgStyle],
  templateUrl: './booking-status.component.html',
  styleUrl: './booking-status.component.scss'
})
export class BookingStatusComponent {
  @Input() visible: boolean = false;
  selectedBookingStatus!: string;
  miIcons = AppIcons;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() updateStatus: EventEmitter<any> = new EventEmitter();
  @Input() statusData: StatusData;
  BOOKING_STATUS_LABEL: BookingStatusLabel = AppSettings.BOOKING.STATUS;
  country: Country;
  language: Language;
  @Input() bookingId: string;
  constructor(private entityService: EntityService,
    private bookingService: BookingService,
    private messageService: MessageService,
    private cs: CommonBindingDataService,
    private confService: ConfigService
  ) {
  }

  onHide() {
    this.onClose.emit();
  }

  onApply() {

    const selectedBookingStatus = this.findStatusObj(this.selectedBookingStatus);
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));


    const bookingData = {
      forTenantCode: this.confService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.BOOKING,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    bookingData.data.push({
      'attributeId': AppSettings.BOOKING.ATTRIBUTE_IDS.STATUS,
      attributeValue: selectedBookingStatus.bookingStatusId
    });

    const { bookingId } = this.statusData

    const data = {
      forTenantCode: this.confService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      bookingEntityId: bookingId,
      bookingStatus: selectedBookingStatus.bookingStatusId
    }
    this.bookingService.updateStatus(data).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('bookings.booking_status_added'), });
      this.onHide();
      this.visible = false;
      this.updateStatus.emit(this.selectedBookingStatus);
    })
  }

  private findStatusObj(selectedStatus) {
    const { bookingStatusList } = this.statusData;
    return bookingStatusList.find(ele => ele.bookingCode === selectedStatus);
  }
}
