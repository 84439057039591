import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';

interface Location {
  name: string;
}

@Component({
  selector: 'app-queue-location-dropdown',
  standalone: true,
  imports: [DropdownModule, FormsModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './queue-location-dropdown.component.html',
  styleUrl: './queue-location-dropdown.component.scss',
  providers: [FormGroupDirective]
})
export class QueueLocationDropdownComponent implements OnInit {
  @Output() queueLocationChange: EventEmitter<any> = new EventEmitter();
  locations: Location[] | undefined;
  selectedLocation: Location | undefined;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  placeholder: any;
  countries: any[] | undefined;
  selectedCountry: any;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }
  
  ngOnInit() {
      this.locations = [
          { name: 'T Court Q1'},
          { name: 'TP Pickup'},
          { name: 'Tennis Centre'},
          { name: 'Aorangi Pavilion Passenger'}
      ];
  }

  selectQueueLocation(event) {
    this.queueLocationChange.emit(event.value);
  }
}
