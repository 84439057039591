<div class="inline-flex">
    <div class="col-auto mr-3">
        <div class="relative">
            <p-inputNumber (onInput)="onTimeInput()" [(ngModel)]="hour" [showButtons]="true"  [min]="0" [max]="24"
            buttonLayout="horizontal"  spinnerMode="horizontal" [step]="1"
            decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus" class="w-full" [placeholder]="placeholder"></p-inputNumber>
        </div>

    </div>

    <div class="col-auto  mr-3">
        <div class="relative">
            <p-inputNumber (onInput)="onTimeInput()" [(ngModel)]="minutes"  [showButtons]="true"  [min]="0" [max]="60"
            buttonLayout="horizontal"  spinnerMode="horizontal" [step]="1"
            decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus" class="w-full" [placeholder]="placeholder"></p-inputNumber>
        </div>

    </div>

    <div class="col-auto  mr-3">
        <div class="relative">
            <p-inputNumber (onInput)="onTimeInput()" [(ngModel)]="seconds"  [showButtons]="true" [min]="0" [max]="60"
            buttonLayout="horizontal" spinnerMode="horizontal" [step]="1"
            decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus" class="w-full" [placeholder]="placeholder"></p-inputNumber>
        </div>

    </div>
</div>

<input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/>