import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-activity-tab",
  templateUrl: "./activity-tab.component.html",
  styleUrls: ["./activity-tab.component.scss"],
  standalone: true,
  imports: [TranslateModule],
})
export class ActivityTabComponent implements OnInit {
  data: any;
  constructor() { }

  ngOnInit(): void {
    this.data = [
      {
        status: "Confirmed",
        time: "18:20",
        date: "28 July 2023",
        author: "John Doe",
        authorRole: "System Admin",
        location: "123 Main Street Anytown, CA 12345 USA",
      },
      {
        status: "Acknowledged",
        time: "18:20",
        date: "28 July 2023",
        author: "John Doe",
        authorRole: "System Admin",
        location: "123 Main Street Anytown, CA 12345 USA",
      },
      {
        status: "Driver Arriving",
        time: "18:20",
        date: "28 July 2023",
        author: "John Doe",
        authorRole: "System Admin",
        location: "123 Main Street Anytown, CA 12345 USA",
      },
    ];
  }
}
