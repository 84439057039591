<div class="flex flex-column dispatch" [ngClass]="{ 'bg-detail': hideSearchFilters }">
  <div class="flex flex-row align-items-center justify-content-between " [ngClass]="{'pb-0': hideSearchFilters, 'pb-3': !hideSearchFilters}">
    <div class="font-18 font-semibold default-text-black-color" [ngClass]="{'pt-3': hideSearchFilters, 'pl-3': hideSearchFilters}">{{headerLabel}}</div>
    @if(!hideSearchFilters){
      <div class="font-14 font-normal default-print-label-color">{{lastSyncTime}} <i class="mi-lg mi-refresh wh-16 inline-block vertical-align-text-bottom cursor-pointer" (click)="refreshTime()"></i>
      </div>
    }
  </div>
  @if(!hideSearchFilters){<div class="flex flex-row justify-content-between">
    <div style="margin-left: -14px;width: 80%;">
      <span class="p-input-icon-left">
        <i class="pi relative left-20 cursor-pointer"
         [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
         (click)="clearSearch()"></i>
        <input style="width: 20vw" type="text" pInputText [placeholder]="'lbl_search' | translate"
         [(ngModel)]="searchValue" (input)="onSearch($event)"/>
      </span>
    </div> 
    <button icon="mi-lg mi-basic_sort inline-block wh-24 vertical-align-text-bottom" pButton class="bg-f5f5f5 border-none" style="width:10%"></button>
    <button icon="mi-lg mi-filter_2 inline-block wh-24 vertical-align-text-bottom" pButton class="bg-f5f5f5 border-none" style="width:10%"
    (click)="showFilterDialog()"></button>
    @if(filterList) {
      <app-filter-box [filterList]="filterList" [show]="show" [position]="position" [moduleName]="moduleName"
          [selectedFilterCount]="selectedFilterCount" [filterAttributeLabels]="filterAttributeLabels" class="filter-box-div"
         (onValueChange)="onFilterValueChange(true,$event)" (onToggleOverLay)="show = false" isFilterBox="true"></app-filter-box>
    }
  </div>}
  @if(hideSearchFilters){
    <app-driver-list-card [forDetail]="forDetail" [driverDetail]="driverDetail" (closeBtnClicked)="onCloseDriverDetailView($event)" class="bg-detail"></app-driver-list-card>
  }
</div>
