<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button  class="flex align-items-center justify-content-center cursor-pointer" (click)="onNextBtnClick()" [label]="saveExportSettingsBtnLabel"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg details mr-2"></i>
              <span translate>settings.export_account_data</span>
            </div>
          </ng-template>

          @for(tab of exportAccountDataAttributeData?.tabs; track tab?.tabCode) {
            @if (showForm) {
              <app-mi-form
                [attributeLabels]="attributeLabelsForExportAccountDataSettings"
                [moduleName]="exportDataAccountSettingsModuleName"
                [attributeValues]="attributeValues"
                [groups]="tab.groups"
                [isMultipleFilesUpload]="false"
                [previousBtnText]="previousBtnLabel"
                [submitBtnText]="saveExportSettingsBtnLabel"
                (saveData)="saveExportSettings($event)"
                [saveBtnId]="'export'"
              >
          </app-mi-form>
            }

        }
</p-tabPanel>
</p-tabView>