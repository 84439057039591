
        <div>
          <label class="w-full mt-0 uppercase text-lg font-semibold" translate>organization.lbl_time_settings</label><br>
          <div class="mt-1 mb-3">
              <label class="help-text" translate>organization.lbl_enable_or_disable_specific_notifications_about_vehicles</label>
          </div>

          <div class="grid">
              <div class="col-6">
                  <label class="font-semibold" translate>organization.time_setting_for</label>
              </div>
              <div class="col-auto ml-3 mr-3 pl-2 time-header">
                  <label class="font-semibold time-heading" translate>organization.lbl_hours</label>
              </div>
              <div class="col-auto ml-3 mr-3 time-header">
                  <label class="font-semibold time-heading" translate>organization.lbl_minutes</label>
              </div>
              <div class="col-auto ml-3 mr-3 time-header">
                  <label class="font-semibold time-heading" translate>organization.lbl_seconds</label>
              </div>
          </div>
      </div>
      <hr class="hr-line">

          @for (field of group.fields; track field.attributeCode;let j = $index) {
              <div class="grid">
                  <div class="col-6 horizontal">
                    <label class="flex labels">{{cs.getLabelValue(moduleName
                      +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                      @if (field.validation?.required) {
                        <span class="required">*</span>
                      }
                      @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                        <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
                          +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                        </app-mi-tooltip>
                      }
                    </label>

                    <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                    [moduleName]="moduleName"></app-mi-error>
                  </div>
                  <div class="col ml-4">
                      <app-hour-minute-second
                          [field]="field"
                      >
                      </app-hour-minute-second>
                  </div>
                </div>
                <hr class="hr-line">
          }