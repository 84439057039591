import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';

@Component({
  selector: 'app-mi-textarea',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextareaModule],
  templateUrl: './mi-textarea.component.html',
  styleUrl: './mi-textarea.component.scss'
})
export class MiTextareaComponent {
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  formName: FormGroup;
  placeholder: any;
  maxLength = 300;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    this.placeholder = this.placeholder === null ? '' : this.placeholder;
  }
}
