@for(item of data ; track item){
<div class="flex flex-column p-3 pl-4 pt-0">
  <div class="flex flex-row py-12px">
    <div class="w-6 text-green-color">
        <span><i class="mi-lg user_circle bg-green inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-1"></i></span>
        {{item.status}}
    </div>
    <div class="w-6 flex flex-column">
        <div class="font-16 font-bold default-text-black-color">{{item.time}}<span class="default-text-black-color font-normal">, {{item.date}}</span></div>
        <div class="default-text-black-color">By {{item.author}} <span class="default-text-light-gray">({{item.authorRole}})</span></div>
        <div class="flex flex-row pt-2">
           <div><i class="mi-lg mi-location_outline bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-1"></i></div>
           <div class="default-print-label-color">{{item.location}}</div>
        </div>
    </div>
  </div>
</div>
}
