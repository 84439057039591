import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { TrimSpacesDirective } from 'app/modules/shared/directives/trim-spaces.directive';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import {  BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {  TabViewModule } from 'primeng/tabview';

interface faqInfo {
  forTenantCode: string | null;
  question: string;
  answer: string;
  roleCode: string;
  faqId: string;
  tenantId: string;
  countryId: string;
  languageId: string;
  activated: boolean;
}

interface getFaqResponse {
  data: faqInfo[];
  count: number;
  pagination: {
    limit: number;
    offset: number;
    searchStr: string;
    defaultSortColumn: string;
    defaultSortType: string;
  };
}

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports:  [TrimSpacesDirective, EditorModule, TranslateModule, BreadcrumbModule,ButtonModule, TabViewModule, FormsModule,ReactiveFormsModule, AccordionModule, InputTextModule,InputTextareaModule ],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss'
})
export class FAQsComponent {
  btnLabel: string;
  routePath: MenuItem[] = [];
  faqForm: FormGroup;
  faqs: { question: string, answer: string, faqId: string }[] = [];
  addBtnLabel: string;
  isEditing: boolean = false;
  editIndex: number | null = null;
  editFaqId: string | null = null; 
  miIcons = AppIcons;
  roleCode: string = 'driver';
  activeTabIndex: number = 0;
  tabs = [
    { roleCode: 'driver', label: this.cs.getLabel('content.lbl_driver'), icon: this.miIcons.DEFAULT_DRIVER },
    { roleCode: 'passenger', label: this.cs.getLabel('content.lbl_passenger'), icon: this.miIcons.DRIVER_ARRIVED },
    { roleCode: 'ADMIN_USER', label: this.cs.getLabel('content.lbl_admin'), icon: this.miIcons.DEFAULT_USER }
  ];

  constructor(private cs: CommonBindingDataService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private configService: ConfigService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { 
  }


  ngOnInit(): void {
    this.setLabels();
    this.setRoutePath();
    this.initForm();
    this.getFaqList();
  }

  private setLabels() {     
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  private initForm() { 
    this.faqForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required]
    });
  }

  private setRoutePath() { 
    this.routePath = [
      { label: this.cs.getLabel('content.lbl_faqs'), routerLink: 'content/faqs' },
    ];
  }
  
  addFaq() {
    if (this.faqForm.valid) {
      const faqData = { ...this.faqForm.value };
      faqData["forTenantCode"] = this.configService.getForTenantCode();
      faqData["roleCode"] = this.roleCode;

      if (this.isEditing && this.editFaqId) {
        this.updateFaq(this.editFaqId, faqData);
      } else {
        this.settingsService.addFaq(faqData).subscribe((response:faqInfo)  => {
          if (response?.question && response?.answer) {
            this.faqs.push({
              question: response.question,
              answer: response.answer,
              faqId: response.faqId
            });
            this.showSuccessMessage('settings.faq_add_message')
            this.faqForm.reset();
          } else {
            console.error('Response does not contain expected properties');
          }
        }, error => {
          this.showErrorMessage(error.errors.general[0].message)
        });
      }
    }
  }
  
  editFaq(index: number) {
    const faq = this.faqs[index];
    this.faqForm.setValue({ question: faq.question, answer: faq.answer });
    this.isEditing = true;
    this.editIndex = index;
    this.editFaqId = faq.faqId; 
    this.faqs.splice(index, 1);
    this.addBtnLabel = this.cs.getLabel('label_update');
  }

  updateFaq(faqId: string, updatedFaqData: any) {
    updatedFaqData["forTenantCode"] = this.configService.getForTenantCode();
    updatedFaqData["roleCode"] = this.roleCode;
    updatedFaqData["faqId"] = faqId;

    this.settingsService.updateFaqById(faqId, updatedFaqData).subscribe((response: any) => {
      if (response?.messageCode == 'success.updateFaq') {
        this.faqs.splice(this.editIndex, 0, updatedFaqData);
        this.showSuccessMessage('settings.faq_update_message');
        this.isEditing =false;
        this.faqForm.reset();
        this.addBtnLabel = this.cs.getLabel('label_save');
      } else {
        console.error('Response does not contain expected properties');
      }
    }, error => {
      this.showErrorMessage(error.errors.general[0].message);
    });
  }

  onCancel() {
    if (this.isEditing) {
      const faqData = { ...this.faqForm.value };
      this.faqs.splice(this.editIndex, 0, faqData);

    }
    this.faqForm.reset();
    this.isEditing =false;
    this.addBtnLabel = this.cs.getLabel('label_save');
  }
  
  deleteFaq(index: number) {
    const faqId = this.faqs[index].faqId;
    if (faqId) {
      this.showConsentPopup(index, () => {
        this.settingsService.deleteFaq(faqId).subscribe(() => {
          this.faqs.splice(index, 1);
          this.showSuccessMessage('settings.faq_delete_message');
        }, error => {
          this.showErrorMessage(error.errors.general[0].message)
        });
      });
    } else {
      console.error('No faqId found for deletion');
    }
  }
  
  onAdd() { 
    this.addFaq();
  }

  getFaqList() {
    const entityData = {
      limit: 20,
      offset: 0,
      searchStr: "",
      defaultSortColumn: "updatedAt",
      defaultSortType: "desc"
    }
    this.settingsService.getFaqData(this.roleCode, entityData).subscribe((res: getFaqResponse) => {
      if (res && res.data) {
        this.faqs = res.data.map(faq => ({
          question: faq.question,
          answer: faq.answer,
          faqId: faq.faqId
        }));
      } else {
        console.error('Invalid response structure', res);
      }
    }, error => {
      console.error('Error fetching FAQ list:', error);
    });
  }

  private updateRoleCode() {
    this.roleCode = this.tabs[this.activeTabIndex].roleCode;
    this.getFaqList();
  }

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
    this.updateRoleCode();
  }

  showConsentPopup(index: number, onConfirm: () => void) {
    const confirmHeader = this.cs.getLabel('content.delete_faq_header');
    const confirmHeaderMsg = this.cs.getLabel('content.delete_faq_msg');
    this.confirmationService.confirm({
      header: confirmHeader,
      message: confirmHeaderMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('content.lbl_delete'),
      rejectLabel: this.cs.getLabel('content.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        onConfirm();
      },
      reject: () => {
        
      }
    });
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }

}
