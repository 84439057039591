import * as dayjs from 'dayjs';

export function getTimeDiffInHours(startTime, endTime) {
  const diffInMilliseconds = endTime.getTime() - startTime.getTime();
  const hours = (((diffInMilliseconds / 1000) / 60) / 60);
  return hours > 0 ? Math.trunc(hours) : 0;
}

export function convertSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return {
    hour: hours,
    minutes: minutes,
    seconds: remainingSeconds
  };
}