import { CommonModule, NgClass } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { HideButtonComponent } from "../hide-button/hide-button.component";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-booking-status",
  templateUrl: "./booking-status.component.html",
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    ButtonModule,
    HideButtonComponent,
    CommonModule,
  ],
})
export class BookingStatusComponent implements OnInit {
  @Input() bookingStatusData: any;
  @Input() items: any;
  cardId: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.booking_status");
  }
}
