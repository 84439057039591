import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mi-image-container',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './mi-image-container.component.html',
  styleUrl: './mi-image-container.component.scss'
})
export class MiImageContainerComponent {
  @Input() miImage;
  @Input() miHeight;
  @Input() miWidth;

}
