import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TabMenuModule } from "primeng/tabmenu";
import { MenuItem } from "primeng/api";
import { DriverListCardComponent } from "./driver-list-card/driver-list-card.component";
import data from "../../../../../../src/assets/json/dispatch/data.json";
import { Country } from "../../../shared/models/country";
import { Language } from "../../../shared/models/language";
import { EntityService } from "../../../shared/services/entity.service";
import { ConfigService } from "../../../shared/services/config.service";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { AppSettings } from "../../../shared/app.settings";
import { DriverList } from "../../../driver/modal/driverListModal";
import * as _ from 'lodash';
import { NoResultFoundComponent } from "app/modules/shared/components/no-result-found/no-result-found.component";

@Component({
  selector: "app-assign-driver",
  templateUrl: "./assign-driver.component.html",
  styleUrls: ["./assign-driver.component.scss"],
  standalone: true,
  imports: [TabMenuModule, DriverListCardComponent,NoResultFoundComponent],
})
export class AssignDriverComponent implements OnInit {
  @Output() showDriversDetails: EventEmitter<any> = new EventEmitter<any>();
  items: MenuItem[];
  driverList: any = [];
  country: Country;
  language: Language;
  entityData = {
    limit: 40,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: ''
  };
  prevSearch;
  @Input() bookingDetailItem:string;

  constructor(private enitityService: EntityService,
    private configService: ConfigService,
    private cs: CommonBindingDataService,) { }

  ngOnInit() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.initializeTabs();
    this.searchEntity();
  }

  initializeTabs() {
    this.items = [
      { label: "TOTAL", number: '' },
      { label: "AVAILABLE", number: 98 },
      { label: "BUSY", number: 98 },
      { label: "IDLE", number: 98 },
    ];
  }

  ngDoCheck() {
    this.entityData.searchStr = this.cs.getSearchData();
    if (this.entityData.searchStr !== this.prevSearch && (this.entityData.searchStr.length >= 3 || this.entityData.searchStr.length === 0)) {
      this.prevSearch = this.entityData.searchStr;
      this.searchEntity();
    }
  }

  handleDriverCardClicked(event) {
    const hasData = event !== null && event !== undefined;
    const eventData = hasData ? event : null;
    const emissionData = { hasData: hasData, eventData: eventData };
    this.showDriversDetails.emit(emissionData);
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.countryCode = country[0].countryCode;
    this.enitityService.searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityData).subscribe((res: DriverList) => {
        this.items[0].number = res.count;
        this.driverList = res.data?.map(data => {
          const relatedDrivers = data?.relatedData?.map(relatedData => ({
            id: relatedData?.id,
            ...relatedData?.values
          })) || [];
          return { id: data.id, ...data.values, drivers: relatedDrivers };
        }) || [];
      });
  }
}
