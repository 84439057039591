import { Component, Input, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { BookingTabComponent } from "./booking-tab/booking-tab.component";
import { ActivityTabComponent } from "./activity-tab/activity-tab.component";
import { PassengerTabComponent } from "./passenger-tab/passenger-tab.component";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { AppSettings } from "app/modules/shared/app.settings";

@Component({
  selector: "app-dispatch-booking-details",
  templateUrl: "./dispatch-booking-details.component.html",
  styleUrls: ["./dispatch-booking-details.component.scss"],
  standalone: true,
  imports: [
    TabMenuModule,
    BookingTabComponent,
    ActivityTabComponent,
    PassengerTabComponent,
  ],
})
export class DispatchBookingDetailsComponent implements OnInit {
  items: MenuItem[];
  selectedTab: string = this.cs.getLabel("dispatch.booking");
  @Input() bookingDetailItem: any;
  @Input() dispatchBookingDetails;

  constructor(private cs: CommonBindingDataService) { }

  ngOnInit() {
    this.items = [
      {
        label: this.cs.getLabel("dispatch.booking"),
        command: () => this.selectTab(this.cs.getLabel("dispatch.booking")),
      },
      {
        label: this.cs.getLabel("dispatch.passenger_tab"),
        command: () => this.selectTab(this.cs.getLabel("dispatch.passenger_tab")),
        number: this.bookingDetailItem.passengers.length
      },
      {
        label: this.cs.getLabel("dispatch.activity"),
        command: () => this.selectTab(this.cs.getLabel("dispatch.activity")),
      },
    ];
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }
}
