

@if(selectedValue === "Other"){
    <form [formGroup]="formName">
    <input type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full" [placeholder]="'Enter ' + cs.getLabelValue(moduleName
    +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)" />
    <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="danger" (click)="selectedValue ='' "></p-button>
    </form>
}@else{

    <form [formGroup]="formName">
        <p-dropdown #miSelect [options]="field.presetValues" optionLabel="labelValue"  [placeholder]="placeholder" optionValue="labelValue"
            (onChange)="changedValue(miSelect.value)" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [disabled]="selectedValue === 'Other' "></p-dropdown>



    </form>
}