<form [formGroup]="miQuickFilterForm">

            <div class="w-full formgrid grid ml-2 ">
              @for (field of fields; track field.attributeCode;let j = $index) {
                  <app-mi-fields class="mr-2" [field]="field" [attributeLabels]="attributeLabels"
                  [moduleName]="moduleName" filter="quickFilter" [label]="getLabel(field)"></app-mi-fields>
              }
            </div>

  </form>
  