<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer" (click)="onSaveAppLogins()"
        [label]="'lbl_save' | translate"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
    @for (tab of appLoginAttributeData?.tabs; track tab; let i = $index) {
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
                <i class="{{miIcons.DETAILS}} mi-lg wh-16 inline-block relative top-3"></i>
                <span>{{ cs.getLabelValue('user_app_login_setting.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
            </div>
        </ng-template>
        <p-card>
            <div class="grid relative bg-color-gray">
                <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [saveBtnId]="'details'"
                    [moduleName]="moduleName" [groups]="tab.groups" (saveData)="onSaveAppLogins($event)" [showImg]="true"
                    [activeIndex]="activeIndex"(nextClick)="onNextClick()" (selectedLoginOptionIdToAppLogin)="selectedLoginOptionIdToAppLogin($event)"
                    [submitBtnText]="nextBtnLabel" [previousBtnText]="previousBtnText" (previousClick)="onCancel()" [selectedLoginOptionIds]="selectedLoginOptionIds">
                </app-mi-form>
            </div>
        </p-card>
    </p-tabPanel>
    }
</p-tabView>