import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-time-for-queue',
  standalone: true,
  imports: [],
  templateUrl: './get-time-for-queue.component.html',
  styleUrl: './get-time-for-queue.component.scss'
})
export class GetTimeForQueueComponent implements OnInit{
  @Input() data;

  ngOnInit() {
    this.getRelativeTime(this.data);
  }

  getRelativeTime(timestamp: number | null | undefined): string {
    if (timestamp == null) {
        return '';
    }
    const now = Date.now();
    const diffInMs = now - timestamp;
    const diffInMinutes = Math.floor(diffInMs / 60000);
    const diffInHours = Math.floor(diffInMs / 3600000);

    if (diffInMinutes < 1) {
      return 'just now';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    } else {
      const date = new Date(timestamp);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }
  }

}
