<div class="flex align-items-center w-full">
    <p-autoComplete
        [(ngModel)]="selectedItems"
        [suggestions]="items"
        (completeMethod)="search($event)"
        (onSelect)="onSelectItem($event)"
        [placeholder]="'placeholder_search' |translate"
        [multiple]="true"
        (onUnselect)="handleUnselect($event)"
        class="mi-auto-complete">

        <ng-template let-item pTemplate="selectedItem">
            {{item.group_name}} {{item.location_short_code}}  {{item.name_code}}  {{item.first_name}} {{item.last_name}} 
         </ng-template>


        <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div class="passenger-popup bold-name">
                    <div class="ellipsis mr-2 font-semibold">
                    {{item.group_name}}    {{item.location_short_code}}  {{item.name_code}}  {{item.first_name}} {{item.last_name}} <span class="font-normal text-sm">{{item.mobile_number}} {{item.reg_number}} {{item.location_display_name_for_passenger}} 
                        </span>
                    </div>
                    @if(item.email || item.location_display_name_for_booker ) {
                        <div class="unique ellipsis mr-2">
                            {{item.email}} {{item.location_display_name_for_booker}}
                        </div>
                    }
                </div>

            </div>
        </ng-template>
    </p-autoComplete>
</div>

