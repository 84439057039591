<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
  <p-button class="flex align-items-center justify-content-center add-org-btn" icon="pi pi-plus" [label]="btnLabel" (click)="onAddNewLeave()" ></p-button>
</div>
<p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-align-left mr-2"></i>
        <span translate>lbl_all</span>&nbsp;({{totalRecords}})
      </ng-template>
      <ng-template pTemplate="body">
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          [staticEntity]="'leaves'"
          [isLoading]="isLoading"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddNewLeave()"
          [attributeData]="attributeData"
          [totalRecords]="totalRecords"
          [isEntity]="isEntity"
          [uniqueId]="uniqueId"
          (onSearchValueChange)="onSearchValueChanges($event)">
        </app-mi-table>
      </ng-template>
  </p-tabPanel>
</p-tabView>