import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { Router } from '@angular/router';
import { AppSettings } from '../../../../shared/app.settings';

@Component({
  selector: 'app-overview-details',
  templateUrl: './overview-details.component.html',
  styleUrl: './overview-details.component.scss',
  standalone: true,
  imports: [ButtonModule, TranslateModule, ChartModule, FormsModule],
})
export class OverviewDetailsComponent implements OnInit {
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() incidentsComplaintsAttributeData: any;
  @Input() incidentsComplaintsDetails: any;

  selectedItem: any = [];
  data: any;
  options: any;
  tab: any;
  details: any;
  center: google.maps.LatLngLiteral = {
    lat: 24,
    lng: 12,
  };
  zoom = 4;
  map: google.maps.Map;

  constructor(public cs: CommonBindingDataService, private router: Router) {
    this.data = {
      labels: AppSettings.MONTH_LABELS,
      datasets: [
        this.createDataset('First Dataset', '--blue-500'),
        this.createDataset('Second Dataset', '--pink-500'),
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: this.getTextColor()
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            color: this.getTextColorSecondary()
          },
          grid: {
            color: this.getSurfaceBorder(),
            drawBorder: false
          }
        }
      }
    };

  }

  ngOnInit() {
    this.tab = this.incidentsComplaintsAttributeData.tabs.find(ele => ele.tabCode === 'overview');
    this.details = this.tab.groups.find(ele => ele.code === 'details');
    this.initMap();
  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 8,
    });
  }

  private createDataset(label: string, colorVar: string): any {
    return {
      label,
      data: [15, 17, 19],
      fill: false,
      borderColor: document.documentElement.style.getPropertyValue(colorVar),
      backgroundColor: document.documentElement.style.getPropertyValue(colorVar),
      tension: 0.4,
      showLine: false
    };
  }

  private getTextColor(): string {
    return document.documentElement.style.getPropertyValue('--text-color');
  }

  private getTextColorSecondary(): string {
    return document.documentElement.style.getPropertyValue('--text-color-secondary');
  }

  private getSurfaceBorder(): string {
    return document.documentElement.style.getPropertyValue('--surface-border');
  }

  redirectToEdit() {
    this.router.navigate(['/app/incidents-complaints/add/' + this.incidentsComplaintsDetails.entityId]);
  }
}
