@if(hideService.getVisibilityState(cardId) | async){
<div class="card p-0 h-full shadow-card">
  <div class="ellipsis-container justify-content-end height-26 pt-14 pr-14">
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>
  <div>
    <p-table [value]="parkingQueueData">
      <ng-template pTemplate="header">
        <tr>
          <th class="font-16 font-bold default-text-black-color" translate>
            dashboard.parking_and_queues
          </th>
          <th class="font-14 font-semibold" translate>dashboard.bookings</th>
          <th class="font-14 font-semibold" translate>dashboard.vehicles</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td [ngClass]="product.labelClass">{{ product.label }}</td>
          <td [ngClass]="product.availableClass">{{ product.available }}</td>
          <td [ngClass]="product.occupiedClass">{{ product.occupied }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
}
