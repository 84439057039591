
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { CommonBindingDataService } from '../../services/common-binding-data.service';


@Component({
  selector: 'app-mi-error',
  templateUrl: './mi-error.component.html',
  styleUrls: ['./mi-error.component.scss'],
  standalone: true,
  imports: []
})
export class MiErrorComponent implements OnInit {
  formName: FormGroup;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;

  constructor(private formgroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) { }

  ngOnInit() {
    this.formName = this.formgroupDirective.control;
  }
}

