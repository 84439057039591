
@if(!isPassengerDetails)
{
  <div class="help-tips-text mt-1" translate>bookings.search_and_select_passenger_help_tip </div>


<div class="text-xl font-semibold mt-5 mb-3">{{ 'bookings.added_passenger' | translate:{passengerCount:
    addedPassengerList?.length || 0} }} </div>

}
<p-dropdown [options]="addedPassengerList" [(ngModel)]="selectAddedPassenger" placeholder="Select added passenger"
  [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false"
  (onChange)="onSelectAddedPassenger()"  [ngClass]="{ 'passenger-details': isPassengerDetails}">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center gap-2">
      <div class="passenger-popup bold-name">
        <span class="ellipsis mr-2">
          {{selectAddedPassenger?.pass_full_name}}
        </span>
        <span class="unique ellipsis mr-2">
          | {{selectAddedPassenger.unique_id}} | {{selectAddedPassenger.passenger_type}}
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template let-passenger pTemplate="item">
    <div class="flex align-items-center gap-2">
      <div class="passenger-popup bold-name">

        <img [src]="passenger.passenger_profile_image" class="vertical-align-middle border-round-sm list-img mr-2" />
        <span class="ellipsis mr-2">
          {{passenger.pass_full_name}}
        </span>
        <span class="unique ellipsis mr-2">
          ({{passenger.unique_id}}) ({{passenger.passenger_type}})
        </span>

        @if(!isPassengerDetails){
        <i class="{{miIcons.OFF_OUTLINE_CLOSE}} wh-24 close-icon cursor-pointer" (click)="onRemove(passenger)"></i>
    }
      </div>

    </div>
  </ng-template>
</p-dropdown>
