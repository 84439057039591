<div class="grid mt-2 ml-3 overview-details">
    <div class="col-6 pl-0">

        <div class="card p-fluid shadow-none">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>incidents_complaints.lbl_overview_details</label>
                </div>
                <div class="edit flex justify-content-center mb-3" (click)="redirectToEdit()">
                    <label class="card-action py-2" translate>incidents_complaints.edit</label>
                    <i class="mi-lg edit_pencil"></i>
                </div>
            </div>
            @for (field of details.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-6 pt-1 mt-1 mb-1">
                    <label class="detail-heading" translate>{{cs.getLabelValue('incidents_and_complaint.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                </div>
            </div>
        }
        </div>

    </div>
    <div class="col-6">
        <div class="card p-fluid mr-2 shadow-none">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center distance-down">
                    <label class="card-heading uppercase" translate>incidents_complaints.lbl_address</label>
                </div>
            </div>
           <div class="map-div" id="map"></div>
        </div>
    </div>
</div>
