<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="grid mt-1 details-div bg-white">
    <div class="col-8">
        @if (showStatus) {
            <app-overview-status [dataForStatus]="dataForStatus" [incidentsComplaintsCategory]="incidentsComplaintsCategory"></app-overview-status>
        }
    </div>
    <div class="col-4 flex justify-content-end align-items-center">
        <div class="mr-3">
            <p-dropdown [options]="statuses" [(ngModel)]="selectedStatus" optionLabel="labelKey" optionValue="labelValue"
                    
                    (onChange)="onStatusChange($event)"  [ngClass]="getStatusClassForStatus(selectedStatus)">
                <ng-template let-status pTemplate="item">
                    <div>{{ status.labelValue }}</div>
                </ng-template>
            </p-dropdown>
        </div>
        <div>
            <button (click)="edit()" pButton class=" border-400 bg-white text-color mr-1 pl-2 pr-2 pt-2 pb-2 mr-2 hover:surface-100" [label]="editBtnLabel">
                <i class="mi-lg mr-2 edit-pen edit_pencil"></i>
            </button>
        </div>
    </div>
</div>

<div>
    @if (incidentsComplaintsAttributeData) {
        <app-overview-details
            [attributeValues]="attributeValues"
            [attributeLabels]="attributeLabels"
            [incidentsComplaintsAttributeData]="incidentsComplaintsAttributeData"
            [incidentsComplaintsDetails]="incidentsComplaintsDetails"
        ></app-overview-details>
    }
</div>
