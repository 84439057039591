import { Injectable } from '@angular/core';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {


  constructor(private storageService: StorageService
  ) { }


  getLoggedInTenantCode() {
    const profileData = JSON.parse(localStorage.getItem(AppSettings.PROFILE_DATA));
    return profileData?.tenantCode;
  }

  getForTenant() {
    const forTenantDetails = JSON.parse(localStorage.getItem(AppSettings.FOR_TENANT_AND_COUNTRY_DETAILS));
    return forTenantDetails.tenant || {};
  }

  getForTenantId() {
    const forTenantDetails = JSON.parse(localStorage.getItem(AppSettings.FOR_TENANT_AND_COUNTRY_DETAILS));
    return forTenantDetails?.tenant?.tenantId || this.getLoggedInTenantCode();
  }

  getForTenantCode() {
    const forTenantDetails = JSON.parse(localStorage.getItem(AppSettings.FOR_TENANT_AND_COUNTRY_DETAILS));
    return forTenantDetails?.tenant?.tenantCode || this.getLoggedInTenantCode();
  }


  getSelectedCountry() {
    const countryDetails = JSON.parse(localStorage.getItem(AppSettings.FOR_TENANT_AND_COUNTRY_DETAILS));
    return countryDetails.country || {};
  }

  getSelectedCountryId() {
    const countryDetails = JSON.parse(localStorage.getItem(AppSettings.FOR_TENANT_AND_COUNTRY_DETAILS));
    return countryDetails.country.countryId || "2";
  }


}
