import { Component } from '@angular/core';
import dayjs from 'dayjs';
import { AppSettings } from '../../../../../shared/app.settings';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { setWeek } from '../../../../../shared/utility-functions/schedule'
import { generateTimeSlots } from '../../../../../shared/utility-functions/schedule'

@Component({
  selector: 'app-schedule-tab',
  templateUrl: './schedule-tab.component.html',
  styleUrl: './schedule-tab.component.scss',
  standalone: true,
  imports: [TranslateModule, DatePipe],
  providers: [DatePipe]
})
export class ScheduleTabComponent {
  weekDaysList: any = [];
  weekday = AppSettings.WEEK_DAYS;
  dayNumber: number = 0;
  weekNumber: number = 0;
  selectedMonthOrDay: any;
  timeSlots: string[] = [];

  constructor(private datePipe: DatePipe) {

  }

  ngOnInit() {
    setWeek(this.weekNumber, this.weekday);
    this.timeSlots = generateTimeSlots();
    this.updateWeek();
  }

  previousClicked() {
    this.weekNumber--;
    this.weekDaysList = setWeek(this.weekNumber, this.weekday);
  }

  nextClicked() {
    this.weekNumber++;
    this.weekDaysList = setWeek(this.weekNumber, this.weekday);
  }

  updateWeek() {
    this.weekDaysList = setWeek(this.weekNumber, this.weekday);
    }

}
