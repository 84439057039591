import {  ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { debounceTime, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { AppSettings } from 'app/modules/shared/app.settings';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { SettingsService } from 'app/modules/settings/settings.service';

@Component({
  selector: 'app-mi-chips',
  templateUrl: './mi-chips.component.html',
  styleUrls: ['./mi-chips.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ChipsModule, AutoCompleteModule ]
})
export class MiChipsComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  
  selectedItems: any[] = [];
  suggestions: any[] = [];
  newItem: string = '';

  constructor(private formGroupDirective: FormGroupDirective, private configService: ConfigService,
    public cs: CommonBindingDataService, private fb: FormBuilder, private entityService: EntityService, 
    private cd: ChangeDetectorRef, private settingsService: SettingsService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.selectedItems = this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).value;
  }

  search(event: { query: string }) {
    const query = event.query;
    this.newItem = query;
    const payload = {
      limit: 20,
      offset: 0,
      searchStr: query,
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc'
    };

    this.entityService.getTagsList(payload).pipe(
      debounceTime(300),
      switchMap((res: any) => {
        this.suggestions = res.data.map(ele => ({
          label: ele.label,
        }));
        return of(this.suggestions);
      })
    ).subscribe();
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.code === 'Enter' && this.newItem.trim()) {
      const newItemLabel = this.newItem.trim();
      if (!this.selectedItems.includes(newItemLabel)) {
        this.selectedItems.push(newItemLabel);
  
        const control = this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode);
        control.setValue(this.getLabelValues(this.selectedItems));
        const reqBody = {
          forTenantCode: this.configService.getForTenantCode(),
          label: control.value,
        }
        this.settingsService.addTags(AppSettings.ENTITY_CODE.TAGS, reqBody).subscribe(res => {
          console.log(res);
        })
  
        let autocompleteInput = document.querySelector('p-autocomplete input.p-element') as HTMLInputElement;
        if (autocompleteInput) {
          autocompleteInput.value = '';
        }
        this.newItem = '';
      }
    }
  }

  onSelectItem(event: any) {
    const selectedItem = event?.value?.label;
    if (selectedItem) {
      const control = this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode);
      control.setValue(this.getLabelValues(this.selectedItems));
  
      this.newItem = '';
      this.cd.markForCheck();
    }
  }

  onUnselect(event : any){
    const unSelectedItem = event?.value;
    if (unSelectedItem) {
      const control = this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode);
      if (control) {
        const currentValue = control.value || [];
        const valueToRemove = unSelectedItem;
        const updatedValue = currentValue.filter(value => value !== valueToRemove);
        control.setValue(updatedValue);
      }
      this.newItem = '';
      this.cd.markForCheck();
    }
  }

  getLabelValues(items: any[]): string[] {
    const allValues = _.flatMap(items, item =>
      _.isObject(item) && !_.isArray(item) ? _.get(item, 'label') : item
    );
    return _.uniq(allValues);
  }

}
