import { Directive, AfterViewInit, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.renderer.selectRootElement(this.elementRef.nativeElement).focus();
  }
}