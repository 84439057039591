<div>
    <!-- <span #mydiv class="relative white-space-nowrap pl-1">
        <i class="{{miIcons.INFO_CIRCLE_OUTLINE}} inline-block wh-18" [appendTo]="mydiv" [pTooltip]="messageData" tooltipPosition="right" tooltipStyleClass="tooltip-container"></i>
    </span> -->

    <span class="pl-1 info">
        <i class="{{miIcons.INFO_CIRCLE_OUTLINE}} inline-block wh-18"
        type="text"
        pInputText
        [pTooltip]="messageData"
        placeholder="auto"></i>
    </span>
</div>