import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from '../../../app.settings';

@Component({
  selector: 'app-restricted-date-of-birth',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CalendarModule],
  templateUrl: './restricted-date-of-birth.component.html',
  styleUrl: './restricted-date-of-birth.component.scss'
})
export class RestrictedDateOfBirthComponent implements OnInit{
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() dateFormat = AppSettings.DATE_FORMAT_DD_MM_YY;
  @Input() isFilter: boolean = false;
  placeholder: any;
  minDate: Date;
  maxDate: Date;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit(): void {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    const today = new Date();
    this.maxDate = new Date(today.setFullYear(today.getFullYear() - 15));
    this.minDate = new Date(this.maxDate.getFullYear() - 100, this.maxDate.getMonth(), this.maxDate.getDate());
  }

}
