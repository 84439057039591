import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { DropdownModule } from 'primeng/dropdown';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-mi-select-options-with-icon',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule,NgClass],
  templateUrl: './mi-select-options-with-icon.component.html',
  styleUrl: './mi-select-options-with-icon.component.scss'
})
export class MiSelectOptionsWithIconComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  alive = true;
  placeholder: any;

  constructor(
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
  }


}
