import { Component, Input, input } from '@angular/core';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { MiTooltipComponent } from '../../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';

@Component({
  selector: 'app-attribute-label',
  standalone: true,
  imports: [MiTooltipComponent],
  templateUrl: './attribute-label.component.html',
  styleUrl: './attribute-label.component.scss'
})
export class AttributeLabelComponent {
  @Input() field;
  @Input() moduleName;
  @Input() attributeLabels;

  excludeAttributeLabel = [
    'ride_preference', 'number_of_passenger', 'wheelchair',
    'additional_vehicle_requirement'
  ];


  constructor(public cs: CommonBindingDataService) {

  }

}
