<div class="flex flex-column">
    <p-tabMenu [model]="items" [activeItem]="items[0]">
      <ng-template pTemplate="item" let-item>
            <div class="p-menuitem-link flex flex-row align-items-center cursor-pointer mr-3">
                <span> {{ item.label }}</span>
              @if(item.number){
                <span class="ml-1"> ({{item.number}})</span>
              }
            </div>
      </ng-template>
    </p-tabMenu>
    <div class="booking-details-container">
      @if(selectedTab === 'BOOKING'){<app-booking-tab [bookingDetailItem]="bookingDetailItem" [dispatchBookingDetails]="dispatchBookingDetails"></app-booking-tab>}
      @if(selectedTab === 'PASSENGER'){<app-passenger-tab [bookingDetailItem]="bookingDetailItem"></app-passenger-tab>}
      @if(selectedTab === 'ACTIVITY'){<app-activity-tab></app-activity-tab>}
    </div>
</div>