import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  pure: true,
  name: 'filtersCommunication',
  standalone: true
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], ...args: any): unknown {
    if (!value) return null;
    if (!args) return value;

    args[0] = args[0].toLowerCase();

    return value.filter(function(item) {
      return item.name.toLowerCase().includes(args[0]);
    });
  }

}
