import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from '../../../app.settings';
import { Language } from '../../../models/language';

@Component({
  selector: 'app-input-mileage',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, InputNumberModule],
  templateUrl: './input-mileage.component.html',
  styleUrl: './input-mileage.component.scss'
})
export class InputMileageComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  unit: string;
  languageCode: string;
  language: Language;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }
  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.languageCode = this.language[0].langCode;
    this.unit = JSON.parse(this.field?.[AppSettings.SET_UNIT])[this.languageCode];
  }
}
