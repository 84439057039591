<div class="pb-1 pr-3 pl-1 flex">
    <div class="flex-1 flex align-items-center">
        <label class="truncate-text"> {{'placeholder_search_by' |translate}}  {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
    </div>

    
</div>
<div class="flex align-items-center w-full -ml-2">
    @if(searchQuery){
      <span><i class="pi pi-times cursor-pointer text-500" (click)="onSearchClear()"></i></span>
    }
    @if(!searchQuery){
        <span><i class="pi pi-search text-500"></i></span>
    }
    <p-autoComplete 
    [(ngModel)]="selectedItems" 
    [suggestions]="items" 
    (completeMethod)="search($event)" 
    (onSelect)="onSelectItem($event)"    
    [placeholder]="'placeholder_search' |translate"
    [multiple]="true"
    class="mi-auto-complete"
   >

    <ng-template let-item pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div class="passenger-popup bold-name">           
            <span class="ellipsis mr-2">
              {{item[field.attributeCode]}}
            </span>
          </div>
        </div>
      </ng-template>
      </p-autoComplete>
</div>
