import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class StatusCellComponent {

  @Input() data: any;

}
