@if(hideService.getVisibilityState(cardId) | async){
<div class="card shadow-card">
  <div class="flex justify-content-between align-items-center mb-4">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.emergency
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>
  @for(item of emergencyStatusData; track item){
  <div class="text-center mb-6">
    <div class="flex justify-content-center mb-3">
      <div>
        <div class="text-red-500 font-bold text-5xl">
          {{ item.count }}
        </div>
      </div>
    </div>
    <span class="font-medium">{{ item.label }}</span>
  </div>
  }
</div>
}
