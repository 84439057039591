import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ButtonModule } from 'primeng/button';
import { AppSettings } from '../../../../shared/app.settings';
import { MiErrorComponent } from '../../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { JsonPipe } from '@angular/common';
import { AppIcons } from '../../../../shared/app.icons';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MiValidationsService } from 'app/modules/shared/services/mi-validations.service';
@Component({
  selector: 'app-stop-locations',
  standalone: true,
  imports: [DragDropModule, FormsModule, JsonPipe, ReactiveFormsModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, TranslateModule],
  templateUrl: './stop-locations.component.html',
  styleUrl: './stop-locations.component.scss'
})
export class StopLocationsComponent implements OnInit {

  @Input() field: any;
  @Input() isHorizontalForm: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  stopLocations: any = [];
  @Input() edit: boolean = false;
  stops: any = [];
  groupCopy: any = [];
  bookingTypeRestrictionCount = 1000;
  miIcons = AppIcons;
  currentSelectedCode: string;

  constructor(
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private validationsService: MiValidationsService,
  ) {
  }

  ngOnInit(): void {
    this.moduleName = AppSettings.ENTITY_CODE.BOOKING;
    this.groupCopy = _.cloneDeep(this.field);

    if (this.edit) {
      this.setFieldsForEdit();
    } else { 
      this.setFields();
    }
  }

  setFieldsForEdit() {
  
    
    this.stopLocations= this.attributeValues.booking_stops_location_geolocation.map((address, index) => ({
      address:this.attributeValues.booking_stops_location_address[index],
      geoLocation: this.attributeValues.booking_stops_location_geolocation[index]
    }));
    
    for (let j = 0; j < this.stopLocations.length; j++) {
      this.addMoreStops(this.stopLocations[j]);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stops, event.previousIndex, event.currentIndex);
  }

  setFields() {    
    this.currentSelectedCode = this.field.inputCode;
    const validators = this.validationsService.addValidator(this.field.validation,this.currentSelectedCode);
    this.field.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${this.field.attributeCode}`;
    this.field.isOneToMultiple = true;
    this.field.fieldName = this.field.attributeCode;
    const value = this.attributeValues ? this.attributeValues[this.field?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(this.field.attributeCodeOneToMultiple, new FormControl(value, validators));
    this.stops.push(this.field);
  }

 

  addMoreStops(stopLocations) {
    if (this.stops.length > 2) {
      event.stopPropagation(); 
      return;
  }
    this.bookingTypeRestrictionCount += 1;
    const newStop = _.cloneDeep(this.groupCopy);
    const validators = this.validationsService.addValidator(newStop.validation, this.currentSelectedCode );
    newStop.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${newStop.attributeCode}`;
    newStop.isOneToMultiple = true;
    newStop.fieldName = newStop.attributeCode;
    const value = this.attributeValues ? this.attributeValues[newStop?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(newStop?.attributeCodeOneToMultiple, new FormControl(stopLocations, validators));
    this.stops.push(newStop);
    this.cd.detectChanges();
  }

  removeStop(index, stop) {
    this.deleteStopFromDb(index, stop);
  }

  deleteStopFromDb(index, stop) {    
    this.stops.splice(index, 1);
    this.miFormGroup.removeControl(stop.attributeCodeOneToMultiple);
    this.cd.detectChanges();
  }
}
