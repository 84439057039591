<form [formGroup]="formName">
    <div class="flex">
        <p-dropdown #miSelect [options]="field.presetValues" optionLabel="labelValue.address" [placeholder]="placeholder"
            optionValue="labelValue" (onChange)="changedValue()"
            [filter]="true"
            filterBy="labelValue.address" 
            [showClear]="true"
            [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [class]="field.attributeCode"></p-dropdown>
        <div>
            <p-button [class]="moduleName === ENTITY_CODE.PASSENGER_ADDRESS ? 'geo-location' : 'location'" class="inline-flex ml-3" icon="mi-lg wh-24" [icon]="moduleName === ENTITY_CODE.PASSENGER_ADDRESS ? 'basic-location' : 'mi-basic-location'" (click)="showDialog()"></p-button>
        </div>
    </div>

</form>

<p-dialog header="Geo Location" (onHide)="close()" focusOnShow="false" [(visible)]="visible" [style]="{width: '50vw'}">
    <div class="map-div mb-3 mt-3" id="map">
    </div>

    <div class="col-12 save-div mb-3 pr-0">
        <button pButton class="cancel mr-20 text-color height-44 border-button" (click)="close()" [label]="cancelBtnText"></button>
        <p-button class="inline-flex height-44" (click)="saveGeoLocation()" [label]="saveBtnText"></p-button>
    </div>
</p-dialog>

<!-- <input style="display: none;" type="text" pInputText [formControlName]="field.attributeCode" class="w-full" /> -->
@if (showGoogleSearchBox) {
<span id="googleSearch" class="p-input-icon-left ">
    <i class="pi search-icon relative left-30 cursor-pointer pi-search"></i>
    <input id="autocompleteSearch" [(ngModel)]="searchText" type="text" 
    pInputText class="search-field"  [placeholder]="'lbl_search' | translate"
    />
  </span>
}