<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div
  class="flex justify-content-end align-items-center flex-wrap btn-with-links"
>
  <button
    class="flex align-items-center justify-content-center ml-3 bg-white default-text-light-black-color font-16 font-semibold border-button"
    [label]="cancelLabel"
    pButton
    (click)="onCancelForm()"
  ></button>
  <button
    class="flex align-items-center font-16 font-semibold justify-content-center ml-3 bg-white default-text-blue-color"
    [label]="resetLabel"
    pButton
    (click)="onResetForm()"
  ></button>
  <p-button
    class="flex align-items-center font-16 font-semibold justify-content-center ml-3"
    [label]="saveLabel"
    (click)="onSubmit()"
    [disabled]="(zoneForm.controls['zoneType'].value === 'GEO' && !isPolygonCoordinatesSet) && !zoneId"
  ></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg {{miIcons.DETAILS}}" ></i>
      <span class="mr-1" translate>manage_zones.setup_new_zone</span>
    </ng-template>
    <ng-template pTemplate="body">
      <div class="grid grid-nogutter">
        <div class="col-fixed flex flex-column justify-content-between" style="width: 25vw; height: calc(100vh - 120px); overflow: scroll">
          <form [formGroup]="zoneForm" (ngSubmit)="onSubmit()" class="m-4 mt-3">
            <div class="flex flex-row justify-content-end mb-3">
              <label for="active" class="font-14 font-semibold default-text-black-color mr-2 mt-1" translate>lbl_active</label>
              <p-inputSwitch id="active" formControlName="active"></p-inputSwitch>
            </div>
            <div class="flex flex-column mb-3">
              <label for="zoneName" class="font-14 font-semibold default-text-black-color mb-2" translate>
                manage_zones.zone_name<span class="required-field ml-1">*</span>
              </label>
              <input id="zoneName" type="text" pInputText formControlName="zoneName" placeholder="Zone Name" (keydown)="Space($event)"/>
              @if( showRequiredErrorMessage('zoneName') ){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.zone_name_required
                </div>
              } @else if ((zoneForm.controls.zoneName.errors && (zoneForm.controls.zoneName.dirty || zoneForm.controls.zoneName.touched && !formSubmitted))) {
                <div class="font-13 mt-1 font-normal required-field">
                    <div [hidden]="!zoneForm.controls.zoneName.errors.required" class="" translate>
                      manage_zones.zone_name_required
                    </div>
                </div>
              }
              @if( zoneForm.get('zoneName').errors?.maxlength){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.zone_name_max
                </div>
              }
            </div>
            <div class="flex flex-column mb-3">
              <label for="aliasName" class="font-14 font-semibold default-text-black-color mb-2" translate>
                manage_zones.alias_name<span class="required-field ml-1">*</span>
              </label>
              <input id="aliasName" type="text" pInputText formControlName="aliasName" placeholder="Alias Name" (keydown)="Space($event)"/>
              @if( showRequiredErrorMessage('aliasName') ){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.alias_name_required
                </div>
              } @else if ((zoneForm.controls.aliasName.errors && (zoneForm.controls.aliasName.dirty || zoneForm.controls.aliasName.touched && !formSubmitted))) {
                <div class="font-13 mt-1 font-normal required-field">
                    <div [hidden]="!zoneForm.controls.aliasName.errors.required" class="" translate>
                      manage_zones.alias_name_required
                    </div>
                </div>
              }
              @if( zoneForm.get('aliasName').errors?.maxlength){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.alias_name_max
                </div>
              }
            </div>

            <div class="flex flex-column mb-4">
              <label for="description" class="font-14 font-semibold default-text-black-color mb-2" translate>
                manage_zones.description
              </label>
              <textarea id="description" pInputTextarea formControlName="description" placeholder="Description" maxlength ="300"></textarea>
            </div>
            <div class="flex flex-row align-items-center mb-4">
              <p-radioButton value="RADIUS" inputId="RADIUS" name="zoneType" formControlName="zoneType" class="cursor-pointer"></p-radioButton>
              <label for="RADIUS" class="ml-2 mr-3 cursor-pointer" translate>manage_zones.use_radius</label>

              <p-radioButton value="GEO" inputId="GEO" name="zoneType" formControlName="zoneType" class="cursor-pointer"></p-radioButton>
              <label for="GEO" class="ml-2 cursor-pointer" translate>manage_zones.draw_geofencing</label>
            </div>
            <div [hidden]="zoneForm.get('zoneType').value === 'GEO'">
              <div class="flex flex-column mb-3">
                <label for="latitude" class="font-14 font-semibold default-text-black-color mb-2" translate>manage_zones.latitude<span class="required-field ml-1">*</span></label>
                <p-inputNumber
                  id="latitude"
                  type="number"
                  pInputNumber
                  formControlName="latitude"
                  mode="decimal"
                  [(ngModel)]="formLat"
                  [maxFractionDigits]="5"
                  maxlength="20"
                />
                @if (zoneForm.get('zoneType').value === 'RADIUS') {
                  @if (zoneForm.get('latitude').errors?.required && formSubmitted) {
                    <div class="font-13 mt-1 font-normal required-field" translate>
                      manage_zones.latitude_required
                    </div>
                  } @else if ((zoneForm.controls.latitude.errors && (zoneForm.controls.latitude.dirty || zoneForm.controls.latitude.touched && !formSubmitted))) {
                    <div class="font-13 mt-1 font-normal required-field">
                        <div [hidden]="!zoneForm.controls.latitude.errors.required" class="" translate>
                          manage_zones.latitude_required
                        </div>
                    </div>
                  }
                  @if (zoneForm.get('latitude').errors?.pattern) {
                    <div class="font-13 mt-1 font-normal required-field" translate>
                      manage_zones.latitude_max
                    </div>
                  }
                }
              </div>
              <div class="flex flex-column mb-3">
                <label for="longitude" class="font-14 font-semibold default-text-black-color mb-2" translate>manage_zones.longitude<span class="required-field ml-1">*</span></label>
                <p-inputNumber
                  id="longitude"
                  type="number"
                  pInputNumber
                  formControlName="longitude"
                  mode="decimal"
                  [(ngModel)]="formLng"
                  [maxFractionDigits]="5"
                  maxlength="20"
                />
                @if (zoneForm.get('zoneType').value === 'RADIUS') {
                  @if (zoneForm.get('longitude').errors?.required && formSubmitted) {
                    <div class="font-13 mt-1 font-normal required-field" translate>
                      manage_zones.longitude_required
                    </div>
                  } @else if ((zoneForm.controls.longitude.errors && (zoneForm.controls.longitude.dirty || zoneForm.controls.longitude.touched && !formSubmitted))) {
                    <div class="font-13 mt-1 font-normal required-field">
                        <div [hidden]="!zoneForm.controls.longitude.errors.required" class="" translate>
                          manage_zones.longitude_required
                        </div>
                    </div>
                  }
                  @if (zoneForm.get('longitude').errors?.pattern) {
                    <div class="font-13 mt-1 font-normal required-field" translate>
                      manage_zones.longitude_max
                    </div>
                  }
                }
              </div>

              <div class="flex flex-column mb-3">
                <label for="radius" class="font-14 font-semibold default-text-black-color mb-2" translate>manage_zones.radius</label>
                <p-inputNumber
                  id="radius"
                  type="number"
                  pInputNumber
                  formControlName="radius"
                  [(ngModel)]="formRadius"
                  [maxFractionDigits]="2"
                />
                @if (zoneForm.get('zoneType').value === 'RADIUS') {
                  @if (zoneForm.controls.radius.errors && (zoneForm.controls.radius.dirty || zoneForm.controls.radius.touched && !formSubmitted)) {
                    <div class="font-13 mt-1 font-normal required-field">
                      <div [hidden]="!zoneForm.controls.radius.errors.required" class="" translate>
                        manage_zones.radius_required
                      </div>
                    </div>
                  }
                  @if (zoneForm.get('radius').errors?.zeroValue) {
                    <div class="font-13 mt-1 font-normal required-field" translate>
                      manage_zones.radius_nonzero_err
                    </div>
                  }
                  @if (zoneForm.get('radius').errors?.negativeValue) {
                    <div class="font-13 mt-1 font-normal required-field" translate>
                      manage_zones.radius_negative_err
                    </div>
                  }
                }
                <span class="font-13 mt-1 font-normal default-text-light-black-color" translate>manage_zones.default_radius</span>
              </div>
            </div>
            <div class="flex flex-column mb-4">
              <label for="color" class="font-14 font-semibold default-text-black-color mb-2" translate>manage_zones.color_picker</label>
              <div class="flex flex-row">
                <p-colorPicker id="color" formControlName="zoneColor" (onChange)="onColorChange($event.value)"></p-colorPicker>
                <input type="text" class="ml-2 p-inputtext-sm uppercase" pInputText [value]="selectedColor" readonly/>
              </div>
            </div>
          </form>
        </div>
        <div class="col">
          @if(zonesData){
            <app-zone-map
              [drawGeofence]="drawGeofence"
              [showGoogleSearchBox]="true"
              [colorPicker]="selectedColor"
              (polygonCoordinates)="onGetPolygonCoordinates($event)"
              (searchLatLng)="onGetSearchLatLng($event)"
              [circleRadius]="formRadius"
              [circleLat]="formLat"
              [circleLng]="formLng"
              [zoneFormDetails]="zoneFormDetails"
              [zonesData]="zonesData"
              [centerCordinateList]="centerCordinateList"
            ></app-zone-map>
          }
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
