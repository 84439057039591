<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <p-button class="flex align-items-center justify-content-center add-org-btn" icon="pi pi-plus" [label]="btnLabel" (click)="onAddNewLeave()" ></p-button>
</div>
<div class="grid">
    <div class="col-12 pr-0">
        <p-card class="p-0">
            <div class="col-9 pt-2 ml-2 pt-3 mt-1">
                <div class="flex">
                    @if(profileImage) {
                        <img class="img-preview" [src]="profileImage" id="preview">
                    }
                    @else {
                        <p-avatar [label]="driverInitials" styleClass="mr-2" size="xlarge"></p-avatar>
                    }
                    <div class="leave-grid pl-2">
                        <div class="font-semibold driver-full-name">{{driverFullName}}</div>
                        <p class="ml-2">
                            @if(emailId) {
                                <span class="font-bold">{{'lbl_email' | translate}}:</span>&nbsp;<span>{{emailId}}</span>
                                <span>&nbsp;|&nbsp;</span>
                            }
                            <span class="font-bold">{{'lbl_classification' | translate}}:</span>&nbsp;<span>{{'lbl_driver' | translate}}</span>
                            <span>&nbsp;|&nbsp;</span>
                            @if(uniqueId) {
                                <span class="font-bold">{{'lbl_unique_id' | translate}}:</span>&nbsp;<span>{{uniqueId}}</span>
                            }
                            @if(callSign) {
                                <span>&nbsp;|&nbsp;</span>
                                <span class="font-bold">{{'lbl_call_sign' | translate}}:</span>&nbsp;<span>{{callSign}}</span>
                            }
                        </p>
                    </div>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="col-9">
                <div class="flex">
                    <div class="leave-div col-4">
                        <div class="flex">
                            <div class="col-4">
                                <div class="casual">
                                    <span class="leave-grid justify-content-center align-items-center">{{casualCount || 0}}</span>
                                </div>
                            </div>
                            <div class="col-6 inner">
                                <span translate>lbl_casual_leaves</span>
                            </div>
                        </div>
                    </div>
                    <div class="leave-div col-4">
                        <div class="flex">
                            <div class="col-4">
                                <div class="earned">
                                    <span class="leave-grid justify-content-center align-items-center">{{earnedCount || 0}}</span>
                                </div>
                            </div>
                            <div class="col-6 inner">
                                <span translate>lbl_earned_leaves</span>
                            </div>
                        </div>
                    </div>
                    <div class="leave-div col-4">
                        <div class="flex">
                            <div class="col-4">
                                <div class="sick">
                                    <span class="leave-grid justify-content-center align-items-center">{{sickCount || 0}}</span>
                                </div>
                            </div>
                            <div class="col-6 inner">
                                <span translate>lbl_sick_leaves</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
    <div class="col-12 overview-details">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                <i class="pi pi-align-left mr-2"></i>
                <span translate>lbl_request</span>&nbsp;({{leaveRequestCount || 0}})
                </ng-template>
                <ng-template pTemplate="body">
                    @if(driverEntityId) {
                        <app-leave-request [driverEntityId]="driverEntityId" (getDetailsLeaveCountByDriverId)="getDetailsLeaveCountByDriverId()"></app-leave-request>
                    }
                </ng-template>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="{{miIcons.HISTORY}} inline-block relative mr-1 wh-16"></i>
                <span translate>lbl_history</span>&nbsp;({{leaveHistoryCount || 0}})
                </ng-template>
                <ng-template pTemplate="body">
                    @if(driverEntityId) {
                        <app-leave-history [driverEntityId]="driverEntityId"></app-leave-history>
                    }
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>