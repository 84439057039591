@if(hideService.getVisibilityState(cardId) | async){
<div class="card h-full shadow-card">
  <div class="flex justify-content-between align-items-center mb-3">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.time_to_resolve
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>
</div>
}
