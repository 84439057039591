import { Component, OnInit } from "@angular/core";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { FilterSectionComponent } from "./filter-section/filter-section.component";
import { AccordionModule } from "primeng/accordion";
import { NgClass } from "@angular/common";
import data from "../../../../../../src/assets/json/dashboard/data.json";

@Component({
  selector: "app-activity-feed",
  templateUrl: "./activity-feed.component.html",
  styleUrls: ["./activity-feed.component.scss"],
  standalone: true,
  imports: [
    DashboardNavbarComponent,
    TranslateModule,
    FilterSectionComponent,
    AccordionModule,
    NgClass,
  ],
})
export class ActivityFeedComponent implements OnInit {
  nodes!: any[];

  constructor() { }

  ngOnInit() {
    this.nodes = data.nodes;
  }

  callFull(e) {
    e.stopPropagation();
  }
}
