@if(hideService.getVisibilityState(cardId) | async){
<div class="card shadow-card">
  <div class="flex justify-content-between align-items-center mb-4">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.drivers
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>
  <div class="grid">
    <div class="text-center mt-6 col-6">
      <div class="flex justify-content-center mb-3">
        <div>
          <div class="text-blue-500 font-bold text-5xl">152</div>
        </div>
      </div>
      <span class="font-medium" translate>dashboard.on_duty</span>
    </div>
    <div class="col-6 pb-4">
      <ul class="list-none p-0 m-0">
        @for( item of driverStatusData;track item ){
        <li
          class="flex flex-column justify-content-between md:flex-row md:align-items-center md:justify-content-between mb-3"
        >
          <div>
            <span [ngClass]="item.labelClass">{{ item.label }}</span>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <span [ngClass]="item.countClass">{{ item.count }}</span>
          </div>
        </li>
        }
      </ul>
    </div>
  </div>
</div>
}
