
<!-- calendar code start -->
<div id="shift-cells" class="scroll">
    <div class="flex">
        <div class="driver-list-column pr-0">
            @if (groupList.length === 0) {
                <div class="first-column first-row first-cell pl-2 pt-2 pb-2 align-content-center">
                    <div class="inline-flex">
                        <p-dropdown [options]="listTypeCopy"  [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                            <ng-template  pTemplate="dropdownicon">
                                <i class="mi-lg caret-down-2"></i>
                            </ng-template>
                        </p-dropdown>
                        <div class="filter-az" (click)="sortGroups()">
                            <span translate>shift.az</span>
                            <i class="mi-lg mi-filter_2"></i>
                        </div>
                    </div>
                </div>
            }
            @for(group of groupList; track group; let j=$index) {
                @if (j === 0) {
                    <div class="first-column first-row first-cell pl-2 pt-2 pb-2 align-content-center">
                        <div class="inline-flex">
                            <p-dropdown [options]="listTypeCopy" [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                                <ng-template  pTemplate="dropdownicon">
                                    <i class="mi-lg caret-down-2"></i>
                                </ng-template>
                            </p-dropdown>
                            <div class="filter-az" (click)="sortGroups()">
                                @if (isAscendingData) {
                                    <span translate>shift.za</span>
                                } @else {
                                    <span translate>shift.az</span>
                                }
                                <i class="mi-lg mi-filter_2"></i>
                            </div>
                        </div>
                    </div>
                }
    
                <div class="col-12 flex td">
                    <div class="column-cells pl-2 align-content-center">
                        <div class="inline-flex">
                           <div class="block w-13rem ml-2">
                                <div class="flex justify-content-between flex-wrap">
                                    <div class="flex align-items-center justify-content-center ">
                                        <span class="list-group-name align-content-center ellipse"  (mouseover)="op.toggle($event); hoveredGroup = group" (mouseout)="op.toggle($event)"  >{{group.driverGroup}}</span>
                                    </div>
                                </div>
                                <div class="inline-flex align-items-center mt-1">
                                    <i class="mi-lg mi-endofshift"></i>
                                    <span class="total-week-hours ml-1">{{group.workingHours / 60}} hrs</span>
                                </div>
                           </div>
                        </div>
    
                    </div>
                </div>
            }
        </div>
    
        <div class=" toolbar-scroll pl-0">
            <!-- day row -->
            <div >
                <ul>
                    @for (day of weekDaysList; track day; let i = $index) {
                        <li class="first-row">
                            <div class="day inline-block pt-2 pb-2">
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <div [class]="(day.date | date: 'dd MM yyyy') === (currentDay | date: 'dd MM yyyy') ? 'current-day' : 'day-name'" class="mr-2"> {{ day.dayName}} </div>
                                    <div [class]="(day.date | date: 'dd MM yyyy') === (currentDay | date: 'dd MM yyyy') ? 'current-day-number' : 'day-number'" class="ml2"> {{ day.day}} </div>
                                </div>
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <div class="mr-2">
                                        <label class="available-value">{{day.drivers}}</label>
                                        <label class="available-text capitalize ml-1" translate>shift.drivers</label>
                                    </div>
                                    <div class="ml-2">
                                        <label class="available-value">{{day.shifts}}</label>
                                        <label class="available-text ml-1" translate>shift.lbl_shifts</label>
                                    </div>
                                    <div class="ml-2">
                                        <label class="available-value">{{day.groups}}</label>
                                        <label class="available-text ml-1" translate>shift.groups</label>
                                    </div>
                                </div>
                            </div>
                        </li>
                    }
    
                </ul>
            </div>
    
            <!-- shift cells-->
            @for(group of groupList; track group; let m=$index) {
                    <ul>
                        @for (day of weekDaysList; track day; let n = $index) {
                            <li>
                                    <div class="shift-cells align-content-center flex align-items-center">
                                        @for (shift of group.shift; track shift; let k = $index) {
                                            @if ((shift.startDateStr | date: 'dd MM yyyy') === (day.date | date: 'dd MM yyyy')) {
                                                @if (shift.isShiftPublish) {
                                                    <div class="block shift-block">
                                                        @if (shift.endTimeStr) {
                                                            <div class="shift-time ml-3 align-content-center pointer" [style.backgroundColor]="group.color" [style.width]="shift.dateDifference > 1 ? shift.dateDifference * 200 + 'px' : '84%'" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                <div class="ml-2 font-semibold"> {{shift.startTimeStr}} -  {{shift.endTimeStr}}</div>
                                                            </div>
                                                        } @else {
                                                            <div class="shift-time ml-3 align-content-center pointer" [style.backgroundColor]="group.color" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width]="shift.dateDifference > 1 ? shift.dateDifference * 200 + 'px' : '84%'" >
                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                <div class="ml-2 font-semibold"> <span>{{shift.startDateStr  | date: 'd MMM'}} -  {{shift.endDateStr  | date: 'd MMM'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTime  | date: 'h:mma'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                            </div>
                                                        }
                                                    </div>
                                                } @else {
                                                    <div class="block shift-block" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                        <div class="unpublish-shift ml-3 align-content-center pointer"  [style.backgroundColor]="group.color">
                                                            <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                            <div class="ml-2 font-semibold"> {{shift.startTimeStr}} -  {{shift.endTimeStr}}</div>
                                                        </div>
                                                    </div>
                                                }
                                            }
                                        }
    
                                    </div>
                            </li>
                        }
                    </ul>
            }
        </div>
    </div>
    <!-- calendar code end -->
    @if (groupList.length === 0) {
        <app-no-result-found></app-no-result-found>
    }
</div>



<!-- Group overlay panels -->
<p-overlayPanel #op>
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-1">
                <span class="group-color" [style.background-color]="hoveredGroup.color"></span>
            </div>
            <div class="col-11 pl-0">
                <div class="flex">
                    <label class="driver-overlay-name mr-2">{{hoveredGroup.driverGroup}}</label> <label class="mr-2">(Group)</label>
                    <span class="group">
                        <i class="mi-lg mi-driver"></i>
                        <span class="ml-1">
                            {{hoveredGroup.driverGroup}}
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-5 label-text" translate>shift.status</div>
            <div class="col-7 label-value capitalize">
                <span [class]="hoveredGroup.status.toLowerCase()"></span>
                <span [class]="hoveredGroup.status.toLowerCase"></span> {{hoveredGroup.status}}
            </div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-5 label-text" translate>shift.groupMembers</div>
            <div class="col-7 label-value">{{ hoveredGroup.groupMembers }}</div>
        </div>
        <hr class="hr">
    </ng-template>
</p-overlayPanel>


<!-- unpublish, publish shift overlay panel -->
<p-overlayPanel #shiftOverlay>
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-1">
                <span class="group-color" [style.background-color]="publishedUnpublishedShiftGroup.color"></span>
            </div>
            <div class="col-11 pl-0">
                <div class="flex">
                    <label class="driver-overlay-name mr-2">{{publishedUnpublishedShiftGroup.driverGroup}}</label> <label class="mr-2">(Group)</label>
                </div>
            </div>

        </div>
        @if (hoveredPublishedUnPublishedShift.endTimeStr) {
            <div class="grid">
                <div class="col-6 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr  | date: 'dd MMM'}} </span>
                </div>
                <div class="col-6 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr}} -  {{hoveredPublishedUnPublishedShift.endTimeStr}}</span>

                </div>
            </div>
        } @else {
            <div class="grid">
                <div class="col-6 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr}} -  {{hoveredPublishedUnPublishedShift.endDateStr}} </span>
                </div>
                <div class="col-6 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr}} -  <span translate>shift.noEndTime</span></span>
                </div>
            </div>
        }

        <hr class="hr">
        <div class="grid">
            <div class="col-6" translate>
                <i class="mi-lg mi-breaks"></i>
                <label class="label-text ml-2" translate>shift.breaks</label>
            </div>
            <div class="col-6 label-value">
                @for (break of hoveredPublishedUnPublishedShift.shiftBreakList; track break; let n = $index) {
                        <div>
                            <i class="mi-lg mi-endofshift"></i>
                            <span class="ml-1">  {{break.breakStartTime}} -  {{break.breakEndTime}}</span>
                        </div>
                }
            </div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-6 label-text">
                <label translate>shift.driverAssigned</label>
            </div>
            <div class="col-6 label-value">
             <div>{{hoveredPublishedUnPublishedShift.assignCount}}</div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>