<div class="header-rule flex justify-content-end">
    <div class="header">
      <p-dropdown [options]="listColumns" optionLabel="label" optionValue="value" [(ngModel)]="selectedEvent" [placeholder]="'events.lbl_select_an_event'|translate">
        <ng-template let-option pTemplate="selectedItem">
          @if (selectedEvent) {
            <div class="flex align-items-center">
              @if (option.icon) {
                <div [ngStyle]="{'background-image': 'url(' + option.icon + ')'}" class="event-dropdown-img vertical-align-middle border-round-sm event-img mr-2">
                </div>
              } @else {
                <div class="inline-flex mr-2">
                  <img [src]="option.icon" class="option-icon mr-2">
                </div>
              }
              <span>{{ option.label }}</span>
            </div>
          }
        </ng-template>

        <ng-template let-option pTemplate="item">
          <div class="flex align-items-center">
            @if (option.icon) {
              <div [ngStyle]="{'background-image': 'url(' + option.icon + ')'}" class="event-dropdown-img vertical-align-middle border-round-sm event-img mr-2">
              </div>
            } @else {
              <div class="inline-flex mr-2">
                <img [src]="option.icon" class="option-icon mr-2">
              </div>
            }
            <span>{{ option.label }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

  <ul class="header--menu fadeInDown flex flex-row">
    <li>
      <a class="bg-white top-7" (click)="goToSettings()"><span><i class="{{miIcon.SETTINGS}} inline-block wh-22"></i></span></a>
    </li>
    <li>
      <a class="bg-white top-7"><span><i class="{{miIcon.NOTIFICATION_RED}} inline-block wh-22"></i></span></a>
    </li>
    <li class="user-profile" (click)="onProfileClick($event)">
        @if (profileData?.profileImage) {
          <img [src]="profileData.profileImage"
            class="height-40-px width-40-px border-circle cursor-pointer"/>
        }
        @if (!profileData?.profileImage) {
          <div class="img-background relative height-40-px width-40-px border-circle surface-300 cursor-pointer">
            <span class="image-name text-lg font-bold">{{createAcronym(profileData?.username)}}</span>
          </div>
        }
      <ul class="fadeInDown">
        <li role="menuitem"><a href="#"><span>Edit Profile</span><i class="{{miIcon.USER_CIRCLE}} inline-block wh-24"></i></a></li>
        <li role="menuitem"><a href="#"><span>Change Password</span><i class="{{miIcon.PASSWORD}} inline-block wh-24"></i></a></li>
        <li role="menuitem"  (click)="doSignOut()"><a href="#"><span>Logout</span><i class="{{miIcon.LOG_OUT}} inline-block wh-24"></i></a></li>
      </ul>
    </li>
  </ul>
 
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
