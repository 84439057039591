<p-breadcrumb [model]="routePath"></p-breadcrumb>
@if(!isBookingHistory){
<app-module-header [label]="btnLabel" (onBtnClick)="onAddBooking()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>
}
<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)" id="bookingId">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg menu_alt_03 mr-1 width-14"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.ALL  }})</span>
    </ng-template>
    <ng-template pTemplate="body" >
@defer  {
  @if (data) {
      <app-booking-table
      [collectionOfColumns]="allColumns"
      [attributeLabels]="filterAttributeLabels"
      [data]="tableData"
      entityName="booking"
      [isLoading]="isLoading"
      [totalRecords]="totalRecords"
      [message]="deleteBookingMsg"
      [header]="deleteVehicleHeaderMsg"
      [filterList]="filterList"
      [filterFields]="filterFieldsArray"
      [label]="btnLabel"
      [attributeData]="attributeData"
      [rowItems]="rowItems"
      [visiblePrintLabel]="visiblePrintLabel"
      [showBulkUpdateDialog]="showBulkUpdateDialog"
      [visible]="visible"
      [bookingStatus]="bookingStatus"
      [isBookingHistory]="isBookingHistory"
      (onPageChange)="onPageChange($event)"
      (itemPerPageChange)="itemPerPageChange($event)"
      (onDelete)="onBookingDelete($event)"
      (onRowSelection)="onRowSelect($event)"
      (onValueChange)="onFilterValueChange($event)"
      (onBtnClick)="onAddBooking()"
      (onSearchValueChange)="onSearchValueChanges($event)"
      (setCurrentRowData)="setCurrentRowData($event)"
      >
    </app-booking-table>
     }
} @placeholder (minimum 900ms){
  <app-table-skelton></app-table-skelton>
} @error {
  <app-error-skelton></app-error-skelton>
}
    </ng-template>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg mi-watchlist width-14  mr-1"></i>
      <span translate>bookings.watchlist</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.WATCHLIST}})</span>
    </ng-template>
    <ng-template pTemplate="body">
      @defer{
      @if (data) {
        <app-booking-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          entityName="booking"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [message]="deleteBookingMsg"
          [header]="deleteVehicleHeaderMsg"
          (onDelete)="onBookingDelete($event)"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          (onChangeStatus)="onFilterValueChange([])"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddBooking()"
          [attributeData]="attributeData"
          (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateVehicle($event)"
          [rowItems]="rowItems"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          (setCurrentRowData)="setCurrentRowData($event)"
          [visiblePrintLabel]="visiblePrintLabel"
          [showBulkUpdateDialog]="showBulkUpdateDialog"
          [visible]="visible"
          (onToggleOverLay)="showBulkUpdateDialog = false; visiblePrintLabel = false"
          [bookingStatus]="bookingStatus"
          [isBookingHistory]="isBookingHistory"
          >
        </app-booking-table>
      }

    }@loading(){
 
    } @placeholder (minimum 1000ms){
      <app-table-skelton></app-table-skelton>
    } @error {
      <app-error-skelton></app-error-skelton>
    }
    </ng-template>
  </p-tabPanel>

</p-tabView>

<p-toast></p-toast>

<router-outlet></router-outlet>
<input hidden type="file" id="upload" (change)="onChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>