import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-child-seats',
  standalone: true,
  imports: [],
  templateUrl: './settings-child-seats.component.html',
  styleUrl: './settings-child-seats.component.scss'
})
export class SettingsChildSeatsComponent {

}
