import { NgClass } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { ToastModule } from "primeng/toast";
import { AppSettings } from "../../app.settings";
import { Country } from "../../models/country";
import { Language } from "../../models/language";
import { CommonBindingDataService } from "../../services/common-binding-data.service";
import { HeaderComponent } from "../header/header.component";
import { LoaderComponent } from "../loader/loader.component";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { FullScreenService } from "../../../dashboard/services/full-screen-service";
import { GoogleMapsService } from "../../services/google-map.services";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  standalone: true,
  imports: [
    LoaderComponent,
    ToastModule,
    NgClass,
    SidebarComponent,
    HeaderComponent,
    RouterOutlet,
  ],
})
export class LayoutComponent implements OnInit {
  isSidebarFull: boolean = false;
  metaData: Country;
  languageDetails: Language;
  isFullScreen: boolean = false;

  constructor(
    private cs: CommonBindingDataService,
    private fullScreenService: FullScreenService,
    private googleMapsService: GoogleMapsService
  ) { }

  ngOnInit() {
    this.googleMapsService.loadScript().then(() => {
      console.log('map loaded')

    });

    this.cs.getCountryDetails().subscribe((res: Country) => {
      localStorage.setItem(AppSettings.COUNTRY, JSON.stringify(res.data));
      this.metaData = res;
      this.cs.getLanguageDetails().subscribe((res: Language) => {
        localStorage.setItem(
          AppSettings.LANGUAGE,
          JSON.stringify(res.data)
        );
        this.languageDetails = res;
      });
    });
    
    this.fullScreenService.isFullScreen$.subscribe((isFullScreen) => {
      this.isFullScreen = isFullScreen;
    });
  }
}
