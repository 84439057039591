import { ChangeDetectorRef, Component, ElementRef, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserManagementService } from '../../services/user-management.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AppIcons } from '../../../shared/app.icons';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PermissionCardComponent } from '../access-components/permission-card/permission-card.component';
import { ConfigService } from '../../../shared/services/config.service';
import { AppSettings } from '../../../shared/app.settings';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrl: './add-role.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, DialogModule, ButtonModule, TranslateModule, FormsModule, ReactiveFormsModule, InputTextModule, InputSwitchModule,
    OverlayPanelModule, PermissionCardComponent]
})
export class AddRoleComponent implements OnInit {
  miIcons = AppIcons;
  @ViewChild('rightSection', { static: false }) rightSection!: ElementRef;
  @ViewChild(PermissionCardComponent) permissionCard: PermissionCardComponent;
  routePath: MenuItem[] = [];
  roleForm: FormGroup;
  isChecked;
  roleId: string;
  name;
  modules: string[] = [];

  selectedModule: string | null = null;
  permissionArray: any[] = [];
  country: Country;
  language: Language;

  constructor(private userManagementService: UserManagementService,
    private cs: CommonBindingDataService,
    private fb: FormBuilder, private renderer: Renderer2,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.roleId = this.route.snapshot.paramMap.get("id");
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.setRoutePath();
    this.getPermissionListAndInitializeRoleDetail();
    this.initializeForm();
  }

  initializeForm() {
    this.roleForm = this.fb.group({
      roleName: ['', Validators.required],
      description: [''],
    });
  }

  setRoutePath() {
    const addEditLabel = this.roleId ? this.cs.getLabel('manage_roles.lbl_edit_role') : this.cs.getLabel('manage_roles.lbl_add_new_role')
    this.routePath = [
      {
        label: addEditLabel,
        routerLink: this.roleId ? '../../../manage-roles' : '../../manage-roles',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
    ];
  }

  selectModule(module: string) {
    this.selectedModule = module;
  }

  getPermissionListAndInitializeRoleDetail() {
    this.userManagementService.getPermissionMenus().subscribe((res:any[]) => {
      this.permissionArray = res;
      this.modules = res.map(result => result.label);
      if (this.modules.length > 0) {
        this.selectedModule = this.modules[0];
      }
      if (this.roleId) {
        this.getRoleData();
      }
    });
  }

  onSave() {
    const selectedPagesData = this.extractPagesData(this.permissionCard.getSelectedPermission(this.permissionArray));

    if (this.roleForm.valid) {
      const formData = { ...this.roleForm.value };
      formData["forTenantCode"] = this.configService.getForTenantCode();
      formData["countryCode"] = this.country[0].countryCode;
      formData["roleCode"] = formData.roleName;
      formData["systemRole"] = true;
      formData["active"] = true;
      formData["pages"] = selectedPagesData;
      const RoleFormData = formData;
  
      if (this.roleId) {
        this.onEditRole(RoleFormData);
      } else {
        this.onAddRole(RoleFormData);
      }
    } else {
      console.error("Form is invalid");
    }
  }

  onAddRole(RoleFormData) {
    this.userManagementService.AddNewRole(RoleFormData).subscribe((res: any) => {
      this.showSuccessMessage('manage_roles.message.msg_role_added_successfully')
      this.router.navigate(["app/user-management/manage-roles"]);
    }, (error) => {
      const errorMessage = error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
      this.showErrorMessage(errorMessage);
    });
  }

  onEditRole(RoleFormData) {
    this.userManagementService.EditRole(this.roleId, RoleFormData).subscribe((res: any) => {
      this.showSuccessMessage('manage_roles.message.msg_role_update_suucessfully');
      this.router.navigate(["app/user-management/manage-roles"]);
    }, (error) => {
      const errorMessage = error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
      this.showErrorMessage(errorMessage);
    });
  }

  getRoleData() {
    this.userManagementService.getRoleDetails(this.roleId).subscribe((roleData: any) => {
        this.roleForm.patchValue({
            roleName: roleData.roleName,
            description: roleData.description
        });

        for (const permission of this.permissionArray) {
          if (permission.pages && permission.pages.length > 0) {
            this.markPagesAndSubPagesSelected(permission.pages, roleData.pages);
          }
          if (permission.subMenus && permission.subMenus.length > 0) {
              for (const submenu of permission.subMenus) {
                  if (submenu.pages && submenu.pages.length > 0) {
                      this.markPagesAndSubPagesSelected(submenu.pages, roleData.pages);
                  }
                  if (submenu.pages) {
                      for (const page of submenu.pages) {
                          if (page.subPages && page.subPages.length > 0) {
                              this.markPagesAndSubPagesSelected(page.subPages, roleData.pages);
                          }
                      }
                  }
              }
          }

          if (permission.pages.some(page => page.selected) ||
              (permission.subMenus && permission.subMenus.some(submenu => 
                  submenu.pages && submenu.pages.some(page => page.selected)
              ))) {
              permission.selected = true;
          }
        }

        this.cdr.detectChanges();
    });
  }

  markPagesAndSubPagesSelected(pages: any[], rolePages: any[]): void {
    for (const page of pages) {
        const pageExists = rolePages.some(rolePage =>
            rolePage.tenantMenuId === page.tenantMenuId && rolePage.tenantPageId === page.tenantPageId
        );
        if (pageExists) {
            page.selected = true;
        }
        if (page.subPages && page.subPages.length > 0) {
            this.markPagesAndSubPagesSelected(page.subPages, rolePages);
        }
    }
  }

  extractPagesData(selectedPermission: any[]): any[] {
    const pagesData = [];

    const extractPages = (pages: any[]) => {
        for (const page of pages) {
            if (page.tenantPageId) {
                pagesData.push({
                    tenantPageId: page.tenantPageId,
                    tenantMenuId: page.tenantMenuId
                });
            }
            if (page.subPages && page.subPages.length > 0) {
                extractPages(page.subPages);
            }
        }
    };

    for (const permission of selectedPermission) {
        if (permission.pages && permission.pages.length > 0) {
            extractPages(permission.pages);
        }
        if (permission.subMenus && permission.subMenus.length > 0) {
            for (const submenu of permission.subMenus) {
                if (submenu.pages && submenu.pages.length > 0) {
                    extractPages(submenu.pages);
                }
            }
        }
    }

    return pagesData;
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }
  

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  onCancel() {
    this.roleForm.reset();
    this.router.navigate(["app/user-management/manage-roles"]);
  }

}
