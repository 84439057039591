import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Countries {

  public static COUNTRY_CODE = [
    { label: 'Please Select', value: '', code: '' },
    { label: '+1', value: '+1', code: 'US' },
    { label: '+7', value: '+7', code: 'RU' },
    { label: '+20', value: '+20', code: 'EG' },
    { label: '+27', value: '+27', code: 'ZA' },
    { label: '+30', value: '+30', code: 'GR' },
    { label: '+31', value: '+31', code: 'NL' },
    { label: '+32', value: '+32', code: 'BE' },
    { label: '+33', value: '+33', code: 'FR' },
    { label: '+34', value: '+34', code: 'ES' },
    { label: '+36', value: '+36', code: 'HU' },
    { label: '+39', value: '+39', code: 'IT' },
    { label: '+40', value: '+40', code: 'RO' },
    { label: '+41', value: '+41', code: 'CH' },
    { label: '+43', value: '+43', code: 'AT' },
    { label: '+44', value: '+44', code: 'GB' },
    { label: '+45', value: '+45', code: 'DK' },
    { label: '+46', value: '+46', code: 'SE' },
    { label: '+47', value: '+47', code: 'NO' },
    { label: '+48', value: '+48', code: 'PL' },
    { label: '+49', value: '+49', code: 'DE' },
    { label: '+51', value: '+51', code: 'PE' },
    { label: '+52', value: '+52', code: 'MX' },
    { label: '+53', value: '+53', code: 'CU' },
    { label: '+54', value: '+54', code: 'AR' },
    { label: '+55', value: '+55', code: 'BR' },
    { label: '+56', value: '+56', code: 'CL' },
    { label: '+57', value: '+57', code: 'CO' },
    { label: '+58', value: '+58', code: 'VE' },
    { label: '+60', value: '+60', code: 'MY' },
    { label: '+61', value: '+61', code: 'AU' },
    { label: '+62', value: '+62', code: 'ID' },
    { label: '+63', value: '+63', code: 'PH' },
    { label: '+64', value: '+64', code: 'NZ' },
    { label: '+65', value: '+65', code: 'SG' },
    { label: '+66', value: '+66', code: 'TH' },
    { label: '+81', value: '+81', code: 'JP' },
    { label: '+82', value: '+82', code: 'KR' },
    { label: '+84', value: '+84', code: 'VN' },
    { label: '+86', value: '+86', code: 'CN' },
    { label: '+90', value: '+90', code: 'TR' },
    { label: '+91', value: '+91', code: 'IN' },
    { label: '+92', value: '+92', code: 'PK' },
    { label: '+93', value: '+93', code: 'AF' },
    { label: '+94', value: '+94', code: 'LK' },
    { label: '+95', value: '+95', code: 'MM' },
    { label: '+98', value: '+98', code: 'IR' },
    { label: '+212', value: '+212', code: 'MA' },
    { label: '+213', value: '+213', code: 'DZ' },
    { label: '+216', value: '+216', code: 'TN' },
    { label: '+218', value: '+218', code: 'LY' },
    { label: '+220', value: '+220', code: 'GM' },
    { label: '+221', value: '+221', code: 'SN' },
    { label: '+222', value: '+222', code: 'MR' },
    { label: '+223', value: '+223', code: 'ML' },
    { label: '+224', value: '+224', code: 'GN' },
    { label: '+225', value: '+225', code: 'CI' },
    { label: '+226', value: '+226', code: 'BF' },
    { label: '+227', value: '+227', code: 'NE' },
    { label: '+228', value: '+228', code: 'TG' },
    { label: '+229', value: '+229', code: 'BJ' },
    { label: '+230', value: '+230', code: 'MU' },
    { label: '+231', value: '+231', code: 'LR' },
    { label: '+232', value: '+232', code: 'SL' },
    { label: '+233', value: '+233', code: 'GH' },
    { label: '+234', value: '+234', code: 'NG' },
    { label: '+235', value: '+235', code: 'TD' },
    { label: '+236', value: '+236', code: 'CF' },
    { label: '+237', value: '+237', code: 'CM' },
    { label: '+238', value: '+238', code: 'CV' },
    { label: '+239', value: '+239', code: 'ST' },
    { label: '+240', value: '+240', code: 'GQ' },
    { label: '+241', value: '+241', code: 'GA' },
    { label: '+242', value: '+242', code: 'CG' },
    { label: '+243', value: '+243', code: 'CD' },
    { label: '+244', value: '+244', code: 'AO' },
    { label: '+245', value: '+245', code: 'GW' },
    { label: '+248', value: '+248', code: 'SC' },
    { label: '+249', value: '+249', code: 'SD' },
    { label: '+250', value: '+250', code: 'RW' },
    { label: '+251', value: '+251', code: 'ET' },
    { label: '+252', value: '+252', code: 'SO' },
    { label: '+253', value: '+253', code: 'DJ' },
    { label: '+254', value: '+254', code: 'KE' },
    { label: '+255', value: '+255', code: 'TZ' },
    { label: '+256', value: '+256', code: 'UG' },
    { label: '+257', value: '+257', code: 'BI' },
    { label: '+258', value: '+258', code: 'MZ' },
    { label: '+260', value: '+260', code: 'ZM' },
    { label: '+261', value: '+261', code: 'MG' },
    { label: '+262', value: '+262', code: 'RE' },
    { label: '+263', value: '+263', code: 'ZW' },
    { label: '+264', value: '+264', code: 'NA' },
    { label: '+265', value: '+265', code: 'MW' },
    { label: '+266', value: '+266', code: 'LS' },
    { label: '+267', value: '+267', code: 'BW' },
    { label: '+268', value: '+268', code: 'SZ' },
    { label: '+269', value: '+269', code: 'KM' },
    { label: '+290', value: '+290', code: 'SH' },
    { label: '+291', value: '+291', code: 'ER' },
    { label: '+297', value: '+297', code: 'AW' },
    { label: '+298', value: '+298', code: 'FO' },
    { label: '+299', value: '+299', code: 'GL' },
    { label: '+350', value: '+350', code: 'GI' },
    { label: '+351', value: '+351', code: 'PT' },
    { label: '+352', value: '+352', code: 'LU' },
    { label: '+353', value: '+353', code: 'IE' },
    { label: '+355', value: '+355', code: 'AL' },
    { label: '+356', value: '+356', code: 'MT' },
    { label: '+357', value: '+357', code: 'CY' },
    { label: '+358', value: '+358', code: 'FI' },
    { label: '+359', value: '+359', code: 'BG' },
  { label: '+370', value: '+370', code: 'LT' },
  { label: '+371', value: '+371', code: 'LV' },
  { label: '+372', value: '+372', code: 'EE' },
  { label: '+373', value: '+373', code: 'MD' },
  { label: '+374', value: '+374', code: 'AM' },
  { label: '+375', value: '+375', code: 'BY' },
  { label: '+376', value: '+376', code: 'AD' },
  { label: '+377', value: '+377', code: 'MC' },
  { label: '+378', value: '+378', code: 'SM' },
  { label: '+380', value: '+380', code: 'UA' },
  { label: '+381', value: '+381', code: 'RS' },
  { label: '+382', value: '+382', code: 'ME' },
  { label: '+385', value: '+385', code: 'HR' },
  { label: '+386', value: '+386', code: 'SI' },
  { label: '+387', value: '+387', code: 'BA' },
  { label: '+389', value: '+389', code: 'MK' },
  { label: '+420', value: '+420', code: 'CZ' },
  { label: '+421', value: '+421', code: 'SK' },
  { label: '+423', value: '+423', code: 'LI' },
  { label: '+500', value: '+500', code: 'FK' },
  { label: '+501', value: '+501', code: 'BZ' },
  { label: '+502', value: '+502', code: 'GT' },
  { label: '+503', value: '+503', code: 'SV' },
  { label: '+504', value: '+504', code: 'HN' },
  { label: '+505', value: '+505', code: 'NI' },
  { label: '+506', value: '+506', code: 'CR' },
  { label: '+507', value: '+507', code: 'PA' },
  { label: '+508', value: '+508', code: 'PM' },
  { label: '+509', value: '+509', code: 'HT' },
  { label: '+590', value: '+590', code: 'GP' },
  { label: '+591', value: '+591', code: 'BO' },
  { label: '+592', value: '+592', code: 'GY' },
  { label: '+593', value: '+593', code: 'EC' },
  { label: '+594', value: '+594', code: 'GF' },
  { label: '+595', value: '+595', code: 'PY' },
  { label: '+596', value: '+596', code: 'MQ' },
  { label: '+597', value: '+597', code: 'SR' },
  { label: '+598', value: '+598', code: 'UY' },
  { label: '+599', value: '+599', code: 'AN' },
  { label: '+670', value: '+670', code: 'TL' },
  { label: '+672', value: '+672', code: 'NF' },
  { label: '+673', value: '+673', code: 'BN' },
  { label: '+674', value: '+674', code: 'NR' },
  { label: '+675', value: '+675', code: 'PG' },
  { label: '+676', value: '+676', code: 'TO' },
  { label: '+677', value: '+677', code: 'SB' },
  { label: '+678', value: '+678', code: 'VU' },
  { label: '+679', value: '+679', code: 'FJ' },
  { label: '+680', value: '+680', code: 'PW' },
  { label: '+681', value: '+681', code: 'WF' },
  { label: '+682', value: '+682', code: 'CK' },
  { label: '+683', value: '+683', code: 'NU' },
  { label: '+685', value: '+685', code: 'WS' },
  { label: '+686', value: '+686', code: 'KI' },
  { label: '+687', value: '+687', code: 'NC' },
  { label: '+688', value: '+688', code: 'TV' },
  { label: '+689', value: '+689', code: 'PF' },
  { label: '+690', value: '+690', code: 'TK' },
  { label: '+691', value: '+691', code: 'FM' },
  { label: '+692', value: '+692', code: 'MH' },
  { label: '+850', value: '+850', code: 'KP' },
  { label: '+852', value: '+852', code: 'HK' },
  { label: '+853', value: '+853', code: 'MO' },
  { label: '+855', value: '+855', code: 'KH' },
  { label: '+856', value: '+856', code: 'LA' },
  { label: '+870', value: '+870', code: 'PN' },
  { label: '+880', value: '+880', code: 'BD' },
  { label: '+886', value: '+886', code: 'TW' },
  { label: '+960', value: '+960', code: 'MV' },
  { label: '+961', value: '+961', code: 'LB' },
  { label: '+962', value: '+962', code: 'JO' },
  { label: '+963', value: '+963', code: 'SY' },
  { label: '+964', value: '+964', code: 'IQ' },
  { label: '+965', value: '+965', code: 'KW' },
  { label: '+966', value: '+966', code: 'SA' },
  { label: '+967', value: '+967', code: 'YE' },
  { label: '+968', value: '+968', code: 'OM' },
  { label: '+971', value: '+971', code: 'AE' },
  { label: '+972', value: '+972', code: 'IL' },
  { label: '+973', value: '+973', code: 'BH' },
  { label: '+974', value: '+974', code: 'QA' },
  { label: '+975', value: '+975', code: 'BT' },
  { label: '+976', value: '+976', code: 'MN' },
  { label: '+977', value: '+977', code: 'NP' },
  { label: '+992', value: '+992', code: 'TJ' },
  { label: '+993', value: '+993', code: 'TM' },
  { label: '+994', value: '+994', code: 'AZ' },
  { label: '+995', value: '+995', code: 'GE' },
  { label: '+996', value: '+996', code: 'KG' },
  { label: '+998', value: '+998', code: 'UZ' }
  ];
}