import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { EntityService } from '../../services/entity.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { AppIcons } from '../../app.icons';
import { AppSettings } from '../../app.settings';
import { ConfigService } from '../../services/config.service';
import { CalendarModule } from 'primeng/calendar';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ShiftTemplateService } from '../../../shift-template/services/shift-template.service';
import { ListResponse } from 'app/modules/vehicles/modal/listResponse';
import { EntityList } from 'app/modules/vehicles/modal/entityModal';
import { Country } from '../../models/country';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import * as _ from 'lodash';

interface ReqBody {
  forTenantCode: any;
  entityCode: string;
  countryCode: any;
  languageCode: any;
  driverEntityIds: any;
  driverStatus?: any;
  driverGroupEntityId?: any;
}

@Component({
  selector: 'app-bulk-update-dialog',
  templateUrl: './bulk-update-dialog.component.html',
  styleUrls: ['./bulk-update-dialog.component.scss'],
  standalone: true,
  imports: [DialogModule, DividerModule, FormsModule, ReactiveFormsModule, DropdownModule, ButtonModule, TableModule, TranslateModule,
    CalendarModule, DatePipe, TitleCasePipe, AutoCompleteModule,
  ],
  providers: [DatePipe]
})
export class BulkUpdateDialogComponent {
  miIcons = AppIcons;
  @Input() entityNameForBulkUpdate;
  @Input() entityName;
  @Input() entityNameToDisplay;
  @Input() attributeData;
  @Input() filterFields = [];
  @Input() visibleBulkUpdate: boolean = true;
  @Input() showBulkUpdateDialog;
  @Input() bulkRowCount;
  @Input() selectedRecords;
  @Input() bulkUpdateHeader;
  @Input() reviewHeader;
  @Input() btnUpdate;
  @Input() isEntity;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onBulkDataUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() onStaticBulkDataUpdate: EventEmitter<any> = new EventEmitter();
  @Output() clearSelectedEvent: EventEmitter<any> = new EventEmitter();
  bulkUpdateMaxCount;
  hourFormat = AppSettings.HOUR_FORMAT;

  bulkUpdateForm: FormGroup;
  updateFormData;
  isVisible: boolean = false;
  show: boolean = false;
  position: string = "center";
  selectedStatus: any;
  selectedFields: any[] = [];
  HOUR_MINUTE_FORMAT: any = AppSettings.HOUR_MINUTE_FORMAT;
  replaceWithDropdown: any[] = [
    {
      label: this.cs.getLabel('lbl_replace_with'),
      value: AppSettings.REPLACE_WITH_DROPDOWN[0].value,
      disabled: AppSettings.REPLACE_WITH_DROPDOWN[0].disabled,
      icon: this.miIcons.REPEAT,
    }
  ];
  country: Country;
  driverGroupData: any = [];
  groupNames;
  selectedDriverGroup: any = [];
  visibleItems:any = [];
  items: any[] | undefined;
  selectedDriverGroupName: string = '';
  tableData = [];
  suggestions: any[] | undefined;
  entityDataForDriverGroup: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_DRIVER_GROUP,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.confService.getLoggedInTenantCode(),
    actionStatus: ''
  };

  selectedItems: string = this.replaceWithDropdown[0];
  lbl_update;
  constructor(private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private cs: CommonBindingDataService,
    private bulkUpdateService: EntityService,
    private messageService: MessageService,
    private shiftTemplateService: ShiftTemplateService,
    private confService: ConfigService) {
  }

  ngOnInit() {
    this.lbl_update = this.cs.getLabel('btn_update_vehicles');
    this.bulkUpdateForm = this.formBuilder.group({
      filterFieldControls: this.formBuilder.array([]),
    });
    this.bulkUpdateMaxCount = this.filterFields?.length;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.cd.detectChanges();
    this.addMultipleColumn('eve');
  }

  addMultipleColumn(event) {
    this.setSelectedFieldOptionAndDropdownDisabled();
    if (
      (this.bulkUpdateForm.get("filterFieldControls") as FormArray)?.length <= 5
    ) {
      (this.bulkUpdateForm.get("filterFieldControls") as FormArray).push(
        this.formBuilder.group({
          fieldName: ['', [Validators.required]],
          replaceWith: [this.selectedItems],
          fieldValue: ['', [Validators.required]],
          listOfStatus: [this.filterFields],
          presetValues: []
        })
      );
    }
  }

  setSelectedFieldOptionAndDropdownDisabled() {
    if (this.filterFields?.length > 0) {
      const fieldName = this.filterFields.find(element => element.attributeCode === this.selectedStatus);
      if (fieldName) {
        fieldName.isDisable = true;

        const obj = this.bulkUpdateForm.get("filterFieldControls") as FormArray;
        obj.controls.forEach((element: any) => {
          element.controls.fieldName.disable();
        });
      }
    }
  }

  setSelectedFieldOptionAndDropdownEnabled(removedField: any, idx: number) {
    const fieldName = this.filterFields.find(element => element.attributeCode === removedField.attributeCode);
    if (fieldName) {
      fieldName.isDisable = false;
    }
  }

  deleteMultipleColumn(fieldValue: any, idx: number) {
    if (this.bulkUpdateForm.get("filterFieldControls")) {
      if (fieldValue) {
        this.setSelectedFieldOptionAndDropdownEnabled(fieldValue, idx);
      }
      this.cd.detectChanges();
      (this.bulkUpdateForm.get("filterFieldControls") as FormArray).removeAt(idx);
      this.selectedStatus = null
      this.refreshDropdown();
    }
  }

  removeAllChange(event, index) {
    if (event?.value?.value === this.replaceWithDropdown[1]?.value) {
      (this.bulkUpdateForm?.get("filterFieldControls") as FormArray)?.controls[
        index
      ]
        ?.get("fieldValue")
        ?.disable();
    } else {
      (this.bulkUpdateForm?.get("filterFieldControls") as FormArray)?.controls[
        index
      ]
        ?.get("fieldValue")
        ?.enable();
    }
  }

  confirmPopupShow() {
    const obj = this.bulkUpdateForm.get("filterFieldControls") as FormArray;
    obj.controls.forEach((element: any) => {
      element.controls.fieldName.enable();
    });

    this.isVisible = true;
    this.visibleBulkUpdate = false;
    this.updateFormData = (
      this.bulkUpdateForm.get("filterFieldControls") as FormArray
    ).value;
  }

  confirmPopupHide() {
    this.isVisible = false;
  }

  onHide(event) {
    this.visibleBulkUpdate = false;
  }

  extractGroupNames(response: any): string[] {
    return response.data
      .filter((item: any) => item.values && item.values.group_name)
      .map((item: any) => item.values.group_name);
  }

  filterStatus(event, idx) {
    this.selectedStatus = event.value.attributeCode;
      const obj = this.bulkUpdateForm.get("filterFieldControls") as FormArray;
      const formGroupLength = obj.length;
      (obj.controls[formGroupLength - 1] as FormArray).controls['presetValues'].value = this.filterFields.find(element => element.attributeCode === this.selectedStatus).presetValues;
      if (this.bulkUpdateForm.get("filterFieldControls")) {
        const id = this.filterFields.find(element => element.value === obj.value[idx].fieldName.value);
        if (obj.value[idx].fieldName.value) {
          this.filterGroup(obj, idx);
        }
        this.refreshDropdown();
      }
      this.selectedFields?.push(this.selectedStatus);
  }

  filterGroup(obj, idx) {
    if (obj.value[idx].fieldName.value !== 'group') {
      this.replaceWithDropdown[1].disabled = true;
    } else {
      this.replaceWithDropdown[1].disabled = false;
    }
  }

  search(event: AutoCompleteCompleteEvent) {
    this.entityDataForDriverGroup.searchStr = event.query;
    this.searchEntity();
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.tableData = [];
    this.entityDataForDriverGroup.countryCode = country[0].countryCode;
    this.entityDataForDriverGroup.forTenantCode = this.confService.getForTenantCode();
    const isLoader = false;
    this.bulkUpdateService.searchEntity(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.entityDataForDriverGroup, isLoader).subscribe((res: ListResponse) => {
      const driverGroup = this.cs.getTableData(res);
      driverGroup.map(item => {
        item.name = item.group_name
      });
      this.suggestions = driverGroup;
    });
  }

  onSelectDriver(event) {
    this.bulkUpdateForm.value.fieldValue = event.id;
    this.selectedDriverGroupName = event.value.group_name;
  }

  displayDriverName(): string {
    const fieldControl = this.bulkUpdateForm.get('fieldValue');
    if (!fieldControl) {
      return this.selectedDriverGroupName;
    }
    const driverId = fieldControl.value;
    const selectedDriver = this.suggestions?.find(driverGroup => driverGroup.id === driverId);
    return selectedDriver ? selectedDriver.name : this.selectedDriverGroupName;
  }

  refreshDropdown() {
    this.filterFields = [...this.filterFields];
  }

  onFieldValueChange(event, idx) {
    const obj = this.bulkUpdateForm.get("filterFieldControls");
    obj.value[idx].fieldValue = event.value;
  }

  updateData() {
    if (this.isEntity) {
      this.saveData();
    } else {
      const obj = this.bulkUpdateForm.get("filterFieldControls");
      let entityIds = this.selectedRecords.map(ele => ele.id);
      let data = obj.value.map(ele => {
        return {
          attributeId: ele.fieldName.attributeId,
          attributeValue: ele.fieldValue
        }
      });
      this.isVisible = false;
      this.onStaticBulkDataUpdate.emit({entityIds: entityIds, data: data});
      this.clearSelectedEvent.emit();
    }
  }

  saveData() {
    const obj = this.bulkUpdateForm.get("filterFieldControls");
    const langCode = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const CountryCode = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    
    if (this.entityName === AppSettings.ENTITY_CODE.DRIVER) {
      const driverStatus = obj.value.map(ele => ele.fieldValue);
      const commonReqBody = {
        forTenantCode: this.confService.getLoggedInTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.DRIVER,
        countryCode: CountryCode[0].countryCode,
        languageCode: langCode[0].langCode,
        driverEntityIds: this.selectedRecords.map(ele => ele.id),
      };
  
      if (this.selectedFields.includes('driver_status')) {
        const status = driverStatus.find(status => typeof status === 'string');
        const reqBodyForStatus = {
          ...commonReqBody,
          driverStatus: status
        };
        this.bulkUpdateService.updateDriverStatus(reqBodyForStatus).subscribe(
          res => {
            this.onBulkDataUpdate.emit(true);
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.cs.getLabel('data_updated_successfully') });
          },
          err => {
            console.error('Error updating driver status:', err);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: err });
          }
        );
      }
  
      if (this.selectedFields.includes('driver_group_id')) {
        const group = driverStatus.find(status => status.group_name);
        const reqBodyForGroup = {
          ...commonReqBody,
          driverGroupEntityId: group?.id
        };
        this.bulkUpdateService.assignDriverGroup(AppSettings.ENTITY_CODE.DRIVER, reqBodyForGroup).subscribe(
          res => {
            this.onBulkDataUpdate.emit(true);
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.cs.getLabel('data_updated_successfully') });
          },
          err => {
            console.error('Error assigning driver group:', err);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: err });
          }
        );
      }
    } else {
      const reqBody = {
        forTenantCode: this.confService.getLoggedInTenantCode(),
        entityCode: this.attributeData.entityCode,
        countryCode: CountryCode[0].countryCode,
        languageCode: langCode[0].langCode,
        entityIds: this.selectedRecords.map(ele => ele.id),
        data: obj.value.map(ele => {
          return {
            attributeId: ele.fieldName.attributeId,
            attributeValue: ele.fieldValue
          };
        }),
        relationshipData: this.attributeData?.relations?.map(ele => {
          return {
            entityRelationshipConfigId: ele.entityRelationshipConfigId,
            otherEntityId: ele.otherEntityId,
          };
        })
      };
  
      this.bulkUpdateService.updateBulkData(reqBody).subscribe(
        res => {
          this.onBulkDataUpdate.emit(true);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.cs.getLabel('data_updated_successfully') });
        },
        err => {
          console.error('Error updating bulk data:', err);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: err });
        }
      );
    }
  }

  saveCustomEntityData() {
    const obj = this.bulkUpdateForm.get("filterFieldControls");
    const CountryCode = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const reqBody = {
      forTenantCode: this.confService.getLoggedInTenantCode(),
      countryCode: CountryCode[0].countryCode,
      shiftTemplateIds: this.selectedRecords.map(ele => ele.id),
      startTime: obj.value[0]?.fieldValue?.getTime(),
      endTime: obj.value[1]?.fieldValue?.getTime()
    };
    this.shiftTemplateService.bulkUpdateShiftTemplate(reqBody).subscribe(
      res => {
        this.onBulkDataUpdate.emit(true);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.cs.getLabel('data_updated_successfully') });
      },
      err => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err });
      });
  }

  get formArray() {
    return this.bulkUpdateForm.controls.filterFieldControls as FormArray;
  }

}
