import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppIcons } from '../../app.icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-no-result-found',
  standalone: true,
  imports: [TranslateModule,
    ButtonModule
  ],
  templateUrl: './no-result-found.component.html',
  styleUrl: './no-result-found.component.scss'
})
export class NoResultFoundComponent {
  miIcons = AppIcons;
}
