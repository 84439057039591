import { CommonModule, NgClass } from "@angular/common";
import { Component, Input, OnInit, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TableModule } from "primeng/table";
import { HideButtonComponent } from "../hide-button/hide-button.component";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-parking-queues",
  templateUrl: "./parking-queues.component.html",
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    TableModule,
    HideButtonComponent,
    CommonModule,
  ],
})
export class ParkingQueuesComponent implements OnInit {
  @Input() parkingQueueData: any;
  cardId: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.parking_queues");
  }
}
