<div class="w-full">
    <div class="flex flex-column h-27rem surface-200 p-2 justify-content-center align-items-center style-box">
      <div class="m-3">
        <div class="flex justify-content-center align-items-center">
          <span><i class="{{miIcons.ARCHIVED_SEARCH}} inline-block wh-80"></i></span>
        </div>
        <div class="no-result-div">
          <p translate>lbl_no_results_to_show</p>
        </div>
      </div>
      <div class="mb-4">
        <p class="my-0" translate>lbl_no_records_available1</p>
        <p class="flex justify-content-center" translate>lbl_no_records_available2</p> 
      </div>
      <div>
      </div>
    </div>
</div>
