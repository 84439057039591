@if(!isFullScreen){<p-breadcrumb [model]="routePath"></p-breadcrumb>}
<div class="flex flex-row">
  <div class="dropdown-menu bg-white">
    <span class="mi-lg mi-live_dashboard wh-18"></span>
    <p-dropdown
      [options]="liveDashboardMenu"
      [(ngModel)]="selectedMenuItem"
      optionLabel="label"
      (onChange)="onMenuItemChange($event.value)"
    ></p-dropdown>
  </div>
  <div class="flex flex-grow-1 justify-content-between bg-white">
    <div
      class="flex-initial flex align-items-center justify-content-center font-bold"
    >
      <p-tabMenu [model]="menuItemsStart"></p-tabMenu>
    </div>
    <div
      class="flex-initial flex align-items-center justify-content-center font-bold"
    >
      <p-menubar [model]="menuItemsEnd"></p-menubar>
    </div>
  </div>
</div>

<p-overlayPanel #overlayPanel>
  <div class="overlay-panel-content">
    <div class="flex justify-content-between align-items-center mb-4">
      <span class="font-16 font-semibold default-text-black-color"
        >Widgets(28)</span
      >
      <button
        class="border-none bg-white flex align-items-center cursor-pointer"
        title="Restore"
      >
        <span class="mr-2 font-14 font-normal default-text-blue-color"
          >Restore</span
        ><i
          class="mi-lg mi-refresh inline-block wh-20 bg-default-text-blue"
        ></i>
      </button>
    </div>

    <div class="flex align-items-center mt-2">
      <p-inputSwitch
        [(ngModel)]="reorganizeAutomatically"
        class="mr-2"
      ></p-inputSwitch>
      <span>Reorganize Automatically</span>
      <i class="pi pi-info-circle ml-2" title="Icon"></i>
    </div>

    <!-- <div class="checkbox-group mt-3">
      <div class="checkbox-title mb-2">
        <strong>Vehicles List</strong>
      </div>

      <div class="flex flex-column">
        <p-checkbox [(ngModel)]="checkbox1" label="Vehicle Status"></p-checkbox>
        <p-checkbox [(ngModel)]="checkbox2" label="Driver Status"></p-checkbox>
      </div>
    </div> -->
  </div>
</p-overlayPanel>
