import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../shared/app.icons';
import { AppSettings } from '../shared/app.settings';
import { MITableComponent } from '../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../shared/components/module-header/module-header.component';
import { Country } from '../shared/models/country';
import { Language } from '../shared/models/language';
import { CommonBindingDataService } from '../shared/services/common-binding-data.service';
import { Column } from '../vehicles/modal/attributeModal';
import { EntityCount, EntityCountsResponse, EntityList } from '../vehicles/modal/entityModal';
import { ListResponse, Values } from '../vehicles/modal/listResponse';
import { EntityService } from '../shared/services/entity.service';
import { ConfigService } from '../shared/services/config.service';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, MITableComponent, AsyncPipe],
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss'
})

export class EventsComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  filterList;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "2",
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  deleteEventMsg: string;
  deleteVehicleHeaderMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  filterFieldsArray: any[];
  attributeData: any;
  data;
  totalRecords: number = 0;
  btnUpdate: any;
  deletePassengerMsg: string;
  bulkSelectionsItems: MenuItem[] | undefined;
  bulkDeleteLabel: string = this.cs.getLabel('events.delete');
  rowItems: MenuItem[] | undefined;
  currentRow: any;
  showImportExportBtn: boolean = false;
  fieldForState: any;

  constructor(private entityService: EntityService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService) { }

  ngOnInit() {
    this.routePath = [
      { label: "Events", routerLink: 'events/list' },
    ];
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.setBulkSelectionOptions();
    this.setRowSelectionOptions();
    this.getAttributeData();
    this.getData();
    this.setHeadersAndLabels();
  }

  setHeadersAndLabels() {
    this.btnUpdate = this.cs.getLabel('events.sidebar_lbl_events');
    this.btnLabel = this.cs.getLabel('events.add_event');
    this.deleteEventMsg = this.cs.getLabel('events.message.confirm_delete_msg');
    this.deleteVehicleHeaderMsg = this.cs.getLabel('events.message.confirm_delete_header');
    this.btnLabel = this.cs.getLabel('add_event');
  }

  setBulkSelectionOptions() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  setRowSelectionOptions() {
    this.rowItems = [
      {
        label: this.cs.getLabel('events.lbl_edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: (event) => {
          this.router.navigate(['app/events/edit/' + this.currentRow.id]);
        }
      }
    ];
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
    this.cd.detectChanges();
  }

  getAttributeData() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.EVENTS, this.country[0]?.tenantId, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.attributeData = res;
        this.isLoading = false;
        this.filterFieldsArray = res.tabs[0].groups[0].fields;
        this.filterFieldsArray.forEach(element => {
          switch (element.attributeCode) {
            case 'event_status':
              element.attributeValue = 'Status';
              element.isDisable = false;
              break;
          }
        });
      }
    });
  }


  getTableView() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.EVENTS, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.allColumns = this.cs.getColumns(this.data);
        if (this.data && this.data.tabs && this.data.tabs[0] && this.data.tabs[0].groups && this.data.tabs[0].groups[0] && this.data.tabs[0].groups[0].fields) {
          this.fieldForState = _.cloneDeep(this.data.tabs[0].groups[0].fields[this.data.tabs[0].groups[0].fields.length - 1]);
        }
        this.fieldForState.attributeCode = 'State';
        this.allColumns.push(this.fieldForState);
        this.searchEntity();
        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            "event" + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });
        this.filterAvailableColumns = _.clone(this.allColumns);
      }
    });
  }

  getFilterView() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.EVENTS, this.country[0]?.tenantId, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
        this.filterList = this.cs.getColumns(filterResponse);
        this.filterList = _.sortBy(this.filterList, 'attributeIndex');
      }
    })
  }


  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount()
    })
  }


  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.EVENTS, this.entityData).subscribe({
      next: (res: any) => {
        this.listColumns = res?.data[0]?.values;
        this.setStateForEvent(res);
        this.pagination = res?.pagination;
        this.totalRecords = res?.count;
        this.tableData = [];
        this.tableData = this.cs.getTableData(res);
        this.tableData.forEach(rowData => {
          if (rowData?.event_image && (rowData?.event_image[0])) {
            this.getFile(rowData)
          }
        });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }

    })
  }

  getFile(rowData) {
    this.entityService.getFile(rowData.event_image[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'entity');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        rowData.profileImage =  base64data;
      }
    })
  }

  setStateForEvent(res) {
    const currentTime = new Date().getTime();
    res?.data.forEach((element: any) => {
      if (element.values.event_start_date < currentTime && element.values.event_end_date > currentTime) {
        element.values.State = AppSettings.EVENT_ATTRIBUTES.STATE.ONGOING;
      } else if (element.values.event_start_date > currentTime) {
        element.values.State = AppSettings.EVENT_ATTRIBUTES.STATE.UPCOMING;
      } else if (element.values.event_start_date < currentTime && element.values.event_end_date < currentTime) {
        element.values.State = AppSettings.EVENT_ATTRIBUTES.STATE.COMPLETED;
      }
    });
  }

  onAddEvent() {
    this.router.navigate(['app/events/add']);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.EVENTS,
      entityIds: event,
      countryCode: this.country[0].countryCode,
    };
    this.entityService.deleteEntityActionStatus(requestBody).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('events.lbl_event_deleted_successfully'), });
      this.searchEntity();
    })
  }

  onEventStatusChangeEvent(event) {
      const data = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        eventEntityId: event.id,
        eventStatus: event.checked ? AppSettings.EVENT_STATUS.ACTIVE : AppSettings.EVENT_STATUS.INACTIVE
      }
      this.entityService.updateEventStatus(AppSettings.ENTITY_CODE.EVENTS, data).subscribe({
        next: (result) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('events.lbl_event_status_updated_successfully') });
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
      });
  }

  onUpdateEvent(eventId) {
    this.router.navigate(['app/events/add/' + eventId,]);
  }

  onRowSelect(event) {
    if (event.type === 'row') {
    this.router.navigate(['app/events/details/' + event.data.id,]);
    }
  }

  onFilterValueChange(event) {
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(event)) {
      const attributeValue = value;
      if (attributeValue) {
        if (key === AppSettings.DATE_ATTRIBUTE_IDS.EVENT_START_DATE) {
          const date = new Date(value.toString());
          const timestamp = date.getTime();
          this.entityData.filters.push({
            attributeId: this.cs.getAttributeIdForFilterFields(this.filterList, key),
            attributeValue: timestamp
          });
        } else {
          this.entityData.filters.push({
            attributeId: this.cs.getAttributeIdForFilterFields(this.filterList, key),
            attributeValue
          });
        }
      }
    }
    this.entityData.offset = event.first;
    this.searchEntity();
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.EVENTS,
      countryCode: country[0].countryCode,
    };

    this.entityService.getEntityCount(AppSettings.ENTITY_CODE.EVENTS, entityCountPayload).subscribe((res: EntityCountsResponse) => {
      this.entityCount = res;
    })
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted =  AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      case 1:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE;
        this.searchEntity();
        break;
      default:
        break;
    }
  }

  onBulkDataUpdateEvent(event) {
    this.searchEntity();
  }

  onChange(event) {

  }
}
