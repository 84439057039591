@for (permission of permissionArray; track permission; let i = $index) {
    <div class="card modules-card p-0 mb-3 border-0" #permissionCard>
        <div class="parent-sidebar p-2">
            <div class="grid">
                <div class="col-4 flex align-items-center pb-0">
                    <p-inputSwitch class="mr-2 ml-1 mt-1" id="active{{i}}" [(ngModel)]="permission.selected" (ngModelChange)="updatePermissionState(permission, { type: 'click' })"></p-inputSwitch>
                    <label for="active{{i}}" class="font-14 uppercase font-bold" translate>{{permission.label}}</label>
                </div>
            </div>
        </div>
        <div class="border-bottom"></div>
        <div class="child-sidebar p-2">
            @if (permission.pages) {
                @for (page of permission.pages; track page) {
                    <div class="col-12 p-0 flex">
                        <div class="col-4 flex align-items-center custom-checkbox pl-0">
                            <input type="checkbox" [id]="page?.pageId" class="mr-2 cursor-pointer" [(ngModel)]="page.selected"
                                name="access" [value]="page?.pageId" (change)="updatePermissionState(permission, $event)">
                            <label [for]="page?.pageId" class="font-14 cursor-pointer" translate>{{ page.label }}</label>
                        </div>
                        @if (page.subPages && page.subPages.length > 0) {
                            <div class="col-8 pl-0" >
                                <span class="cursor-pointer" (click)="overlayPanelPagesSubPage.toggle($event)">
                                    @if (!page.selected) {
                                        <span>Please select</span>
                                    }
                                    @if (page.selected) {
                                        @let selectedCount = countSelectedSubPages(page.subPages).length;
                                        @let maxDisplayCount = 4;
                            
                                        @if (selectedCount > 0) {
                                            @for (option of countSelectedSubPages(page.subPages).slice(0, maxDisplayCount); track option; let j = $index) {
                                                @if(option.selected) {
                                                    <span class="inline-block">{{ option.label }}</span>
                                                    @if (j < maxDisplayCount - 1 && j < selectedCount - 1) {
                                                        <span>, </span>
                                                    }
                                                }
                                            }
                                            @if (selectedCount > maxDisplayCount) {
                                                <span> & {{ selectedCount - maxDisplayCount }} More</span>
                                            }
                                        }
                                    }
                                    <span class="vertical-align-middle"><i class="{{miIcons.CHEVRON_BIG_DOWN}} inline-block relative mr-2 text-xs  wh-16 ml-1 chevron-big-down"></i></span>
                                </span>
                            </div>
                            <p-overlayPanel class="p-1" #overlayPanelPagesSubPage [style]="{ width: '225px'}">
                                <div class="overlay-panel-content -ml-1">
                                    <div class="flex flex-column">
                                        @for (pageOptions of page.subPages; track pageOptions) {
                                            <div class="flex flex-row align-items-center mb-2">
                                                <input type="checkbox" [id]="pageOptions.pageId" class="mr-2 cursor-pointer" [(ngModel)]="pageOptions.selected" name="access"
                                                    [value]="pageOptions.pageId" (change)="updatePermissionState(permission, $event)">
                                                <label [for]="pageOptions.pageId" class="font-12 cursor-pointer" translate>{{ pageOptions.label }}</label>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </p-overlayPanel>
                        }
                    </div>
                }
            }
            @if (permission.subMenus) {
                <div class="grid">
                    @for (sub of permission.subMenus; track sub; let first = $first) {
                        <div class="col-12 p-0 flex" [ngClass]="{'border-top border-100 mt-2': !first}">
                            <div class="col-4 flex align-items-center font-bold capitalize ml-1"  >
                                {{ sub.label }}
                            </div>
                        </div>
                       
                        @for (screen of sub.pages; track screen) {
                            <div class="col-12 p-0 flex">
                                <div class="col-4 flex align-items-center custom-checkbox">
                                    <input type="checkbox" [id]="screen?.pageId" class="mr-2 cursor-pointer" [(ngModel)]="screen.selected" name="access" [value]="screen?.pageId"
                                     (change)="updatePermissionState(permission, $event)">
                                    <label [for]="screen?.pageId" class="font-14 cursor-pointer" translate>{{ screen.label }}</label>
                                </div>
                                @if (screen.subPages && screen.subPages.length > 0) {
                                    <div class="col-8 pl-0">
                                        <span class="cursor-pointer" (click)="opSubMenusSubPage.toggle($event)">
                                            @if (!screen.selected) {
                                                <span>Please select</span>
                                            }
                                            @if (screen.selected) {
                                                @let selectedCount = countSelectedSubPages(screen.subPages).length;
                                                @let maxDisplayCount = 4;
                                    
                                                @if (selectedCount > 0) {
                                                    @for (option of countSelectedSubPages(screen.subPages).slice(0, maxDisplayCount); track option; let j = $index) {
                                                        @if(option.selected) {
                                                            <span class="inline-block">{{ option.label }}</span>
                                                            @if (j < maxDisplayCount - 1 && j < selectedCount - 1) {
                                                                <span>, </span>
                                                            }
                                                        }
                                                    }
                                                    @if (selectedCount > maxDisplayCount) {
                                                        <span> & {{ selectedCount - maxDisplayCount }} More</span>
                                                    }
                                                }
                                            }
                                            <span class="vertical-align-middle"><i class="{{miIcons.CHEVRON_BIG_DOWN}} inline-block relative mr-2 text-xs  wh-16 ml-1 chevron-big-down"></i></span>
                                        </span>
                                    </div>
                                    <p-overlayPanel class="p-1" #opSubMenusSubPage [style]="{ width: '225px'}">
                                        <div class="overlay-panel-content -ml-1">
                                            <div class="flex flex-column">
                                                @for (pageOptions of screen.subPages; track pageOptions) {
                                                    <div class="flex flex-row align-items-center mb-2">
                                                        <input type="checkbox" [id]="pageOptions.pageId" class="mr-2 cursor-pointer" [(ngModel)]="pageOptions.selected" name="access"
                                                            [value]="pageOptions.pageId" (change)="updatePermissionState(permission, $event)">
                                                        <label [for]="pageOptions.pageId" class="font-12 cursor-pointer" translate>{{ pageOptions.label }}</label>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </p-overlayPanel>
                                }
                            </div>
                        }
                    }
                </div>
            }
        </div>
    </div>
}
