import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { Status } from 'app/modules/shared/models/status';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ShiftService } from 'app/modules/shift-management/services/shift.service';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-shift-details',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, ButtonModule, BreadcrumbModule,
    DropdownModule, DatePipe, CommonModule
  ],
  templateUrl: './shift-details.component.html',
  styleUrl: './shift-details.component.scss',
  providers: [DatePipe]
})
export class ShiftDetailsComponent {
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST_FOR_ORGANIZATION;
  tenantId: any;
  country: Country;
  language: Language;
  routePath: MenuItem[] = [];
  dataForStatus: Array<Status> = [];
  imgFileId: any;
  entityForStatus: any;
  logoImage: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  userDetails: any;
  tenantDetails: any;
  showStatus: boolean = false;
  moduleList: any[] = [];
  moduleListValues = [];

  activeIndex: number = 0;
  tenantCode: any;
  editBtnLabel: any;
  groups: any[] = [];
  selectedGroup: any;
  shiftId: any;
  shiftDetails: any;
  DRIVER_TYPE = AppSettings.DRIVER_TYPE;
  driverList: any[] = [];
  numberOfDays: any;

  constructor(
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private router: Router,
    private messageService: MessageService,
    private shiftService: ShiftService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.shiftId = this.route.snapshot.paramMap.get('id');
    if (this.shiftId) {
      this.getShiftDetails();
    }
    this.setCountryAndLanguage();
    this.setRoutePath();
    this.editBtnLabel = this.cs.getLabel('shift.lbl_edit');
  }

  getShiftDetails() {
    this.shiftService.getShiftDetails(this.shiftId).subscribe(result => {
      this.shiftDetails = result;
      if (this.shiftDetails?.assignedEntity === AppSettings.DRIVER_TYPE.DRIVERS) {
        if (this.shiftDetails.shiftDriverList.length > 0) {
          this.getDriverList(this.shiftDetails.shiftDriverList);
        }
      } else {
        if (this.shiftDetails.shiftGroupList.length > 0) {
          this.getGroupList(this.shiftDetails.shiftGroupList);
        }
      }
    })
  }

  getGroupList(groupList: any[]) {
    groupList.forEach(groupId => {
      this.entityService.getEntity(groupId, AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe((result: any) => {
        this.setGroupList(result, groupId);
      })
    });
  }

  setGroupList(result, groupId) {
    let group = {
      groupName: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'group_name')?.attributeValue,
      color: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'color')?.attributeValue,
      groupStatus: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'group_status')?.attributeValue,
      createdOn: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'created_on')?.attributeValue,
      createdBy: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'created_by')?.attributeValue,
      totalDrivers: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'assigned_drivers')?.attributeValue?.length,
      driverIds: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'assigned_drivers')?.attributeValue,
      groupId: groupId
    }
    this.groups.push(group);

    this.getDriverList(this.groups[0].driverIds);
    this.selectedGroup = this.groups[0];
  }

  getDriverList(driverList: any[]) {
    driverList.forEach(driverId => {
      this.entityService.getEntity(driverId, AppSettings.ENTITY_CODE.DRIVER).subscribe((result: any) => {
        this.setDriverList(result);
      })
    });
  }

  setDriverList(result) {
    let driver = {
      driverName: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'first_name')?.attributeValue + ' ' +
                  result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'last_name')?.attributeValue,
      uniqueId: result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'unique_id')?.attributeValue,
      profilePhoto: '',
    }
    let profilePhotoId: any;
    let driverPhotoFileIdArray = result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'driver_profile_image')?.attributeValue;
    if (driverPhotoFileIdArray && driverPhotoFileIdArray.length > 0) {
      profilePhotoId = driverPhotoFileIdArray[0];
      this.getImage(profilePhotoId, driver);
    }
    this.driverList.push(driver);
  }

  onGroupSelect(event) {
    if (this.selectedGroup.driverIds.length > 0) {
      this.driverList = [];
      this.getDriverList(this.selectedGroup.driverIds);
    }
  }

  getUserDetails(userId) {
    this.entityService.getUserDetails(userId, this.configService.getForTenantCode()).subscribe(result => {
      this.userDetails = result;
    })
  }

  getImage(fileId, driver) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'driver');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
       driver.profilePhoto = base64data;
      }
    })
 }

 edit() {
   this.router.navigate([`/app/manage-shift/edit-shift/${this.shiftId}`])
 }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('shift.shift_details'),
        routerLink: '/app/manage-shift',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label:  this.cs.getLabel('shift.manage_shift'),
        styleClass: 'breadcrumb-child forward-slash details-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
