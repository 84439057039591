import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { Language } from 'app/modules/shared/models/language';
import { AppSettings } from 'app/modules/shared/app.settings';

@Component({
  selector: 'app-mi-input-width-height-length',
  templateUrl: './mi-input-width-height-length.component.html',
  styleUrls: ['./mi-input-width-height-length.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputNumberModule]
})

export class MiInputWidthHeightLengthComponent implements OnInit{
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  unit: string;
  language: Language;
  languageCode: string;

  constructor(private formGroupDirective: FormGroupDirective,
    public controlContainer: ControlContainer,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.languageCode = this.language[0].langCode;
  
  }

  ngOnInit(): void {
    if (this.language && this.languageCode) { 
      if (this.field) { 
        const tempUnit = JSON.parse(this.field?.imperialUnit);
        this.languageCode = this.language[0].langCode;
        this.unit = tempUnit[this.languageCode];
      }
    }
  }
  
}
