import { Injectable } from "@angular/core";
import { AppSettings } from "app/modules/shared/app.settings";
import { ConfigService } from "app/modules/shared/services/config.service";
import { RestApiService } from "app/modules/shared/services/rest-api.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class DashboardService {
    constructor(private restApiService: RestApiService, private confService: ConfigService) { }

    getTotalVehicleData(data): Observable<any> {
        return this.restApiService.post('Total Vehicle Data', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/entities/counts`, data, 'page-center');
    }

    getVehicleBodyTypeData(data): Observable<any> {
        return this.restApiService.post('vehicle body type', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/attributes/preset-values/search`, data, 'page-center');
    }

    getDriverOnDutyData(data): Observable<any> {
        return this.restApiService.post('Driver On Duty', `${this.confService.getLoggedInTenantCode()}/api/v1/leaves/status/count`, data, 'page-center');
    }

    getVehicleModuleData(data): Observable<any> {
        return this.restApiService.post('Vehicle Module Data', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/attributes/preset-values/search`, data, 'page-center');
    }

    multipleApiData(data): Observable<any> {
        return this.restApiService.post('multiple API call', `${this.confService.getLoggedInTenantCode()}/api/v1/multi-api-call`, data, 'page-center');
    }
}