<p-breadcrumb [model]="routePath"></p-breadcrumb>
<app-module-header [label]="btnLabel" [showImportExportBtn]="showImportExportBtn" (onBtnClick)="onAddEvent()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>

<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg details mr-2"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{entityCount?.counts?.ALL}})</span>
    </ng-template>
    <ng-template pTemplate="body">
        @if (data){
            <app-mi-table
              [collectionOfColumns]="allColumns"
              [attributeLabels]="filterAttributeLabels"
              [data]="tableData"
              entityName="event"
              [isLoading]="isLoading"
              (onPageChange)="onPageChange($event)"
              (itemPerPageChange)="itemPerPageChange($event)"
              [totalRecords]="totalRecords"
              [message]="deleteEventMsg"
              [header]="deleteVehicleHeaderMsg"
              (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
              (onRowSelection)="onRowSelect($event)"
              [filterList]="filterList"
              (onValueChange)="onFilterValueChange($event)"
              [filterFields]="filterFieldsArray"
              [label]="btnLabel"
              (onBtnClick)="onAddEvent()"
              [bulkDeleteLabel]="bulkDeleteLabel"
              [attributeData]="attributeData"
              (onSearchValueChange)="onSearchValueChanges($event)"
              (onUpdate)="onUpdateEvent($event)"
              [bulkSelectionsItems] = "bulkSelectionsItems"
              [btnUpdate]="btnUpdate"
              [rowItems]="rowItems"
              (setCurrentRowData)="setCurrentRowData($event)"
              (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
              (onEventStatusChangeEvent)="onEventStatusChangeEvent($event)"
              >
            </app-mi-table>
        }
    </ng-template>
  </p-tabPanel>

</p-tabView>

<input hidden type="file" id="upload" (change)="onChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>
