@if (allColumns) {
    <app-mi-table
        [collectionOfColumns]="allColumns"
        [attributeLabels]="filterAttributeLabels"
        [data]="tableData"
        entityName="vehicle_assignment_history"
        [isLoading]="isLoading"
        [totalRecords]="totalRecords"
        [filterList]="filterList"
        (onSearchValueChange)="onSearchValueChanges($event)"
        (onPageChange)="onPageChange($event)"
        (itemPerPageChange)="itemPerPageChange($event)">
    </app-mi-table>
}