@if (bookingDetailItem.id) {
  <p-breadcrumb [model]="routePath" (click)="onCloseBookingDetailView(false)"></p-breadcrumb>

  <div class="flex flex-row absolute top-14 left-240 z-1">
    <div class="flex flex-row light-blue-container align-items-center">
      <div class="color-bar green mr-2"></div>
      <div class="font-16 font-bold default-text-black-color mr-2">{{bookingDetailItem.departure_time_and_date | date: 'hh:mm, dd MMM yyyy'}}</div>
      <div class="font-14 font-semibold default-print-label-color mr-2">{{bookingDetailItem.id}}</div>
      <div class="font-13 font-semibold default-text-black-color mr-2">
        {{(bookingDetailItem?.number_of_passenger ? bookingDetailItem?.number_of_passenger : null) + bookingDetailItem?.passengers?.length}}
         <span translate>dispatch.passenger</span>
      </div>
      <div class="font-13 font-semibold default-text-black-color type-of-pass mr-2">{{bookingDetailItem?.passengers[0]?.passenger_type}}</div>
      <div><i class="mi-lg mi-watchlist inline-block vertical-align-text-bottom cursor-pointer wh-20"></i></div>
    </div>
    <div class="flex flex-row align-items-center ml-2">
      <div class="mr-2 flight-container">
        @if (bookingDetailItem?.transportation_type === TRANSPORTATION_TYPE.AIRPORT) {
          <i class="mi-lg mi-airport inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
        } @else {
          @switch (bookingDetailItem?.transportation_mode) {
            @case (TRANSPORTATION_MODE.CAR) {
            <i class="{{miIcons.BOOKING_CAR}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
            }
  
            @case (TRANSPORTATION_MODE.SHUTTLE) {
            <i class="{{miIcons.BOOKING_SHUTTLE}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
            }
  
            @case (TRANSPORTATION_MODE.BUS) {
            <i class="{{miIcons.BOOKING_BUS}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
            }
          }
        }
      </div>
      <div class="mr-2">
        @if ((bookingDetailItem?.number_of_passenger ? bookingDetailItem?.number_of_passenger : null) + bookingDetailItem?.passengers?.length > 1) {
          <i class="{{miIcons.BOOKING_SHARED_RIDE}} inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
        } @else {
          <i class="{{miIcons.BOOKING_PRIVATE_RIDE}} inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
        }
        <span class="default-separator-color font-15">|</span>
      </div>
      @if (bookingDetailItem.child_seat > 0) {
        <div class="mr-2">
          <span class="font-16 font-bold text-dark-green">{{bookingDetailItem.child_seat}}</span>
          <span class="font-14 font-semibold text-dark-green" translate>dispatch.child</span>
          <span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (dispatchBookingDetails.wheelchair > 0) {
        <div class="mr-2">
          <span class="font-16 font-bold text-dark-green">{{dispatchBookingDetails.wheelchair}}</span>
          <span class="font-14 font-semibold text-dark-green" translate>dispatch.wheelChair</span>
          <span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (bookingDetailItem.bags > 0) {
        <div class="mr-2">
          <span class="font-16 font-bold text-dark-green">{{bookingDetailItem.bags}}</span>
          <span class="font-14 font-semibold text-dark-green ml-1" translate>dispatch.bags</span>
          <span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (dispatchBookingDetails.additional_vehicle_requirement) {
        <div>
          <i class="mi-lg mi-stepper_color mi-additional_vehicle inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
          <span class="font-18 font-semibold default-text-black-color ml-1">{{dispatchBookingDetails.additional_vehicle_requirement}}</span
          ><span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (bookingDetailItem.booking_note) {
        <div>
          <span #mydiv class="relative white-space-nowrap pl-1">
            <i class="mi-lg bg-orange mi-instructions mt-2 inline-block cursor-pointer wh-20" [appendTo]="mydiv"
              [pTooltip]="bookingDetailItem.booking_note" tooltipPosition="right" tooltipStyleClass="tooltip-container"
            ></i>
          </span>
        </div>
      }
    </div>
  </div>
}

<div class="flex flex-wrap gap-2 justify-content-between">
  <p-button
    (click)="showLeftBookingsDialog('left')"
    icon="mi-lg chevron-duo-right wh-30 mi-stepper_color inline-block"
    styleClass="toggle-btn border-radius-right bg-white absolute top-59 left-90 z-1"
  ></p-button>
  <p-button
    (click)="showRightDriversDialog('right')"
    icon="mi-lg chevron-duo-left wh-30 mi-stepper_color inline-block"
    styleClass="toggle-btn border-radius-left bg-white absolute right-0 top-59 z-1"
  ></p-button>
</div>
<!-- bookings -->
<p-sidebar
  [(visible)]="leftBookingsSectionVisible"
  [position]="leftDialogPosition"
>
  <ng-template pTemplate="header">
    @if(!showBookingDetails){
      <app-dialog-header class="flex-grow-1" [headerLabel]="bookingHeader" [position]="position" [moduleName]="'booking'" [view]="'BOOKING_FILTER_ON_DISPATCH_VIEW'" 
        (onValueChange)="onBookingFilterValueChange($event)">
      </app-dialog-header>
    }
    @if(showBookingDetails){
      <app-booking-list-card [forDetail]="true" [bookingListItem]="bookingDetailItem" (closeBtnClicked)="onCloseBookingDetailView($event)" class="flex-grow-1 bg-detail" style="margin: -14px;"></app-booking-list-card>
    }
  </ng-template>
  <ng-template pTemplate="content" >
    @if(!showBookingDetails){<app-booking-section (showBookingDetails)="onShowBookingDetail($event)"></app-booking-section>}
    @if(showBookingDetails){<app-dispatch-booking-details [bookingDetailItem]="bookingDetailItem" [dispatchBookingDetails]="dispatchBookingDetails"></app-dispatch-booking-details>}
  </ng-template>
</p-sidebar>
<!-- drivers -->
<p-sidebar
  [(visible)]="rightDriversSectionVisible"
  [position]="rightDialogPosition"
>
  <ng-template pTemplate="header">
    @if(!showDriverDetails){
      <app-dialog-header class="flex-grow-1" [headerLabel]="assignDriverHeader" [position]="position" [moduleName]="'driver'" [view]="'DRIVER_FILTER_ON_DISPATCH_VIEW'" 
        (onValueChange)="onDriverFilterValueChange($event)">
      </app-dialog-header>
    }
    @if(showDriverDetails){
      <app-dialog-header class="flex-grow-1 p-0 -ml-3 -mr-3 -mt-3" [hideSearchFilters]="true" [forDetail]="true" [driverDetail]="driverDetailItem" (closeBtnClicked)="onCloseDriverDetailView($event)"
       [headerLabel]="assignDriverHeader"></app-dialog-header>
    }
  </ng-template>
  <ng-template pTemplate="content">
    @if(!showDriverDetails){<app-assign-driver (showDriversDetails)="onShowDriverDetail($event)" [bookingDetailItem]="bookingDetailItem"></app-assign-driver>}
    @if(showDriverDetails){<app-driver-details [driverDetailItem]="driverDetailItem"></app-driver-details>}
  </ng-template>
</p-sidebar>

<app-dispatch-map [geoLocationsData]="geoLocationsData"></app-dispatch-map>
