import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { entityResponse } from '../../../vehicles/modal/attributeModal';
import { AssignVehicleToBookingPayload, GetAttributes } from '../../modal/bookingModal';
import { BookingService } from '../../services/booking.service';
import { AssignVehicleFromBookingsComponent } from '../ui-components/assign-vehicle-from-bookings/assign-vehicle-from-bookings.component';
import { BookingFormComponent } from '../ui-components/booking-form/booking-form.component';
import { SelectPassengersComponent } from './select-passengers/select-passengers.component';
@Component({
  selector: 'app-add-booking',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, RouterModule, BreadcrumbModule, TabViewModule, DialogModule, MiFormComponent, ButtonModule, TranslateModule, SelectPassengersComponent, BookingFormComponent, AssignVehicleFromBookingsComponent],
  templateUrl: './add-booking.component.html',
  styleUrl: './add-booking.component.scss'
})
export class AddBookingComponent {
  @ViewChild(BookingFormComponent) miFormComponent: BookingFormComponent;
  activeIndex: number = 0;
  data: GetAttributes;
  bookingAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  relation: string = 'oneToOne'
  cols: any = [];
  bookingAttributeLength;
  bookingId: string;
  bookingDocumentId;
  attributeValues = [];
  nextBtnLabel;
  previousBtnLabel;
  entitiesData;
  bookingBookingPass: GetAttributes;
  bookingPass: GetAttributes;
  prefixForOneToManyRelatedFields: any = '_';
  stopLocationCode = 'booking_stops_location_geolocation';
  passengersAttributeCode = 'booking_passenger_section';
  selectVehicleCode = 'selectVehicleCode';
  assignVehicleControlCode = 'vehicle_assignment';
  assignedVehicleId: string;
  stopGeoLocations = [];
  stopAddresses = []
  bookingData: any;
  selectedPassengers: any;
  addedPassengerList: any = [];
  passengerView: any;
  isEdit = false;
  bookingBtnLabel: string;
  isEditView: boolean = false;

  constructor(private entityService: EntityService,
    private bookingService: BookingService,
    public cs: CommonBindingDataService,
    private configService: ConfigService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.setLabels()
    if (this.bookingId) {
      this.setEditFlow();
    } else {
      this.getAttributes()
    }
    this.setRoutePath();
  }

  setEditFlow() {
    this.bookingBtnLabel = this.cs.getLabel('bookings.update_book_request');
    this.isEdit = true;
    this.entityService.getEntity(this.bookingId, AppSettings.ENTITY_CODE.BOOKING).subscribe(res => {
      if (res) {
        const data = res;
        const relatedData = _.groupBy(res['relatedData'], 'entityCode');
        this.addedPassengerList = this.cs.getPassengerData(relatedData.booking_pass);
        this.attributeValues = this.cs.getOrgAttributeValues(data);
        const dateAttributes = [
          AppSettings.DATE_ATTRIBUTE_IDS.DEPARTURE_TIME_AND_DATE,
          AppSettings.DATE_ATTRIBUTE_IDS.RETURN_TIME_AND_DATE,
        ];
        dateAttributes.forEach(attr => {
          this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
        });
        this.getAttributes();

        this.selectedPassengers = this.attributeValues['booking_passenger_section'];
      }
    });
  }

  setLabels() {
    this.nextBtnLabel = this.cs.getLabel('bookings.lbl_book_request');
    this.bookingBtnLabel = this.cs.getLabel('bookings.book_request');
    this.previousBtnLabel = this.cs.getLabel('lbl_previous');
    this.bookingId = this.route.snapshot.paramMap.get('id');
    this.moduleName = AppSettings.ENTITY_CODE.BOOKING;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const href = this.router.url;
    this.isEditView = href.includes('edit');
  }

  onNextBtnClick() {
    const btn = document.getElementById('saveBookingId');
    btn.click();
  }

  getAttributes() {  
    const BOOKING_PASS_VIEW = AppSettings.ENTITY_CODE.BOOKING_PASS + '_' + AppSettings.VIEW_CODE.ADD_EDIT_VIEW;
    const BOOKING_VIEW = AppSettings.ENTITY_CODE.BOOKING + '_' + AppSettings.VIEW_CODE.ADD_EDIT_VIEW;
    const savedBookingPassView = this.entityService.getMiState(BOOKING_PASS_VIEW);
    const savedBookingView= this.entityService.getMiState(BOOKING_VIEW);
    if (savedBookingPassView && savedBookingView) {
      this.setPassengerView(savedBookingPassView);
      this.setBookingView(savedBookingView);
    }else { 
      this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING_PASS, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((passenger: GetAttributes) => {
        if (passenger) {
          const passengerData = passenger;
          this.bookingPass =   this.bookingBookingPass = _.find(passengerData.relatedAttributes, { entityCode: 'passenger' });
          this.bookingPass.relations = _.find(passengerData.relations, { otherEntityCode: 'passenger', relation: "oneToOne" });

          this.entityService.setMiState(BOOKING_PASS_VIEW, passengerData);
          this.setPassengerView(passengerData);  
  
          this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res: GetAttributes) => {
            if (res) {
              this.entityService.setMiState(BOOKING_VIEW, res);
              this.setBookingView(res);
            }
          });
        }
      });
    }
  }

  setPassengerView(passengerAttributeView) {
    this.passengerView = {
      relations: _.find(passengerAttributeView.relations, { otherEntityCode: 'passenger', relation: "oneToOne" })
    }
  }

  setBookingView(res) {
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.bookingAttributeData = this.cs.getOrganizedAttribute(this.data);
    this.bookingAttributeData.tabs[0].groups = this.bookingAttributeData.tabs[0].groups.filter(group => group.code !== 'admin_note');
    this.bookingAttributeLength = this.bookingAttributeData.tabs.length;
    this.bookingBookingPass = _.find(this.data.relatedAttributes, { entityCode: 'booking_pass' });
    this.bookingBookingPass.relations = _.find(this.data.relations, { otherEntityCode: 'booking_pass', relation: "oneToMany" })
    this.bookingBookingPass.ownerRelation = _.find(this.data.relations, { otherEntityCode: 'booking_pass', relation: "oneToOne" })
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('bookings.header'),
        routerLink: this.bookingId ? '../../current-bookings' : '../current-bookings',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.bookingId ? this.cs.getLabel('bookings.edit') : this.cs.getLabel('bookings.details'),
        routerLink: '../current-bookings',
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }


  onSaveBooking(event) {
    if (!this.selectedPassengers) {
      this.showErrorMessage();
      return;
    }

    this.initializeBookingData();

    const passengerIds: string[] = this.getPassengerIds();

    this.setRelationshipData(passengerIds);

    const bookingData = _.cloneDeep(event);

    this.setGeolocationData(bookingData, 'booking_pickup_location');

    this.setGeolocationData(bookingData, 'booking_dropoff_location');

    this.populateBookingData(bookingData);

    if (this.stopGeoLocations) {
      this.addStopAttributes();
    }

    this.assignedVehicleId = _.find(this.bookingData.data, { attributeCode: this.selectVehicleCode })?.attributeValue;
    this.bookingData.data = this.bookingData.data.filter(this.filterBookingData.bind(this));

    this.entitiesData = {
      'countryCode': this.country[0].countryCode,
      'tenantCode': AppSettings.TENANT_CODE,
      'entityCode': this.data.entityCode
    }

    if (!this.bookingId) {
      this.createEntityAndSaveBooking();
    } else {
      this.saveBookingData(this.bookingData);
    }
  }



  setAttributeValuesForStops(key, attributeValue) {
    if (attributeValue) {
      this.stopGeoLocations.push(attributeValue.geoLocation);
      this.stopAddresses.push(attributeValue.address);
    }
  }

  getPassengerIds() {
    return this.selectedPassengers.map(item => typeof item === 'object' ? item.booking_pass_entityId : item) || [];
  }

  saveBookingData(bookingData) {
    this.mapAttributeIds(bookingData);
    this.addPassengerIdsToBookingData(bookingData);

    this.entityService.saveAttributeData(this.entitiesData.entityCode, this.bookingId, bookingData).subscribe(res => {
      if (this.isEditView) {
        this.showSuccessMessage('bookings.booking_update');
      } else {
        this.showSuccessMessage('bookings.booking_added');
      }
      if (this.assignedVehicleId) {
        this.assignVehicleToBooking();
      } else {
        this.router.navigate(['app/bookings/current-bookings']);
      }
    });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel('bookings.lbl_book_request');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.bookingAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.cs.getLabel('bookings.lbl_book_request');
    }
  }

  getLastIndex(): number {
    return this.bookingAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onAddDocument(event) {
  }

  onCancel() {
    
    this.router.navigate(['app/bookings/current-bookings']);
  }

  onPassengerList(data) {
    this.selectedPassengers = data;
  }

  private mapAttributeIds(bookingData) {
    bookingData.data = this.cs.mapAttributeIds(bookingData.data, this.bookingAttributeData.tabs);
  }

  private addPassengerIdsToBookingData(bookingData) {
    const passengerIds = this.getPassengerIds();
    if (passengerIds.length > 0) {
      bookingData.data.push({
        attributeId: this.bookingBookingPass.relations.ownerAttributeId,
        attributeValue: passengerIds
      });
    }
  }

  private assignVehicleToBooking() {
    const data: AssignVehicleToBookingPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      bookingEntityId: this.bookingId,
      vehicleEntityId: this.assignedVehicleId
    };

    this.bookingService.assignVehicleToBooking(data).subscribe(res => {
      this.router.navigate(['app/bookings/current-bookings']);
    });
  }

  private createEntityAndSaveBooking() {
    this.entityService.createEntities(this.entitiesData.entityCode, this.entitiesData).subscribe((res: entityResponse) => {
      this.bookingId = res.entityId;
      this.saveBookingData(this.bookingData);
    });
  }

  private filterBookingData(item: any): boolean {
    return (
      (!_.isArray(item.attributeValue) || item.attributeValue.length > 0) &&
      item.attributeValue !== null &&
      
      item.attributeValue !== "" &&
      item.attributeCode !== 'stops' &&
      item.attributeCode !== this.assignVehicleControlCode &&
      item.attributeCode !== this.selectVehicleCode &&
      !/^100[0-9]/.test(item.attributeCode)
    );
  }

  private addStopAttributes() {
    this.bookingData.data.push({
      attributeCode: "booking_stops_location_geolocation",
      attributeValue: this.stopGeoLocations
    });
    this.bookingData.data.push({
      attributeCode: "booking_stops_location_address",
      attributeValue: this.stopAddresses
    });
  }

  private setGeolocationData(bookingData: any, prefix: string) {
    bookingData[`${prefix}_address`] = bookingData[`${prefix}_geolocation`].address;
    bookingData[`${prefix}_geolocation`] = bookingData[`${prefix}_geolocation`].geoLocation;
  }

  private initializeBookingData() {
    this.bookingData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: []
    };
  }


  private setRelationshipData(passengerIds: string[]) {
    passengerIds.forEach((item, index) => {
      const relationshipConfig = index === 0 ? this.bookingBookingPass.relations : this.bookingBookingPass.ownerRelation;
      this.bookingData.relationshipData.push({
        entityRelationshipConfigId: relationshipConfig.entityRelationshipConfigId,
        otherEntityId: item
      });
    });
  }

  private populateBookingData(bookingData: any) {
    for (const [key, value] of Object.entries(bookingData)) {
      let attributeValue = value;
      if (AppSettings.BOOKING.DATE_ATTRIBUTE_CODES.includes(key)) {
        attributeValue = new Date(value as string).getTime();
      }
      if (key.includes('booking_stops_location_geolocation')) {
        this.setAttributeValuesForStops(key, attributeValue);
      } else {
        this.bookingData.data.push({ attributeCode: key, attributeValue });
      }
    }
  }

  private showErrorMessage() {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel('bookings.msg_please_select_passengers')
    });
  }


  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

}
