<div class="w-full formgrid grid pt-2">

    @for (slot of slots; track slot;let s = $index) {
      <div class="col-12 pl-0 mt-3 mb-3">
        <div class="grid">
          <div class="col-5">
              <label class="slot" translate>lbl_record <span>{{ s + 1 }}</span> </label>
          </div>
          @if (slots.length > 1) {
            <div class="col-6 remove flex justify-content-end" (click)="removeSlot(s, slot)">
              <label class="pointer" translate>lbl_remove</label>
              <i class="mi-lg basic-delete"></i>
          </div>
          }
        </div>
      </div>

        @for (field of slot; track field;let j = $index) {
            <div class="field col-12 pl-0 md:col-{{field.uiColumns}}">
              @if(field.inputCode !== 'spacer'){
                  <div class="pt-2">
                    <div class="col-5 pl-0 pb-3">
                      <label class="flex field-label">{{cs.getLabelValue('driver_driving_record.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                        @if (field.validation?.required) {
                          <span class="required">*</span>
                        }
                        @if (attributeLabels['driver_driving_record.fields.'+field.attributeCode+'.helpTips']) {
                          <app-mi-tooltip [messageData]="cs.getLabelValue('driver_driving_record.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)">
                          </app-mi-tooltip>
                        }
                      </label>
                    </div>
                    <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"
                      [moduleName]="moduleName"></app-mi-fields>

                    <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                      [moduleName]="moduleName"></app-mi-error>
                  </div>
              }

            </div>

          }
          <div class="col-12 ml-1 mr-2 separator"></div>
    }

  <div class="add-more pt-3 pb-3" (click)="addMoreSlots()">
    <i class="mi-lg mi-add"></i>
    <label class="pointer" translate>lbl_add_more</label>
  </div>
</div>