<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="grid mt-1 mr-0 details-div">
    <div class="col-9">
        @if (showStatus) {
            <status [dataForStatus]="dataForStatus" [entityForStatus]="entityForStatus" [imgFileId]="imgFileId"></status>
        }
    </div>
    <div class="col-3 text-right align-content-center">
        <div>
            @if (!isDeletedRecord) {
                <p-button styleClass="p-button-secondary" class="menu-btn p-2 p-button-secondary" (click)="menu.toggle($event)" icon="{{miIcons.MORE_HORIZONTAL}} block wh-24"></p-button>
            }
            <p-button  class="menu-btn p-2 edit-btn" (click)="edit()" icon="{{miIcons.EDIT_PENCIL}} block wh-24" [label]="editBtnLabel"></p-button>
        </div>
    </div>
</div>

<div class="grid mt-2 ml-3 mr-3 overview-details">
    <div class="col-6 pl-0">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>events.lbl_details</label>
                </div>
                <div (click)="edit()" class="edit flex justify-content-center mb-3">
                    <label class="card-action" translate>events.lbl_edit</label>
                    <i class="mi-lg edit_pencil"></i>
                </div>
            </div>
            @for (field of details?.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
                @if(field.attributeCode === 'event_name') {
                    @if(eventState) {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>events.lbl_state</label>
                            </div>
                            <div class="col-6 pb-1 status-container">
                                <span class="status-dot" [class]="eventState"></span>
                                <label class="detail-value">{{ eventState ? eventState : '--' }}</label>
                            </div>
                        </div>
                    }
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                } @else if (field.attributeCode === 'event_image') {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading"
                                translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            @if (eventImage) {
                            <img class="event-profile-image" [src]="eventImage || defaultLoaderImg">
                            } @else {
                            <span>{{ 'lbl_empty' | translate }}</span>
                            }
                        </div>
                    </div>
                } @else if(field.attributeCode === 'transport_supervisor_number') {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            @if(countryCodeForTransportSupervisorNumber) {
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + countryCodeForTransportSupervisorNumber?.toLowerCase()" class="width-18"/>
                            }
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                } @else if(field.attributeCode === 'emergency_number') {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            @if(emergencyNumber) {
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + emergencyNumber?.toLowerCase()" class="width-18"/>
                            }
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                } @else {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                }
        }
        </div>
    
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>events.event_related_settings</label>
                </div>
                <div (click)="edit()" class="edit flex justify-content-center mb-3">
                    <label class="card-action" translate>events.lbl_edit</label>
                    <i class="mi-lg edit_pencil"></i>
                </div>
            </div>
            @for (field of eventRelatedSettings?.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
                @if(field.attributeCode === 'service_area_radius') {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }} 
                                @if(field.imperialUnit && attributeValues[field.attributeCode]){
                                    {{getUnit(field)}}
                                }
                            </label>
                        </div>
                    </div>
                }
                @else {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                }
            }
        </div>
    </div>
    <div class="col-6 pr-0">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase text-lg" translate>events.lbl_address</label>
                </div>
            </div>
           <div class="map-div" id="map"></div>
           <div class="col-12 mt-3 p-0">
                <label class="address font-sm text-400">{{ address }}</label>
            </div>
        </div>
    </div>
</div>

<div class="header-module-menu">
    <p-menu  #menu  [model]="moduleHeaderSecondaryBtnMenuItems" [popup]="true"></p-menu>
</div>