@if(isShowDialog) {
    <p-dialog [header]="header" [(visible)]="isAddBroadcastVisible" (onHide)="onHide()" [style]="{width: '50vw'}">
        @for (tab of broadcastAttributeData?.tabs; track tab; let i = $index) {
            <div class="send-notifications">
                <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues"
                [moduleName]="moduleName" [groups]="tab.groups" (saveData)="onSaveBroadcast($event)" [showImg]="true"
                [activeIndex]="activeIndex" (onClickCancel)="onPreviousClick()" 
                [submitBtnText]="nextBtnLabel" [previousBtnText]="previousBtnText"></app-mi-form>
        </div>
    }
</p-dialog>
}
