import { Component, Input, OnInit } from "@angular/core";
import { AppIcons } from "../../../../shared/app.icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { QuickFilterFormComponent } from "../../../../shared/components/quick-filter-form/quick-filter-form.component";
import { FilterBoxComponent } from "../../../../shared/components/filter-box/filter-box.component";
import { InputTextModule } from "primeng/inputtext";
import { ManageColumnComponent } from "../../../../shared/components/manage-column/manage-column.component";
import { AppSettings } from "../../../../shared/app.settings";
import { MITableService } from "../../../../shared/components/mi-table/mitable.service";
import { Column } from "../../../../vehicles/modal/attributeModal";
import { NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-filter-section",
  templateUrl: "./filter-section.component.html",
  styleUrls: ["./filter-section.component.scss"],
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    InputTextModule,
    ButtonModule,
    QuickFilterFormComponent,
    FilterBoxComponent,
    ManageColumnComponent,
    FormsModule,
  ],
})
export class FilterSectionComponent implements OnInit {
  miIcons = AppIcons;
  filterList;
  moduleName;
  attributeLabels;
  show;
  position;
  selectedFilterCount;
  isModal: boolean = false;
  selectedColumns!: Column[];
  collectionOfColumns: any;
  columns;
  searchValue: string = "";

  constructor(private miTableService: MITableService) { }

  ngOnInit() { }

  clearSearch() {
    this.searchValue = "";
  }

  openDialog() {
    this.isModal = true;
  }

  onToggle(event) {
    this.isModal = false;
  }

  onUpdateColumns(event) {
    this.selectedColumns = event;
    this.isModal = false;

    const data = {
      config: JSON.stringify(event),
      forTenantCode: AppSettings.TENANT_CODE,
      prefType: AppSettings.USER_PREFERENCE.COLUMN,
    };

    this.miTableService
      .updateUserEntityConfig("booking", data)
      .subscribe((res) => { });
  }

  onFilterValueChange(filterFlag, event) { }
  onFiltersPopup() { }
}
