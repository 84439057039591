
<div class="w-full formgrid grid">
    @for(item of field.presetValues; track item; let i=$index;) {
        <div class="field col-12 md:col-6">
            <p-checkbox
                [value]="item.labelValue"
                [label]="item.labelKey"
                [ngModel]="featuresArray?.includes(item.labelValue)?true:false"
                name="field.attributeCode"
                binary="true"
                (onChange)="valueChange($event, item.labelKey)"
                inputId="field.attributeCode" />
        </div>

    } 
</div>
<form [formGroup]="formName">
    <div class="field col-12 md:col-12 mt-3 pl-0">
        <div class="field col-12 md:col-3 pl-0">
            <button  pButton [label]="'lbl_add_new_features' | translate" class="default-border bg-white default-text-black-color height-44 width-170 white-space-nowrap hover:surface-100"
                (click)="onShowDialog()"></button>
        </div>
    </div>
</form>

<input style="display: none;" type="text" pInputText [formControlName]="field.attributeCode" class="w-full"/>

<p-dialog class="feature-dialog" [header]="'lbl_add_new_feature' | translate" [(visible)]="visible" [modal]="true" [draggable]="false" (onHide)="onHide()" [style]="{width: '50vw'}">
    <div class="pt-16">
        <label class="font-bold" translate>lbl_name</label>
        <span class="text-red-500 ml-1">*</span>
        <input class="w-full mt-2 mb-2" type="text" pInputText [pKeyFilter]="blockChars" [(ngModel)]="addFeatureValue" [placeholder]="'lbl_enter_here' | translate"/>
        @if (!isValidInput()) {
            <span class="text-sm text-red-600" translate="">vehicle.vehicle_feature_add_err_msg</span>
        }
        <p class="pt-16">
            <span class="font-bold" translate>lbl_note</span>
            <span translate>lbl_edit_or_delete_new_feature</span>
            <span class="font-bold" translate>lbl_settings_vehicle_features</span>
        </p>
    </div>
    <div class="col-12 flex justify-content-end px-0 py-3">
        <button class="mr-2 bg-white text-color-secondary border-none width-90 hover:surface-100" (click)="onHide()" [label]="'cancel' | translate" pButton></button>
        <p-button [label]="'label_save' | translate" (click)="onAddNewFeatures()" [disabled]="(addFeatureValue == undefined) ? true : !isValidInput() "></p-button>
    </div>
</p-dialog>
