export interface ListResponse {
  data: Datum[];
  count: number;
  pagination: Pagination;
}

export interface Datum {
  id: string;
  values: Values;
  deleted: string;
  relatedData?: RelatedDatum[];
}

export interface RelatedDatum {
  entityCode: string;
  id: string;
  values: RelatedDatumValues;
  actionStatus: null;
  deleted: string;
  relatedData: null;
}

export interface RelatedDatumValues {
  unique_id: string;
  city: string;
  last_name: string;
  employer_name: string;
  contact_number: string;
  driver_address_landmark: string;
  driver_geo_location: string;
  address_line_1: string;
  address_line_2: string;
  state: string;
  driving_record_section: string[];
  driver_note: string;
  mobile_number: string;
  postal_code: string;
  first_name: string;
  email: string;
}

export interface Values {
  additionalProp1: AdditionalProp;
  additionalProp2: AdditionalProp;
  additionalProp3: AdditionalProp;
}

export interface AdditionalProp {
}

export interface Pagination {
  limit: number;
  offset: number;
  searchStr: string;
  defaultSortColumn: string;
  defaultSortType: string;
}

export interface FilterFields {
  label: string;
  value: string;
  isDisable: boolean;
  presetValues?: Array<any>;
}