@if(hideService.getVisibilityState(cardId) | async){
<div class="card h-full shadow-card">
  <div class="flex justify-content-between align-items-center mb-3">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.service_compliance
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>

  <div class="flex flex-row mt-6 mb-6">
    <div class="flex flex-column vehicle-assigned">
      <p class="overdue-number">44%</p>
      <p class="assignment-label" translate>dashboard.all_time</p>
    </div>
    <div class="flex flex-column vehicle-unassigned">
      <p class="unassignment-number">66%</p>
      <p class="assignment-label" translate>dashboard.last_30_days</p>
    </div>
  </div>
</div>
}
