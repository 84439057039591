<form [formGroup]="formName">
    @if(field.attributeCode === 'mobile_number'){ 
        <div class="flex mobile-div contact-number">
            <p-dropdown #dropdown (onChange)="selectCountry($event)" appendTo="body" [options]="countries" [(ngModel)]="selectedCountry" [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false">
                <ng-template pTemplate="selectedItem">
                    @if(selectedCountry) {
                        <div class="flex align-items-center gap-2">
                            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry?.code?.toLowerCase()" class="width-18"/>
                            <div>{{ selectedCountry.value }}</div>
                        </div>
                    }
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                        <div>{{ country.value }}</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="filtericon">
                    <i [class]="filterIcon" [ngClass]="{'cursor-pointer': filterIcon === 'pi pi-times'}" (click)="onFilterIconClick()"></i>
                </ng-template>
            </p-dropdown>
            <input pInputText type="text" class="w-full input-mobile" pKeyFilter="int" (input)="selectCountry($event)" [(ngModel)]="selectedMobile" [ngModelOptions]="{standalone : true}" [placeholder]="placeholder"/>
        </div>
    }
    @else {
        <div class="flex mobile-div">
            <p-dropdown #dropdown (onChange)="selectCountry($event)" appendTo="body" [options]="countries" [(ngModel)]="selectedCountry" [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false">
                <ng-template pTemplate="selectedItem">
                    @if(selectedCountry) {
                        <div class="flex align-items-center gap-2">
                            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry?.code?.toLowerCase()" class="width-18"/>
                            <div>{{ selectedCountry.value }}</div>
                        </div>
                    }
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                        <div>{{ country.value }}</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="filtericon">
                    <i [class]="filterIcon" [ngClass]="{'cursor-pointer': filterIcon === 'pi pi-times'}" (click)="onFilterIconClick()"></i>
                </ng-template>
            </p-dropdown>
            <input pInputText type="text" class="w-full input-mobile" pKeyFilter="int" (input)="selectCountry($event)" [(ngModel)]="selectedMobile" [ngModelOptions]="{standalone : true}" [placeholder]="placeholder"/>
        </div>
    }
    @if (showMobileValidationError) {
        <div class="required" translate>lbl_mobile_number_max_length</div>
    }
</form>
<input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/>