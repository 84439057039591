
<label class="flex mb-1">
    @if(!excludeAttributeLabel.includes(field.attributeCode)){
        {{cs.getLabelValue(moduleName
    +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
    }
    @if (field.validation?.required) {
    <span class="required">*</span>
    }
    
    @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
        <div class="relative attribute-label">
            <app-mi-tooltip
                [messageData]="cs.getLabelValue(moduleName
                                      +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
            </app-mi-tooltip>
        </div>
    }
</label>
