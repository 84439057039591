import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';

interface Chip {
  label: string;
  value: number;
  selected: boolean;
}

@Component({
  selector: 'app-days-of-week',
  standalone: true,
  imports: [ChipModule, FormsModule, ReactiveFormsModule, NgClass],
  templateUrl: './days-of-week.component.html',
  styleUrl: './days-of-week.component.scss'
})
export class DaysOfWeekComponent implements OnInit{
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  selectedChips: any = [];

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    const controlName = this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode;
    const controlValue = this.formName.controls[controlName].value;
    this.selectedChips = controlValue || [];
    this.chips.forEach(chip => {
      chip.selected = this.selectedChips.includes(chip.value);
    });
  }

  chips: Chip[] = [
    { label: 'Mon', value: 1, selected: false },
    { label: 'Tue', value: 2, selected: false },
    { label: 'Wed', value: 3, selected: false },
    { label: 'Thu', value: 4, selected: false },
    { label: 'Fri', value: 5, selected: false },
    { label: 'Sat', value: 6, selected: false },
    { label: 'Sun', value: 7, selected: false }
  ];

  toggleSelection(chip: Chip): void {
    chip.selected = !chip.selected;
    if (this.selectedChips) {
      this.selectedChips.push(chip.value);
    } else {
      this.selectedChips.push(chip.value);
    }
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.selectedChips);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }

}
