<div class="grid col-12 top-4 relative">
    <div class="col-12 bottom-20 relative">
        <div class="flex default-text-black-color font-semibold" translate>lbl_selected_people_header
        </div>
    </div>
    <div class="col-6">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="flex default-text-black-color"><span class="font-semibold">{{'lbl_all_people' | translate}}</span>
            <div>&nbsp;({{driverListCount}})</div>
          </div>
        </ng-template>
        <div>
          <span class="p-input-icon-left w-full">
              <i class="{{miIcons.SEARCH}} inline-block wh-10 left-22 relative"></i>
              <input type="text" pInputText (input)="onSearch($event, allDriverData)" [placeholder]="'lbl_search' | translate" />
          </span>
        </div>
        <div class="all-drivers-container">  
          <div  class="all-drivers-list">
            @for (col of allDriverData; track col.header) {
              @if(col.first_name && col.last_name) {
                <div class="all-drivers-box">
                    <div>
                        <p-checkbox [label]="col.first_name + ' ' + col.last_name" [(ngModel)]="col.selected" [binary]="true" (onChange)="pushToSelectedColumns(col)"></p-checkbox>
                        <div class="relative left-23"><span class="font-12">{{ col.unique_id }}</span> | <span translate>lbl_driver</span> | <span>{{ col.account_status}}</span></div>
                    </div>
                </div>
              }
            }
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-6">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="flex justify-content-between w-full">
            <div class="flex default-text-black-color"><span class="font-semibold">{{'lbl_selected_people' | translate}}</span>
              <div>&nbsp;({{selectedColumns?.length}})</div>
            </div>
          </div>
        </ng-template>
        <div class="all-drivers-container mt-0">
          <div class="selected-driver-list">

            @for (column of selectedColumns; track column.first_name; let i = $index ) {
            <div >
              <div class="all-drivers-box align-items-center grid">
                <div class="flex align-items-center justify-content-between w-full">
                  <div class="col-8">
                    <div>{{ column.first_name }} {{ column.last_name }}</div>
                    <div class=""><span class="font-12">{{ column.unique_id }}</span> | <span class="font-12" translate>lbl_driver</span> | <span class="font-12">{{ column.account_status}}</span></div>
                  </div>
                  <div class="col-4">
                    <div class="pi pi-times-circle cursor-pointer" (click)="removeFromSelectedColumn(i,column)"></div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </p-panel>
    </div>
  </div>