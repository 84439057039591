import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import CommunicationJson from '../../../../assets/json/communication/individual.json';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  chatProfile = CommunicationJson as any;
  constructor() { }

  profileIndividualData = new BehaviorSubject(this.chatProfile[0]);
  searchValue = new BehaviorSubject("");
}