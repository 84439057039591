import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../../shared/services/entity.service';
import { AppSettings } from '../../../../shared/app.settings';
import { CommonModule, DatePipe } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MiMobileValueComponent } from 'app/modules/shared/ui-sharable/mi-mobile-value/mi-mobile-value.component';
import { Countries } from 'app/modules/shared/countries';

@Component({
  selector: 'passenger-overview-details',
  standalone: true,
  imports: [ButtonModule, TranslateModule, DropdownModule, ChartModule, OverlayPanelModule, FormsModule, DatePipe, SelectButtonModule, MiMobileValueComponent, CommonModule],
  templateUrl: './overview-details.component.html',
  styleUrls: ['./overview-details.component.scss', '../../../../shared/components/mi-fields/mobile-input/flags.scss'],
  providers: [DatePipe]
})
export class OverviewDetailsComponent {
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() vehicleAttributeData: any;
  @Input() passengerDetails: any;

  dropDownItems: any[] = [];
  selectedItem: any = [];
  data: any;
  options: any;
  tab: any;
  details: any;
  map: google.maps.Map;
  passengerProfileImage: any;
  dateOfBirth: any;
  locationTypeList: any = [];
  selectedButton: any;
  showAddressButtons: boolean = false;
  stayHomeAddressArray = [];
  ADDRESS_FIELDS = AppSettings.ADDRESS_FIELDS;
  countryCodeForMobileNumber: any;
  countryCodeWithName = Countries.COUNTRY_CODE // AppSettings.COUNTRY_CODE_WITH_COUNTRY_NAME;
  countryName = AppSettings.COUNTRY_NAME_COUNTRY_CODE;
  moduleName: any;
  countryCodeForEmergencyContact: any;
  countryFlagForNationality: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;

  constructor(
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.tab = this.vehicleAttributeData.tabs.find(ele => ele.tabCode === 'overview');
    this.details = this.tab.groups.find(ele => ele.code === 'details');
    this.getStayHomeAddress();
    if (this.attributeValues[AppSettings.PASSENGER_PROFILE_IMAGE_ATTRIBUTE_CODE]) {
      this.getPassengerProfileImage();
    }
    setTimeout(() => {
      this.getFlagsForMobileNumber();
      this.getFlagsForEmergencyNumber();
      this.getFlagForCountry();
    }, 200);
    this.setDateOfBirth();
    this.dropDownItems = [
      { name: 'All Time', code: '' },
      { name: '1', code: '1' },
      { name: '2', code: '2' },
      { name: '3', code: '3' },
      { name: '4', code: '4' }
    ];
    this.initMap();
  }

  redirectToEdit() {
    this.router.navigate(['/app/passengers/edit/' + this.passengerDetails.entityId]);
  }

  getFlagForCountry() {
    const findFlag = (countryName: string) =>
      this.countryName.find(ele => ele.name === countryName)?.code;
    const country = this.findAttributeValue(AppSettings.NATIONALITY_ATTRIBUTE_CODE);
    const flag1 = findFlag(country);
    this.countryFlagForNationality = flag1 ? flag1 : null;
  }

  getFlagsForEmergencyNumber() {
    const findFlag = (flagCode: string) =>
      this.countryCodeWithName.find(ele => ele.value === flagCode)?.code;
    const emergencyContact = this.findAttributeValue(AppSettings.EMERGENCY_CONTACT_ATTRIBUTE_CODE);
    const flag1 = findFlag(emergencyContact?.split('-')[0])
    this.countryCodeForEmergencyContact = flag1 ? flag1 : null;
  }

  getFlagsForMobileNumber() {
    const findFlag = (flagCode: string) =>
      this.countryCodeWithName.find(ele => ele.value === flagCode)?.code;
    const mobileNumber = this.findAttributeValue(AppSettings.MOBILE_NUMBER_ATTRIBUTE_CODE);
    const flag1 = findFlag(mobileNumber?.split('-')[0])
    this.countryCodeForMobileNumber = flag1 ? flag1 : null;
  }

  findAttributeValue(attributeCode: string) {
    return this.passengerDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;
  }

  setDateOfBirth() {
    if (this.attributeValues[AppSettings.PASSENGER_DATE_OF_BIRTH_ATTRIBUTE_CODE]) {
      const date = new Date(this.attributeValues[AppSettings.PASSENGER_DATE_OF_BIRTH_ATTRIBUTE_CODE]);
      this.dateOfBirth = this.datePipe.transform(date, 'dd MMM yyyy');
    } else {
      this.dateOfBirth = this.cs.getLabel('lbl_empty')
    }
  }

  onChange() {
    let stayHomeAddressObject;
    if (this.stayHomeAddressArray) {
      stayHomeAddressObject = this.stayHomeAddressArray.find(ele => ele?.location === this.selectedButton);
    }

    let address;
    if (stayHomeAddressObject) {
      address = stayHomeAddressObject.address.find(ele => ele.attributeCode === 'select_location_address').attributeValue;
    }
    if (address) {
      this.cs.getLatLngFromAddress(address, AppSettings.GOOGLE_API_KEY).subscribe((result: any) => {
        this.setLocationOnMap(result.results, address);
      })
    }

  }

  async setLocationOnMap(result, address) {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: result[0]?.geometry?.location,
      zoom: 14,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: AppSettings.MAP_ID,
    });

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: result[0]?.geometry?.location,
    });

    this.map.panTo(result[0]?.geometry?.location);
  }

  getPassengerProfileImage() {
    this.entityService.getFile(this.attributeValues[AppSettings.PASSENGER_PROFILE_IMAGE_ATTRIBUTE_CODE], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'passenger');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.passengerProfileImage = base64data;
      }
    })
  }

  getStayHomeAddress() {
    this.locationTypeList = [];
    const stayHomeAddressIdArray = this.attributeValues[AppSettings.FIELDS.STAY_HOME_ADDRESS_SECTION] as [];
    const that = this;
    stayHomeAddressIdArray?.forEach(function(entityId, i) {
      that.entityService.getEntity(entityId, AppSettings.ENTITY_CODE.PASSENGER_ADDRESS).subscribe((res: any) => {
        const field = res.attributeCodeValueDtoList.find(element => element.attributeCode === AppSettings.FIELDS.SELECT_LOCATION_TYPE);
        const label = field ? field.attributeValue : '';
        that.locationTypeList[i] = { index: i, labelKey: label, labelValue: label };
        that.stayHomeAddressArray[i] = { index: i, location: label, address: res.attributeCodeValueDtoList, addressDTO: _.keyBy(res.attributeCodeValueDtoList, 'attributeCode') };
        if (i === (stayHomeAddressIdArray.length - 1)) {
          setTimeout(() => {
            that.selectedButton = that.stayHomeAddressArray[0].location;
            that.onChange();
            that.showAddressButtons = true;
          }, 500);

        }
      });
    });

  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.54852264234098, lng: 73.77387933736206 },
      zoom: 19,
    });
  }

  setChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [15, 17, 19],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4,
          showLine: false
        },
        {
          label: 'Second Dataset',
          data: [16, 18, 20],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          backgroundColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4,
          showLine: false
        }
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  scrollToTop() {
    const tabviewPanels = document.querySelector('.p-tabview-panels');
    if (tabviewPanels) {
      tabviewPanels.scrollTop = 0;
    }
  }
}
