<div class="grid mt-2 ml-3 overview-details mr-0">
    <div class="col-6 pl-0">

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>DETAILS</label>
                </div>
                <div class="edit flex justify-content-center mb-3" (click)="redirectToEdit()">
                    <label class="card-action" translate>vehicle.lbl_edit</label>
                    <i class="mi-lg edit_pencil"></i>
                </div>
            </div>
            @for (field of details.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-4 pt-1">
                    <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-8 pb-1">
                    @if (field.attributeCode === 'vehicle_status') {
                        <div class="status">
                            <span [class]="attributeValues[field.attributeCode]?.toLowerCase()"></span>
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    } @else if(field.attributeCode === 'driver'){
                        @for (driver of driverList; track driver; let i = $index) {
                            <div class="user-info flex align-items-center">
                                <app-mi-image-container [miImage]="driver.driverProfileImage" miHeight="15" miWidth="15"></app-mi-image-container>
                                <label class="driver-name">{{ driver.driverName ? driver.driverName: '--' }}</label>
                            </div>
                        }
                    }@else {
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                    }
                </div>
            </div>
        }
        </div>

        <div class="card p-fluid flex">
            <div class="">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>vehicle.lbl_qr_sticker</label>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12">
                        <label translate>vehicle.lbl_qr_instruction</label>
                        @if(isLoading){
                            <div class="loader"> 
                                <div >
                                    <div  class="spin-div">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="flex justify-content-end mt-4">
                            <p-button [iconPos]="iconPos" class="print-label mr-2 border-600 bg-primary-reverse text-color" (click)="showPrintLabelDialog()" label="Print Label">
                                <span><i class="{{miIcons.MI_PRINT}} inline-block wh-15 relative mr-1"></i></span>
                            </p-button>
                            <p-button [iconPos]="iconPos" class="download-label mr-2 border-600 bg-primary-reverse text-color white-space-nowrap" (click)="generateTemplate()" label="Download Label">
                                <span><i class="{{miIcons.DOWNLOAD2}} inline-block wh-15 relative mr-1"></i></span>
                            </p-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <img class="wh-130 border-1 border-300 border-round" [src]="barcodeImage"/>
            </div>
        </div>

        <!-- <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase">LINKED EQUIPMENT</label>
                </div>
                <div class="flex justify-content-center mb-3">
                    <label class="card-action" translate>vehicle.lbl_link_equipment</label>
                </div>
            </div>
            <div class="grid pt-3 ml-2">
                <div class="col-1">
                    <img class="status-img" src="../../../../../assets/images/default_profile.png">
                </div>
                <div class="col-11">
                    <div class="initial-details">
                        <label class="equipment-name" (mouseover)="op.toggle($event)">baby seat</label>
                        <div class="active"></div>
                        <label class="status">In Service</label>
                    </div>
                    <div class="other-details">
                        <label class="name">Brand :</label>
                        <label class="value"> Deere</label>
                        <label class="separator">|</label>
                    </div>
                </div>
            </div>


        </div> -->
    </div>
    <div class="col-6">
        <div class="card p-fluid mr-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>vehicle.last_known_location</label>
                </div>
                <div class="flex justify-content-center align-items-center mb-3">
                    <span class="wh-10 bg-red-500 border-circle mr-2"></span>
                    <label class="card-action font-semibold mr-1">Busy</label> <span>- On the way</span>
                </div>
            </div>
           <!-- Google map integration -->
           <!-- <div id="googleMap" style="width:100%;height:200px;"></div> -->
           <div class="map-div" id="map"></div>
           <!-- <iframe
                width="450"
                height="250"
                frameborder="0" style="border:0"
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDDE5tOJG6bh7Vzbxv60TVJoykNIZdCEe8&q=18.548441270144465, 73.77391152387018"
                allowfullscreen>
            </iframe> -->
        </div>
        <!-- <div class="card p-fluid mr-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase">FUEL EFFIECIENCY</label>
                </div>
                <div class="flex justify-content-center mb-3">
                    <p-dropdown [options]="dropDownItems" [(ngModel)]="selectedItem" optionLabel="name"></p-dropdown>
                </div>
                <div class="col-12">
                    <p-chart type="line" [data]="data" [options]="options"></p-chart>
                </div>
            </div>
        </div> -->
    </div>
</div>
<hr class="ml-3 mr-3">
<div class="col-12 text-center">
    <button pButton icon="pi pi-arrow-up" (click)="scrollToTop()" iconPos="right" class="bg-white text-blue-300 border-transparent" label="Back to Top">
    </button>
</div>

<app-print-labels-dialog (onToggleOverLay)="visiblePrintLabel = false" [visiblePrintLabel]="visiblePrintLabel"
    [bulkRowCount]="bulkRowCount" [vehicleRowData]="rowData"></app-print-labels-dialog>