<div class="justify-content-start flex align-items-start">
    <p-skeleton height="2rem" class="m-2 w-1" /> <p-skeleton height="2rem" class="m-2 w-1" /> <p-skeleton height="2rem"
        class="m-2 w-1" /> <p-skeleton height="2rem" class="m-2 w-1" /> <p-skeleton height="2rem" class="m-2 w-1" />
    <p-skeleton height="2rem" class="m-2 w-1" /> <p-skeleton height="2rem" class="m-2 w-1" />
    <div class="justify-content-end flex align-items-end w-full">
        <p-skeleton size="2rem" styleClass="m-2 mr-0" />
        <p-skeleton size="2rem" styleClass="m-2" />
        <p-skeleton size="2rem" styleClass="m-2" />
    </div>
</div>
<div class="justify-content-start flex flex-column align-items-left h-screen">
    <p-table [value]="[1,2,3,4,5,6,7,8,9,10]" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th><p-skeleton /></th>
                <th><p-skeleton /></th>
                <th><p-skeleton /></th>
                <th><p-skeleton /></th>
                <th><p-skeleton /></th>
                <th><p-skeleton /></th>
                <th><p-skeleton /></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td><p-skeleton /></td>
                <td><p-skeleton /></td>
                <td><p-skeleton /></td>
                <td><p-skeleton /></td>
                <td><p-skeleton /></td>
                <td><p-skeleton /></td>
                <td><p-skeleton /></td>
            </tr>
        </ng-template>
    </p-table>
</div>


