import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AppSettings } from '../../../../shared/app.settings';
import { Country } from '../../../../shared/models/country';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../../shared/services/entity.service';

@Component({
  selector: 'app-booking-driver-info',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './booking-driver-info.component.html',
  styleUrl: './booking-driver-info.component.scss'
})
export class BookingDriverInfoComponent implements OnInit{
  @Input() driverId: string;
  @Input() entityId: string;
  @Input() driverRelatedData: any;
  country: Country;
  driverAttributeData: any;
  attributeValues: any;
  driverDetails: any;

  attributeLabels:any;
  constructor(private bookingService: EntityService,
    public cs: CommonBindingDataService,
    private router: Router
  ) { 
    
  }


ngOnInit(): void {
  this.getAttributeData();
  this.attributeValues = this.driverRelatedData?.[0];
}


  getAttributeData() {     
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.bookingService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DRIVER_DETAILS_ON_BOOKING_VIEW).subscribe(res => {      
      this.driverAttributeData = _.find(res.relatedAttributes, { entityCode: 'driver' });    
      this.driverDetails = this.driverAttributeData?.tabs[0].groups[0];
      const labels = _.find(res.relatedAttributes, { entityCode: 'driver' }).attributeLabels;    
      this.attributeLabels = this.cs.getAttributeLabels(res);
    });
  }
  
  redirectToEdit() {
    this.router.navigate(['/app/bookings/edit/' + this.entityId]);
  }


}

