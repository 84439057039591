import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';
import { ConfigService } from './config.service';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor(
    private restApiService: RestApiService,
    private confService: ConfigService) {
  }
 
  getSidebarMenus(): Observable<any> {
    return this.restApiService.get('ATTRIBUTE DEFINITION', `${this.confService.getLoggedInTenantCode()}/api/v1/menus?forTenantCode=${AppSettings.TENANT_CODE}`, 'page-center');
  }

  getMenuPermissions(): Observable<any> {
    return this.restApiService.get('Menu Permissions', `${this.confService.getLoggedInTenantCode()}/api/v1/permissions?forTenantCode=${AppSettings.TENANT_CODE}`, 'page-center');
  }
}
