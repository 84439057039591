<div>
  @if (profileData.profileImage) {
    <img [src]="profileData.profileImage"
      class="rounded-circle border-offwhite border-2 border-solid" [ngClass]="activeClasses"/>
  }
  @if (!profileData.profileImage) {
    <div class="img-background rounded-circle border-offwhite border-2 border-solid relative" [ngClass]="activeClasses"
      >
      <span class="absolute top-50 left-50 image-name text-lg font-bold">{{createAcronym(profileData.name)}}</span>
    </div>
  }
</div>