<p-breadcrumb [model]="routePath"></p-breadcrumb>
<app-module-header [label]="btnLabel" [lastSync]="lastSync" (onBtnClick)="onAddShift()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>
<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="pi pi-align-left mr-2"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{count}})</span>
    </ng-template>
    <ng-template pTemplate="body">

          <app-mi-table
              [collectionOfColumns]="allColumns"
              [attributeLabels]="filterAttributeLabels"
              [data]="tableData"
              [isLoading]="isLoading"
              [entityNameForBulkUpdate]="entityNameForBulkUpdate"
              (onPageChange)="onPageChange($event)"
              (itemPerPageChange)="itemPerPageChange($event)"
              [message]="deleteShiftTemplateMsg"
              [header]="deleteShiftTemplateHeaderMsg"
              (onDelete)="onShiftDelete($event)"
              (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
              (onRowSelection)="onRowSelect($event)"
              [filterList]="filterList"
              [filterFields]="filterFieldsArray"
              [label]="btnLabel"
              [attributeData]="attributeData"
              [rowItems]="rowItems"
              [bulkSelectionsItems] = "bulkSelectionsItems"
              (onBtnClick)="onAddShift()"
              [bulkUpdateLabel]="bulkUpdateLabel"
              [bulkUpdateHeader]="bulkUpdateHeader"
              [bulkDeleteLabel]="bulkDeleteLabel"
              (setCurrentRowData)="setCurrentRowData($event)"
              [showBulkUpdateDialog]="showBulkUpdateDialog"
              (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
              [reviewHeader]="reviewHeader"
              (onSearchValueChange)="onSearchValueChanges($event)"
              [totalRecords]="totalRecords"
              [visible]="visible"
              (onToggleOverLay)="showBulkUpdateDialog = false; visiblePrintLabel = false"
              [isEntity]="isEntity"
              [uniqueId]="uniqueId"
              [staticEntity]="entityName"
              (onAssignDriver)="assignDriver($event)"
              (onValueChange)="onFilterValueChange($event)"
              [entityNameToDisplay]="entityNameToDisplay"
              >
            </app-mi-table>

    </ng-template>
  </p-tabPanel>
</p-tabView>

<input hidden type="file" id="upload" (change)="onFileSelect($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>


<p-dialog [header]="shiftActionHeader" [(visible)]="showShiftDialog" [style]="{width: '30vw'}">
    <div class="flex flex-column gap-3 mt-3">
        <div>
            @for (shift of tableData; track shift;let i = $index) {
                <div  class="row-module col-12 inline-flex">
                        <div class="col-6">
                            <label class="shift-name" translate>{{ shift.shiftName }}</label> &nbsp;
                            <label translate>{{ shift?.driverCount }}</label>
                        </div>
                        <div class="col-6 text-right">
                            @if (isDelete) {
                                <p-checkbox   (onChange)="onIndividualCheckboxChange($event, shift)" [binary]="true"
                                name="driver" [value]="shift.id"></p-checkbox>
                            } @else {
                                <p-radioButton
                                    name="'shifts'"
                                    [value]="shift"
                                    [inputId]="shift.shiftId"
                                    [(ngModel)]="selectedShift"
                                />
                            }
                        </div>
                </div>
            }
        </div>
        <div class="flex justify-content-end flex-wrap mb-3 clear-shift">
            <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer"
                [label]="'cancel' | translate" (click)="onCancel()"></button>
            <p-button  class="cursor-pointer unpublish-btn" (click)="isDelete ? confirmDelete() : editShift()"
                [label]="actionBtnLabel"></p-button>
        </div>
    </div>
</p-dialog>