<form [formGroup]="formName">
    <div class="flex">
        <div class="w-full">
            <p-dropdown #miSelect [options]="field.presetValues" optionLabel="labelValue" [placeholder]="placeholder"
                optionValue="labelValue" (onChange)="changedValue(miSelect.value)"
                [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"></p-dropdown>
        </div>
        <div>
            <p-button class="inline-flex ml-3" icon="mi-lg basic-location" (click)="showDialog()"></p-button>
        </div>
    </div>

</form>

<p-blockUI [blocked]="blockedDocument"></p-blockUI>

<p-dialog header="Geo Location" (onHide)="close()" focusOnShow="false" [(visible)]="visible" [style]="{width: '50vw'}">
    <div class="map-div mb-3 mt-3" id="map">
    </div>

    <div class="col-12 save-div mb-3 pr-0">
        <button pButton class="cancel mr-20 text-color height-44" (click)="close()" [label]="cancelBtnText"></button>
        <p-button class="inline-flex height-44" (click)="saveGeoLocation()" [label]="saveBtnText"></p-button>
    </div>
</p-dialog>

<!-- <input style="display: none;" type="text" pInputText [formControlName]="field.attributeCode" class="w-full" /> -->
@if (showGoogleSearchBox) {
<span id="googleSearch" class="p-input-icon-left">
    <i class="pi pi-search mt-0"></i>
    <input id="autocompleteSearch" [(ngModel)]="searchText" type="text" pInputText class="w-full search-field"
        placeholder="Search" />
</span>
}