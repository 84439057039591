import { Component } from '@angular/core';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrl: './document-list.component.scss',
  standalone: true,
  imports: [],
})
export class DocumentListComponent {

  constructor() { }

  ngOnInit() {

  }

}
