import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';

import { EntityService } from '../../../shared/services/entity.service';
import { MessageService } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';

@Component({
  selector: 'app-driver-all-status-dialog',
  standalone: true,
  imports: [DialogModule, TranslateModule, InputTextModule, RadioButtonModule, ButtonModule, FormsModule, NoResultFoundComponent],
  templateUrl: './driver-all-status-dialog.component.html',
  styleUrl: './driver-all-status-dialog.component.scss'
})
export class DriverAllStatusDialogComponent implements OnDestroy {
  @Output() loadSearchEntity: EventEmitter<any> = new EventEmitter();
  miIcons = AppIcons;
  @Input() visibleDriverStatus;
  @Input() rowData;
  @Input() header: string;
  @Input() tableDriverGroupData: any[] = [];
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onValueChange = new EventEmitter<any>();
  moduleName: string | null;
  selectedFilterCount: number;
  driverData: any;
  driverStatus: any;
  driverGroupStatus: any;
  country: Country;
  language: Language;
  driverGroupLabel: string;
  tableDriverGroupDataTemp: any;

  constructor(private driverService: EntityService, private cs: CommonBindingDataService, private messageService: MessageService) { }

  ngOnInit() {
    this.moduleName = AppSettings.ENTITY_CODE.DRIVER;
  }

  ngOnChanges(): void {
    this.driverData = this.rowData;
    this.driverGroupStatus = this.tableDriverGroupData;
    this.tableDriverGroupDataTemp = this.tableDriverGroupData;
  }

  onHide() {
    this.visibleDriverStatus = false;
    this.onToggleOverLay.emit(this.visibleDriverStatus);
  }

  onSearch(event, driverList) {
    const value = event.target.value.toLowerCase();
    if (driverList) {
      this.tableDriverGroupData = this.tableDriverGroupDataTemp.filter(ele => ele?.group_name.toLowerCase().includes(value));
    }
  }

  selectGroupRadioButton(data) {
    this.driverGroupStatus = data;
  }

  selectDriverGroup(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const reqBody = {
      forTenantCode: AppSettings.TENANT_CODE,
      countryCode: country[0].countryCode,
      languageCode: language[0].langCode,
      driverEntityIds: [this.driverData?.id],
      driverGroupEntityId: event?.driverGroupId
    }
    const entityCode = AppSettings.ENTITY_CODE.DRIVER_GROUP;
    this.driverService.assignDriverGroup(entityCode, reqBody).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_assign_driver_group_success'), });
      this.visibleDriverStatus = false;
    },
      errors => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
  }

  goTodriverList() {
    this.loadSearchEntity.emit();
  }

  ngOnDestroy() {
    this.tableDriverGroupData = [];
  }
}
