@if(hideService.getVisibilityState(cardId) | async){
<div class="card h-full shadow-card">
  <div class="flex justify-content-between align-items-center mb-1">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.inspections_summary
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>
  <div>
    <span class="pie-chart-label" translate>dashboard.last_30_days</span
    ><p-chart
      type="pie"
      [data]="pieChartData"
      [options]="pieChartOptions"
      style="height: 159px; display: flex; justify-content: center"
    ></p-chart>
  </div>
</div>
}
