<form [formGroup]="formName">
        <p-dropdown #miSelect [options]="field.presetValues" [placeholder]="placeholder" optionValue="labelValue" optionLabel="labelValue" [formControl]="formName.controls[field.attributeCode]" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode">
                <ng-template let-option pTemplate="selectedItem">
                        <div class="flex align-items-center">
                          <i [ngClass]="option.labelKey" class="mr-2 mi-lg"></i>
                          <span>{{ option.labelValue }}</span>
                        </div>
                      </ng-template>


                <ng-template let-option pTemplate="item">
                        <div class="flex align-items-center">
                                <i [ngClass]="option.labelKey" class="mr-2 mi-lg"></i>
                                <span>{{ option.labelValue }}</span>
                              </div>
                      </ng-template>

        </p-dropdown>
</form>

