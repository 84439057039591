import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { MenuItem, MessageService } from 'primeng/api';
import { Status } from '../../../shared/models/status';
import { Country } from '../../../shared/models/country';
import { AttributeData } from '../../../vehicles/modal/attributeModal';
import { AppSettings } from '../../../shared/app.settings';
import { OverviewDetailsComponent } from './overview-details/overview-details.component';
import { SpecificationComponent } from '../../../vehicles/components/vehicle-details/specification/specification.component';
import { OverviewStatusComponent } from './overview-status/overview-status.component';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from 'app/modules/shared/app.icons';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { Language } from 'app/modules/shared/models/language';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrl: './location-details.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, OverviewStatusComponent, TranslateModule, MenuModule, ToastModule, ButtonModule, OverviewDetailsComponent, SpecificationComponent, TabViewModule]
})
export class LocationDetailsComponent implements OnInit {
  routePath: MenuItem[] = [];
  locationId: string;
  dataForStatus: Array<Status> = [];
  country: Country;
  activeIndex: any = 0;
  data: AttributeData;
  locationAttributeData: any;
  attributeLabels = {};
  locationAttributeLength;
  attributeValues = [];
  showStatus: boolean = false;
  locationDetails: any;
  locationCategory: any;
  editBtnLabel: any;
  miIcons = AppIcons;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  language: Language;
  attributeIdOfLocationStatus;

  constructor(private route: ActivatedRoute,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private manageLocationService: EntityService,
    private router: Router,
    private configService: ConfigService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.locationId = this.route.snapshot.paramMap.get('id');
    this.getLocationDetails();
    this.getAttributeData();
    this.routePath = [
      {
        label: "Locations",
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Overview",
        routerLink: '../' + this.locationId,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_STATUS] === AppSettings.LOCATION_STATUS.ACTIVE ? this.cs.getLabel('manage_locations.lbl_make_location_inactive') : this.cs.getLabel('manage_locations.lbl_make_location_active'),
        icon: AppIcons.OVERDUE + ` wh-16 ${this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_STATUS] === AppSettings.LOCATION_STATUS.ACTIVE ? 'bg-red-500' : 'bg-teal-300'}`,
        command: () => {
          this.updateLocationStatus();
        }
      }
    ];
  }

  updateLocationStatus() {
    const newStatus = this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_STATUS] === AppSettings.LOCATION_STATUS.ACTIVE ? false : true;
    const data = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.LOCATION,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [
        {
          attributeId: this.attributeIdOfLocationStatus,
          attributeValue: this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_STATUS] === AppSettings.LOCATION_STATUS.ACTIVE ? AppSettings.LOCATION_STATUS.INACTIVE : AppSettings.LOCATION_STATUS.ACTIVE
        }
      ]
    };
    this.manageLocationService.saveAttributeData(data.entityCode, this.locationDetails.entityId, data).subscribe(result => {
      setTimeout(() => {
        this.getLocationDetails();
      }, 500);
      if (newStatus) {
        this.showSuccessMessage('manage_locations.message.msg_location_active');
      } else {
        this.showSuccessMessage('manage_locations.message.msg_location_inactive');
      }
    }, error => {
      this.showErrorMessage(error.errors.general[0].message);
    });

  }

  setStatusData() {
    this.dataForStatus = [
      {
        label: 'Short Code',
        text: this.locationDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'location_short_code')?.attributeValue
      },
      {
        label: 'Display Name for Passenger',
        text: this.locationDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'location_display_name_for_passenger')?.attributeValue
      }
    ];
  }

  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.manageLocationService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {
      this.data = res;
      this.cd.detectChanges();
      this.setStatusData();
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.locationAttributeData = this.cs.getOrganizedAttribute(this.data);
      this.locationAttributeLength = this.locationAttributeData.tabs.length;
    })
  }

  getLocationDetails() {
    this.manageLocationService.getEntity(this.locationId, AppSettings.ENTITY_CODE.LOCATION).subscribe((result: any) => {
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.locationDetails = result;
      this.setModuleHeaderSecondaryBtnMenuItems();
      this.attributeIdOfLocationStatus = result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'location_status')?.attributeId;
      const generateCodeValue = result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'generate_code_for_booking');
      if (generateCodeValue) {
        this.attributeValues['generate_code_for_booking'] = generateCodeValue.attributeValue === true ? 'Yes' : 'No';
      }
      const value = result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'location_category');
      if (value) {
        this.locationCategory = value.attributeValue;
        this.cd.detectChanges();
      }
      this.showStatus = true;
    })
  }

  edit() {
    this.router.navigate(['app/manage-locations/add/' + this.locationId,]);
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }

}
