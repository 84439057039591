import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-tab',
  templateUrl: './vehicle-tab.component.html',
  styleUrl: './vehicle-tab.component.scss',
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class VehicleTabComponent {
  @Input() driverDetailItem: any;

  constructor() { }

  ngOnInit(): void { }
}
