import { Component, Input, OnInit } from '@angular/core';
import { Status } from '../../../../shared/models/status';

@Component({
  selector: 'app-overview-status',
  templateUrl: './overview-status.component.html',
  styleUrl: './overview-status.component.scss',
  standalone: true,
  imports: []
})
export class OverviewStatusComponent implements OnInit {
  @Input() public dataForStatus: Array<Status> = [];
  @Input() public locationCategory;

  constructor() { }

  ngOnInit(): void {

  }

}
