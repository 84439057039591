<div class="flex justify-content-end flex-wrap btn-with-links">
      @if (showLastSync) {
            <label class="flex align-items-center mr-3 h-4rem last-sync" >Last Sync:&nbsp;&nbsp;<span class="time">{{lastSync | date: HOUR_MINUTE_FORMAT}}</span> </label>
      }
      @if (showImportExportBtn) {
            <p-button styleClass="p-button-secondary" class="flex align-items-center w-4rem h-4rem p-button-secondary" (click)="menu.toggle($event)" icon="{{miIcons.MORE_HORIZONTAL}} block wh-24"></p-button>
      }
      <p-button class="flex align-items-center justify-content-center" icon="pi pi-plus" [label]="label" (click)="onAdd()" ></p-button>
      @if (showSecondaryBtn) {
            <p-button class="flex align-items-center justify-content-center ml-1" icon="pi pi-check" [label]="secondaryBtnLabel" (click)="onSecondaryBtnClick()" ></p-button>
      }
</div>
<div class="header-module-menu">
      <p-menu  #menu  [model]="moduleHeaderSecondaryBtnMenuItems" [popup]="true"></p-menu>
</div>