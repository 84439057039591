import { Injectable } from '@angular/core';
import { Country } from '../../shared/models/country';
import { ConfigService } from '../../shared/services/config.service';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  country: Country;

  constructor(private restApiService: RestApiService, private confService: ConfigService) { }

  addShift(data: any) {
    return this.restApiService.post('add shift', `${this.confService.getLoggedInTenantCode()}/api/v1/shifts`, data, 'page-center');
  }

  updateShift(data: any, shiftId: any) {
    return this.restApiService.put('update shift', `${this.confService.getLoggedInTenantCode()}/api/v1/shifts/${shiftId}`, data, 'page-center');
  }

  getShiftList(entityData: any) {
    return this.restApiService.post('shift list', `${this.confService.getLoggedInTenantCode()}/api/v1/shifts/search?forTenantCode=${this.confService.getForTenantCode()}`, entityData, 'page-center');
  }

  deleteShift(requestBody: any) {
    return this.restApiService.post('delete shift', `${this.confService.getLoggedInTenantCode()}/api/v1/shifts/bulk-delete`, requestBody, 'page-center');
  }

  getShiftDetails(id: any) {
    return this.restApiService.get('get shift details', `${this.confService.getLoggedInTenantCode()}/api/v1/shifts/${id}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  getDriverDetails(id: any) {
    return this.restApiService.get('get driver details', `${this.confService.getLoggedInTenantCode()}/api/v1/shifts/${id}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  downloadImportTemplate(): Observable<any> {
    return this.restApiService.downloadDocument(`${this.confService.getLoggedInTenantCode()}/api/v1/shifts/template?forTenantCode=${this.confService.getForTenantCode()}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  downloadImportGuide(): Observable<any> {
    return this.restApiService.downloadDocument(`${this.confService.getLoggedInTenantCode()}/api/v1/shifts/guide?forTenantCode=${this.confService.getForTenantCode()}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  importShift(file): Observable<any> {
    return this.restApiService.importEntities('FILE UPLOAD', `${this.confService.getLoggedInTenantCode()}/api/v1/shifts/import-shifts?forTenantCode=${this.confService.getForTenantCode()}`, file);
  }

  getDriverList(data: any, selectionType) {
    return this.restApiService.post('get driver list', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-calendars/search/${selectionType}?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center');
  }

  publishUnpublishShift(data: any, status: any) {
    return this.restApiService.post('publish and unpublish shift', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-calendars/${status}?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center');
  }

  clearShift(data: any) {
    return this.restApiService.post('clear shift', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-calendars/clear-shifts?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center');
  }

  getPublishUnPublishListInDateRange(data: any) {
    return this.restApiService.post('publish and unpublish shift list', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-calendars/shifts-status`, data, 'page-center');
  }

  getCalendarHeaderCountByDate(data: any, selectionType: any) {
    return this.restApiService.post('calendar header count by date', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-calendars/calendar-header-count?selectionType=${selectionType}`, data, 'page-center');
  }

}
