import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { StatusComponent } from '../../../shared/components/status/status.component';
import { Country } from '../../../shared/models/country';
import { Status } from '../../../shared/models/status';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { OverviewDetailsComponent } from '../../../vehicles/components/vehicle-details/overview-details/overview-details.component';
import { SpecificationComponent } from '../../../vehicles/components/vehicle-details/specification/specification.component';
import { AttributeData } from '../../../vehicles/modal/attributeModal';
import { EntityService } from '../../../shared/services/entity.service';
import { DriverOverviewDetailsComponent } from './driver-overview-details/driver-overview-details.component';
import { AssignmentHistoryComponent } from '../assignment-history/assignment-history.component';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { DocumentListComponent } from 'app/modules/shared/components/document-list/document-list.component';

@Component({
  selector: 'app-driver-details',
  standalone: true,
  imports: [BreadcrumbModule,
    StatusComponent,
    TabViewModule,
    TranslateModule,
    MenuModule,
    ToastModule,
    OverviewDetailsComponent,
    ButtonModule,
    SpecificationComponent,
    DriverOverviewDetailsComponent,
    MITableComponent,
    AssignmentHistoryComponent,
    DocumentListComponent],
  templateUrl: './driver-details.component.html',
  styleUrl: './driver-details.component.scss'
})
export class DriverDetailsComponent {
  miIcons = AppIcons;
  routePath: MenuItem[] = [];
  driverId: string;
  dataForStatus: Array<Status> = [];
  userId: any = '232323';
  country: Country;
  activeIndex: any = 0;
  data: AttributeData;
  driverAttributeData: any;
  attributeLabels = {};
  driverAttributeLength;
  attributeValues = [];
  imgFileId: any;
  showStatus: boolean = false;
  driverDetails: any;
  entityForStatus: any;
  deleteBtnLabel: string;
  driverGroupList: any[] = [];

  constructor(private route: ActivatedRoute,
    public cs: CommonBindingDataService,
    private router: Router,
    private messageService: MessageService,
    private configService: ConfigService,
    private confirmationService: ConfirmationService,
    private driverService: EntityService) {

  }

  ngOnInit() {
    this.driverId = this.route.snapshot.paramMap.get('id');
    this.deleteBtnLabel = this.cs.getLabel('lbl_delete');
    this.getDriverDetails();
    this.getAttributeData();
    this.routePath = [
      {
        label: "Drivers",
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Overview",
        routerLink: '../' + this.driverId,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.driverService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {

      res.tabs.forEach(element => {
        element.groups.forEach(group => {
          if (group.code === 'details') {
            group.entityRelationshipConfigId = null;
          }
        });
      });
      this.data = res;
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.driverAttributeData = this.cs.getOrganizedAttribute(this.data);
      this.driverAttributeLength = this.driverAttributeData.tabs.length;
    })
  }

  getDriverDetails() {
    this.driverService.getEntity(this.driverId, AppSettings.ENTITY_CODE.DRIVER).subscribe((result: any) => {
      this.driverDetails = result;
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.imgFileId = this.attributeValues[AppSettings.DRIVER_PROFILE_IMAGE_ATTRIBUTE_CODE];
      this.showStatus = true;
      this.setStatusData();
      this.driverGroupList = result.relatedData.filter(ele => ele.entityCode === 'driver_group').map(ele => {
        return  {
          driverGroupName: ele.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'group_name')?.attributeValue,
          driverGroupColor: ele.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'group_color')?.attributeValue
        }
      });
    })
  }

  setStatusData() {
    const findAttributeValue = (attributeCode: string) =>
      this.driverDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;

    const getAttributeValueOrEmpty = (attributeCode: string) =>
      findAttributeValue(attributeCode) || this.cs.getLabel('lbl_empty');

    this.entityForStatus = {
      entityName: `${findAttributeValue('first_name')} ${findAttributeValue('last_name')}`,
      entityStatus: `${findAttributeValue('driver_status')}`
    };

    this.dataForStatus = [
      { label: 'Email', text: getAttributeValueOrEmpty('email') },
      { label: '', text: getAttributeValueOrEmpty('call_sign_code') }
    ];
  }

  deleteDriver() {
    this.confirmationService.confirm({
      header: this.cs.getLabel('driver.message.confirm_delete_header'),
      message: this.cs.getLabel('driver.message.confirm_delete_msg'),
      acceptIcon: null,
      rejectIcon: null,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      acceptLabel: this.cs.getLabel('lbl_delete'),
      rejectLabel: this.cs.getLabel('cancel'),
      accept: ($event) => {
        const requestBody = {
          forTenantCode: this.configService.getForTenantCode(),
          entityCode: AppSettings.ENTITY_CODE.DRIVER,
          entityIds: [this.driverId],
          countryCode: this.country[0].countryCode
        };
        this.driverService.deleteEntityActionStatus(requestBody).subscribe(response => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.lbl_driver_deleted_successfully') });
          this.router.navigate(['/app/drivers/list']);
        })
      },
      reject: () => {
      }
    });
  }
}
