import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HideButtonComponent } from "../../dashboard/hide-button/hide-button.component";
import { CommonModule } from "@angular/common";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-recent-comments-section",
  templateUrl: "./recent-comments-section.component.html",
  standalone: true,
  imports: [TranslateModule, HideButtonComponent, CommonModule],
})
export class RecentCommentsSectionComponent implements OnInit {
  @Input() commentSectionsData: any;
  cardId: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.recent_comments");
  }
}
