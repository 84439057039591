<div class="flex mi-filter-container">
  <div class="flex flex-wrap justify-content-center">
    <div class="flex-1 flex justify-content-end mr-2">
      <span class="p-input-icon-left">
        <i
          class="pi relative left-20 cursor-pointer"
          [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
          (click)="clearSearch()"
        ></i>
        <input
          class="width-200"
          type="text"
          pInputText
          [placeholder]="'lbl_search' | translate"
          [(ngModel)]="searchValue"
        />
      </span>
    </div>

    @if(filterList && filterList?.length>0){
    <app-quick-filter-form
      [fields]="filterList?.slice(0, 3)"
      [moduleName]="moduleName"
      class="relative mr-2"
      [attributeLabels]="attributeLabels"
      (onValueChange)="onFilterValueChange(false, $event)"
    ></app-quick-filter-form>

    } @if(filterList && filterList.length>3){
    <app-filter-box
      [filterList]="filterList?.slice(3, filterList.length)"
      [moduleName]="moduleName"
      [show]="show"
      [position]="position"
      [selectedFilterCount]="selectedFilterCount"
      [filterAttributeLabels]="attributeLabels"
      class="filter-box-div"
      (click)="onFiltersPopup()"
      (onValueChange)="onFilterValueChange(true, $event)"
      (onToggleOverLay)="show = false"
      isFilterBox="true"
    ></app-filter-box>

    <button
      class="flex width-90 surface-ground text-color border-0 cursor-pointer"
      pButton
      label="{{ 'filters_lbl' | translate }}"
    >
      <span
        ><i
          class="{{ miIcons.TEXT_ALIGN }} inline-block wh-13 relative right-5"
        ></i
      ></span>
    </button>
    }
  </div>

  <div
    class="flex-1 flex align-items-center justify-content-end custom-feed-container"
  >
    <button
      class="flex text-color nav-item-border bg-white cursor-pointer align-items-center font-14 mr-2"
      pButton
      label="{{ 'customize_feeds' | translate }}"
      (click)="openDialog()"
    >
      <span
        ><i
          class="{{
            miIcons.DASHBOARD_MANAGE_COLUMN
          }} wh-18 inline-block relative right-5 top-3"
        ></i
      ></span>
    </button>
  </div>
</div>


