
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MiErrorComponent } from 'app/modules/shared/components/mi-error/mi-error.component';
import { CounterComponent } from 'app/modules/shared/components/mi-fields/counter/counter.component';
import { HourMinuteSecondComponent } from 'app/modules/shared/components/mi-fields/hour-minute-second/hour-minute-second.component';
import { MiFieldsComponent } from 'app/modules/shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from 'app/modules/shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-time-settings',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TabViewModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, TranslateModule, HourMinuteSecondComponent, CounterComponent],
  templateUrl: './time-settings.component.html',
  styleUrl: './time-settings.component.scss'
})
export class TimeSettingsComponent {
  @Input() group: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;


  constructor(
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    console.log(this.group);
  }

}
