<p-breadcrumb [model]="routePath"></p-breadcrumb>

<div class="shift-details mt-2">
    <form  [formGroup]="addShiftTemplateForm">
        <div class="grid pl-3 pr-2">
            <div class="col-12 tab-group">
                <div class="card p-fluid">
                    <label class="w-full mt-0 uppercase text-lg font-semibold"
                        translate>shift.lbl_shift_details</label>

                    <div class="w-full formgrid grid pt-3">
                        <div class="col-12 pl-0 pr-0">
                            <div class="field col-6 align-content-end md:col-6">
                                <label translate>shift.lbl_shift_name</label>
                                <span class="required ml-1">*</span>
                            </div>
                            <div class="col-6">
                                <input type="text" (blur)="shiftNameBlur()"  pInputText formControlName="name" class="w-full" />
                                @if (addShiftTemplateForm.controls.name.errors && (addShiftTemplateForm.controls.name.dirty || addShiftTemplateForm.controls.name.touched)) {
                                    <div class="form-errors ">
                                    <div [hidden]="!addShiftTemplateForm.controls.name.errors.required" class="text-err" translate>
                                        shiftTemplate.shift_name_is_required
                                    </div>
                                    <div [hidden]="!addShiftTemplateForm.controls.name.errors.pattern" class="text-err" translate>
                                        shiftTemplate.shift_name_pattern
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>


                    <div class="w-full formgrid grid pt-3">
                        <div class="col-3 pl-0 pr-0">
                            <div class="field col-12 align-content-end">
                                <label translate>shift.start_time</label>
                                <span class="required ml-1">*</span>
                            </div>
                            <div class="col-12">
                                <p-calendar formControlName="startTime" icon="mi-lg mi-endofshift" [showIcon]="true" [showTime]="true" timeOnly="true"
                                    [hourFormat]="hourFormat" (onInput)="onStartTimeInput($event)" (onSelect)="onStartTimeSelect($event)"></p-calendar>
                                    @if (addShiftTemplateForm.controls.startTime.errors && (addShiftTemplateForm.controls.startTime.dirty || addShiftTemplateForm.controls.startTime.touched)) {
                                        <div class="form-errors ">
                                        <div [hidden]="!addShiftTemplateForm.controls.startTime.errors.required" class="text-err" translate>
                                            shiftTemplate.start_time_is_required
                                        </div>
                                    </div>
                                    }
                            </div>
                        </div>
                        <div class="col-3 pl-0 pr-0">
                            <div class="field col-12 align-content-end">
                                <label translate>shift.end_time</label>
                                <span class="required ml-1">*</span>
                            </div>
                            <div class="col-12">
                                <p-calendar formControlName="endTime" icon="mi-lg mi-endofshift" [showIcon]="true" [showTime]="true" timeOnly="true"
                                    [hourFormat]="hourFormat" (onInput)="onEndTimeInput($event)"  (onSelect)="onEndTimeSelect($event)"></p-calendar>
                                    @if (addShiftTemplateForm.controls.endTime.errors && (addShiftTemplateForm.controls.endTime.dirty || addShiftTemplateForm.controls.endTime.touched)) {
                                        <div class="form-errors ">
                                        <div [hidden]="!addShiftTemplateForm.controls.endTime.errors.required" class="text-err" translate>
                                            shiftTemplate.end_time_is_required
                                        </div>
                                    </div>
                                    }
                                    @if (isTimeValid) {
                                        <div class="form-errors " translate>
                                            shiftTemplate.invalid_time
                                        </div>
                                    }
                            </div>
                        </div>

                        <div class="col-3 pl-0 pr-0">
                            <div class="field col-12 align-content-end">
                                <label class="flex" translate>shiftTemplate.calculate_hours</label>
                            </div>
                            <div class="col-12">
                                <input  type="text" pInputText formControlName="calculateHoursSuffixed" [(ngModel)]="shiftHoursSuffixed" class="w-full" disabled/>
                                <input style="display: none;" type="text" pInputText formControlName="calculateHours" [(ngModel)]="shiftHours" class="w-full" disabled/>
                            </div>
                        </div>
                    </div>

                    <!-- add break -->
                        <div formArrayName="breakFields">
                            <div class="mt-4 mb-3">
                                <label class="w-full mt-0 uppercase text-lg font-semibold" translate>shift.lbl_add_break</label>
                                @for (data of breakFields.controls; track data; let idx = $index) {
                                <div [formGroupName]="idx">
                                    <div class="w-full formgrid grid pt-2">
                                        <div class="w-full formgrid grid pt-3">
                                            <div class="col-3 pr-0">
                                                <div class="field col-12 align-content-end">
                                                    <label class="flex" translate>shift.lbl_break_start_time_from</label>
                                                </div>
                                                <div class="col-12">
                                                    <p-calendar [showIcon]="true" icon="mi-lg mi-endofshift" formControlName="breakStartTime" [showTime]="true"
                                                        timeOnly="true" [hourFormat]="hourFormat"></p-calendar>
                                                </div>
                                            </div>
                                            <div class="col-3 pr-0 pl-0">
                                                <div class="field col-12 align-content-end">
                                                    <label class="flex" translate>shift.lbl_break_end_time_from</label>
                                                </div>
                                                <div class="col-12 pr-0">
                                                    <p-calendar [showIcon]="true" icon="mi-lg mi-endofshift" formControlName="breakEndTime" [showTime]="true"
                                                        timeOnly="true" [hourFormat]="hourFormat"></p-calendar>
                                                </div>
                                            </div>
                                            <div class="col-3 mr-2 pr-0 pl-0 align-content-end">
                                                <p-button (click)="removeBreak(idx)" class="delete-btn" icon="mi-lg basic-delete"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                                @if (breakFields.length < 2) {
                                    <div class="add-more pt-1 pb-3 mt-2">
                                        <i class="mi-lg mi-add" (click)="addMoreSlots()"></i>
                                        <label class="ml-1 pointer" (click)="addMoreSlots()" translate>shift.lbl_add_break</label>
                                    </div>
                                }
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="col-12 pr-3 flex justify-content-end flex-wrap">
    @if (!shiftTemplateId) {
        <button pButton class="mr-2 bg-white border-600 mr-2 default-text-light-black-color cursor-pointer"
        [label]="'shiftTemplate.lbl_save_and_add_another' | translate" (click)="onSaveShiftTemplate(1)"></button>
    }
    <p-button class="flex align-items-center justify-content-center cursor-pointer" (click)="onSaveShiftTemplate(0)"
        [label]="saveBtnLabel"></p-button>
</div>