import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { PanelModule } from 'primeng/panel';
import { AppSettings } from '../../../../shared/app.settings';
import { MiErrorComponent } from '../../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { AttributeLabelComponent } from '../attribute-label/attribute-label.component';

import { JsonPipe, NgClass } from '@angular/common';
import * as _ from 'lodash';
import { AvatarModule } from 'primeng/avatar';
import { AppIcons } from '../../../../shared/app.icons';
import { Countries } from '../../../../shared/countries';
import { Language } from '../../../../shared/models/language';
import { ConfigService } from '../../../../shared/services/config.service';
import { MiValidationsService } from '../../../../shared/services/mi-validations.service';
import { EntityService } from '../../../../shared/services/entity.service';
import { TranslateModule } from '@ngx-translate/core';
import { StopLocationsComponent } from '../stop-locations/stop-locations.component';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AssignVehicleFromBookingsComponent } from '../assign-vehicle-from-bookings/assign-vehicle-from-bookings.component';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'app-booking-form',
  standalone: true,
  imports: [AccordionModule, AvatarModule, NgClass, DropdownModule, FormsModule, ReactiveFormsModule, JsonPipe, MiFieldsComponent, MiErrorComponent, StopLocationsComponent, ButtonModule, MiTooltipComponent, PanelModule, InputNumberModule, AttributeLabelComponent, AvatarModule, TranslateModule, AssignVehicleFromBookingsComponent],
  templateUrl: './booking-form.component.html',
  styleUrl: './booking-form.component.scss'
})
export class BookingFormComponent implements OnInit, OnDestroy {
  items: { label?: string; icon?: string; separator?: boolean }[] = [];
  miIcons = AppIcons;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() groups: any;
  @Input() submitBtnText: string;
  @Input() attributeValues: [];
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Input() isEdit: boolean;
  @Input() saveBtnId: string;
  miFormGroup: FormGroup;
  formGroupFields = [];
  public fields = [];
  formGroup: FormGroup;
  entityCodes = AppSettings.ENTITY_CODE;
  currentSelectedCode: string;
  country: Countries;
  language: Language;
  excludedCodes = [
    'transportation',
    'vehicle_preference'
  ];
  vehiclePreference = 'vehicle_preference';
  includeSpacer = [
    "child_seat",
    "luggage"
  ];
  excludeToggle = [
    'wheelchair',
    'additional_vehicle_requirement',
    'number_of_passenger'
  ];
  skipGroup: string[] = ['booking_pass', 'passenger', 'flight_details', 'admin_note'];
  excludeFields = ['booking_pickup_location_address',
    'booking_pickup_location_geolocation',
    'booking_stops_location_address',
    'booking_stops_location_geolocation',
    'booking_dropoff_location_address',
    'booking_dropoff_location_geolocation'
  ]
  bookingIcons = {
    'number_of_passenger': AppIcons.BOOKING_PASSENGER,
    'child_seat': AppIcons.BABY_SEAT,
    'luggage': AppIcons.BOOKING_LUGGAGES,
    'wheelchair': AppIcons.WHEEL_CHAIR,
    'additional_vehicle_requirement': AppIcons.BOOKING_ADDITIONAL_VEHICLE,
    'ride_preference': AppIcons.BOOKING_ADDITIONAL,
    'flight_details': AppIcons.BOOKING_AIRPORT,
    'driver_instructions': AppIcons.BOOKING_NOTES,
  };

  ride_preference_code = 'ride_preference'

  stopPlaceAttributeCode = 'stops';
  usersForm: FormGroup;
  stopField;
  isReady = false;
  isManualDispatch = false;

  manualDispatch = {
    "attributeCode": "vehicle_assignment",
    "presetValues": [
      {
        "labelKey": 'assign_vehicle_later',
        "labelValue": this.cs.getLabel('bookings.assign_vehicle_later'),
        "labelIcon": "mi-basic-clock",
        "parentLabelKey": null
      },
      {
        "labelKey": 'assign_vehicle_now',
        "labelValue": this.cs.getLabel('bookings.assign_vehicle_now'),
        "labelIcon": "mi-car",
        "parentLabelKey": null
      }
    ],
    "uiColumns": 6,
    "inputCode": "radioButton",
    "optionVertical": true,
  };

  selectVehicle = {
    "attributeCode": 'selectVehicle',
    "presetValues": [
      {
        "labelKey": this.cs.getLabel('bookings.any_vehicle'),
        "labelValue": this.cs.getLabel('bookings.any_vehicle'),
        "parentLabelKey": null
      },
    ],
  };
  selectedVehicle: any;
  isVisibleAssignVehicle: boolean = false;
  isVisibleSelectVehicle: boolean = false;
  assignVehicleControlCode = 'vehicle_assignment';
  stopsAttributeCode = "booking_stops_location_geolocation";
  manualDispatchConst = "Manual Dispatch";
  assignVehicleNow = "assign_vehicle_now";
  selectVehicleCode = 'selectVehicleCode';
  transportationTypeCode = "transportation_type";
  trip_code = 'trip'
  excludeLabels = ['trip', 'transportation_mode']
  horizontalForm = ['luggage', 'child_seat']
  returnTimeAndDateCode = "return_time_and_date";
  journeyTimeCode = "journey_time";
  departureTimeAndDate = "departure_time_and_date";
  airportValue = "Airport";
  isShowAirportDetails = false;
  tripCode = "trip";
  roundTripValue = ["Round Trip", "Hourly Trip"];
  isRoundTrip = false;
  isHourlyTrip = false;
  formValue: any;
  vehicleTypePreference: 'vehicle_type_preference';
  vehicleBodyType: string;
  private subscriptions: Subscription[] = [];

  constructor(public cs: CommonBindingDataService, private validationsService: MiValidationsService,
    private entityService: EntityService, private configService: ConfigService, private fb: FormBuilder
  ) {

  }

  ngOnInit() {
    this.initializeVars();
    this.formGroup = new FormGroup({
      value: new FormControl(1)
    });


    const apiCalls = [];
    apiCalls.push(this.fetchBookingLocationAddress('booking_pickup_location_geolocation', AppSettings.ENTITY_CODE.PICKUP_AUTOCOMPLETE_VIEW));
    apiCalls.push(this.fetchBookingLocationAddress('booking_dropoff_location_geolocation', AppSettings.ENTITY_CODE.DROPOFF_AUTOCOMPLETE_VIEW));


    forkJoin(apiCalls).subscribe(() => {
      this.buildForm();
      this.isReady = true;

      this.miFormGroup.valueChanges.subscribe(value => {
        this.formValue = value;
        this.groups.forEach(group => {
          group.codeActive = false;
          group.fields.forEach(field => {
            if (this.formValue.hasOwnProperty(field.attributeCode) && this.formValue[field.attributeCode]) {
              group.codeActive = true; 
            }
          });
        });

      });


      if (this.isEdit) {
        this.miFormGroup.controls['booking_pickup_location_geolocation'].setValue({
          address: this.attributeValues["booking_pickup_location_address"],
          geoLocation: this.attributeValues["booking_pickup_location_geolocation"]
        });

        this.miFormGroup.controls['booking_dropoff_location_geolocation'].setValue({
          address: this.attributeValues["booking_dropoff_location_address"],
          geoLocation: this.attributeValues["booking_dropoff_location_geolocation"]
        });

        const value = this.miFormGroup.controls[this.tripCode].value;
        this.setTrip(value);

      } else {
        this.miFormGroup.get(this.tripCode).setValue("One Way Trip");
      }

      const pickDropAddressLocationsValuePairs = [
        { controlCode: 'booking_pickup_location_geolocation', targetCode: 'booking_pickup_location_address' },
        { controlCode: 'booking_dropoff_location_geolocation', targetCode: 'booking_dropoff_location_address' },
      ];


      pickDropAddressLocationsValuePairs.forEach(pair => {
        const subscription = this.miFormGroup.controls[pair.controlCode]?.valueChanges.subscribe(value => {
          this.miFormGroup.controls[pair.targetCode].setValue(value);
        });
        this.subscriptions.push(subscription);
      });

      const dispatchControlCode = 'vehicle_dispatch_preference';
      const dispatchSubscription = this.miFormGroup.controls[dispatchControlCode]?.valueChanges.subscribe(value => {
        this.isManualDispatch = value === this.manualDispatchConst;
      });

      const assignVehicleSubscription = this.miFormGroup.controls['vehicle_assignment']?.valueChanges.subscribe(value => {
        this.isVisibleSelectVehicle = value === this.assignVehicleNow;
      });

      const vehiclePreference = this.miFormGroup.controls['vehicle_type_preference']?.valueChanges.subscribe(value => {
        this.vehicleBodyType = value
      });


      const tripSubscription = this.miFormGroup.controls[this.tripCode]?.valueChanges.subscribe(value => {
        this.setTrip(value);

      });

      const returnTime = this.miFormGroup.controls[this.returnTimeAndDateCode]?.valueChanges.subscribe(value => {
        const departureDate = this.miFormGroup.get(this.returnTimeAndDateCode).value;
        const returnDate = this.miFormGroup.get(this.returnTimeAndDateCode).value;
        const journeyTime = returnDate.diff(departureDate, 'millisecond');

        this.miFormGroup.get(this.journeyTimeCode).setValue(journeyTime);
        this.miFormGroup.get(this.journeyTimeCode).updateValueAndValidity();

      });




      const transportationTypeSubscription = this.miFormGroup.controls[this.transportationTypeCode]?.valueChanges.subscribe(value => {
        this.isShowAirportDetails = value === this.airportValue;
        if (this.isShowAirportDetails) {
          const itemToRemove = 'flight_details';
          this.skipGroup = _.without(this.skipGroup, itemToRemove);
          ['flight_number', 'flight_name', 'arrival_or_departure_time'].forEach(flightDetailsCode => {
            this.miFormGroup.controls[flightDetailsCode].setValue('');
          });
        } else {
          this.skipGroup = ['booking_pass', 'passenger', 'flight_details'];
        }
      });

      this.subscriptions.push(dispatchSubscription, tripSubscription);
      this.subscriptions.push(assignVehicleSubscription);
      this.subscriptions.push(transportationTypeSubscription, returnTime);


    });
  }

  private setTrip(value) {
    this.isRoundTrip = this.roundTripValue.includes(value);
    const returnTimeAndDateCode = 'return_time_and_date';

    if (!this.isRoundTrip) {
      this.miFormGroup.get(returnTimeAndDateCode).setValidators(null);
      this.miFormGroup.get(returnTimeAndDateCode).updateValueAndValidity();

    } else {
      this.isHourlyTrip = value === "Hourly Trip" ? true : false;
      this.miFormGroup.get(returnTimeAndDateCode).setValidators(Validators.required);
      this.miFormGroup.get(returnTimeAndDateCode).updateValueAndValidity();
    }
  }

  showAssignVehicle() {
    this.isVisibleAssignVehicle = !this.isVisibleAssignVehicle;
  }

  initializeVars() {
    this.items = [
      {
        label: 'Refresh',
        icon: 'pi pi-refresh'
      },
      {
        label: 'Search',
        icon: 'pi pi-search'
      },
      {
        separator: true
      },
      {
        label: 'Delete',
        icon: 'pi pi-times'
      }
    ];
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }


  fetchBookingLocationAddress(attributeCode: string, viewCode: string) {
    const requestBody = {
      viewCode: viewCode,
      countryCode: this.country[0].countryCode,
      forTenantCode: this.configService.getForTenantCode(),
      searchText: "",
      actionStatus: "",
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
    }
    return this.entityService.autoComplete(AppSettings.ENTITY_CODE.BOOKING, requestBody).pipe(
      tap(res => {
        let attributeKey;
        if (attributeCode === 'booking_pickup_location_geolocation') {
          attributeKey = 'booking_pickup_location_address';
          this.setPresetValuesToLocationsFields(res.data, attributeKey, attributeCode);


        } else if (attributeCode === 'booking_dropoff_location_geolocation') {
          attributeKey = 'booking_dropoff_location_address';
          this.setPresetValuesToLocationsFields(res.data, attributeKey, attributeCode);
          this.setPresetValuesToLocationsFields(res.data, 'booking_stops_location_address', 'booking_stops_location_geolocation');
        }
      }));
  }


  setPresetValuesToLocationsFields(data, attributekey, attributeCode) {
    const locationAddress = data.map(item => item.values) || [];
    const locationFilter = _.chain(this.groups).find({ code: 'transportation' }).get('fields').find({ attributeCode }).value();
    if (locationFilter) {
      locationFilter.presetValues = locationAddress.map(address => {
        if (attributekey === 'booking_stops_location_address') {
          return address?.[attributekey]?.map((add, index) => {
            return {
              labelKey: add,
              labelValue: {
                address: add,
                geoLocation: address?.[attributeCode][index]
              }
            };

          })[0]
        } else if (address?.[attributekey]) {
          return {
            labelKey: address?.[attributekey],
            labelValue: {
              address: address?.[attributekey],
              geoLocation: address?.[attributeCode]
            }
          };

        }
      }
      ).filter(Boolean);
    }
  }

  getValues() {
  }

  get value() {
    return this.miFormGroup.value;
  }

  private buildForm() {
    this.buildGroups();
  }

  private buildGroups() {
    const miFormControl = this.getFormControl();
    this.miFormGroup = new FormGroup(miFormControl);
  }

  resetForm() {
    this.miFormGroup.reset();
  }

  private getFormControl() {
    _.remove(this.groups, {
      code: "passenger"
    });

    for (const group of this.groups) {
      for (const field of group.fields) {
        this.currentSelectedCode = field.inputCode;
        const validators = this.validationsService.addValidator(field.validation, this.currentSelectedCode);
        field.fieldName = field.attributeCode;
        const value = this.attributeValues ? this.attributeValues[field?.attributeCode] : '';
        if (field.attributeCode === this.stopsAttributeCode) {
          this.stopField = field;
          this.formGroupFields[this.stopPlaceAttributeCode] = new FormArray([new FormControl(value, validators)], validators);
        } else {
          this.formGroupFields[field.attributeCode] = new FormControl(value, validators);
        }
      }
    }
    this.formGroupFields[this.assignVehicleControlCode] = new FormControl();
    this.formGroupFields[this.selectVehicleCode] = new FormControl();
    return this.formGroupFields;
  }

  addStops() {
    const stop = new FormControl('');
    this.formGroupFields[this.stopPlaceAttributeCode].push(stop);
  }

  get stops() {
    return this.miFormGroup.get(this.stopPlaceAttributeCode) as FormArray;
  }

  submitData(event) {
    if (this.miFormGroup.valid) {
      this.saveData.next(this.miFormGroup.value);
    } else {
      this.miFormGroup.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');
      this.scrollTo(firstElementWithError);
    }
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getIcon(id) {
    return this.bookingIcons[id];
  }

  removeStops(index) {
    this.stops.controls.splice(index, 1);
  }

  onSelectedVehicle(vehicle) {
    this.selectVehicle.presetValues.push({
      labelKey: vehicle.name_code,
      labelValue: vehicle.id,
      parentLabelKey: ''
    });
    this.miFormGroup.controls[this.selectVehicleCode].setValue(vehicle.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
