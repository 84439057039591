export interface EntityList {
  limit: number;
  offset: number;
  searchStr: string;
  defaultSortColumn?: string;
  defaultSortType?: string;
  filters?: Filter[];
  forTenantCode: string;
  countryCode?: string;
  deleted?: string;
  actionStatus?: string;
  status?: string;
  userName?: string;
  tagIds?: string[];
}

export interface Filter {
  attributeCode?:any,
  attributeId?: any;
  attributeValue: AttributeValue;
}

export interface AttributeValue {
}


export interface EntityCount {
  forTenantCode: string,
  entityCode: string
}

export interface EntityCountsResponse {
  tenantId: string;
  entityCode: string;
  counts: Counts;
}

export interface Counts {
  additionalProp1: number;
  additionalProp2: number;
  additionalProp3: number;
}


export interface ActionStatusUpdate {
  forTenantCode: string;
  entityCode: string;
  actionStatus: string;

}