import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { OrderListModule } from 'primeng/orderlist';
import { AppIcons } from '../../app.icons';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { AdvanceFilterFormComponent } from '../advance-filter-form/advance-filter-form.component';
import { FilterService } from '../advance-filter-form/filter.service';

interface City {
  name: string,
  code: string
}
interface Product {
  name: string,
  qty: number,
}

@Component({
  selector: 'app-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
  standalone: true,
  imports: [DialogModule, OrderListModule, AdvanceFilterFormComponent, DividerModule, ButtonModule, JsonPipe, TranslateModule, NgClass]
})
export class FilterBoxComponent {
  miIcons = AppIcons;

  @Input() selectedFilterCount: number;
  @Input() filterList: any;
  @Input() filterAttributeLabels;
  @Output() onDeleteRow: EventEmitter<any> = new EventEmitter();
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();
  @Input() moduleName: string;

  appliedFilterList = [];
  visibleFilter: boolean = false;
  checked: boolean = false; 
  filterPosition: string = 'center';
  cities!: City[];
  selectedFilter = { attributeCode: 1 }
  citiesOption;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Input() show;
  @Input() position;
  @Input() isFilterBox: boolean = false;
  @Input() attributeValues;
  @ViewChild(AdvanceFilterFormComponent) miForm: AdvanceFilterFormComponent;
  status;


  constructor(public cs: CommonBindingDataService, private renderer: Renderer2, private el: ElementRef,
    private cd: ChangeDetectorRef, private filterService:FilterService) {

  }

  ngOnInit() {

    const elements = document.querySelectorAll('.filter-item');
    elements[0]?.classList.add('.active');
    // console.log("this.attributeValues, appliedFilterList", this.attributeValues, this.appliedFilterList)
    // const prevData = this.filterService.getFilterState(this.moduleName);

    // this.appliedFilterList = prevData.apiData || [];
    if (this.filterList.length > 0) {
      this.selectedFilter = { attributeCode: this.filterList[0].attributeCode };
    }
  }

  onHide() {
    this.visibleFilter = false;
    this.onToggleOverLay.emit(this.visibleFilter);
  }

  onDelete(event) {
    this.onDeleteRow.emit(event);
  }


  selectionChange(event) {
    this.selectedFilter = event;
  }


  onFilterValueChange(event) {
    this.appliedFilterList = event;
    console.log("appliedFilterList", this.appliedFilterList)
  }


  onApply() {
    const value = this.appliedFilterList;
    this.onValueChange.emit(value);
    this.onHide();
  }

  toggleClass(event: any, className: string) {
    const elements = document.querySelectorAll('.filter-item');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');

    }
    this.renderer.addClass(event.target, className);
  }


  getCount(param: string | any[]): number {
    if (Array.isArray(param)) {
      return param.length;
    } else if (typeof param === 'string') {
      return 1;
    } else {
      throw new Error('Invalid parameter type');
    }
  }


  onClearAll() {
    this.filterList = [];
    this.cd.detectChanges();
    this.appliedFilterList = []; 
    this.miForm.resetForm(); 
    this.onApply();
  
  }
}
