
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Column } from '../../../shared/components/mi-table/modal/table';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { forkJoin } from 'rxjs';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { EntityCount, EntityCountsResponse, EntityList } from '../../../vehicles/modal/entityModal';
import { BookingResponse, BookingStatus } from '../../modal/bookingModal';
import { BookingService } from '../../services/booking.service';
import { BookingTableComponent } from '../booking-table/booking-table.component';
import { TableSkeltonComponent } from 'app/modules/shared/components/mi-defer/table-skelton/table-skelton.component';
import { ErrorSkeltonComponent } from 'app/modules/shared/components/mi-defer/error-skelton/error-skelton.component';

enum AttributeCode {
  VehicleType = 'vehicle_type',
  VehicleStatus = 'vehicle_status',
  Group = 'group',
}


@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  standalone: true,
  imports: [ErrorSkeltonComponent, TableSkeltonComponent, RouterOutlet, RouterLink, RouterLinkActive, RouterModule, ToastModule, BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, BookingTableComponent, AsyncPipe]
})

export class BookingsComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  filterList;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: any;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  deleteBookingMsg: string;
  deleteBookingHeaderMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  filterFieldsArray: any[];
  isReady: boolean = false;
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  currentRow;
  visible: boolean = false;
  totalRecords: number = 0;
  bookingStatus: BookingStatus[];
  isBookingHistory: boolean = false;
  constructor(private entityService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService, private bookingService: BookingService,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    const href = this.router.url;
    this.isBookingHistory = href.includes('booking-history');
    this.initializeRoutePath();
    this.initializeModuleHeaderSecondaryBtnMenuItems();
    this.initializeOtherVariableAndAPI();
  }

  private initializeOtherVariableAndAPI() {
    this.btnLabel = this.cs.getLabel('bookings.add_bookings');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.deleteBookingMsg = this.cs.getLabel('bookings.confirm_delete_msg');
    this.deleteBookingHeaderMsg = this.cs.getLabel('bookings.confirm_delete_header');
    this.entityData.forTenantCode = this.configService.getForTenantCode()
    this.getData();
    this.fetchBookingLocationAddress('booking_pickup_location_address', 'PICKUP_AUTOCOMPLETE_VIEW');
    this.fetchBookingLocationAddress('booking_dropoff_location_address', 'DROPOFF_AUTOCOMPLETE_VIEW');

  }

  private initializeRoutePath() {
    this.routePath = [
      {
        label: this.isBookingHistory ? this.cs.getLabel('bookings.booking_history'): this.cs.getLabel('bookings.bookings'),
        routerLink: this.isBookingHistory ? 'bookings/booking-history' : 'bookings/current-bookings'
      },
    ];
  }

  private initializeRowItems() {

  }


  private initializeModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('bookings.import_booking'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => this.downloadImportTemplate('Import Template.xlsx', AppSettings.ENTITY_CODE.BOOKING)
      },
      {
        label: this.cs.getLabel('vehicle.download_important_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => this.downloadImportTemplate('Import Guide.xlsx', AppSettings.ENTITY_CODE.BOOKING)
      },
      { separator: true },
      {
        label: this.cs.getLabel('vehicle.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  private downloadImportTemplate(fileName: string, entityCode: string) {
    this.entityService.downloadImportTemplate(entityCode).subscribe(
      (result: any) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(result);
        a.download = fileName;
        a.click();
      },
      err => {
        console.log(err);
      });
  }

  public onChange(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', AppSettings.TENANT_CODE);
          formData.append('entityCode', AppSettings.ENTITY_CODE.BOOKING);
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.cs.importEntity(formData, AppSettings.ENTITY_CODE.BOOKING).subscribe(res => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }

  }


  setCurrentRowData(event) {
    this.currentRow = event;

    this.initializeRowItems();
  }

  update() {
    this.onUpdateBooking(this.currentRow.id);
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteBookingHeaderMsg,
      message: this.deleteBookingMsg,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        if (deleteType === 'row') {
          this.onBookingDelete(this.currentRow.id);
        }
      },
      reject: () => {
      }
    });
  }

  getTableView() {
    const bookingTableView = AppSettings.ENTITY_CODE.BOOKING + '_' + AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW;
    const savedBookingView = this.entityService.getMiState(bookingTableView);
    if (savedBookingView) {
      this.setBookingView(savedBookingView);
    } else {
      this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, this.country[0]?.tenantId, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
        if (res) {
          this.entityService.setMiState(bookingTableView, res);
          this.setBookingView(res);
        }
      });
    }
  }

  setBookingView(res) {
    this.isLoading = false;
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);

    this.allColumns = this.cs.getColumns(this.data);
    this.searchEntity();

    this.allColumns?.forEach((key, index) => {
      this.allColumns[index] = key;
      this.allColumns[index].field = key.attributeCode;
      this.allColumns[index].header = this.cs.getLabelValue(
        "booking" + ".fields." + key.attributeCode + ".label",
        this.attributeLabels,
        key.attributeCode
      );
    });

    this.filterAvailableColumns = _.clone(this.allColumns);
  }

  setStatusFilter() {

    const locationFilter = this.filterList?.find(filter => filter.attributeCode === "booking_status");
    if (locationFilter) {
      locationFilter.presetValues = this.bookingStatus.map(booking => ({
        labelKey: AppSettings.BOOKING.STATUS[booking.bookingCode],
        labelValue: booking.bookingStatusId,
      }));
    }

  }

  fetchBookingLocationAddress(attributeCode: string, viewCode: string) {
    const requestBody = {
      viewCode: viewCode,
      countryCode: this.country[0].countryCode,
      forTenantCode: this.configService.getForTenantCode(),
      searchText: "",
      actionStatus: "",
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
    }
    this.entityService.autoComplete(AppSettings.ENTITY_CODE.BOOKING, requestBody).subscribe(res => {
      let attributekey: string;
      if (attributeCode === 'booking_pickup_location_address') {
        attributekey = 'booking_pickup_location_address';
      } else if (attributeCode === 'booking_dropoff_location_address') {
        attributekey = 'booking_dropoff_location_address';
      } else {
        return;
      }
      const locationAddress = res?.data.map(item => item.values?.[attributekey]) || [];
      const locationFilter = this.filterList?.find(filter => filter.attributeCode === attributeCode);
      console.log(locationAddress)
      if (locationFilter) {
        locationFilter.presetValues = locationAddress.map(address => ({
          labelKey: address,
          labelValue: address,
        }));
      }
    });
  }

  getFilterView() {
    const bookingAdvancedFilterView = AppSettings.ENTITY_CODE.BOOKING + '_' + AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW;
    const bookingFilter = this.entityService.getMiState(bookingAdvancedFilterView);
    if (bookingFilter) {
      this.setAdvancedFilterView(bookingFilter);
    }else {
      this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, this.country[0]?.tenantId, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
        if (filterResponse) {
          this.entityService.setMiState(bookingAdvancedFilterView, filterResponse);
          this.setAdvancedFilterView(filterResponse);
        }
      });
    }
  }
  setAdvancedFilterView(filterResponse) {
    this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.filterList = this.cs.getColumns(filterResponse);
    this.filterList = _.sortBy(this.filterList, 'attributeIndex');
  }

  getData() {

    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount(),
      setBookingStatus: this.setBookingStatus()
    })
  }


  setBookingStatus() {
   const tempStatus= this.entityService.getMiState('BOOKING_STATUS');
    if (tempStatus) {
      this.bookingStatus = tempStatus;
      this.setStatusFilter();
    } else { 
      this.bookingService.getBookingStatus().subscribe((res: BookingStatus[]) => {
        this.entityService.setMiState('BOOKING_STATUS', res);
        this.bookingStatus = res;
        this.setStatusFilter();
      });
    }
  }

  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING, this.entityData).subscribe((res: BookingResponse) => {
      if (!res) {
        console.error('Invalid response.');
        return;
      }
      this.listColumns = res?.data[0]?.values;
      this.pagination = res.pagination;
      this.totalRecords = res.count;

      this.tableData = res.data?.map(data => {
        const relatedData = data?.relatedData || [];

        const relatedPassengers = relatedData
          .filter(related => related.entityCode === "booking_pass")
          .map(related => ({
            id: related.id,
            ...related.values
          }));

        const relatedVehicle = relatedData
          .filter(related => related.entityCode === "vehicle")
          .map(related => ({
            id: related.id,
            ...related.values
          }));

        return {
          id: data.id,
          ...data.values,
          passengers: relatedPassengers,
          vehicle: relatedVehicle
        };
      }) || [];
    });
  }

  onAddBooking() {
    this.router.navigate(['app/bookings/add']);
  }

  getLabel(params) {
    return params + 'hi';
  }

  onRowClicked(e: any) {
    alert('Row click : ' + JSON.stringify(e));
  }

  onActionBtnClicked(e: any) {
    alert('Action btn click : ' + JSON.stringify(e));
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onConfirmDelete(event) {

  }

  onBookingDelete(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.BOOKING,
      entityIds: [
        event
      ]
    };
    this.entityService.deleteEntityActionStatus(requestBody).subscribe(response => {
      this.searchEntity();
    })
  }

  onUpdateBooking(bookingId) {
    this.router.navigate(['app/bookings/edit/' + bookingId]);
  }


  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/bookings/' + event.data.id,]);
    }
    this.router.navigate(['app/bookings/' + event.data.id]);
  }


  onFilterValueChange(event) {
    const vData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(vData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function (item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeId;

      }
    }), function (element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterAvailableColumns, 'filter');
    this.searchEntity();
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.BOOKING,
      countryCode: country[0].countryCode,
    };

    this.entityService.getEntityCount(AppSettings.ENTITY_CODE.BOOKING, entityCountPayload).subscribe((res: EntityCountsResponse) => {
      this.entityCount = res;
    })
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      case 1:
        this.entityData.actionStatus = AppSettings.BOOKING.TAB_LIST.CRITICAL;
        this.searchEntity();
        break;

      case 2:
        this.entityData.actionStatus = AppSettings.BOOKING.TAB_LIST.WATCHLIST;
        this.searchEntity();
        break;

      default:
        break;
    }
  }


}
