import { CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { AppIcons } from '../../../shared/app.icons';
import { Column } from '../../../vehicles/modal/attributeModal';
import { Product } from '../../../shared/models/product';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AppSettings } from '../../../shared/app.settings';
import { DatumValues, DriverList } from '../../../driver/modal/driverListModal';
import { EntityService } from '../../../shared/services/entity.service';
import { EntityList } from '../../../vehicles/modal/entityModal';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-selected-driver',
  standalone: true,
  imports: [
    DialogModule,
    CdkDropListGroup,
    PanelModule,
    FormsModule,
    InputTextModule,
    CheckboxModule,
    CdkDropList,
    CdkDrag,
    ButtonModule,
    TranslateModule,
    JsonPipe
  ],
  templateUrl: './selected-driver.component.html',
  styleUrl: './selected-driver.component.scss'
})
export class SelectedDriverComponent implements OnInit, OnChanges {
  miIcons = AppIcons;
  @Input() selectedColumns = [];
  @Input() selectedDriver = [];
  headerText;
  @Input() isShow: boolean;
  @Input() manageColumnFromLocal: boolean = false;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onManageColumns = new EventEmitter<Column[]>();
  @Output() onChangeSelectedDriver: EventEmitter<any> = new EventEmitter<any>();
  draggedProduct: Column | undefined | null;
  allDriverData: any = [];
  allDriverDataTemp: any = [];
  targetProducts!: Product[];
  driverListCount: Column[];
  listColumns: DatumValues;
  entityData: EntityList = {
    limit: AppSettings.ENTITY_DATA_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: 't001',
    actionStatus: ''
  };

  defaultIconUrl: string = '/assets/images/mi-theme/light/basic/default_user.svg';
  private maxDataReached = {
    entityDataForManageGroupDriver: false,
  }

  constructor(private cs: CommonBindingDataService, private cdr: ChangeDetectorRef, private driverService: EntityService) {

  }
  ngOnInit() {
    this.allDriverData = [];
    this.searchEntity();
    this.getDriverData();

    setTimeout(() => {
      const cb = () => {
        this.entityData.offset = this.entityData.offset + (this.entityData.offset === 0 ? this.entityData.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForManageGroupDriver) {
          this.searchEntity();
        }
      };
      this.applyInfiniteScroll('.all-drivers-list', cb)
    }, 30);
  }

  ngOnChanges() {

  }

  searchEntity() {
    const country = this.getCountry();
    this.resetDriverDataIfNeeded();
    this.entityData.countryCode = country?.countryCode;
  
    this.driverService.searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityData).subscribe((res: DriverList) => {
      this.handleSearchResponse(res);
    });
  }

  private getCountry() {
    const countryData = localStorage.getItem(AppSettings.COUNTRY);
    return countryData ? JSON.parse(countryData)[0] : null;
  }

  private resetDriverDataIfNeeded() {
    if (this.entityData.offset === 0) {
      this.allDriverData = [];
    }
  }

  private handleSearchResponse(res: DriverList) {
    this.listColumns = res?.data[0]?.values;
    
    const observables = this.createImageObservables(res.data);
    
    forkJoin(observables).subscribe(
      allDriverDataData => {
        this.updateDriverData(allDriverDataData, res);
      },
      (error: any) => {
        console.error('Error fetching event images:', error);
      }
    );
  }

  private createImageObservables(data: any[]) {
    return data.map((element: any) => 
      this.getEventImage(element?.values?.driver_profile_image?.[0] || null).pipe(
        map(base64data => ({
          ...element,
          icon: base64data
        }))
      )
    );
  }

  private updateDriverData(allDriverDataData: any[], res: DriverList) {
    const allDriverData = allDriverDataData?.map(data => {
      const relatedDrivers = data?.relatedData?.map(relatedData => ({
        id: relatedData?.id,
        ...relatedData?.values
      })) || [];
      return { id: data.id, ...data.values, drivers: relatedDrivers, icon: data.icon };
    }) || [];
  
    this.allDriverData = [...this.allDriverData, ...allDriverData];
  
    // Check if no data is returned
    if (res?.data.length === 0) {
      this.maxDataReached.entityDataForManageGroupDriver = true;
    }
  
    // Determine if max data is reached
    if (!this.maxDataReached.entityDataForManageGroupDriver) {
      this.allDriverDataTemp = this.allDriverData;
      this.maxDataReached.entityDataForManageGroupDriver = this.allDriverDataTemp.length >= res.count;
    }
  
    this.driverListCount = this.allDriverData.length;
    this.allDriverDataTemp = this.allDriverData;
    
    this.markSelectedDrivers();
  }

  private markSelectedDrivers() {
    this.allDriverData.forEach(ele => {
      if (this.selectedDriver?.includes(ele.id)) {
        ele.selected = true;
        this.selectedColumns.push(ele);
      }
    });
  }

  getEventImage(eventImage): Observable<string> {
    if (eventImage) {
      return this.getImage(eventImage);
    } else {
      return of(this.defaultIconUrl);
    }
  }
  
  getImage(eventImage): Observable<string> {
    return new Observable(observer => {
      this.driverService.getFile(eventImage, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
        const file = new File([result], 'image');
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          observer.next(reader.result as string);
          observer.complete();
        };
      });
    });
  }

  getDriverData() {
    this.selectedColumns = this.manageColumnFromLocal ? this.selectedColumns : [];
    this.cdr.markForCheck();
    this.targetProducts = [];
  }

  dragStart(product: Column) {
    this.draggedProduct = product;
  }

  dragEnd() {
    this.draggedProduct = null;
  }

  findIndex(product: Column) {
    let index = -1;
    for (let i = 0; i < (this.driverListCount as Column[]).length; i++) {
      if (product.field === (this.driverListCount as Column[])[i].field) {
        index = i;
        break;
      }
    }
    return index;
  }

  onSearch(event, allDriverData) {
    const value = event.target.value.toLowerCase();
    if (allDriverData) {
      this.allDriverData = this.allDriverDataTemp.filter(ele => {
        const firstName = ele?.first_name ? ele.first_name.toLowerCase() : '';
        const lastName = ele?.last_name ? ele.last_name.toLowerCase() : '';
        return firstName.includes(value) || lastName.includes(value);
      });
    }
  }

  onHide(event) {
    this.isShow = false;
    this.onToggleOverLay.emit(this.isShow);
  }

  drop1(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  filterResults(text) {
    if (!text) {
      return;
    }
    this.driverListCount = this.driverListCount.filter(
      col => col?.header.toLowerCase().includes(text.toLowerCase())
    );
  }

  pushToSelectedColumns(selectedCol) {
    if (selectedCol.selected) {
      this.selectedColumns.push(selectedCol);
    } else {
      this.selectedColumns = this.selectedColumns.filter(function(el) {
        return el.selected === true;
      });
    }
    this.onChangeForSelectedDriver();
  }

  removeFromSelectedColumn(index, removedColumns) {
    const deletedColumn = this.selectedColumns.splice(index, 1);
    this.allDriverData = this.allDriverData.map(ele => {
      if (deletedColumn[0].id === ele.id) {
        ele.selected = false;
        return ele;
      }
      return ele;
    })
    this.onChangeForSelectedDriver();
  }

  onChangeForSelectedDriver() {
    this.onChangeSelectedDriver.emit(this.selectedColumns)
  }

  applyInfiniteScroll(selector, onEndPageCallback) {
    const contentEl = document.querySelector(selector) as HTMLElement;

    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const endOfPage = Math.round(contentEl.scrollTop) === heightDiff || Math.round(contentEl.scrollTop) === (heightDiff + 1);

      if (endOfPage) {
        onEndPageCallback()
      }
    };

    contentEl.addEventListener("scroll", handleInfiniteScroll)
  }

  clearSelectedColumns () {
    this.selectedColumns = [];
    this.allDriverData = this.allDriverData.map(col => {
      col.selected = false
      return col;
    });
  }

}
