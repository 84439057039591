import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppSettings } from '../../../shared/app.settings';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { MenuItem, MessageService } from 'primeng/api';
import { AttributeData, entityResponse } from '../../../vehicles/modal/attributeModal';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { EntityService } from '../../../shared/services/entity.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

import * as _ from 'lodash';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-driver-driving-record-entity',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, ToastModule],
  templateUrl: './driver-driving-record-entity.component.html',
  styleUrl: './driver-driving-record-entity.component.scss'
})
export class DriverDrivingRecordEntityComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() tab;
  @Input() activeIndex;
  @Input() attributeLabels = {};
  @Input() moduleName: string;
  @Input() submitBtnText: string;
  @Input() previousBtnText: string;
  @Input() attributeValues = [];
  @Input() attributeData: any;
  @Input() driverId: string;
  @Input() saveBtnId: string;
  routePath: MenuItem[] = [];
  data: AttributeData;
  driverAttributeData: any;
  country: Country;
  language: Language;
  prefixForOneToManyRelatedFields: any = '_';
  driverDrivingRecord: any[] = [];
  driverDrivingRecordFields: any = 3;
  start: number = 0;
  end: number;
  entityId: string;
  driverDrivingRecordEntityIdList: any[] = [];
  driverDrivingRecordData = {data: []};
  attributeIdForDriverDrivingRecord: any;
  driverDetails: any;
  isReady: boolean = false;
  driverAttributeLength: number;
  edit: boolean = false;
  driverData: any;  
  removedDriverDrivingRecordId;
  removedIndex;
  relationshipDataForDriverDrivingRecord: any[] = [];

  constructor(private driverService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    public appSettings: AppSettings) { }
    
  ngOnInit() {
    this.setLabels();
    this.setDriverId();
    this.setCountryAndLanguage();
    this.loadDriverData();
    this.setRoutePath();
  }

  setLabels() {
    this.submitBtnText = this.cs.getLabel('lbl_next');
    this.previousBtnText = this.cs.getLabel('lbl_previous');
  }

  setDriverId() {
    this.driverId = this.route.snapshot.paramMap.get('id');
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadDriverData() {
    if (this.driverId) {
      this.driverService
        .getEntity(this.driverId, AppSettings.ENTITY_CODE.DRIVER)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.driverDetails = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.parseAttributeValues();
            this.getDriverDrivingRecordData(this.attributeValues);
          }
          this.getAttributes();
        });
    } else {
      this.getAttributes();
    }
  }

  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.START_TIME,
      AppSettings.DATE_ATTRIBUTE_IDS.END_TIME
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const driverDrivingRecordIdArray = this.driverDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.FIELDS.DRIVER_DRIVING_RECORD_SECTION);
    this.driverDrivingRecordEntityIdList = (driverDrivingRecordIdArray && driverDrivingRecordIdArray.attributeValue.length > 0) ? driverDrivingRecordIdArray.attributeValue : [];
  }

  getDriverDrivingRecordData(attributeValues) {
    const attributeValueArray = attributeValues[AppSettings.GROUP_CODE.DRIVER_DRIVING_RECORD];
    for (const key in attributeValueArray) {
      if (Object.prototype.hasOwnProperty.call(attributeValueArray, key)) {
        const value = attributeValueArray[key];
        this.driverDrivingRecordEntityIdList.push(value)
      }
    }
    const uniqueDriverDrivingRecordEntityIdList = Array.from(new Set(this.driverDrivingRecordEntityIdList));
    if (uniqueDriverDrivingRecordEntityIdList.length > 0) {
      this.edit = true;
      let index = 1000;
      for (let i = 0; i < uniqueDriverDrivingRecordEntityIdList.length; i++) {
        this.driverService.getEntity(uniqueDriverDrivingRecordEntityIdList[i], AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD).subscribe((res: any) => {
          this.setAttributeValuesForDriverDrivingRecordEditMode(res, index);
          index = index + 1;
        });
      }
    }
  }

  setAttributeValuesForDriverDrivingRecordEditMode(data, index) {
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[index + '_' + labelsObj.attributeCode] = 
        labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE ? new Date(labelsObj.attributeValue) : labelsObj.attributeValue;
    }
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.DRIVER;
    this.driverService.getAttributeDefinition(entityType, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.driverAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.driverAttributeLength = this.driverAttributeData.tabs.length;
        this.attributeIdForDriverDrivingRecord = this.getAttributeIdForGroupCode('driving_record');
        this.isReady = true;
      }
    });
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.driverAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  onRemoveDriverDrivingRecordEntity(event) {
    this.removedIndex = event[1];
    this.removedDriverDrivingRecordId = typeof event[0] === 'string' ? event[0] : String(event[0]);
    this.driverDrivingRecordEntityIdList = this.driverDrivingRecordEntityIdList.filter(id => id !== this.removedDriverDrivingRecordId);
    this.saveDriverDrivingRecord();
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('lbl_add_new_driver'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.driverId ? this.cs.getLabel('lbl_edit_driver') : this.cs.getLabel('driver.add'),
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  onSaveOtherDetails(event) {
    const driverFormData = event;
    this.driverDrivingRecord = [];
    this.driverData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };
    const removedDriverFormData = this.cs.removeDocumentByIndex(driverFormData, this.removedIndex);
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE, AppSettings.DATE_ATTRIBUTE_IDS.START_TIME, AppSettings.DATE_ATTRIBUTE_IDS.END_TIME];
      for (const [key, value] of Object.entries(removedDriverFormData)) {
        let attributeValue = value;
        const arr = key.split('_');
        if (dateIds.includes(key)) {
          attributeValue = this.cs.convertToTimestamp(value as any);
        }
        if (parseInt(arr[0]) >= 0) {
          this.setAttributeValuesForDriverDrivingRecord(arr[1], attributeValue);
        } else {
          this.setAttributeValuesForOtherDetails(key, attributeValue);
        }
      }
    this.driverData.data = this.cs.mapAttributeIds(this.driverData.data, this.driverAttributeData.tabs);
    this.saveDriverDrivingRecord();
  }
    
  setAttributeValuesForOtherDetails(key, attributeValue) {
    if (attributeValue) {
      this.driverData.data.push({
        attributeCode: key,
        attributeValue
      });
    }
  }

  setAttributeValuesForDriverDrivingRecord(key, attributeValue) {    
    const attributeCode = key.substring(key.indexOf('_') + 1);
    if (attributeValue) {
      const driverRecordDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE;
      const startTime = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.START_TIME;
      const endTime = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.END_TIME;
      const value = driverRecordDate || startTime || endTime ? new Date(attributeValue).getTime() : attributeValue;
      this.driverDrivingRecord.push({ attributeCode, attributeValue: value });
    }
  }

  saveDriverDrivingRecord(){
    this.driverDrivingRecord = this.cs.mapAttributeIds(this.driverDrivingRecord, this.driverAttributeData.tabs);
    const { countryCode } = this.country[0] || {};
    const numberOfDriverDrivingRecord = this.driverDrivingRecord.length / this.driverDrivingRecordFields;
    this.start = 0;
    this.end = this.driverDrivingRecordFields;
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: AppSettings.TENANT_CODE,
      entityCode: AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD,
    }
    for (let i = 0; i < numberOfDriverDrivingRecord; i++) {
      const driverDrivingRecord = {
        forTenantCode: AppSettings.TENANT_CODE,
        entityCode:  AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD,
        countryCode: countryCode,
        languageCode: this.language[0].langCode,
        data: this.driverDrivingRecord.slice(this.start, this.end),
      };
      this.start = this.end;
      this.end = this.end + this.driverDrivingRecordFields;
      if (this.driverDrivingRecordEntityIdList.length > 0) {
        this.saveAttributeDataForDriverDrivingRecordEditMode(driverDrivingRecord, this.driverDrivingRecordEntityIdList[i], i, numberOfDriverDrivingRecord, entitiesData);
      } else {
        this.createEntityForDriverDrivingRecord(entitiesData, driverDrivingRecord, i, numberOfDriverDrivingRecord);
      }
    }
    this.saveDriverApiCall(this.driverData.entityCode);
  }
  
  createEntityForDriverDrivingRecord(entitiesData, driverDrivingRecord, index, numberOfDriverDrivingRecord) {
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe({
      next: (res: entityResponse) => {
        const entityId = res.entityId;
        this.driverDrivingRecordEntityIdList.push(res.entityId);
        driverDrivingRecord.data = this.cs.mapAttributeIdsForRelatedData(driverDrivingRecord.data, this.attributeData.tabs);
        this.driverService.saveAttributeData(AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD, entityId, driverDrivingRecord).subscribe((res: any) => {
          this.messageService.add({ key:'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message)});
        });
        const uniqueDriverDrivingRecordEntityIdList = Array.from(new Set(this.driverDrivingRecordEntityIdList));
        this.relationshipDataForDriverDrivingRecord.push(
          {
            entityRelationshipConfigId: this.driverAttributeData?.tabs[1]?.groups.find(ele => ele.code === 'driving_record')?.relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if(index === (numberOfDriverDrivingRecord - 1)) {
          this.driverData.data.push({
            attributeId: this.attributeIdForDriverDrivingRecord,
            attributeValue: uniqueDriverDrivingRecordEntityIdList
          })
          this.saveDriverData(this.driverData);
        }
      },
      error: (error) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: this.cs.getLabel(error)});
      }
    });
  }

  saveAttributeDataForDriverDrivingRecordEditMode(driverDrivingRecord, entityId, index, numberDriverDrivingRecord, entitiesData) {
    driverDrivingRecord.data = this.cs.mapAttributeIdsForRelatedData(driverDrivingRecord.data, this.attributeData.tabs);
    if (entityId) {
      this.driverService.saveAttributeData(driverDrivingRecord.entityCode, entityId, driverDrivingRecord).subscribe((res: any) => {
        this.relationshipDataForDriverDrivingRecord.push(
          {
            entityRelationshipConfigId: this.driverAttributeData?.tabs[1]?.groups.find(ele => ele.code === 'driving_record')?.relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberDriverDrivingRecord - 1)) {
          const uniqueDriverDrivingRecordEntityIdList = Array.from(new Set(this.driverDrivingRecordEntityIdList));
          this.driverData.data.push({
            attributeId: this.attributeIdForDriverDrivingRecord,
            attributeValue: uniqueDriverDrivingRecordEntityIdList
          });
          this.saveDriverDrivingDataToDb();
        }
      });
    } else {
      this.createEntityForDriverDrivingRecord(entitiesData, driverDrivingRecord, index, numberDriverDrivingRecord);
    }
  }

  saveDriverDrivingDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: AppSettings.TENANT_CODE,
      entityCode: this.data.entityCode
    }
    this.driverData.relationshipData = this.relationshipDataForDriverDrivingRecord;
    if (this.driverId) {
      this.saveAttributeData(entitiesData.entityCode)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode) {
    this.driverService.saveAttributeData(entityCode, this.driverId, this.driverData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
    });
  }



  createEntityAndUpdateAttributeData(entitiesData) {
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.driverId = res.entityId;
      this.driverService.saveAttributeData(entitiesData.entityCode, this.driverId, this.driverData).subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
      });
    });
  }

  saveDriverData(driverData) {
    this.driverData.relationshipData = this.relationshipDataForDriverDrivingRecord;
    this.driverData.data = this.cs.mapAttributeIds(this.driverData.data, this.driverAttributeData.tabs);
    if (this.driverId) {
      this.saveDriverApiCall(this.driverData.entityCode);
    } else {
      this.createDriverEntity();
    }
  }

  saveDriverApiCall(entityCode) {
    this.driverService.saveAttributeData(entityCode, this.driverId, this.driverData).subscribe({
      next: (res: any) => {
        this.messageService.add({key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
        setTimeout(() => {
          this.onNextClick();
        }, 500);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  createDriverEntity() {
    this.driverService.createEntities(this.driverData.entityCode, this.driverData).subscribe({
      next: (res: entityResponse) => {
        this.driverId = res.entityId;
        this.saveDriverApiCall(this.driverData.entityCode);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onPreviousClick(event) {
    this.previousClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }

  getLastIndex(): number {
    return this.driverAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/drivers/list']);
  }
}
