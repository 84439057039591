import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, FormGroup, FormsModule, PatternValidator, ReactiveFormsModule, Validators } from '@angular/forms';

import { AutoFocusDirective } from 'app/modules/shared/directives/auto-focus.directive';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MessageService } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { Language } from 'app/modules/shared/models/language';
import { AppIcons } from 'app/modules/shared/app.icons';
import { Timezone } from 'app/modules/shared/time-zone';
import { CheckboxModule } from 'primeng/checkbox';
import { passwordMatchValidator } from '../../../../../modules/shared/utility-functions/passwordMatch';
import { LoginModal } from 'app/modules/settings/modal/loginModal';
import { SettingsService } from 'app/modules/settings/settings.service';
import { Country } from 'app/modules/shared/models/country';
import { AttributeData } from 'app/modules/vehicles/modal/attributeModal';
import { EntityList } from 'app/modules/vehicles/modal/entityModal';
import { ListResponse } from 'app/modules/vehicles/modal/listResponse';

@Component({
  selector: 'app-settings-login-and-password',
  standalone: true,
  imports: [TranslateModule, ButtonModule, ToastModule, InputTextModule, CheckboxModule, ReactiveFormsModule, FormsModule, TabViewModule, DropdownModule],
  templateUrl: './settings-login-and-password.component.html',
  styleUrl: './settings-login-and-password.component.scss',
  providers: [AutoFocusDirective]
})
export class SettingsLoginAndPasswordComponent implements OnInit{
  @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
  miIcons = AppIcons;
  loginForm: FormGroup;
  changePasswordForm: FormGroup;
  updateLabel = this.cs.getLabel('lbl_update');
  changePasswordLabel = this.cs.getLabel('lbl_change_password');
  editLabel: string = this.cs.getLabel('settings.edit');
  deleteLabel: string = this.cs.getLabel('settings.delete');
  deleteHeaderMsg: string = this.cs.getLabel('settings.events.delete_header');
  deleteMsg: string = this.cs.getLabel('settings.events.delete_msg');
  userDetails: any;
  userId: number;
  countries: any[] | undefined;
  selectedCountry: any;
  userName: string;
  language: Language;
  timezoneList;
  selectedTimezone: any;;
  country: Country;
  data: AttributeData;
  userProfileAttributeData;
  attributeIdForUserProfile;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted:  AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getLoggedInTenantCode(),
  };

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder,
    private configService: ConfigService, private entityService: EntityService, private settingsService: SettingsService,
    private messageService: MessageService,) {
  }

  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.getTimeZone();
    this.onShowLogin();
    this.formValidation();
    this.getUserId();
  }

  getTimeZone() {
    this.entityService.getTimeZones().subscribe(res => {
      this.timezoneList = res;
    })
  }

  formValidation() {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      timezones: [''],
      emailUpdates: [false],
    });

    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern(AppSettings.PASSWORD_PATTERN)]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: passwordMatchValidator });
  }

  onShowLogin() {
    if (this.nameInput) {
      this.nameInput?.nativeElement?.focus();
    }
  }

  getUserId() {
    const profileData = JSON.parse(localStorage.getItem(AppSettings.PROFILE_DATA));
    this.userName = profileData.username;
    this.userId = profileData.userId;
    if(this.userId) {
      this.getUserDetails(this.userId);
      this.getAttributes();
    }
  }
  
  getUserDetails(userId) {
    this.entityService.getUserDetails(userId, this.configService.getForTenantCode()).subscribe((result : LoginModal) => {
      this.userDetails = result;
      this.loginForm.controls['userName'].setValue(this.userDetails.username);
      this.loginForm.controls['email'].setValue(this.userDetails.email);
    })
  }

  getAttributes() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.USER_DETAIL, this.country[0].tenantId, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.userProfileAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.attributeIdForUserProfile = this.getAttributeId('user_id');
        this.searchEntity(this.attributeIdForUserProfile);
      }
    });
  }

  getAttributeId(attributeCode) {
    const fields = this.userProfileAttributeData.tabs[0].groups[0].fields;
    const field = fields.find(field => field.attributeCode === attributeCode);
    return field ? field.attributeId : null;
  }

  searchEntity(attributeIdForUserProfile) {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.filters.push({
      attributeId: attributeIdForUserProfile,
      attributeValue: this.userId
    });
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.USER_DETAIL, this.entityData).subscribe((res: any) => {
      const timezone = res?.data[0]?.values?.timezone;
      if (timezone) {
        this.selectedTimezone = timezone;
        this.patchTimezone(timezone);
      }
      this.loginForm.patchValue({
        emailUpdates: res.data[0].values.email_update
      });
    })
  }

  patchTimezone(timezone: string) {
    const timezoneObj = this.timezoneList?.find(tz => tz.timezone === timezone);
    if (timezoneObj) {
      this.loginForm.get('timezones').setValue(timezoneObj);
    }
  }

  onCancel() {
    this.loginForm.reset();
    this.onShowLogin();
  }

  onAddLoginInfo() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    if (this.loginForm.valid) {
      const reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: country[0].countryCode,
        languageCode: this.language[0].langCode,
        userName: this.loginForm.value.userName,
        email: this.loginForm.value.email,
        timezone: this.loginForm.value.timezones.timezone ? this.loginForm.value.timezones.timezone : '',
        emailUpdates: this.loginForm.value.emailUpdates,
      }
      this.settingsService.updateLoginInformationById(this.userId, reqBody).subscribe((res: any)=> {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
      },
      error => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

  onChangePassword() {
    if (this.changePasswordForm.valid) {
      const reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        currentPassword: this.changePasswordForm.value.currentPassword,
        newPassword: this.changePasswordForm.value.newPassword,
      }
      this.settingsService.updatePasswordById(this.userId, reqBody).subscribe((res: any)=> {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
      },
      error => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      });
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

}
