<div class="help-tips-text"> {{(field.inputCode === 'fileImg' && field.attributeCode !== 'vehicle_images') ? '' : cs.getLabelValue(moduleName +'.fields.'+ field.attributeCode + '.example',attributeLabels, '') }}</div>
@if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field?.attributeCode]?.invalid && (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field?.attributeCode]?.dirty || formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode]?.touched)) {
  <div class="alert">
    @if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field?.attributeCode].errors.required && field.validation.required) {
      <div>
        <i class="error_outline"></i>
        {{ cs.getLabelValue(moduleName +'.fields.'+ field.attributeCode + '.validation.required',attributeLabels, '') }}
      </div>
    }
    @if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode].errors.min && field.validation.min) {
      <div>
        <i class="error_outline"></i>
        {{ cs.getLabelValue(moduleName +'.fields.'+ field.attributeCode + '.validation.min',attributeLabels, '') }}
      </div>
    }

    @if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode].errors.max && field.validation.max) {
      <div>
        <i class="error_outline"></i>
    {{cs.getLabelValue(moduleName +'.fields.'+field.attributeCode+'.validation.max',attributeLabels,
    'Maximum '+field.validation.max+' character limit exceeded')}}
      </div>
    }

    @if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode].errors.minlength && field.validation.min) {
      <div>
        <i class="error_outline"></i>
        {{ cs.getLabelValue(moduleName +'.fields.'+ field.attributeCode + '.validation.min',attributeLabels, '') }}
      </div>
    }

    @if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode].errors.maxlength && field.validation.max) {
      <div>
        <i class="error_outline"></i>
    {{cs.getLabelValue(moduleName +'.fields.'+field.attributeCode+'.validation.max',attributeLabels,
    'Maximum '+field.validation.max+' character limit exceeded')}}
      </div>
    }

    @if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode].errors.pattern && field.validation.pattern) {
      <div>
        <i class="error_outline"></i>
        {{cs.getLabelValue(moduleName +'.fields.'+field.attributeCode+'.validation.pattern',attributeLabels,'Invalid')}}
      </div>
    }
    @if (formName.controls[field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode].errors.unique && field.validation.unique) {
      <div>
        <i class="error_outline"></i>
        {{cs.getLabelValue(moduleName +'.fields.'+field.attributeCode+'.validation.unique',attributeLabels,'Invalid')}}
      </div>
    }
  </div>
}