<div class="flex flex-column p-3 pl-4 pt-0">
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.name</div>
        <div class="w-6">
            <span><img src="../../../../../assets/images/mi-theme/light/basic/Rectangle.png" alt="" class="inline-block vertical-align-middle mr-2"></span>
            <span class="font-14 font-bold default-text-black-color" translate>{{ driverDetailItem.drivers[0]?.name_code }}</span>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.group</div>
        <div class="w-6">
            <div class="font-14 font-medium">{{ driverDetailItem.drivers[0]?.group }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.capacity</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ driverDetailItem.drivers[0]?.passenger_capacity }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.vehicle_type</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ driverDetailItem.drivers[0]?.vehicle_type }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.body_type</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ driverDetailItem.drivers[0]?.body_type }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.make_model</div>
        <div class="w-6">
            <div class="font-14 font-medium">{{ driverDetailItem.drivers[0]?.manufacturer }} {{ driverDetailItem.drivers[0]?.model }} {{ driverDetailItem.drivers[0]?.year_of_manufacturing }}</div>
        </div>
    </div>
</div>
