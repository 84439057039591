<form [formGroup]="formName">
    <div class="grid pt-2 pl-2">
        <div class="col-auto">
            @if (showSelectButton) {
            <p-selectButton [options]="locationTypeList"  [(ngModel)]="selectedButton" (onChange)="onChange($event)" optionLabel="labelKey"
            optionValue="labelValue" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" unselectable="true" class="w-full">
                <ng-template let-item let-index pTemplate>
                    <label class="p-button-label pointer"> {{item.labelValue}}</label>
                    @if (item.index > 2) {
                        <i #remove class="mi-lg more_vertical" (click)="menu.toggle($event)"></i>
                    }

                </ng-template>
            </p-selectButton>
            }
        </div>
        <div class="col-auto align-items-center flex add-more-location">
            <div class="add-more align-self-center" (click)="addMore()">
                <i class="mi-lg mi-add"></i>
                <label class="pointer" translate>lbl_add_more_locations</label>
            </div>
        </div>
    </div>
</form>

<p-dialog [header]="header" [(visible)]="showAddPopup" [style]="{width: '50vw'}">
    <form [formGroup]="locationNameGroup">
        <div class="pt-3 justify-content-start">
            <label class="label">Location Name</label><span class="required">*</span>
        </div>
        <div class="py-2 justify-content-start">
            <input (blur)="locationNameBlur()" formControlName="name" type="text" pInputText />
            @if (locationNameGroup.controls.name.errors && (locationNameGroup.controls.name.dirty || locationNameGroup.controls.name.touched)) {
                <div class="form-errors text-xs">
                <div [hidden]="!locationNameGroup.controls.name.errors.required" class="text-err" translate>
                    passengers.location_name_is_required
                </div>
            </div>
            }
        </div>
    </form>
    <div class="flex py-2 justify-content-end">
        <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer"
            [label]="'cancel' | translate" (click)="showAddPopup = false"></button>
        <p-button class="border-round-sm" type="submit" [disabled]="!locationNameGroup.valid" (click)="AddUpdateLocation()" [label]="isEditLabel ? ('lbl_save' | translate) : ('lbl_add' | translate)" pAutoFocus
            [autofocus]="true"></p-button>
    </div>
</p-dialog>

<p-toast></p-toast>
<p-menu #menu [model]="items" [popup]="true"></p-menu>