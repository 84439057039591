import { CommonModule, NgClass } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TimelineModule } from "primeng/timeline";
import { HideButtonComponent } from "../hide-button/hide-button.component";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-upcoming-journey",
  templateUrl: "./upcoming-journey.component.html",
  standalone: true,
  imports: [
    TimelineModule,
    NgClass,
    TranslateModule,
    HideButtonComponent,
    CommonModule,
  ],
})
export class UpcomingJourneyComponent implements OnInit {
  @Input() upcomingJourneyData: any;
  cardId: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.upcoming_journey");
  }
}
