import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-mi-read-only-text',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, KeyFilterModule,NgClass],
  templateUrl: './mi-read-only-text.component.html',
  styleUrl: './mi-read-only-text.component.scss'
})
export class MiReadOnlyTextComponent {
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() pKeyFilter: string = null;
  formName: FormGroup;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }
}
