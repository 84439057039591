import { DatePipe, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import CommunicationJson from '../../../../../assets/json/communication/individual.json';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { FilterPipe } from '../../../shared/pipes/filter.pipe';
import { CommunicationService } from '../../services/communication.service';
import { ProfileImageComponent } from '../profile-image/profile-image.component';

@Component({
  selector: 'app-individual-messaging',
  templateUrl: './individual-messaging.component.html',
  styleUrls: ['./individual-messaging.component.scss'],
  standalone: true,
  imports: [NgClass, ProfileImageComponent, TranslateModule, DatePipe, FilterPipe]
})
export class IndividualMessagingComponent implements OnInit {
  miIcons = AppIcons;
  profileData = CommunicationJson as any;
  searchValue;

  constructor(private communicationService: CommunicationService, public appSettings: AppSettings) { }

  ngOnInit() {
    this.profileData[0].active = true;
    this.communicationService.searchValue.subscribe(res => {
      this.searchValue = res;
    });
  }

  activateClass(data) {
    this.profileData.forEach(element => {
      element.active = false;
      if (data.id === element.id) {
        element.active = true;
        this.communicationService.profileIndividualData.next(data);
      } else {
        element.active = false;
      }
    });
  }

}
