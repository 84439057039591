@if(hideService.getVisibilityState(cardId) | async){
<div class="card shadow-card">
  <div class="flex justify-content-between align-items-center mb-5">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.upcoming_journey
    </h5>
    <app-hide-button [cardId]="cardId"></app-hide-button>
  </div>

  <div class="upcoming-journey-container">
    <p-timeline [value]="upcomingJourneyData">
      <ng-template pTemplate="marker" let-event>
        <span
          class="flex z-1 w-1rem h-1rem align-items-center justify-content-center text-white border-circle shadow-2"
          [style.backgroundColor]="event.color"
        >
          <i [ngClass]="event.icon"></i>
        </span>
      </ng-template>
      <ng-template pTemplate="content" let-event>
        <div class="flex flex-row justify-content-between">
          <div>{{ event.date }}</div>
          <div class="journey-card">
            <span class="mi-lg mi-car wh-24 stepper-car"></span>
            <span class="font-20 font-bold">{{ event.journey.car }}</span>
            <span class="separator">|</span>
            <span
              [ngClass]="{
                'journey-code': event.journey.code !== 0,
                'journey-code-empty': event.journey.code === 0
              }"
              >{{ event.journey.code }}C</span
            >
          </div>
        </div>
      </ng-template>
    </p-timeline>
  </div>
</div>
}
