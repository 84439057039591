import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-group-color',
  standalone: true,
  imports: [],
  templateUrl: './group-color.component.html',
  styleUrl: './group-color.component.scss'
})
export class GroupColorComponent {
  @Input() colorCode: string;
  @Input() moduleName: string;
}
