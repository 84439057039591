import { Component, Input, OnInit } from "@angular/core";
import { HideButtonComponent } from "../hide-button/hide-button.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-emergency",
  templateUrl: "./emergency.component.html",
  standalone: true,
  imports: [HideButtonComponent, TranslateModule, CommonModule],
})
export class EmergencyComponent implements OnInit {
  @Input() emergencyStatusData: any;
  cardId: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.emergency_status");
  }
}
