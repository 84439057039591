import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [ButtonModule, TranslateModule, DialogModule, InputTextModule, FormsModule, ReactiveFormsModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent  implements OnInit  {
  @Input() field: any;
  @Input() buttonLabel = 'Button';
  @Input() attributeLabels: [];
  @Input() moduleName: string;

  formName: FormGroup;
  placeholder: any;
  showPopup: boolean = false;
  resetPasswordGroup: FormGroup;
  header: any;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private fb: FormBuilder) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.buttonLabel = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.label', this.attributeLabels, null);
    this.header = this.cs.getLabel('lbl_confirm_action')

    this.resetPasswordGroup = this.fb.group({
      value: ['', [Validators.required]]
    });
  }

  btnClick() {
    this.showPopup = true;
  }

  setValue() {
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.resetPasswordGroup.controls['value'].value);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
    this.showPopup = false;
  }

}
