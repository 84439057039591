<div class="flex flex-column">
    <p-tabMenu [model]="items" [activeItem]="items[0]">
      <ng-template pTemplate="item" let-item>
            <div class="p-menuitem-link flex flex-row align-items-center cursor-pointer mr-5">
                <span> {{ item.label }}</span>
            </div>
      </ng-template>
    </p-tabMenu>

    <div class="driver-details-container">
      @if(selectedTab === 'DRIVER'){
        <app-driver-tab [driverDetailItem]="driverDetailItem"></app-driver-tab>
      }
      @if(selectedTab === 'SCHEDULE'){
        <app-schedule-tab></app-schedule-tab>
      }
      @if(selectedTab === 'VEHICLE'){
        <app-vehicle-tab [driverDetailItem]="driverDetailItem"></app-vehicle-tab>
      }
    </div>
</div>